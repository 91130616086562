import React from "react";

const ECComposition = (props) => {
  const { handleChange, ec_composition_EC, navigateECComposition, ec_composition_members, removeMember } = props;

  const arrangeItems = (arr) =>{
    let len = arr && arr.length;
    if(len === 3 || len > 3){
      return [arr[arr.length - 1],arr[arr.length - 2],arr[arr.length - 3]]
    } else if(len === 2){
      return [arr[arr.length - 1],arr[arr.length - 2]]
    } else if(len === 1){
      return [arr[arr.length - 1]]
    }
  }

  return (
    <div className="my-4 text-left">
      {/* <div className="col-lg-4">
        <p className="mb-0">Members</p>
        <input
          onChange={handleChange}
          type="text"
          name="ec_composition_EC"
          value={ec_composition_EC}
          placeholder="Number of Members"
          className="form-control"
        ></input>
      </div> */}
      <p style = {{ textDecoration: 'underline', cursor:'pointer', color: "#BC5405"}} className="mb-0" onClick={()=>navigateECComposition()}>{ec_composition_members && ec_composition_members.length > 0 ? "View All" : "Add"}</p>
      {ec_composition_members && ec_composition_members.length > 0 ? (
        arrangeItems(ec_composition_members).map((ec_member, i) => (
          <div className="row my-2">
            <div className="col-lg-2">
                <p className="mb-0">Role</p>
                <div className="d-flex align-items-center justify-content-center">
                <select
                  // onChange={this.handleChange}
                  disabled={true}
                  type="text"
                  name={ec_member.id + "role"}
                  value={ec_member[ec_member.id + "role"]}
                  className={`form-control ${ec_member[ec_member.id+"role"] === "Other" ? "col-lg-6" : ""} `}
                >
                  <option>Please select</option>
                  <option>Clinician</option>
                  <option>Chair person</option>
                  <option>Member Secretary</option>
                  <option>Basic Medical Scientist</option>
                  <option>Legal Expert</option>
                  <option>Lay Person</option>
                  <option>Social Scientist</option>
                  <option>Subject Expert</option>
                  <option>Other</option>
                </select>
                {ec_member[ec_member.id+"role"] === "Other" ? 
                <input
                  // onChange={this.handleChange}
                  disabled={true}
                  type="text"
                  name={ec_member.id + "roleName"}
                  value={ec_member[ec_member.id + "roleName"]}
                  placeholder="Role"
                  className="form-control col-lg-6"
                ></input> : null }
                </div>
              </div>
            <div className="col-lg-2">
              <p className="mb-0">Name</p>
              <input
                //onChange={handleChange}
                disabled={true}
                type="text"
                name={ec_member.id + "name"}
                value={ec_member[ec_member.id + "name"]}
                placeholder="Name"
                className="form-control"
              ></input>
            </div>
            <div className="col-lg-2">
              <p className="mb-0">Qualification</p>
              <input
                //onChange={handleChange}
                disabled={true}
                type="text"
                name={ec_member.id + "qualification"}
                value={ec_member[ec_member.id+ "qualification"]}
                placeholder="Qualification"
                className="form-control"
              ></input>
            </div>
            <div className="col-lg-2">
              <p className="mb-0">Experience</p>
              <input
                //onChange={handleChange}
                disabled={true}
                type="text"
                name={ec_member.id + "experience"}
                value={ec_member[ec_member.id + "experience"]}
                placeholder="Experience"
                className="form-control"
              ></input>
            </div>
            <div className="col-lg-2">
              <p className="mb-0">Affiliation</p>
              <input
                //onChange={handleChange}
                disabled={true}
                type="text"
                name={ec_member.id + "affiliation"}
                value={ec_member[ec_member.id + "affiliation"]}
                placeholder="Affiliation"
                className="form-control"
              ></input>
            </div>
            <div className="col-lg-2">
              <p className="mb-0">Other</p>
              <input
                //onChange={handleChange}
                disabled={true}
                type="text"
                name={ec_member.id + "other"}
                value={ec_member[ec_member.id + "other"]}
                placeholder="Other"
                className="form-control"
              ></input>
            </div>
            {/* <h6 onClick={()=>removeMember(ec_member.id)} style={{fontSize:20,fontStyle:'bold',color:'red'}} className="mb-0 mt-4">x</h6> */}
          </div>
        ))
      ) : (
        <p className="mb-0">
          Please click above to continue ...
        </p>
      )}
      
    </div>
  );
};

export default ECComposition;
