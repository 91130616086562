import React from "react";
const ContactDetail = (props) => {
  const {
    handleChange,
    handlePrimaryMailChange,
    handleAlternateMailChange,
    pincode,
    primary_contact_name,
    primary_contact_email,
    primary_contact_contact_number,
    alternative_contact_name,
    alternative_contact_email,
    alternative_contact_contact_number,
    showPrimaryEmailError,
showAlternateEmailError,
  } = props;
  return (
    <div className="">
      <div className="col-lg-6 text-left px-0 pr-3">
        <p className="mb-0">Committee Pincode</p>
        <input
          onChange={handleChange}
          name="pincode"
          value={pincode}
          type="text"
          placeholder="Pincode"
          className="form-control my-2"
        ></input>
      </div>
      <div className="row text-left">
        <div className="col-lg-6">
          <div>
            <p className="mb-0">Primary Contact Person</p>
            <input
              onChange={handleChange}
              name="primary_contact_name"
              value={primary_contact_name}
              type="text"
              placeholder="Name"
              className="form-control my-2"
            ></input>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <input
                onChange={handlePrimaryMailChange}
                name="primary_contact_email"
                value={primary_contact_email}
                type="text"
                placeholder="Email"
                className="form-control my-2"
              ></input>
              {showPrimaryEmailError === true ? (
                  <p style={{ color: "darkred" }} className="mb-0">
                    Please enter valid email
                  </p>
                ) : null}
            </div>
            <div className="col-lg-6">
              <input
                onChange={handleChange}
                name="primary_contact_contact_number"
                value={primary_contact_contact_number}
                type="text"
                placeholder="Contact Number"
                className="form-control my-2"
              ></input>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div>
            <p className="mb-0">Alternative Contact Person</p>
            <input
              onChange={handleChange}
              name="alternative_contact_name"
              value={alternative_contact_name}
              type="text"
              placeholder="Name"
              className="form-control my-2"
            ></input>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <input
                onChange={handleAlternateMailChange}
                name="alternative_contact_email"
                value={alternative_contact_email}
                type="text"
                placeholder="Email"
                className="form-control my-2"
              ></input>
              {showAlternateEmailError === true ? (
                  <p style={{ color: "darkred" }} className="mb-0">
                    Please enter valid email
                  </p>
                ) : null}
            </div>
            <div className="col-lg-6">
              <input
                onChange={handleChange}
                name="alternative_contact_contact_number"
                value={alternative_contact_contact_number}
                type="text"
                placeholder="Contact Number"
                className="form-control my-2"
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactDetail;
