import React from "react";
import { checkForFileExtension } from "../../helpers/helper";
import { enquiry_file_icon } from "../../theme/icons";

const ECPolicy = (props) => {
  const { ec_policy, navigateECPolicy } = props;

  const arrangeItems = (arr) => {
    let len = arr && arr.length;
    if (len === 3 || len > 3) {
      return [arr[arr.length - 1], arr[arr.length - 2], arr[arr.length - 3]];
    } else if (len === 2) {
      return [arr[arr.length - 1], arr[arr.length - 2]];
    } else if (len === 1) {
      return [arr[arr.length - 1]];
    }
  };

  return (
    <div className="d-flex align-items-start justify-content-center flex-column">
      <p style = {{ textDecoration: 'underline', cursor:'pointer', color: "#BC5405"}} className="mb-0" onClick={()=>navigateECPolicy()}>{ec_policy && ec_policy.length > 0 ? "View All" : "Add"}</p>
      {ec_policy && ec_policy.length > 0 ? (
        <table className="table">
          <thead>
            <tr className="text-left px-0 mx-0">
              <th style={{ border: "none" }} scope="col-lg-2 px-0">
                Title
              </th>
              <th style={{ border: "none" }} scope="col-lg-1 px-0">
                Effective Date
              </th>
              <th style={{ border: "none" }} scope="col-lg-1 px-0">
                Validity Date
              </th>
              <th style={{ border: "none" }} scope="col-lg-4 px-0">
                Description
              </th>
              <th style={{ border: "none" }} scope="col-lg-2 px-0">
                Attachments
              </th>
            </tr>
          </thead>
          <tbody>
            {arrangeItems(ec_policy).map((policy) => (
              <tr className="my-2">
                <td style={{ border: "none" }} className="col-lg-2 px-0">
                  <input
                    // onChange={this.handleChange}
                    type="text"
                    disabled={true}
                    name={policy.id + "title"}
                    value={policy[policy.id + "title"]}
                    placeholder="Title"
                    className="form-control"
                  ></input>
                </td>
                <td style={{ border: "none" }} className="col-lg-1 px-2 pl-3">
                  <input
                    // onChange={this.handleChange}
                    type="date"
                    disabled={true}
                    name={policy.id + "effective_date"}
                    value={policy[policy.id + "effective_date"]}
                    placeholder="Effective Date"
                    className="form-control"
                  ></input>
                </td>
                <td style={{ border: "none" }} className="col-lg-1 px-2 pl-3">
                  <input
                    // onChange={this.handleChange}
                    type="date"
                    disabled={true}
                    name={policy.id + "validity_date"}
                    value={policy[policy.id + "validity_date"]}
                    placeholder="Validity Date"
                    className="form-control"
                  ></input>
                </td>
                <td style={{ border: "none" }} className="col-lg-4 px-2 pl-3">
                  <input
                    // onChange={this.handleChange}
                    type="text"
                    disabled={true}
                    name={policy.id + "description"}
                    value={policy[policy.id + "description"]}
                    placeholder="Description"
                    className="form-control"
                  ></input>
                </td>
                <td style={{ border: "none" }} className="col-lg-2 px-2 pl-3">
                  <div className="">
                    {policy[policy.id + "attachments"] &&
                    policy[policy.id + "attachments"].length > 0
                      ? policy[policy.id + "attachments"].map((url) => (
                          <div className="d-flex align-items-center justify-content-start">
                            <img
                              className="p-1"
                              style={{
                                height: 35,
                                width: 40,
                                border: "1.5px solid dimgrey",
                                borderRadius: 10,
                              }}
                              src={
                                checkForFileExtension(url) === true
                                  ? url
                                  : enquiry_file_icon
                              }
                              alt="uploaded file"
                            ></img>
                          </div>
                        ))
                      : "No file chosen"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="mb-0">
          Please click above to continue ...
        </p>
      )}
      <div className="d-block">
        {/* <button
          onClick={() => navigateECPolicy()}
          className="btn btn-sm btn-primary"
        >
          {ec_policy && ec_policy.length > 0 ? "More ..." : "Add"}
        </button> */}        
      </div>
    </div>
  );
};
export default ECPolicy;
