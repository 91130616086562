import axios from "axios";

const naitikCommittees = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/naitik")
      .then((success) => {
        console.log(success, "got naitik committees");
        resolve(success.data);
      })
      .catch((failure) => {
        console.log(failure, "err with fetching naitik committees");
        reject([]);
      });
  });
};

const cdscoCommittees = (type) => {
  let query = type === "reRegistered" ? "reRegistered" : "Registered";
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/cdsco?type=${query}`)
      .then((success) => {
        console.log(success, "got cdsco committees");
        resolve(success.data);
      })
      .catch((failure) => {
        console.log(failure, "err with fetching cdsco committees");
        reject([]);
      });
  });
};

const additionalInformation = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/info", data)
      .then((success) => {
        console.log(success, success.data, "success on posting info");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "err with posting info");
      });
  });
};

const getCommitteeFromAddress = (address) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/committees?address=" + address)
      .then((success) => {
        console.log(success.data, "committee found");
        resolve(success.data.output);
      })
      .catch((failure) => {
        console.log(failure, "failure with getting committee based on address");
        reject(failure);
      });
  });
};

const getCommitteeDetails = (type, id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/details/${type}/${id}`)
      .then((success) => {
        console.log(success, success.data, "details found");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "committee details not found");
      });
  });
};
const userProfile = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/profile`)
      .then((success) => {
        console.log(success.data, "profile found");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "profile not found");
        reject(fail);
      });
  });
};

const login = (email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/login", { email: email, password: password })
      .then((login) => {
        console.log(login, login.data, "login successful");
        resolve(login.data);
      })
      .catch((errLogin) => {
        console.log(errLogin, "err with login api call");
        reject(null);
      });
  });
};

const logout = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/logout", { id: id })
      .then((logout) => {
        console.log(logout.data, "logout successful");
        resolve(logout.data);
      })
      .catch((errLogout) => {
        console.log(errLogout, "err with logout api call");
        reject(errLogout);
      });
  });
};

const ethicsCommitteeRegister = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/register", data)
      .then((success) => {
        console.log(
          success.data,
          "ec coordinator/researcher registration success"
        );
        resolve({
          error: false,
          message: "ec coordinator/researcher registration success",
          output: success.data.output,
        });
      })
      .catch((failRegistering) => {
        console.log(failRegistering, "failed during registering");
        reject({
          error: true,
          message: "failed during registering ec coordinator/researcher",
        });
      });
  });
};

const getUsers = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/users")
      .then((success) => {
        console.log(success && success.data, "got list of users");
        resolve(success.data.output);
      })
      .catch((failUsers) => {
        console.log(failUsers, "err with list of users");
        reject({
          error: true,
          message: "Error with list of users",
          output: [],
        });
      });
  });
};

const getUserWithId = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/users?id=" + id)
      .then((success) => {
        console.log(success && success.data, "got user details");
        resolve(success.data.output);
      })
      .catch((failUsers) => {
        console.log(failUsers, "err with one user");
        reject({
          error: true,
          message: "Error with fetching user details",
          output: null,
        });
      });
  });
};

const getUserEmail = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/user-email?id=" + id)
      .then((success) => {
        console.log(success && success.data, "got user email");
        resolve(success.data.output);
      })
      .catch((failUserEmail) => {
        console.log(failUserEmail, "err with one user");
        reject({
          error: true,
          message: "Error with fetching user details",
          output: null,
        });
      });
  });
};

const getCommittees = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/committees")
      .then((success) => {
        console.log(success, success.data, "got cdsco reregistered committees");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "cdsco reregistered committees not found");
        reject({
          error: true,
          message: "Error with fetching committees",
          output: [],
        });
      });
  });
};

const updateCommittee = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/backend/info?id=" + id, data)
      .then((success) => {
        console.log(success, success.data, "committee updated successfully");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "err with updating committee");
        reject(fail);
      });
  });
};
const updateCommitteeStatus = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/backend/info?id=" + id, data)
      .then((success) => {
        console.log(success, success.data, "committee updated successfully");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "err with updating committee");
        reject(fail);
      });
  });
};

const removeSimilarCommittee = (id,data) => {
  console.log(id, data, "inputs");
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/backend/similar-committee?id=${id}`, data
      )
      .then((success) => {
        console.log(success, success.data, "removed similar committee successfully");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "err with removing similar committee");
        reject(fail);
      });
  });
};

const approveUser = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/backend/users?id=" + id, data)
      .then((success) => {
        console.log(success, success.data, "user updated successfully");
        resolve(success.data);
      })
      .catch((fail) => {
        console.log(fail, "err with updating user");
        reject({
          error: true,
          message: "Error with list of users",
          output: null,
        });
      });
  });
};

const getEnrichedInfo = (regNumber) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/info?regNumber=${regNumber}`)
      .then((foundInfo) => {
        console.log(foundInfo, "enriched info found");
        resolve(foundInfo.data.output);
      })
      .catch((errFoundInfo) => {
        console.log(errFoundInfo, "err with fetching committee enriched info");
        reject([])
      });
  });
};

const getEnrichedInfoFromId = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/enriched?id=${id}`)
      .then((foundInfo) => {
        console.log(foundInfo, "enriched info found with id");
        resolve(foundInfo.data.output);
      })
      .catch((errFoundInfo) => {
        console.log(errFoundInfo, "err with fetching committee enriched info with id");
        reject([])
      });
  });
};

const createEnquiry = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/enquiry", data)
      .then((success) => {
        console.log(success.data, "enquiry created successfully");
        resolve({
          error: false,
          message: "success",
          output: success.data.output,
        });
      })
      .catch((fail) => {
        console.log(fail, "err with creating enquiry");
        reject({
          error: true,
          message: "err with creating enquiry",
        });
      });
  });
};

const updateEnquiry = (id,data)=>{
  return new Promise((resolve,reject)=>{
        axios
          .put("/backend/enquiry?id=" + id, data)
          .then((success) => {
            console.log(success, success.data, "enquiry updated successfully");
            resolve(success.data.output);
          })
          .catch((fail) => {
            console.log(fail, "err with updating enquiry");
            reject(fail);
          });
  })
}

const createReply = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/reply", data)
      .then((success) => {
        console.log(success.data, "reply created successfully");
        resolve({
          error: false,
          message: "success",
          output: success.data.output,
        });
      })
      .catch((fail) => {
        console.log(fail, "err with creating reply for enquiry");
        reject({
          error: true,
          message: "err with creating reply",
        });
      });
  });
};

const getEnquiries = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/enquiry?id=${id}`)
      .then((enquiries) => {
        console.log(enquiries, "enriched info found");
        resolve(enquiries.data.output);
      })
      .catch((errEnquiries) => {
        console.log(errEnquiries, "err with fetching enquiries");
        reject([]);
      });
  });
};

const getReply = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/reply?id=${id}`)
      .then((replies) => {
        console.log(replies, "reply found");
        resolve(replies.data.output);
      })
      .catch((errReplies) => {
        console.log(errReplies, "err with fetching reply");
        reject([]);
      });
  });
};

const createSimilarCommittee = (data)=>{
  return new Promise((resolve,reject)=>{
    axios.post("/backend/similar_committee", data).then(scomCreated=>{
      console.log(scomCreated, 'similar committee created');
      resolve(scomCreated.data.output)
    }).catch(errScomCreated=>{
      console.log(errScomCreated, 'err while created similar committee');
      reject(errScomCreated)
    })
  })
}

const updatedSimilarCommittee=(id,enrichedId,data)=>{
  return new Promise((resolve,reject)=>{
    axios.put(`/backend/similar-committee?enrichedId=${enrichedId}&id=${id}`,data).then(updatedScom=>{
      console.log(updatedScom, 'similar committee updated');
      resolve(updatedScom.data.output)
    }).catch(errUpdatingScom=>{
      console.log(errUpdatingScom, 'err updating scom');
      reject(errUpdatingScom)
    })
  })
}

const getSimilarCommittee = (id)=>{
  return new Promise((resolve,reject)=>{
    axios
      .get(`/backend/similar-committee?id=${id}`)
      .then((scom) => {
        console.log(scom, "similar com found");
        resolve(scom.data.output);
      })
      .catch((errScom) => {
        console.log(errScom, "err with fetching scom");
        reject(null);
      });
  })
}

const uploadAttachment = (file, fileName, fileType) =>{
  let fileHeaders = {
    'Content-Type' : fileType
  }
  return new Promise((resolve,reject)=>{
    axios.post(`/backend/upload/fileUpload?name=${fileName}`, file , { headers : fileHeaders}  ).then(fileUpload=>{
      console.log(fileUpload, 'file uploaded');
      resolve(fileUpload.data.output)
    }).catch(errFileUploaded=>{
      console.log(errFileUploaded, 'err while uploading file');
      reject(errFileUploaded)
    })
  })
}

const deleteAttachment = (fileName) =>{
  return new Promise((resolve,reject)=>{
    axios.delete(`/backend/upload/fileRemove?name=${fileName}`).then(fileRemove=>{
      console.log(fileRemove, 'deleted file successfully');
      resolve(fileRemove.data.output)
    }).catch(errRemoving=>{
      console.log(errRemoving, 'err while deleting file');
      reject(errRemoving)
    })
  })
}

const createUpdate = (data) =>{
  return new Promise((resolve,reject)=>{
    axios.post("/backend/update", data).then(updateCreated=>{
      console.log(updateCreated, 'update created');
      resolve(updateCreated.data.output)
    }).catch(errUpdateCreated=>{
      console.log(errUpdateCreated, 'err while created update');
      reject(errUpdateCreated)
    })
  })
}

const getUpdate = (id) =>{
  return new Promise((resolve, reject)=>{
    axios
      .get(`/backend/update?id=${id}`)
      .then((updates) => {
        console.log(updates, "updates found");
        resolve(updates.data.output);
      })
      .catch((errUpdates) => {
        console.log(errUpdates, "err with fetching updates");
        reject([]);
      });
  })
}

const putUpdate = (id, data) =>{
  return new Promise((resolve, reject)=>{
    axios.put(`/backend/update?id=${id}`,data).then(updatedUpdate=>{
      console.log(updatedUpdate, 'update updated');
      resolve(updatedUpdate.data.output)
    }).catch(errUpdatingUpdate=>{
      console.log(errUpdatingUpdate, 'err updating update');
      reject(errUpdatingUpdate)
    })
  })
}

const getAdmin = () =>{
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/getadmin`)
      .then((user) => {
        console.log(user, "updates found");
        resolve(user.data.output);
      })
      .catch((errUser) => {
        console.log(errUser, "err with fetching admin user");
        reject([]);
      });
  })
}

const forgotPwd = (data) =>{
  return new Promise((resolve, reject)=>{
    axios.post("/backend/forgot-password", data).then(success=>{
      console.log(success, ' send input');
      resolve(success.data.output)
    }).catch(failure=>{
      console.log(failure, 'err sending input');
      reject(null)
    })
  })
}

const updatePassword = (data) => {
  return new Promise((resolve , reject)=>{
    axios.post("/backend/reset", data).then(success=>{
      console.log(success, ' send input');
      resolve(success.data.output)
    }).catch(failure=>{
      console.log(failure, 'err sending input');
      reject(null)
    })
  })
}

const createGeneralProspectEnquiry = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/general-enquiry", data)
      .then((success) => {
        console.log(success.data, "general enquiry created successfully");
        resolve({
          error: false,
          message: "success",
          output: success.data.output,
        });
      })
      .catch((fail) => {
        console.log(fail, "err with creating general enquiry");
        reject({
          error: true,
          message: "err with creating general enquiry",
        });
      });
  });
};

const getGeneralProspectEnquiries = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/general-enquiry?id=${id}`)
      .then((general_enquiries) => {
        console.log(general_enquiries, "general enquiries found");
        resolve(general_enquiries.data.output);
      })
      .catch((errGeneralEnquiries) => {
        console.log(errGeneralEnquiries, "err with fetching general enquiries");
        reject([]);
      });
  });
};

export {
  naitikCommittees,
  cdscoCommittees,
  additionalInformation,
  getCommitteeDetails,
  login,
  userProfile,
  logout,
  ethicsCommitteeRegister,
  getCommittees,
  getUsers,
  approveUser,
  getUserWithId,
  getCommitteeFromAddress,
  updateCommittee,
  updateCommitteeStatus,
  getEnrichedInfo,
  removeSimilarCommittee,
  createEnquiry,
  getEnquiries,
  createReply,
  getReply,
  createSimilarCommittee,
  getSimilarCommittee,
  updatedSimilarCommittee,
  updateEnquiry,
  uploadAttachment,
  deleteAttachment,
  createUpdate,
  getUpdate,
  putUpdate, 
  getAdmin,
  forgotPwd,
  updatePassword,
  getUserEmail,
  getEnrichedInfoFromId,
  createGeneralProspectEnquiry,
  getGeneralProspectEnquiries
};
