import React, { useReducer } from "react";
import Header from "../../components/Header";
import NotFound from "../../components/NotFound";
import {
  userProfile,
  getUsers,
  getCommittees,
  approveUser,
  getUserWithId,
  getCommitteeDetails,
  updateCommittee,
  updateCommitteeStatus,
  getEnrichedInfo,
  getEnquiries,
  updatedSimilarCommittee,
  logout,
  getUserEmail,
  getGeneralProspectEnquiries,
} from "../../helpers/api";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  ecRegisterUser,
  ecType,
  ecSize,
  enquiriesIcon,
  Logout,
} from "../../theme/icons";
import CommitteeDetails from "../../components/CommitteeDetails";
import EnquiryCard from "../../components/enquiry/EnquiryCard";
import {
  getAllCommittees,
  sendUpdate,
  getCurrentUser,
  addUnRead,
} from "../../helpers/helper";
import EnquiryDashboard from "./EnquiryDashboard";
import NotApproved from "../../components/NotApproved";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const features = [
  { id : 1, icon: ecRegisterUser, title: "users", bgColor: "rgb(200, 161, 161)" },
  { id : 2, icon: ecType, title: "committees", bgColor: "rgb(237, 210, 169)" },
  { id : 3, icon: enquiriesIcon, title: "enquiries", bgColor: "#A8CDB2" },
  { id : 4, icon: enquiriesIcon, title: "prospects", bgColor: "#9EA1D4" },
  { id : 5, icon: Logout, title: "logout", bgColor: "#C9AFD2" },
];
const userRoles = [
  { option: "All Users", value: "all" },
  { option: "Admin", value: "ec_qcr_admin" },
  { option: "EC Coordinator", value: "ec_coordinator" },
  { option: "EC Independent Researcher", value: "ec_independent_researcher" },
];
class Dashboard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      adminUser: "",
      committees: [],
      enquiries: null,
      general_queries : null,
      allUsers: "",
      filteredUsers: [],
      enrichedCommittees: [],
      filteredCommittees: "",
      user_role: "All",
      selectedUser: null,
      selected_general_query : null,
      selectedCommittee: null,
      showReasonButtons: false,
      selectedSimilarCommitee : null,
      showReasonError: false,
      approveUser: false,
      approveCommittee: false,
      filterCommitteeType: "",
      selectedCommitteeDetails: null,
      showCommitteeDetails: false,
      feature: "",
      similar_committee_status: "",
      showCommitteeDetailsModal: false,
    };
  }

  handleSelectedSimilarCommittee = (scom) =>{
    this.setState({selectedSimilarCommitee : scom})
  }

  openPdf = (link) => {
    window.open(link, "_blank");
  };
  changeCommitteeDetails = () => {
    this.setState({ showCommitteeDetails: true });
  };
  hideCommitteeDetails = () => {
    this.setState({ showCommitteeDetails: false });
  };

  openCommitteeDetailsModal = () => {
    this.setState({ showCommitteeDetailsModal: true });
  };
  closeCommitteeDetailsModal = () => {
    this.setState({ showCommitteeDetailsModal: false });
  };

  changeSimilarCommittee = (id, data) => {
    updatedSimilarCommittee(id, this.state.selectedCommittee._id, data)
      .then((updated) => {
        console.log(updated, "updated scom");
        this.onSelectCommittee(this.state.selectedCommittee);
        getUserEmail(this.state.selectedCommittee.updated_by).then(coordinator_details=>{
          
          let update = {
            active: false,
            title: `Request for linking similar committee status`,
            update: {
              type: "linking_scom_responded",
              status : updated.approval_status,
              user_name : coordinator_details.name,
              scom_registered_number : updated.committee.registered_number,
            },
            description: `Request for linking similar committee status`,
            from: [getCurrentUser(this.props.user)],
            to: [{user_email : coordinator_details.email}, getCurrentUser(this.props.user)],
            isRead: false,
          };
          sendUpdate(update);
        }).catch(errCoordinatorEmail=>{
          console.log(errCoordinatorEmail, 'err fetching coordinator email')
        })
      })
      .catch((errUpdated) => {
        console.log(errUpdated, "err updating scom");
      });
  };

  getReceiverUser = (userId) => {
    console.log(userId, "while checking receiver");
    return new Promise((resolve, reject) => {
      getUserWithId(userId)
        .then((gotUser) => {
          console.log(gotUser, "got user");
          if (gotUser !== null) {
            let receiverUser = {
              user_id: gotUser._id,
              user_email: gotUser.email,
              user_name: gotUser.username,
              user_role: gotUser.role,
              isRead: false,
            };
            console.log(receiverUser, "receiver user found");
            resolve(receiverUser);
          } else {
            console.log(gotUser, "no user found");
            reject(null);
          }
        })
        .catch((errUser) => {
          console.log(errUser, "err with fetching user");
          reject(null);
        });
    });
  };

  checkForEnrichedInfoForSimilarCommittee = (scom) => {
    let enriched_info = scom && scom.enriched_information;
    if (enriched_info === undefined) {
      this.setState({ showCommitteeDetailsModal: false });
    } else {
      this.setState({ showCommitteeDetailsModal: true });
    }
  };

  handleReasonChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState({ showReasonButtons: true });
    });
  };

  componentDidMount() {
    let { feature } = this.props.match.params;
    let { user } = this.props;
    console.log(feature, this.props.match.params, "feature");
    this.setState({ adminUser: user }, () => {
      if (feature === "committees") {
        this.setState({ feature: feature }, () => {
          getEnrichedInfo("all")
            .then((enrichedComms) => {
              console.log(enrichedComms, "got enriched comms");
              this.setState({
                enrichedCommittees: enrichedComms,
                filteredCommittees: enrichedComms,
                filterCommitteeType: "All",
              });
            })
            .catch((errEnrichedComms) => {
              console.log(
                errEnrichedComms,
                "err with fetching enriched  comms"
              );
              this.setState({ filteredCommittees: [] });
            });
        });
      } else if (feature === "enquiries") {
        this.setState({ feature: feature });
      } else if(feature === "prospects"){
        this.setState({feature : feature},()=>{
          getGeneralProspectEnquiries("all").then(queries=>{
            console.log(queries,'queries');
            this.setState({ general_queries : queries})
          }).catch(errQueries=>{
            console.log(errQueries,'err while fetching queries');
            this.setState({ general_queries : null})
          })
        })
      } else if (
        feature === "users" ||
        feature === undefined ||
        feature === null
      ) {
        this.setState({ feature: feature }, () => {
          getUsers()
            .then((users) => {
              console.log(users, "all users");
              this.setState({ allUsers: users, filteredUsers: users });
            })
            .catch((errUsers) => {
              console.log(errUsers, "err with fetching users");
              this.setState({ allUsers: [], filteredUsers: [] });
            });
        });
      } else {
        this.setState({ feature: "users" }, () => {
          getUsers()
            .then((users) => {
              console.log(users, "all users");
              this.setState({ allUsers: users, filteredUsers: users });
            })
            .catch((errUsers) => {
              console.log(errUsers, "err with fetching users");
              this.setState({ allUsers: [], filteredUsers: [] });
            });
        });
      }
    });
  }

  onSelectUser = (user) => {
    this.setState({ selectedUser: user });
  };

  onSelectQuery = (query) => {
    this.setState({ selected_general_query: query });
  };

  approveUserChange = (status) => {
    approveUser(this.state.selectedUser._id, { active: status })
      .then((success) => {
        console.log(success, "user data updated successfully");
        alert("user updated successfully");
        let update = {
          active: false,
          title: `User status changed`,
          update: {
            type: "new_user_updated",
            role : "ec_independent_researcher",
            status : success.output.active,
            user_name : success.output.username,
            user_email : success.output.email,
            item: this.state.selectedUser._id,
          },
          description: `User status updated by ${this.props.adminUser.user_name}`,
          from: [getCurrentUser(this.props.user)],
          to: [addUnRead(getCurrentUser(this.state.selectedUser))],
          isRead: false,
        };
        sendUpdate(update);
        getUserWithId(this.state.selectedUser._id)
          .then((gotUser) => {
            console.log(gotUser, "got one user");
            this.setState({ selectedUser: gotUser }, () => {
              getUsers()
                .then((users) => {
                  console.log(users, "all users");
                  this.setState({
                    allUsers: users,
                    filteredUsers: users,
                  });
                })
                .catch((errUsers) => {
                  this.setState({ allUsers: [], filteredUsers: [] });
                });
            });
          })
          .catch((errUser) => {
            console.log(errUser, "err with one user");
          });
      })
      .catch((failure) => {
        console.log(failure, "err with updating user");
        alert("Error with updating user");
      });
  };

  approveCommitteeChange = (committee, infoId, status) => {
    updateCommitteeStatus(infoId, { active: status })
      .then((committeeUpdated) => {
        console.log(
          committeeUpdated.output.updated_by,
          "committee updated successfully"
        );
        alert("Committee status updated successfully");
        this.getReceiverUser(committeeUpdated.output.updated_by)
          .then((receiver) => {
            let update = {
              active: false,
              title: `Ethics committee status changed`,
              update: {
                type: "enriched_data_feedback",
                status : status,
                ec_registered_number : committeeUpdated.output.ethics_committee.registered_number,
                item: committeeUpdated.output.ethics_committee._id,
              },
              description: `Ethics committee status updated by ${this.props.adminUser.user_name}`,
              from: [getCurrentUser(this.props.user)],
              to: [receiver],
              isRead: false,
            };
            sendUpdate(update);
            this.onSelectCommittee(committee);
          })
          .catch((errReceiver) => {
            console.log(errReceiver, "err with fetching receiver");
          });
      })
      .catch((errUpdatingCommittee) => {
        console.log(errUpdatingCommittee, "err updating committee status");
        alert("err updating committee status");
      });
  };

  getQueryDetails = () =>{
    let {
      active,
name,
organization,
phone,
email,
question,
others,
isRead,
    } = this.state.selected_general_query;
    return(
      <div className="mt-4">
          <p style={{fontWeight : 'bold', textTransform : 'capitalize'}} className="mb-0">{name}</p>
          <p className="my-2">Organization : {organization}</p>
          <p className="mb-0">Email : {email}</p>
          <p className="my-2">Phone : {phone}</p>
          <p className="my-2">Question : {question}</p>
          <p className="my-2">Raised by : {(this.state.selected_general_query && this.state.selected_general_query.raised_by && this.state.selected_general_query.raised_by.user_name) || "-" }</p>
      </div>
    )
  }

  getUserDetails = () => {
    let {
      username,
      email,
      phone,
      isEmailVerified,
      role,
      createdAt,
      others,
      active,
    } = this.state.selectedUser;
    let detailFields = [
      { field: "Name", value: username },
      { field: "Email", value: email },
      { field: "Phone Number", value: phone },
      {
        field: "Role",
        value:
          (role === "ec_qcr_admin" && "QCR Admin") ||
          (role === "ec_independent_researcher" &&
            "EC Independent Researcher") ||
          (role === "ec_coordinator" && "EC Coordinator"),
      },
      {
        field: "Created",
        value: moment(createdAt).format("MMMM Do YYYY, HH:mm:ss"),
      },
      {
        field: "Email Verified",
        value: isEmailVerified === true ? "Yes" : "No",
      },
    ];
    return (
      <div className="mt-4">
        {detailFields.map((detailField) => (
          <div className="row">
            <p className="col-lg-5">{detailField.field}</p>
            <p className="col-lg-1">-</p>
            <p className="col-lg-5">{detailField.value || "N/A"}</p>
          </div>
        ))}
        <div className="row">
          <p className="col-lg-5">Status</p>
          <p className="col-lg-1">-</p>
          <p className="col-lg-5">
            {active === true ? "Approved" : "Rejected"}
          </p>
        </div>
        {role === "ec_coordinator" && others && others.length > 0 ? (
          <div>
            <h6 className="my-3">Others</h6>
            {others[0] && others[0].ethics_committee.type === "naitik" ? (
              <div className="row">
                <p className="col-lg-5">EC Name</p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">
                  {others[0] &&
                    others[0].ethics_committee.name_of_ethics_committee}
                </p>
              </div>
            ) : null}
            {others[0] && others[0].ethics_committee.type === "naitik" ? (
              <div className="row">
                <p className="col-lg-5">EC Address</p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">
                  {others[0] && others[0].ethics_committee.address}
                </p>
              </div>
            ) : null}
            <div className="row">
              <p className="col-lg-5">EC Name & Address</p>
              <p className="col-lg-1">-</p>
              <p className="col-lg-5">
                {others[0] && others[0].ethics_committee.name_and_address}
              </p>
            </div>
            <div className="row">
              <p className="col-lg-5">EC Registration Number</p>
              <p className="col-lg-1">-</p>
              <p className="col-lg-5">
                {others[0] && others[0].ethics_committee.registered_number}
              </p>
            </div>
            <div className="row">
              <p className="col-lg-5">EC Type</p>
              <p className="col-lg-1">-</p>
              <p className="col-lg-5">
                {(others[0] &&
                  others[0].ethics_committee.type === "reRegistered" &&
                  "CDSCO Re Registered") ||
                  (others[0] &&
                    others[0].ethics_committee.type === "Registered" &&
                    "CDSCO Registered") ||
                  (others[0] &&
                    others[0].ethics_committee.type === "naitik" &&
                    "Naitik")}
              </p>
            </div>
            <div className="row">
              <p className="col-lg-5">
                {others[0] && others[0].ethics_committee.type === "naitik"
                  ? "Approval Date"
                  : "Release Date"}
              </p>
              <p className="col-lg-1">-</p>
              <p className="col-lg-5">
                {others[0] && others[0].ethics_committee.type === "naitik"
                  ? others[0] && others[0].ethics_committee.approval_date
                  : others[0] && others[0].ethics_committee.release_date}
              </p>
            </div>
          </div>
        ) : null}
        {role === "ec_independent_researcher" && others && others.length > 0 ? (
          <div>
            <h6 className="mb-0">Others</h6>
            <div className="row">
              <p className="col-lg-5">Address</p>
              <p className="col-lg-1">-</p>
              <p className="col-lg-5">{others[0].address}</p>
            </div>
            <div className="row">
              <p className="col-lg-5">EC Designation</p>
              <p className="col-lg-1">-</p>
              <p className="col-lg-5">{others[0].ec_designation}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  selectUserChange = (role) => {
    if (role === "All") {
      this.setState({ user_role: "All", filteredUsers: this.state.allUsers });
    } else if (role === "Researcher") {
      this.setState({
        user_role: "Researcher",
        filteredUsers: this.state.allUsers.filter(
          (user) => user.role === "ec_independent_researcher"
        ),
      });
    } else if (role === "Coordinator") {
      this.setState({
        user_role: "Coordinator",
        filteredUsers: this.state.allUsers.filter(
          (user) => user.role === "ec_coordinator"
        ),
      });
    }
  };

  selectEnrichedCommitteesChange = (type) => {
    if (type === "All") {
      this.setState({
        filterCommitteeType: "All",
        filteredCommittees: this.state.enrichedCommittees,
      });
    } else if (type === "Approved") {
      this.setState({
        filterCommitteeType: "Approved",
        filteredCommittees: this.state.enrichedCommittees.filter(
          (committee) => committee.active === true
        ),
      });
    } else if (type === "Not Approved") {
      this.setState({
        filterCommitteeType: "Not Approved",
        filteredCommittees: this.state.enrichedCommittees.filter(
          (committee) => committee.active === false
        ),
      });
    }
  };

  selectFeature = (feature) => {
    if (feature === "logout") {
      logout()
        .then((userLogout) => {
          console.log(userLogout, "user logged out");
          if (
            userLogout &&
            userLogout.error === false &&
            userLogout.message === "User logged out"
          ) {
            cookies.remove("connect.sid");
            window.sessionStorage.clear();
            this.props.changeUserStatus();
          }
        })
        .catch((errUserLogout) => {
          console.log(errUserLogout, "err with user logging out");
          cookies.remove("connect.sid");
          this.props.changeUserStatus();
        });
    } else {
      this.setState({ feature: feature }, () => {
        window.location.href = "/admin/" + feature;
      });
    }
  };

  onSelectCommittee = (committee) => {
    getEnrichedInfo(committee && committee.committee_registered_number)
      .then((enrichedFound) => {
        console.log(enrichedFound, "success");
        this.setState(
          {
            selectedCommittee: enrichedFound,
          },
          () => {
            let scoms = enrichedFound && enrichedFound.similar_committees;
            console.log(scoms , 'scoms')
            if (scoms && scoms.length > 0) {
              scoms.map((scom) => {
                let scomId = scom && scom._id;
                this.setState({
                  [scomId]: scom.approval_status,
                  [scomId + "reason_for_decline"]:
                    scom && scom.reason_for_decline,
                });
              });
            }
          }
        );
      })
      .catch((errEnrichedFound) => {
        console.log(errEnrichedFound, "err with fetching enriched comm");
        alert("Error with fetching info. Please refresh");
      });
  };

  getDetails = () => {
    let {
      type,
      name_and_address,
      state,
      registered_number,
      createdAt,
      release_date,
      download_pdf,
      pdf_size,
      additional_information,
      _id,
    } = this.state.selectedCommittee.ethics_committee;
    let additional_details =
      additional_information && additional_information.length > 0
        ? additional_information
        : [];
    return (
      <div className="mt-4">
        <p>
          {type === "naitik"
            ? this.state.selectedCommittee.ethics_committee
                .name_of_ethics_committee
            : name_and_address}
        </p>
        <p>State: {state}</p>
        <h6>
          {type === "naitik"
            ? "DHR Registered Number"
            : "CDSCO Registered Number"}
          : {registered_number}
        </h6>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {type === "naitik" ? (
              <p className="mb-0">
                Approval Date :{" "}
                <span>
                  {this.state.selectedCommittee.ethics_committee.approval_date}
                </span>
              </p>
            ) : (
              <p className="mb-0">
                Release Date : <span>{release_date}</span>
              </p>
            )}
          </div>
          <div>
            <p className="mb-0">
              Created :{" "}
              <span>{moment(createdAt).format("MMMM Do YYYY, HH:mm:ss")}</span>
            </p>
          </div>
          {type === "naitik" ? null : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.openPdf(download_pdf)}
            >
              <img src={ecSize} alt="pdf_icon" className="mb-0"></img>
              <span className="mb-0">{pdf_size}</span>
            </div>
          )}
        </div>
        <div className="my-5 mx-auto text-center">
          <h6
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => this.changeCommitteeDetails()}
          >
            More Details ...
          </h6>
        </div>
      </div>
    );
  };

  render() {
    const {
      allUsers,
      selectedUser,
      user_role,
      filteredUsers,
      selected_general_query,
      general_queries,
      filteredCommittees,
      feature,
      showReasonError,
      selectedCommittee,
      showCommitteeDetails,
    } = this.state;
    const { user, enquiries } = this.props;
    return (
      <div
        style={{
          height: "100%",
          background: "#FFFFFF",
          //"linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
        }}
      >
        {user === null ? (
          <NotFound message={"Please login to view this application"} />
        ) : (user && user.role === "ec_coordinator") ||
          (user && user.role === "ec_independent_researcher") ? (
          <NotApproved
            message="You are not authorized"
            changeUserStatus={this.changeUserStatus}
            userId={user._id}
          />
        ) : showCommitteeDetails === true ? (
          <CommitteeDetails
            user={user}
            {...this.state}
            openCommitteeDetailsModal={this.openCommitteeDetailsModal}
            closeCommitteeDetailsModal={this.closeCommitteeDetailsModal}
            checkForEnrichedInfoForSimilarCommittee={
              this.checkForEnrichedInfoForSimilarCommittee
            }
            approveCommitteeChange={this.approveCommitteeChange}
            handleChange={this.handleChange}
            selectedSimilarCommitee = {this.state.selectedSimilarCommitee}
            handleSelectedSimilarCommittee = {this.handleSelectedSimilarCommittee}
            handleSimilarCommitteeChange={this.handleSimilarCommitteeChange}
            changeSimilarCommittee={this.changeSimilarCommittee}
            handleReasonChange={this.handleReasonChange}
            showReasonError={showReasonError}
            committee={this.state.selectedCommittee}
            type={this.state.selectedCommittee.committee_type}
            hideCommitteeDetails={this.hideCommitteeDetails}
          />
        ) : (
          <div className="container-fluid px-0">
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: 2,
              }}
              className="row mx-0 px-0"
            >
              <div
                style={{
                  backgroundColor: "#d0dfe3",
                  position: "sticky",
                  zIndex: 10,
                }}
                className="col-lg-2 px-0"
              >
                {features.map((feature, i) => (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    className={
                      feature.title.toLocaleLowerCase() === this.state.feature
                        ? "selected-nav d-flex align-items-center justify-content-start pl-5 py-3"
                        : "admin-nav d-flex align-items-center justify-content-start pl-5 py-3"
                    }
                    onClick={() => this.selectFeature(feature.title)}
                  >
                    <div
                      style={{
                        backgroundColor: feature.bgColor,
                        borderRadius: 4,
                        height: 40,
                        width: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          height: feature.id === 5 ? 28 : 35,
                          width: feature.id === 5 ? 28 : 35,
                        }}
                        className="p-1"
                        src={feature.icon}
                        alt={feature.title + "icon"}
                      ></img>
                    </div>
                    <h5
                      style={{ textTransform: "capitalize" }}
                      className="mb-0 mx-2"
                    >
                      {feature.title}
                    </h5>
                  </div>
                ))}
              </div>
              {feature === "users" ? (
                <div className="col-lg-10 row px-0 mx-0">
                  <div
                    style={{
                      backgroundColor: "#f6f6f6",
                      borderLeft: "1px solid #E5E5E5",
                      borderRight: "1px solid #E5E5E5",
                      position: "sticky",
                    }}
                    className="col-lg-4 px-0 mx-0"
                  >
                    {allUsers && allUsers.length > 0 ? (
                      <div>
                        {/* <div>
                          <p className="mb-0">Please select user role</p>
                          <select
                            style={{
                              fontSize: 15,
                              height: 40,
                            }}
                            className="form-select px-2 my-2 col-lg-12"
                            name={user_role}
                            onChange={this.selectUserChange}
                          >
                            {userRoles.map((userRole, i) => (
                              <option key={i} value={userRole.value}>
                                {userRole.option}
                              </option>
                            ))}
                          </select>
                        </div> */}
                        <div
                          style={{ backgroundColor: "#FFFFFF" }}
                          className="px-0 mx-0"
                        >
                          <ul
                            style={{ listStyle: "none", height: 73 }}
                            className="d-flex align-items-center justify-content-around mb-0 pl-0"
                          >
                            <li
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.user_role === "Researcher"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                              onClick={() =>
                                this.selectUserChange("Researcher")
                              }
                              className="px-2 py-4"
                            >
                              Researcher
                            </li>
                            <li
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.user_role === "Coordinator"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                              onClick={() =>
                                this.selectUserChange("Coordinator")
                              }
                              className="px-2 py-4"
                            >
                              Coordinator
                            </li>
                            <li
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.user_role === "All"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                              onClick={() => this.selectUserChange("All")}
                              className="px-2 py-4"
                            >
                              All
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#f6f6f6",
                            height: 490,
                            overflowY: "scroll",
                            paddingBottom : 100
                          }}
                          className="mx-0 px-3"
                        >
                          {filteredUsers.map((user) => (
                            <div
                              onClick={() => this.onSelectUser(user)}
                              style={{
                                backgroundColor: "#FFFFFF",
                                //border: "1px solid #787A91",
                                borderRadius: 5,
                                cursor: "pointer",
                                borderBottom:
                                  selectedUser && selectedUser._id === user._id
                                    ? "3px solid #BC5405"
                                    : "none",
                              }}
                              className="my-2 p-2"
                            >
                              <h6>
                                <span
                                  style={{
                                    fontStyle: "normal",
                                    color: "rgba(0, 0, 0, 0.6)",
                                  }}
                                >
                                  Email :{" "}
                                </span>
                                {user.email}
                              </h6>
                              <p style={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize'
                                  }} 
                                  className="mb-0">
                                <span style={{ color: "rgba(0, 0, 0, 0.6)", fontWeight: 'normal' }}>
                                  User name :{" "}
                                </span>
                                {user.username}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p className="text-center mx-auto">No Users Found</p>
                    )}
                  </div>
                  <div className="col-lg-8 my-3">
                    {selectedUser === null ? (
                      <p className="text-center mx-auto">No User Selected</p>
                    ) : (
                      <div className="px-3 my-0">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>About User</h5>
                          <p
                            style={{
                              color: "white",
                              cursor: "pointer",
                              backgroundColor:
                                selectedUser.active === true
                                  ? "#F8485E"
                                  : "#83BD75",
                              borderRadius: 5,
                              textTransform: "uppercase",
                            }}
                            onClick={() =>
                              this.approveUserChange(
                                selectedUser.active === true ? false : true
                              )
                            }
                            className={`${
                              selectedUser.active === true
                                ? "border border-danger"
                                : "border border-success"
                            } py-1 px-3 mx-4 mb-0`}
                          >
                            {selectedUser.active === true
                              ? "Reject"
                              : "Approve"}
                          </p>
                        </div>
                        {this.getUserDetails()}
                      </div>
                    )}
                  </div>
                </div>
              ) : feature === "committees" ? (
                <div className="col-lg-10 row px-0 mx-0">
                  <div
                    style={{
                      backgroundColor: "#f6f6f6",
                      borderLeft: "1px solid #E5E5E5",
                      borderRight: "1px solid #E5E5E5",
                      position: "sticky",
                    }}
                    className="col-lg-4 px-0 mx-0"
                  >
                    <div>
                      <div
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="px-0 mx-0"
                      >
                        <ul
                          style={{ listStyle: "none", height: 73 }}
                          className="d-flex align-items-center justify-content-around mb-0 pl-0"
                        >
                          <li
                            style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.Approved === "Approved"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                            onClick={() =>
                              this.selectEnrichedCommitteesChange("Approved")
                            }
                            className="px-2 py-4"
                          >
                            Approved
                          </li>
                          <li
                            style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.filterCommitteeType === "Not Approved"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                            onClick={() =>
                              this.selectEnrichedCommitteesChange(
                                "Not Approved"
                              )
                            }
                            className="px-2 py-4"
                          >
                            Not Approved
                          </li>
                          <li
                            style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.filterCommitteeType === "All"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                            onClick={() =>
                              this.selectEnrichedCommitteesChange("All")
                            }
                            className="px-2 py-4"
                          >
                            All
                          </li>
                        </ul>
                      </div>
                      <div style={{
                            backgroundColor: "#f6f6f6",
                            height: 490,
                            overflowY: "scroll",
                            paddingBottom : 100
                          }}
                          className="mx-0 px-3">
                      {filteredCommittees && filteredCommittees.length > 0
                        ? filteredCommittees.map((filteredCommittee) => (
                            <div
                              onClick={() =>
                                this.onSelectCommittee(filteredCommittee)
                              }
                              style={{
                                backgroundColor: "#FFFFFF",
                                // borderBottom:
                                //   selectedCommittee === null ? "none" : selectedCommittee && selectedCommittee.ethics_committee && selectedCommittee.ethics_committee._id === filteredCommittee.ethics_committee._id
                                //     ? "3px solid #BC5405"
                                //     : "none",
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              className="my-2 p-2"
                            >
                              <h6>
                                <span style={{ fontStyle: "normal" }}>
                                  Registration Number :{" "}
                                </span>
                                {filteredCommittee &&
                                  filteredCommittee.ethics_committee &&
                                  filteredCommittee.ethics_committee
                                    .registered_number}
                              </h6>
                              <p className="mb-0">
                                {filteredCommittee &&
                                filteredCommittee.ethics_committee &&
                                filteredCommittee.ethics_committee.type ===
                                  "naitik"
                                  ? filteredCommittee &&
                                    filteredCommittee.ethics_committee &&
                                    filteredCommittee.ethics_committee
                                      .name_of_ethics_committee
                                  : filteredCommittee &&
                                    filteredCommittee.ethics_committee &&
                                    filteredCommittee.ethics_committee
                                      .name_and_address}
                              </p>
                            </div>
                          ))
                        : filteredCommittees && filteredCommittees.length === 0
                        ? "No Committees with Enriched info found"
                        : "loading ..."}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 my-3">
                    {selectedCommittee === null ? (
                      <p className="text-center mx-auto">
                        No Committee Selected
                      </p>
                    ) : (
                      <div className="px-3 mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Committee Details</h5>
                        </div>
                        {this.getDetails()}
                      </div>
                    )}
                  </div>
                </div>
              ) : feature === "enquiries" ? (
                <div className="col-lg-10 px-0 mx-0">
                  <EnquiryDashboard enquiries={enquiries} />
                </div>
              ) : feature === "prospects" ? (
                <div className="col-lg-10 row px-0 mx-0">
                  <div
                    style={{
                      backgroundColor: "#f6f6f6",
                      borderLeft: "1px solid #E5E5E5",
                      borderRight: "1px solid #E5E5E5",
                      position: "sticky",
                    }}
                    className="col-lg-4 px-0 mx-0"
                  >
                    {general_queries && general_queries.length > 0 ? (
                      <div>
                        {/* <div
                          style={{ backgroundColor: "#FFFFFF" }}
                          className="px-0 mx-0"
                        >
                          <ul
                            style={{ listStyle: "none", height: 73 }}
                            className="d-flex align-items-center justify-content-around mb-0 pl-0"
                          >
                            <li
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.user_role === "Researcher"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                              onClick={() =>
                                this.selectUserChange("Researcher")
                              }
                              className="px-2 py-4"
                            >
                              Researcher
                            </li>
                            <li
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.user_role === "Coordinator"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                              onClick={() =>
                                this.selectUserChange("Coordinator")
                              }
                              className="px-2 py-4"
                            >
                              Coordinator
                            </li>
                            <li
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                borderBottom:
                                  this.state.user_role === "All"
                                    ? "2px solid #22BFE9"
                                    : "none",
                              }}
                              onClick={() => this.selectUserChange("All")}
                              className="px-2 py-4"
                            >
                              All
                            </li>
                          </ul>
                        </div> */}
                        <div
                          style={{
                            backgroundColor: "#f6f6f6",
                            height: 490,
                            overflowY: "scroll",
                            paddingBottom : 100
                          }}
                          className="mx-0 px-3"
                        >
                          {general_queries.map((query) => (
                            <div
                              onClick={() => this.onSelectQuery(query)}
                              style={{
                                backgroundColor: "#FFFFFF",
                                //border: "1px solid #787A91",
                                borderRadius: 5,
                                cursor: "pointer",
                                borderBottom:
                                  selected_general_query && selected_general_query._id === query._id
                                    ? "3px solid #BC5405"
                                    : "none",
                              }}
                              className="my-2 p-2"
                            >
                              <h6>
                                <span
                                  style={{
                                    fontStyle: "normal",
                                    color: "rgba(0, 0, 0, 0.6)",
                                  }}
                                >
                                  Email :{" "}
                                </span>
                                {query.email}
                              </h6>
                              <p style={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize'
                                  }} 
                                  className="mb-0">
                                {/* <span style={{ color: "rgba(0, 0, 0, 0.6)", fontWeight: 'normal' }}>
                                  User name :{" "}
                                </span> */}
                                {query.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p className="text-center mx-auto">No General Queries Found</p>
                    )}
                  </div>
                  <div className="col-lg-8 my-3">
                    {selected_general_query === null ? (
                      <p className="text-center mx-auto">No general query selected</p>
                    ) : (
                      <div className="px-3 my-0">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>General Enquiry Details</h5>
                          {/* <p
                            style={{
                              color: "white",
                              cursor: "pointer",
                              backgroundColor:
                                selectedUser.active === true
                                  ? "#F8485E"
                                  : "#83BD75",
                              borderRadius: 5,
                              textTransform: "uppercase",
                            }}
                            onClick={() =>
                              this.approveUserChange(
                                selectedUser.active === true ? false : true
                              )
                            }
                            className={`${
                              selectedUser.active === true
                                ? "border border-danger"
                                : "border border-success"
                            } py-1 px-3 mx-4 mb-0`}
                          >
                            {selectedUser.active === true
                              ? "Reject"
                              : "Approve"}
                          </p> */}
                        </div>
                        {this.getQueryDetails()}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <p className="py-5 my-5 mb-0">No Feature selected</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Dashboard);
