import React, { cloneElement, useState } from "react";
import { MapIcon } from "../theme/icons";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap((props) => {
    const { position, showMap, committeeCheckboxChange, onNext, obj } = props;
    const [details, showdetails] = useState(false);
    const [committee, setCommittee] = useState("");
    const [selectedCommittees,setSelectedCommittees] = useState([])
    const checkObj = {}
    console.log(position, "pos");

    const handleChange=(e)=>{
      console.log(e.target.name, e.target.value , 'target, value');
      if (e.target.checked) {
        checkObj.hasOwnProperty("selected_committees")
          ? checkObj["selected_committees"].push(e.target.name)
          : (checkObj["selected_committees"] = [e.target.name]);
      } else {
        let unselected = checkObj["selected_committees"].indexOf(e.target.name);
        checkObj["selected_committees"].splice(unselected, 1);
      }
      console.log(checkObj,'set locs')
      setSelectedCommittees(checkObj["selected_committees"])
    }
    return (
      <div>
        {showMap === true ? (
          <GoogleMap
            defaultZoom={position && position.length > 1 ? 7 : 10}
            defaultCenter={
              position && position.length > 0 && position[0].location
            }
            mapTypeId={"roadmap"}
          >
            {position && position.length > 0
              ? position.map((pos, i) => (
                  <Marker
                    key={i}
                    onClick={() => {
                      setCommittee(pos);
                      showdetails(true);
                    }}
                    // onMouseOut={()=>{
                    //   setLabel("");
                    //   setStudyId("")
                    //   showdetails(false);
                    // }}
                    position={pos.location}
                    icon={MapIcon}
                  />
                ))
              : null}
          </GoogleMap>
        ) : null}
        {details === true ? (
          <div
            style={{
              backgroundColor: "#F3F3F3",
              position: "relative",
              bottom: 200,
              left: 700,
            }}
            className="col-lg-4 py-4"
          >
            <p className="mb-0">Selected Location</p>
            <div className="">
              {/* <input
                style={{ height: "auto", width: "auto" }}
                onChange={handleChange}
                checked = {selectedCommittees.includes(committee.id) === true}
                className="mx-1 mt-2"
                type={"checkbox"}
                name={committee.id}
                value={committee.id}
              /> */}
              <span className="mx-2">{committee.actual_address}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  })
);

export default Map;
