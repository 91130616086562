import React from "react";
import { logout } from "../helpers/api";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const NotApproved = ({ userId, message="QCR Admin need to review and grant access. Thank you",changeUserStatus }) => {
  return (
    <div className={message && message.length > 0 ? "mx-auto text-center py-5" : "mx-auto text-center my-5"}>
      <h3>{message && message.length > 0 ? message : "Not Found"}</h3>
      {message && message.length > 0 ? (
        <button
        onClick={()=>{
            logout(userId).then(userLogout=>{
                console.log(userLogout,'user logged out');
                changeUserStatus()
                cookies.remove('connect.sid')
                changeUserStatus()
                window.location.href="/"
            }).catch(errUserLogout=>{
                console.log(errUserLogout,'err with user logging out');
                cookies.remove('connect.sid')
                window.location.href="/"
            });
        }}
          className="btn btn-lg login m-3"
        >
          Log out
        </button>
      ) : null}
    </div>
  );
};

export default NotApproved;
