import React from "react";
import { noEnquiry } from "../../theme/icons";

const NoEnquiry = () => {
  return (
    <div className="no_enquiries text-center my-5 py-5">
      <div className="no_enquiry d-flex align-items-center justify-content-center">
        <img
          style={{height:300,width:'auto'}}
          src={noEnquiry}
          alt="no_enquiries"
          className="img-responsive"
        ></img>
      </div>
      <h2 className="mt-5">No Enquiries Found</h2>
      {/* <p className="mb-0">Click on "Create New" to raise enquiry</p> */}
    </div>
  );
};

export default NoEnquiry;
