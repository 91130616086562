import React from "react";
import {
  getCommitteeDetails,
  additionalInformation,
  getSimilarCommittee,
  getEnrichedInfo,
  updateCommittee,
  updatedSimilarCommittee,
} from "../helpers/api";
import { withRouter } from "react-router-dom";
import { objectDecrypt, getCurrentUser, addUnRead, sendUpdate } from "../helpers/helper";

class Training extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: "",
      ec_training: [],
      ec_composition_members: [],
      showMembers: false,
      selected_row_id: "",
      editing_row_id: "",
      selected_member: "",
      enrichedCommittee: "",
      similarCommittee: null,
      committee: "",
      hideAdd: false,
    };
  }

  createTrainingRow = (index) => {
    let members = this.state.ec_composition_members;
    members.map((member) => (member.checked = false));
    console.log(members, "paradox");
    let obj = { id: "row" + index };
    obj["row" + index + "topic"] = "";
    obj["row" + index + "training_date"] = "";
    obj["row" + index + "members"] = this.state.ec_composition_members;
    console.log(obj, "row obj");
    let training = this.state.ec_training;
    if (training && training.length > 0) {
      training.push(obj);
      this.setState({ ec_training: training, hideAdd: true });
    } else {
      this.setState({ ec_training: [obj], hideAdd: true });
    }
  };

  fetchEnrichedInfo = (regNumber) => {
    getEnrichedInfo(regNumber)
      .then((foundInfo) => {
        console.log(foundInfo, "info found");
        this.setState({ enrichedCommittee: foundInfo }, () => {
          if (this.state.enrichedCommittee !== null) {
            let { ec_composition_EC, ec_training } = foundInfo;
            this.setState(
              {
                ec_composition_members: ec_composition_EC,
                ec_training: ec_training,
              },
              () => {
                this.splitMembers(ec_training);
              }
            );
          } else {
            this.setState({ enrichedCommittee: null }, () => {
              getSimilarCommittee(this.state.id)
                .then((gotSimilarCom) => {
                  console.log(gotSimilarCom, "got similar committee");
                  let similarCom = gotSimilarCom;
                  if (similarCom && similarCom.approval_status === "accepted") {
                    this.setState({ similarCommittee: similarCom }, () => {
                      this.setState(
                        {
                          ec_composition_members:
                            similarCom &&
                            similarCom.enriched_information &&
                            similarCom.enriched_information.ec_composition_EC,
                          ec_training:
                            similarCom &&
                            similarCom.enriched_information &&
                            similarCom.enriched_information.ec_training,
                        },
                        () => {
                          this.splitMembers(
                            similarCom &&
                              similarCom.enriched_information &&
                              similarCom.enriched_information.ec_training
                          );
                        }
                      );
                    });
                  } else {
                    this.setState({ similarCommittee: null });
                  }
                })
                .catch((errSimilarCommittee) => {
                  console.log(
                    errSimilarCommittee,
                    "err fetching similar committee"
                  );
                });
            });
          }
        });
        // }
      })
      .catch((errFoundInfo) => {
        console.log(errFoundInfo, "err with info");
      });
  };

  setSessionBody = () => {
    let sessionData = window.sessionStorage.getItem("enrichedinfo");
    if (sessionData !== null) {
      let parsed = objectDecrypt(sessionData);
      let body = {
        id: this.state.id,
        committee_type:
          this.props.user &&
          this.props.user.others &&
          this.props.user.others[0].ethics_committee &&
          this.props.user.others[0].ethics_committee.type,
        committee_id: parsed["committee"] && parsed["committee"]["_id"],
        committee_registered_number:
          parsed["committee"] && parsed["committee"]["registered_number"],
        committee: parsed["committee"],
        updated_by: this.props.user._id,
        pincode: parsed["pincode"],
        cdsco_approval_date: parsed["cdsco_approval_date"],
        cdsco_validity_date: parsed["cdsco_validity_date"],
        dhr_approval_date: parsed["dhr_approval_date"],
        dhr_validity_date: parsed["dhr_validity_date"],
        next_meeting_date: parsed["next_meeting_date"],
        how_often_ec_meet: parsed["how_often_ec_meet"],
        ec_existing_since: parsed["ec_existing_since"],
        list_of_holidays: parsed["list_of_holidays"],
        ec_composition_EC: parsed["ec_composition_members"],
        ec_days_prior_dossier_submission:
          parsed["ec_days_prior_dossier_submission"],
        ec_issue_approval_post_meeting:
          parsed["ec_issue_approval_post_meeting"],
        list_of_sops: parsed["list_of_sops"],
        conflict_of_interest_policy: parsed["conflict_of_interest_policy"],
        ec_training: this.state.ec_training,
        process_of_ec_submission: parsed["process_of_ec_submission"],
        submission_process: "",
        ec_title_of_policy: parsed["ec_title_of_policy"],
        is_paper_based: parsed["is_paper_based"],
        is_online_based: parsed["is_online_based"],
        submission_process_paper_based_copies:
          parsed["submission_process_paper_based_copies"],
        submission_process_online_description:
          parsed["submission_process_online_description"],
        submission_review_check_list: [],
        review_checklist_process: parsed["review_checklist_process"],
        ec_initial_review_fees:
          parsed["ec_initial_review_fees"] &&
          parsed["ec_initial_review_fees"].includes("INR") === true
            ? parsed["ec_initial_review_fees"]
            : parsed["ec_initial_review_fees"] + "INR",
        ec_amendment_review_fees:
          parsed["ec_amendment_review_fees"] &&
          parsed["ec_amendment_review_fees"].includes("INR") === true
            ? parsed["ec_amendment_review_fees"]
            : parsed["ec_amendment_review_fees"] + "INR",
        ec_final_review_process:
          parsed["ec_final_review_process"] &&
          parsed["ec_final_review_process"].includes("INR") === true
            ? parsed["ec_final_review_process"]
            : parsed["ec_final_review_process"] + "INR",
        mode_of_payment: parsed["mode_of_payment"],
        payee_name: parsed["payee_name"],
        ec_gst:
          parsed["ec_gst"] && parsed["ec_gst"].includes("%") === true
            ? parsed["ec_gst"]
            : parsed["ec_gst"] + "%",
        ec_tds: parsed["ec_tds"],
        submission_process_type: parsed["submission_process_type"],
        ec_submission_forms: [],
        process_for_sae_review: parsed["sae_review"],
        ec_policy_effective_date: parsed["ec_policy_effective_date"],
        ec_policy_validity_date: parsed["ec_policy_validity_date"],
        finance: parsed["finance"],
        frequency_of_ec_site_monitoring:
          parsed["frequency_of_ec_site_monitoring"],
        timeline_for_ec_dossier_submission:
          parsed["timeline_for_ec_dossier_submission"],
        approval_for_ec_and_scientific_timeline:
          parsed["approval_for_ec_and_scientific_timeline"],
        interim_progress_report_timeline:
          parsed["interim_progress_report_timeline"],
        timeline_for_expedite_review_and_approval:
          parsed["timeline_for_expedite_review_and_approval"],
        document_storage_facility: parsed["document_storage_facility"],
        fire_extingusher: parsed["fire_extingusher"],
        emergency_alarm: parsed["emergency_alarm"],
        parent_institute_name: parsed["parent_institute_name"],
        parent_registration_number: parsed["parent_registration_number"],
        primary_contact: {
          name: parsed["primary_contact_name"],
          email: parsed["primary_contact_email"],
          contact_number: parsed["primary_contact_contact_number"],
        },
        alternative_contact: {
          name: parsed["alternative_contact_name"],
          email: parsed["alternative_contact_email"],
          contact_number: parsed["alternative_contact_contact_number"],
        },
      };
      return body;
    } else {
      return null;
    }
  };

  fetchDetails=()=>{
    getCommitteeDetails(
      this.props.user && this.props.user.others[0].ethics_committee.type,
      this.state.id
    )
      .then((committee) => {
        console.log(committee, "found committee");
        this.setState({ committee: committee }, () => {
          this.fetchEnrichedInfo(committee && committee.registered_number);
        });
      })
      .catch((errCommittee) => {
        console.log(errCommittee, "err found committee");
        this.setState({ committee: null });
      });
  }

  componentDidMount() {
    console.log(this.props.match.params.id, "info");
    this.setState(
      {
        id: this.props.match.params.id,
      },
      () => {
        this.fetchDetails()
      }
    );
  }

  changeRowMemberStatus = (row, member, selected) => {
    this.setState({ editing_row_id : row.id})
    let training = this.state.ec_training;
    let rowIndex = training.indexOf(row);
    console.log(rowIndex, "row index");
    let rmembers = row[row.id + "members"];
    let filterMember =
      rmembers.filter((mem) => mem.id === member.id) &&
      rmembers.filter((mem) => mem.id === member.id)[0];
    let memberIndex = rmembers.indexOf(filterMember);
    filterMember["checked"] = selected;
    console.log(training, "before training");
    console.log(memberIndex, filterMember, "after");
    rmembers[memberIndex] = filterMember;
    row[row.id + "members"] = rmembers;
    console.log(row, "after row");
    training[rowIndex] = row;
    console.log(training, "training rows");
    this.setState({ ec_training: training });
  };

  getMember = (targetName) => {
    let replace_topic = targetName.replace("topic", "");
    let replace_training_date = targetName.replace("training_date", "");
    let replace_topicName = targetName.replace("topicName","");
    if(targetName.endsWith("topic") === true){
      return replace_topic
    } else if(targetName.endsWith("training_date") === true){
      return replace_training_date
    } else if(targetName.endsWith("topicName") === true){
      return replace_topicName
    }
  };

  handleChange = (e) => {
    if (e.target.name.endsWith("training_date") === true) {
      let check = this.checkDates(e.target.value);
      if (check === true) {
        alert("Training date already exists, Please select other date");
      } else {
        this.setState({ [e.target.name]: e.target.value }, () => {
          let filterRow =
            this.state.ec_training &&
            this.state.ec_training.filter((row) =>
              this.getMember(e.target.name) === row.id
              // e.target.name.includes(row.id)
            );
          if (filterRow && filterRow.length > 0) {
            filterRow[0][e.target.name] = e.target.value;
            this.setState({ editing_row_id: filterRow[0].id,hideAdd:true });
          }
        });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
        let filterRow =
          this.state.ec_training &&
          this.state.ec_training.filter((row) =>
            this.getMember(e.target.name) === row.id
            // e.target.name.includes(row.id)
          );
        if (filterRow && filterRow.length > 0) {
          filterRow[0][e.target.name] = e.target.value;
          this.setState({ editing_row_id: filterRow[0].id,hideAdd: true });
        }
      });
    }
  };

  splitMembers = (members) => {
    if (members && members.length > 0) {
      members.map((member) => {
        console.log(member, "member");
        let keys = Object.keys(member);
        keys.map((key) => {
          console.log(key, member[key]);
          if (key === "id") {
            console.log(key);
          } else {
            this.setState({ [key]: member[key] });
          }
        });
      });
    } else {
      console.log("No members found");
    }
  };

  setSimilarCommitteeSessionBody = (scomId) => {
    let similarSessionData = window.sessionStorage.getItem(
      `${scomId}-similarinfo`
    );
    if (similarSessionData !== null) {
      let parsed = objectDecrypt(similarSessionData);
      parsed["ec_training"] = this.state.ec_training;
      console.log(parsed, "checking ecc members while updating escom");
      let body = { enriched_information: parsed };
      return body;
    } else {
      return null;
    }
  };
  
  addSimilarComInfo = () => {
    if (this.state.similarCommittee !== null) {
      let info =
        this.state.similarCommittee &&
        this.state.similarCommittee.enriched_information;
      info["ec_training"] = this.state.ec_training;
      let req_body = { enriched_information: info };
      console.log(info, "checking id while updating escom");
      updatedSimilarCommittee(
        this.state.similarCommittee && this.state.similarCommittee._id,
        this.state.similarCommittee &&
          this.state.similarCommittee.enrichedCommittee,
        this.setSimilarCommitteeSessionBody(
          this.state.similarCommittee && this.state.similarCommittee._id
        ) === null
          ? req_body
          : this.setSimilarCommitteeSessionBody(
              this.state.similarCommittee && this.state.similarCommittee._id
            )
      )
        .then((updatedScom) => {
          console.log(updatedScom, "updated similar committee");
          alert("Committee details updated successfully");
          let update = {
            active: false,
            title: `Similar committee enriched information updated`,
            update: {
              type: "ethics committee",
              item: this.state.similarCommittee && this.state.similarCommittee._id,
            },
            description: `Enriched information updated by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_row_id:"" }, () => {
            //window.location.href = "/training/" + this.state.id;
            this.fetchDetails()
          });
        })
        .catch((errUpdatedScom) => {
          console.log(
            errUpdatedScom,
            "err while updating similar committee with enriched info"
          );
          alert("Error with updating committee details");
        });
    } else {
      console.log(this.state.similarCommittee, "error fetching similar com");
      alert("Error with updating committee details");
    }
  };

  addInfo = () => {
    let { user } = this.props;
    let { ec_training } = this.state;
    let req_body = {
      id: this.state.id,
      committee_type:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0].ethics_committee &&
        this.props.user.others[0].ethics_committee.type,
      ec_training: ec_training,
      committee:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0].ethics_committee,
      committee_registered_number:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0] &&
        this.props.user.others[0].ethics_committee &&
        this.props.user.others[0].ethics_committee.registered_number,
    };
    if (
      this.state.enrichedCommittee === "" ||
      this.state.enrichedCommittee === " " ||
      this.state.enrichedCommittee === null ||
      this.state.enrichedCommittee === undefined
    ) {
      additionalInformation(
        this.setSessionBody() === null ? req_body : this.setSessionBody()
      )
        .then((info) => {
          console.log(info, "info");
          alert("Information saved successfully");
          let update = {
            active: false,
            title: `Enriched information created`,
            update: {
              type: "enriched_data_created",
              user_name : this.props.user.username,
              ec_registered_number : req_body.committee_registered_number,
              item: this.state.enrichedCommittee._id,
            },
            description: `Enriched information created by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_row_id:"" }, () => {
            //window.location.href = "/training/" + this.state.id;
            this.fetchDetails()
          });
          //window.location.href = "/addinfo/" + this.state.id;
        })
        .catch((errInfo) => {
          console.log(errInfo, "err with info");
          alert("Error with submitting training information");
        });
    } else {
      let req_body = {
        ec_training: ec_training,
      };
      console.log(req_body, "req body before submitting");
      updateCommittee(
        this.state.enrichedCommittee._id,
        this.setSessionBody() === null ? req_body : this.setSessionBody()
      )
        .then((success) => {
          console.log(success, "committee updated successfully");
          alert("Updated Committee Details Successfully");
          let update = {
            active: false,
            title: `Enriched information updated`,
            update: {
              type: "enriched_data_created",
              user_name : this.props.user.username,
              ec_registered_number : req_body.committee_registered_number,
              item: this.state.enrichedCommittee._id,
            },
            description: `Enriched information updated by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_row_id:"" }, () => {
            //window.location.href = "/training/" + this.state.id;
            this.fetchDetails()
          });
          // getCommitteeDetails(
          //   this.state.committee.type,
          //   this.state.committee._id
          // )
          //   .then((com) => {
          //     console.log(com, "updated com");
          //     this.setState({ committee: com });
          //   })
          //   .catch((errCom) => {
          //     console.log(errCom, "error with fetching updated com");
          //     alert("Error with fetching updated committee");
          //   });
        })
        .catch((failure) => {
          console.log(failure, "error with updating committee details");
          alert("Error with updating committee details");
        });
    }
  };

  checkDates = (date) => {
    let training = this.state.ec_training;
    if (training && training.length > 0) {
      let rowDates = training.map((row) => row[row.id + "training_date"]);
      console.log(rowDates, "row dates");
      let filterDate = rowDates.filter((item) => item === date);
      console.log(filterDate, "date");
      if (filterDate && filterDate.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const { ec_training, hideAdd, editing_row_id } = this.state;
    const { user } = this.props;
    return (
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
        }}
        className="px-5 py-3 mb-5 pb-5"
      >
        <div className="d-flex align-items-center justify-content-between mx-3 col-lg-7 px-0 mx-0">
          <p
            style={{
              color: "#BC5405",
              cursor: "pointer",
              fontWeight: "bolder",
              textTransform: "uppercase",
            }}
            className="mb-0 col-lg-1 mx-0 px-0"
            onClick={() =>
              (window.location.href = `/addinfo/${user.others[0].ethics_committee._id}`)
            }
          >
            Back
          </p>
          <h3 className="mx-5">EC Training</h3>
          {/* <button
            onClick={() =>
              this.state.similarCommittee !== null
                ? this.addSimilarComInfo()
                : this.addInfo()
            }
            className="btn btn-sm login mx-2"
          >
            SUBMIT
          </button> */}
        </div>
        {ec_training && ec_training.length > 0 ? (
          ec_training.map((row) => (
            <div className="mx-3 my-2">
              <div className="row col-lg-12">
                <div className="col-lg-3 d-flex align-items-center justify-content-center">
                  <p className="mb-0 mx-2">Topic</p>
                  <select
                    onChange={this.handleChange}
                    name={row.id + "topic"}
                    value={this.state[row.id + "topic"]}
                    type="text"
                    placeholder="topic"
                    className="form-control"
                  >
                    <option value="">Please Select</option>
                    {/* <option value="Scientific">Scientific</option>
                    <option value="Ethics">Ethics</option> */}
                    <option value="ICH-GCP Guidelines">ICH-GCP Guidelines</option>
                    <option value="NDCT Rules">NDCT Rules</option>
                    <option value="EC SOP">EC SOP</option>
                    <option value="ICMR Guidelines">ICMR Guidelines</option>
                    <option value="Other">Other</option>
                  </select>
                  {this.state[row.id+"topic"] === "Other" ? 
                <input
                  onChange={this.handleChange}
                  type="text"
                  name={row.id + "topicName"}
                  value={this.state[row.id + "topicName"]}
                  placeholder="Topic"
                  className="form-control"
                ></input> : null }
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-center">
                  <p className="mb-0 col-lg-2 px-0 mx-2">Training Date</p>
                  <input
                    onChange={this.handleChange}
                    name={row.id + "training_date"}
                    value={this.state[row.id + "training_date"]}
                    type="date"
                    placeholder="Date of Regulatory Training"
                    className="form-control col-lg-5"
                  ></input>
                </div>
                <p
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => {
                    this.setState({
                      selected_row_id: row.id,
                      selected_row: row,
                      showMembers: true,
                    });
                  }}
                  className="mb-0 col-lg-2"
                >
                  select members
                </p>
                {row && row.id === editing_row_id ? 
                <button
                  onClick={() =>
                    this.state.similarCommittee !== null
                      ? this.addSimilarComInfo()
                      : this.addInfo()
                  }
                  className="btn btn-sm btn-primary mx-2"
                >
                  save
                </button> : null}
              </div>
              <div style={{}}>
                {row.id === this.state.selected_row_id ? (
                  <div
                    className={
                      this.state.showMembers === true
                        ? "d-block modal"
                        : "d-none"
                    }
                  >
                    <div className="modal-content container">
                      
                      <div className="modal-header">
                        <div>
                        <p className="mb-0">
                        Topic - {
                          this.state.selected_row[
                            this.state.selected_row.id + "topic"
                          ]
                        }, Training Date - {
                          this.state.selected_row[
                            this.state.selected_row.id + "training_date"
                          ]
                        }
                      </p>
                      <p className="mb-0">( Please click member item below to update the status )</p>
                        </div>
                        <button
                          style={{ outline: "none" }}
                          onClick={() => this.setState({ showMembers: false })}
                          className="close"
                        >
                          &times;
                        </button>
                      </div>
                      <div style={{overflowY : "scroll", height : 300}} className="modal-body mb-5 pb-5">
                        {this.state.selected_row[
                          this.state.selected_row.id + "members"
                        ] &&
                        this.state.selected_row[
                          this.state.selected_row.id + "members"
                        ].length > 0 ? (
                          this.state.selected_row[
                            this.state.selected_row.id + "members"
                          ].map((mem) => (
                            <div className="d-flex align-items-center justify-content-start my-2">
                              <p
                              onClick={() =>
                                this.changeRowMemberStatus(
                                  row,
                                  mem,
                                  !mem.checked
                                )
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  mem.checked === true ? "#76D6EE" : "#D3D3D3",
                              }}
                              className="col-lg-4 mb-0"
                            >
                              {mem[mem.id + "name"]}
                              <span className="mr-5"> - {mem[mem.id + "role"]}</span>
                            </p>
                            <p style={{ color: 'green' }} className="mb-0 mx-5">{mem.checked === true ? "Attended" : null}</p>
                            </div>
                          ))
                        ) : (
                          <p>
                            EC Composition members not found. Please add in ec
                            compostion section.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <p>Please click below button to add row</p>
        )}
        <button
          onClick={() =>
            this.createTrainingRow(ec_training && ec_training.length)
          }
          disabled={hideAdd === true ? true : false}
          className="btn btn-primary btn-small mx-3"
        >
          add
        </button>
      </div>
    );
  }
}

export default withRouter(Training);
