import React from "react";

const NotFound = ({ message }) => {
  return (
    <div className={message && message.length > 0 ? "mx-auto text-center py-5" : "mx-auto text-center my-5"}>
      <h3>{message && message.length > 0 ? message : "Not Found"}</h3>
      {message && message.length > 0 ? (
        <button
          onClick={() => (window.location.href = "/")}
          className="btn btn-lg login m-3"
        >
          Login
        </button>
      ) : null}
    </div>
  );
};

export default NotFound;
