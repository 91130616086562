import React, { useEffect, useState } from "react";
import "../../App.css";
import { Logo, Login, Email, passwordLock } from "../../theme/icons";
import {
  getCommitteeDetails,
  getCommitteeFromAddress,
  login,
  loginWithGoogle,
} from "../../helpers/api";
import EC_Committees from "./EC_Committees";
import RegisterModal from "../../components/RegisterModal";
import NotFound from "../../components/NotFound";
import { encrypt } from "../../helpers/helper";
import { Redirect } from "react-router";

const Eclogin = (props) => {
  const [showECSelect, changeShowECSelect] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState({});
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const openRegisterModal = () => {
    setShowRegisterModal(true);
  };
  const closeRegisterModal = () => {
    setShowRegisterModal(false);
    console.log(showRegisterModal);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(encrypt(e.target.value));
  };
  useEffect(() => {
    if (Object.keys(user) && Object.keys(user).length > 0) {
      if (user.role === "ec_qcr_admin") {
        window.location.href = "/admin/users";
      } else if (user.role === "ec_coordinator") {
        if (user && user.others && user.others.length > 0) {
          console.log(user.others[0].ethics_committee, "found");
          window.sessionStorage.setItem("userEmail", user.email);
          window.sessionStorage.setItem("userStatus", user.active);
          window.sessionStorage.setItem("userRole", user.role);
          if (user.active === true) {
            window.location.href = `/addinfo/${user.others[0].ethics_committee._id}`;
          } else {
            window.location.href = "/in-review";
          }
        }
      } else {
        if (user.active === true) {
          window.location.href = "/home";
        } else {
          window.location.href = "/in-review";
        }
      }
    }
  }, [user]);
  
  const googleLogin = () => {
    window.location.href = `http://localhost:4000/auth/google`;
  };
  const Next = () => {
    let check_empty_values = [email, password];
    let check = check_empty_values.filter(
      (item) =>
        item === "" || item === " " || item === null || item === undefined
    );
    if (check && check.length > 0) {
      alert("Please enter all required fields");
    } else {
      login(email, password)
        .then((login) => {
          if (login === null) {
            alert("user not found");
          } else {
            setUser(login);
          }
        })
        .catch((errLogin) => {
          console.log(errLogin, "err login");
          alert("user not found");
        });
    }
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      Next();
    }
  };

  const navigateBack = () =>{
    window.location.href = "/"
  }

  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
      }}
      className="container-fluid"
    >
      {props && props.userAuthorized === false ? (
        showECSelect === true ? (
          <EC_Committees />
        ) : (
          <div className="mx-auto container text-center">
            <div className="pt-5 intro">
              <img className="mb-2 new_logo" src={Logo} alt="Logo"></img>
              <div className="col-lg-7 d-flex align-items-center justify-content-between pl-5 my-3">
              <p
                style={{
                  color: "#BC5405",
                  cursor: "pointer",
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                  fontSize: 15
                }}
                className="mb-0"
                onClick={() => navigateBack() }
              >
                Back
              </p>
              <h5 style={{ color: "#BC5405" }} className="mb-0 ml-4">
                Ethical Committees
              </h5>
              </div>
              {/* <h2>Login</h2>
            <p style={{ fontFamily: "LatoRegular" }}>
              Independent Researcher / CRO Registration
            </p> */}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="col-lg-7 p-0 m-0">
                  <img className="circle-img" src={Login} alt="login"></img>
                  <div className="circle"></div>
                </div>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="col-lg-10">
                  <div
                    style={{
                      backgroundColor: "white",
                      border: "1.5px solid #646464",
                      borderRadius: 5,
                    }}
                    className="input-group input-group-lg p-0 my-3 mx-2 mx-auto"
                  >
                    <img
                      style={{
                        height: 39,
                        width: 39,
                        backgroundColor: "#9BCDEA",
                        padding: 7,
                        borderRadius: 3,
                      }}
                      src={Email}
                      alt="email"
                      className="img-responsive m-1"
                    ></img>
                    <input
                      onChange={handleEmailChange}
                      className="form-control input-lg"
                      style={{
                        border: "none",
                        outline: "none",
                        boxSizing: "border-box",
                      }}
                      onKeyPress={onEnter}
                      type="text"
                      name="email"
                      placeholder="Email*"
                    ></input>
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      border: "1.5px solid #646464",
                      borderRadius: 5,
                    }}
                    className="input-group input-group-lg p-0 my-3 mx-2 mx-auto"
                  >
                    <img
                      style={{
                        height: 39,
                        width: 39,
                        backgroundColor: "#D9B7E9",
                        padding: 7,
                        borderRadius: 3,
                      }}
                      src={passwordLock}
                      alt="password"
                      className="img-responsive m-1"
                    ></img>
                    <input
                      onChange={handlePasswordChange}
                      className="form-control input-lg"
                      style={{
                        border: "none",
                        outline: "none",
                        boxSizing: "border-box",
                      }}
                      onKeyPress={onEnter}
                      type="password"
                      name="password"
                      placeholder="Password*"
                    ></input>
                  </div>
                  <h3 style={{ cursor : 'pointer' }} onClick={()=> window.location.href = "/forgot-password"} className="forgot text-right mb-0">Forgot Password?</h3>
                  <button
                    onClick={() => Next()}
                    className="btn btn-lg login col-lg-12 mt-5"
                  >
                    LOGIN
                  </button>
                  {/* <p className="mb-0 my-2">or</p>
                <button
                  style={{ borderColor: "#BC5405", fontSize: 13 }}
                  onClick={() => googleLogin()}
                  className="social_media_login btn btn-lg col-lg-12 my-2"
                >
                  Continue with Google
                </button> */}
                  {/* <RegisterModal
                    showRegisterModal={showRegisterModal}
                    openRegisterModal={openRegisterModal}
                    closeRegisterModal={closeRegisterModal}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        (props && props.user && props.user.role === "ec_coordinator" && (
          <Redirect
            to={`/addinfo/${props.user.others[0].ethics_committee._id}`}
          />
        )) ||
        (props &&
          props.user &&
          props.user.role === "ec_independent_researcher" && (
            <Redirect to={`/home`} />
          )) ||
        (props && props.user && props.user.role === "ec_qcr_admin" && (
          <Redirect to={`/admin/users`} />
        ))
      )}
    </div>
  );
};

export default Eclogin;
