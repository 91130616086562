import React from "react";
const ECOffice = (props) => {
  const {
    handleChange,
    checkboxChange,
    document_storage_facility,
    fire_extingusher,
    emergency_alarm,
  } = props;
  return (
    <div className="row text-left">
      <div className="col-lg-6">
        <p className="mb-0">Document Storage Facility</p>
        <select
          onChange={handleChange}
          name="document_storage_facility"
          value={document_storage_facility}
          type="text"
          placeholder="Document storage facility"
          className="form-control"
        >
          <option>Please select</option>
          <option>Ongoing</option>
          <option>Long Term</option>
        </select>
      </div>
      <div className="col-lg-6 row">
        <div className="col-lg-5 d-flex align-items-center justify-content-center">
          <input
            onChange={checkboxChange}
            name="fire_extingusher"
            value={fire_extingusher}
            checked={fire_extingusher}
            type="checkbox"
            className="mt-2 mr-2"
          ></input>
          <p className="mb-0 mt-2">Fire Extingusher</p>
        </div>
        <div className="col-lg-5 d-flex align-items-center justify-content-center">
          <input
            onChange={checkboxChange}
            name="emergency_alarm"
            value={emergency_alarm}
            checked={emergency_alarm}
            type="checkbox"
            className="mt-2 mr-2"
          ></input>
          <p className="mb-0 mt-2">Emergency Alarm</p>
        </div>
      </div>
    </div>
  );
};
export default ECOffice;
