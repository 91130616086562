import React from "react";
const Timeline = (props) => {
  const {
    handleChange,
    timeline_for_ec_dossier_submission,
    approval_for_ec_and_scientific_timeline,
    interim_progress_report_timeline,
    timeline_for_expedite_review_and_approval,
    ec_days_prior_dossier_submission,
    ec_issue_approval_post_meeting,
  } = props;
  return (
    <div className="text-left">
      <div className="row">
        <div className="col-lg-4">
          <p>Frequency of EC Site Monitoring Visit</p>
          <select
            onChange={handleChange}
            name="timeline_for_ec_dossier_submission"
            value={timeline_for_ec_dossier_submission}
            type="text"
            placeholder="EC Dossier Submission Timeline<"
            className="form-control"
          >
            <option>Please select</option>
            <option>21 days prior</option>
            <option>10 days prior</option>
            <option>7 days prior</option>
            <option>Any other</option>
          </select>
        </div>
        <div className="col-lg-4">
          <p>How many days prior dossier submitted to ethics committee?</p>
          <input
            onChange={handleChange}
            name="ec_days_prior_dossier_submission"
            value={ec_days_prior_dossier_submission}
            type="text"
            placeholder="Enter Number of Days"
            className="form-control"
          ></input>
        </div>
        <div className="col-lg-4">
          <p>How long EC will take to issue approval letter post meeting</p>
          <input
            onChange={handleChange}
            name="ec_issue_approval_post_meeting"
            value={ec_issue_approval_post_meeting}
            type="text"
            placeholder="Enter Number of Days"
            className="form-control"
          ></input>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <p className="mb-0 my-2">Approval for EC & Scientific Timeline</p>
          <input
            onChange={handleChange}
            name="approval_for_ec_and_scientific_timeline"
            value={approval_for_ec_and_scientific_timeline}
            type="date"
            placeholder="Approval for EC & Scientific Timeline"
            className="form-control"
          ></input>
        </div>
        <div className="col-lg-4">
          <p className="mb-0 my-2">Interim progress report timeline</p>
          <input
            onChange={handleChange}
            name="interim_progress_report_timeline"
            value={interim_progress_report_timeline}
            type="text"
            placeholder="Please enter number of days"
            className="form-control"
          ></input>
        </div>
        <div className="col-lg-4">
          <p className="mb-0 my-2">Timeline for expedite review and approval</p>
          <input
            onChange={handleChange}
            name="timeline_for_expedite_review_and_approval"
            value={timeline_for_expedite_review_and_approval}
            type="date"
            placeholder="Timeline for expedite review and approval"
            className="form-control"
          ></input>
        </div>
      </div>
    </div>
  );
};
export default Timeline;
