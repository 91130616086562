import React,{useState} from 'react';
import { forgotPwd } from '../helpers/api';
import { validateEmail } from '../helpers/helper';

const ForgotPassword = (props) =>{
    const [registered_mail , setRegisteredEmail] = useState("");
    const [isEmail, setEmail] = useState("")
    const [showEmailError, setShowEmailError] = useState(false)
    const [showInstructions, setShowInstructions] = useState(false)


    const handleChange = (e) =>{
        setRegisteredEmail(e.target.value);
        let checkEmail = validateEmail(e.target.value);
        // console.log(checkEmail, 'valid')
        if(checkEmail === false){
            setShowEmailError(true)
        } else{
            setShowEmailError(false)
        }
    }

    const next = () =>{
        if(registered_mail && registered_mail.length > 0){
            let input = {
                subject : "Password reset link - QCR ethical committees",
                to : registered_mail,
                html : "<div><h6>is it working? </h6><p>lets check</p></div>"
            }
            forgotPwd(input).then(success=>{
                console.log(success , 'email sent');
                setShowInstructions(true)
            }).catch(failure=>{
                console.log(failure, 'err with sending email')
                alert("Error sending email")
            })
        }else{
            setEmail(false);
            alert("Please enter registered email")
        }
    }
    return(
        <div className='my-5 py-5 container mb-5'>
            <div className='col-lg-8 mx-auto text-left mb-5'>
                <div className='my-5'>
                    <p className='mb-0'>Enter your registered mail address</p>
                    <input className='form-control' type={"email"} name="registered_mail" value = {registered_mail} onChange={handleChange}></input>
                </div>
                { showEmailError === true ? <p style={{ color : 'red', fontSize : 15 }} className='mb-0'>Please enter valid email</p> : null }
                <button onClick={()=>next()} className='login btn btn-small mb-5'>submit</button>
                { isEmail === false ? <h6>User not found with your entered email address. Please try again.</h6> : null }
                { showInstructions === true ? <h6 className='my-3' style={{ lineHeight : '2'}}>An password reset link has been sent to your email. Please check in spam folder too. This link will be active for 15 minutes only.</h6> : null }
                <button onClick={()=>{
                    window.location.href = "/"
                }} className='login btn btn-small mx-2 mb-5'>Back to Home</button>
            </div>
        </div>
    )
}

export default ForgotPassword;