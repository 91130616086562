import React from 'react';
import { openEnquiries } from '../../theme/icons';

const EnquiryLabel = ({ icon,count, title, bgColor, handleClick}) =>{
return(
    <div onClick={()=>handleClick(title)} style={{borderRadius:4,backgroundColor:bgColor, cursor:'pointer'}} className={`${icon === openEnquiries ? "py-2 px-3 mx-2" : "py-3 px-3 mx-2"}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={`icon ${icon === openEnquiries ? "p-4" : "p-3"}`} >
                        <img style={{
                             height:35,
                            // width:35
                            }} className="img-responsive" src={icon} alt={`${title}-enquiries`}></img>
                    </div>
                    <div>
                        <h1 className="count">{count}</h1>
                        <p className="mb-0 status">{title}</p>
                    </div>
                </div>
            </div>
)
}

export default EnquiryLabel