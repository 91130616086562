import React from "react";
import {
  createGeneralProspectEnquiry
} from "../helpers/api";
import { withRouter } from "react-router-dom";

class GeneralProspectEnquiry extends React.Component {
  constructor(props) {
    super();
    this.state = {
        name : "",
        organization : "",
        phone : "",
        email : "",
        question : "",
        others : ""
    };
  }

  clearState = () =>{
    this.setState({
        name : "",
        organization : "",
        phone : "",
        email : "",
        question : "",
        others : ""
    })
  }

  submit = () =>{
    let req_body = {
        name : this.state.name,
        organization : this.state.organization,
        phone : this.state.phone,
        email : this.state.email,
        question : this.state.question,
        others : this.state.others,
        raised_by : {
          user_name : this.props.user.username,
          user_id : this.props.user._id,
          user_email : this.props.user.email,
          user_role: this.props.user && this.props.user.role,
        }
    }
    createGeneralProspectEnquiry(req_body).then(genEnquiry=>{
        console.log(genEnquiry,' gen enquiry created');
        alert("General enquiry created successfully");
        this.clearState()
    }).catch(errGenEnquiry=>{
        console.log(errGenEnquiry,'err while creating general enquiry');
        alert("Error while creating general enquiry");
        this.clearState()
    })
  }

  handleChange = (e) =>{
    this.setState({
        [e.target.name] : e.target.value
    })
  }

  render() {
    const { name, organization, phone, email, question } = this.state;
    const { user } = this.props;
    return (
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
        }}
        className="px-5 py-3 mb-5 pb-5"
      >
        <div>
            <p
                style={{
                color: "#BC5405",
                cursor: "pointer",
                fontWeight: "bolder",
                textTransform: "uppercase",
                }}
                className="mb-0 col-lg-1 mx-0 px-0 mt-2"
                onClick={() => window.location.href = "/home"}
            >
                Back
            </p>
        </div>
        <div>
            <h6>Prospect general inquiry information for the Researcher/Investigator</h6>
            <div className="my-2">
                <input
                    onChange={this.handleChange}
                    type="text"
                    name={"name"}
                    value={name}
                    placeholder="The name of the Person"
                    className="form-control"
                ></input>
            </div>
            <div className="my-2">
                <input
                    onChange={this.handleChange}
                    type="text"
                    name={"organization"}
                    value={organization}
                    placeholder="The name of the Organization"
                    className="form-control"
                ></input>
            </div>
            <div className="row my-2">
                <div className="col-lg-6">
                    <input
                        onChange={this.handleChange}
                        type="text"
                        name={"email"}
                        value={email}
                        placeholder="Email address"
                        className="form-control"
                    ></input>
                </div>
                <div className="col-lg-6">
                    <input
                        onChange={this.handleChange}
                        type="number"
                        name={"phone"}
                        value={phone}
                        placeholder="Phone Number"
                        className="form-control"
                    ></input>
                </div>
            </div>
            <div className="my-3">
              <p className="mb-0">Please enter your query below</p>
              <textarea
                name="question"
                value={question}
                type="text"
                onChange={this.handleChange}
                placeholder="Enter details"
                rows={3}
                className="form-control input-lg"
              ></textarea>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <button
                onClick={() => this.submit()}
                className="btn btn-sm login"
                >
                SUBMIT
                </button>
            </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GeneralProspectEnquiry);
