import moment from "moment";
import React from "react";
import EnquiryCard from "../../components/enquiry/EnquiryCard";
import NewEnquiry from "../../components/enquiry/NewEnquiry";
import NoEnquiry from "../../components/enquiry/NoEnquiry";
import {
  createEnquiry,
  createUpdate,
  getEnquiries,
  getEnrichedInfo,
  getSimilarCommittee,
  getUsers,
  getUserWithId,
  updateEnquiry,
  uploadAttachment,
} from "../../helpers/api";
import { addUnRead, getCurrentUser } from "../../helpers/helper";
import { ecSize, filter, imageAttachment } from "../../theme/icons";

class Enquiry extends React.Component {
  constructor(props) {
    super();
    this.state = {
      enquiryCommittees: [],
      naitik_ethics_committees: [],
      cdsco_reregistered_ethics_committees: [],
      cdsco_registered_ethics_committees: [],
      title: "",
      description: "",
      ethics_committees: [],
      related_ec_type: "",
      raised_by: "",
      responded_by: "",
      assignee: "",
      adminUser : "",
      response: "",
      committees: [],
      file: "",
      fileName: "",
      fileType: "",
      fileUrl: "",
      status: "",
      replies: [],
      allEnquiries: [],
      researcherEnquiries: [],
      coordinatorEnquiries: [],
      filter_enquiry_status: "opened",
      filter_priority_level: "high",
      opened_enquiries: [],
      accepted_enquiries: [],
      rejected_enquiries: [],
      closed_enquiries: [],
      enquiries: [],
      showNewEnquiry: false,
      enquiry_status_type: "",
      enquiry_priority_level: "",
    };
  }

  getEnquiryRelatedECType = (items) => {
    console.log(items, "items");
    if (items && items.length > 0) {
      let filterECType = items.filter((item) => item !== null);
      console.log(filterECType, "items ec");
      return filterECType && filterECType[0];
    }
  };

  handleAttachmentChange = (e) => {
    let file = e.target.files[0];
    let fileName = e.target.files[0].name;
    let fileType = e.target.files[0].type;
    console.log(file, "file details");
    this.setState({
      file: file,
      fileName: fileName,
      fileType: fileType,
    });
  };

  componentDidMount() {
    let naitik_ethics_committees =
      window.sessionStorage.getItem("naitik_ethics_committees") !== null
        ? window.sessionStorage.getItem("naitik_ethics_committees")
        : null;
    let cdsco_reregistered_ethics_committees =
      window.sessionStorage.getItem("cdsco_reregistered_ethics_committees") !==
      null
        ? window.sessionStorage.getItem("cdsco_reregistered_ethics_committees")
        : null;
    let cdsco_registered_ethics_committees =
      window.sessionStorage.getItem("cdsco_registered_ethics_committees") !==
      null
        ? window.sessionStorage.getItem("cdsco_registered_ethics_committees")
        : null;
    let parsed_naitik = JSON.parse(naitik_ethics_committees);
    let parsed_registered = JSON.parse(cdsco_registered_ethics_committees);
    let parsed_reregistered = JSON.parse(cdsco_reregistered_ethics_committees);
    this.setState(
      {
        naitik_ethics_committees:
          parsed_naitik === null ? [] : parsed_naitik.map((com) => com.id),
        cdsco_registered_ethics_committees:
          parsed_registered === null
            ? []
            : parsed_registered.map((com) => com.id),
        cdsco_reregistered_ethics_committees:
          parsed_reregistered === null
            ? []
            : parsed_reregistered.map((com) => com.id),
      },
      () => {
        let enquiry_comms = [
          parsed_naitik,
          parsed_registered,
          parsed_reregistered,
        ];
        console.log(
          this.getEnquiryRelatedECType(enquiry_comms),
          "enq related ec type"
        );
        let relatedECType = this.getEnquiryRelatedECType(enquiry_comms);
        this.setState({ related_ec_type: relatedECType });
        if (relatedECType !== undefined) {
          this.setState({ showNewEnquiry: true });
          this.getAssignee(
            relatedECType && relatedECType[0] && relatedECType[0].id
          );
        }
      }
    );
    getEnquiries("all")
      .then((enquiries) => {
        console.log(enquiries, "enquiries found");
        this.setState(
          {
            allEnquiries: enquiries,
          },
          () => {
            // let opened_enquiries = enquiries.filter(
            //   (enq) => enq.status === "opened"
            // );
            // let accepted_enquiries = enquiries.filter(
            //   (enq) => enq.status === "accepted"
            // );
            // let rejected_enquiries = enquiries.filter(
            //   (enq) => enq.status === "rejected"
            // );
            // let closed_enquiries = enquiries.filter(
            //   (enq) => enq.status === "closed"
            // );
            // let unopened_enquiries = [
            //   ...new Set([
            //     ...accepted_enquiries,
            //     ...rejected_enquiries,
            //     ...closed_enquiries,
            //   ]),
            // ];
            this.setState(
              {
                enquiries:
                  this.props.user.role === "ec_independent_researcher"
                    ? enquiries.filter(
                        (enq) => enq.raised_by.user_id === this.props.user._id
                      )
                    : this.props.user.role === "ec_coordinator"
                    ? enquiries && enquiries.length > 0
                      ? enquiries.filter(
                          (enq) => enq.assignee.user_id === this.props.user._id
                        )
                      : []
                    : enquiries,
              },
              () => {
                this.setState({
                  researcherEnquiries: enquiries,
                  coordinatorEnquiries: enquiries,
                },()=>{
                  this.applyFiltersChange(this.state.filter_enquiry_status, this.state.filter_priority_level)
                });
              }
            );
          }
        );
      })
      .catch((errEnq) => {
        console.log(errEnq, "err with fetching enquiries");
        this.setState({ enquiries: [] });
      });
  }

  handleNewEnquiryChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFiltersChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };

  removeSelectedEthicsCommittee = (number) => {
    if (number && number.length > 0) {
      let coms = this.state.ethics_committees;
      if (coms && coms.length > 0) {
        let filterCom = coms.filter((com) => com === number);
        if (filterCom && filterCom.length > 0) {
          coms.splice(coms.indexOf(filterCom[0]), 1);
          this.setState({ ethics_committees: coms });
        }
      }
    }
  };

  goToDetail = (id) => {
    window.location.href = "/enquiry/detail/" + id;
  };

  getAssignee = async (committeeId) => {
    let enrichedComs = await getEnrichedInfo("all")
      .then((enrichedComs) => enrichedComs)
      .catch((errScoms) => []);
    console.log(enrichedComs, "ecoms");
    let enrichedCommitteeWithSimilarComs =
      enrichedComs && enrichedComs.length > 0
        ? enrichedComs.filter(
            (ecom) =>
              ecom &&
              ecom.similar_committees &&
              ecom.similar_committees.length > 0
          )
        : [];
    let filterEnriched =
      enrichedCommitteeWithSimilarComs &&
      enrichedCommitteeWithSimilarComs.length > 0
        ? enrichedCommitteeWithSimilarComs.map((escom) => {
            if (
              escom &&
              escom.similar_committees &&
              escom.similar_committees.length > 0
            ) {
              let acceptedScoms = escom.similar_committees.filter(
                (scommittee) => scommittee.approval_status === "accepted"
              );
              return {
                user_id: escom && escom.updated_by,
                similar_committees: acceptedScoms,
              };
            }
          })
        : [];
        console.log(filterEnriched , 'filter enriched')
    getUsers()
      .then((users) => {
        console.log(users, committeeId, "users found");
        if (users && users.length > 0) {
          let adminUser = users.filter((user) => user.role === "ec_qcr_admin");
          this.setState({ adminUser : {
              user_id:adminUser[0]._id,
              user_email:adminUser[0].email,
              user_role:adminUser[0].role,
              user_name:adminUser[0].username,
          } })
          let coordinator_type_users = users.filter(
            (user) => user.role === "ec_coordinator"
          );
          console.log(coordinator_type_users, "coordinator users");
          let filterEthicsCommitteeUser = coordinator_type_users.filter(
            (user) => user.others[0].ethics_committee._id === committeeId
          );
          console.log(filterEthicsCommitteeUser, committeeId, "assignee found");
          if (
            filterEthicsCommitteeUser &&
            filterEthicsCommitteeUser.length > 0
          ) {
            let assigneeUser = {
              user_id:
                filterEthicsCommitteeUser && filterEthicsCommitteeUser[0]._id,
              username:
                filterEthicsCommitteeUser &&
                filterEthicsCommitteeUser[0].username,
              user_email:
                filterEthicsCommitteeUser && filterEthicsCommitteeUser[0].email,
              user_role:
                filterEthicsCommitteeUser && filterEthicsCommitteeUser[0].role,
              user_phone:
                filterEthicsCommitteeUser && filterEthicsCommitteeUser[0].phone,
            };
            this.setState({ assignee: assigneeUser });
          } else {
            if (filterEnriched && filterEnriched.length > 0) {
              console.log(filterEnriched, "filtered scom");
              let filterSimilarCommittee = filterEnriched && filterEnriched[0] && filterEnriched[0].similar_committees && filterEnriched[0].similar_committees.length > 0 ? filterEnriched[0].similar_committees.filter(scom=>scom.committee._id === committeeId) : [] ;
              console.log(filterSimilarCommittee, 'fitered scom')
              if(filterSimilarCommittee && filterSimilarCommittee.length > 0){
                let scomUser = coordinator_type_users.filter(
                  (cuser) =>
                    filterEnriched &&
                    filterEnriched[0] &&
                    filterEnriched[0].user_id === cuser._id
                );
                console.log(scomUser, 'scomUser')
                if (scomUser && scomUser.length > 0) {
                  let assigneeUser = {
                    user_id: scomUser && scomUser[0]._id,
                    username: scomUser && scomUser[0].username,
                    user_email: scomUser && scomUser[0].email,
                    user_role: scomUser && scomUser[0].role,
                    user_phone: scomUser && scomUser[0].phone,
                  };
                  this.setState({ assignee: assigneeUser });
                } else {
                  let assigneeUser = {
                    user_id: adminUser && adminUser[0]._id,
                    username: adminUser && adminUser[0].username,
                    user_email: adminUser && adminUser[0].email,
                    user_role: adminUser && adminUser[0].role,
                    user_phone: adminUser && adminUser[0].phone,
                  };
                  this.setState({ assignee: assigneeUser });
                }
              } else{
                let assigneeUser = {
                  user_id: adminUser && adminUser[0]._id,
                  username: adminUser && adminUser[0].username,
                  user_email: adminUser && adminUser[0].email,
                  user_role: adminUser && adminUser[0].role,
                  user_phone: adminUser && adminUser[0].phone,
                };
                this.setState({ assignee: assigneeUser });
              }
            } else {
              let assigneeUser = {
                user_id: adminUser && adminUser[0]._id,
                username: adminUser && adminUser[0].username,
                user_email: adminUser && adminUser[0].email,
                user_role: adminUser && adminUser[0].role,
                user_phone: adminUser && adminUser[0].phone,
              };
              this.setState({ assignee: assigneeUser });
            }
          }
        }
      })
      .catch((errUsers) => {
        console.log(errUsers, "err with fetching users");
        this.setState({ assignee: "" });
      });
  };

  checkForFileInput = (file, fileName, fileType) =>{
    let values = [file , fileName , fileType];
    let check = values.filter(item=> (item === "") || (item === " ")  || (item === undefined) || (item === null));
    if(check && check.length > 0){
      console.log(check, 'no file input');
      return false
    } else {
      return true
    }
  }

  getCurrentUser = (user) =>{
    let currentUser = {
      user_id: user._id,
      user_email : user.email,
      user_name : user.username,
      user_role : user.role
    }
    return currentUser
  }

  createNewEnquiry = () => {
    let {
      title,
      description,
      naitik_ethics_committees,
      cdsco_reregistered_ethics_committees,
      cdsco_registered_ethics_committees,
      raised_by,
      priority,
      responded_by,
      response,
      assignee,
      status,
      replies,
      file,
      fileName,
      fileType,
    } = this.state;
    let req_body = {
      title: title,
      description: description,
      naitik_ethics_committees:
        naitik_ethics_committees === null ? [] : naitik_ethics_committees,
      cdsco_reregistered_ethics_committees:
        cdsco_reregistered_ethics_committees === null
          ? []
          : cdsco_reregistered_ethics_committees,
      cdsco_registered_ethics_committees:
        cdsco_registered_ethics_committees === null
          ? []
          : cdsco_registered_ethics_committees,
      raised_by: {
        user_id: this.props.user && this.props.user._id,
        username: this.props.user && this.props.user.username,
        user_email: this.props.user && this.props.user.email,
        user_phone: this.props.user && this.props.user.phone,
        user_role: this.props.user && this.props.user.role,
      },
      responded_by: responded_by,
      response: response,
      assignee: assignee,
      status: "opened",
      priority: priority,
      replies: replies,
      file: file,
      fileName: fileName,
      fileType: fileType,
    };
    console.log(req_body, "enq related");
    createEnquiry(req_body)
      .then((newEnquiry) => {
        console.log(newEnquiry, newEnquiry.output._id, "new enquiry created");
        let enquiryId =
          newEnquiry && newEnquiry.output && newEnquiry.output._id;
        if(this.checkForFileInput(file, fileName, fileType) === true){
          uploadAttachment(file, fileName, fileType)
          .then((success) => {
            console.log(
              success,
              success.Location,
              enquiryId,
              "file uploaded successfully"
            );
            this.setState({ fileUrl: success.Location }, () => {
              updateEnquiry(enquiryId, { attachments: [this.state.fileUrl] })
                .then((success) => {
                  console.log(success, "file updated to enquiry");
                  alert(this.state.fileUrl);
                })
                .catch((failure) => {
                  console.log(failure, "err updating file to enquiry");
                });
            });
          })
          .catch((failure) => {
            console.log(failure, "err while uploading file");
          });
        }
        alert("Enquiry Created Successfully");
            window.sessionStorage.removeItem("naitik_ethics_committees");
            window.sessionStorage.removeItem(
              "cdsco_reregistered_ethics_committees"
            );
            window.sessionStorage.removeItem(
              "cdsco_registered_ethics_committees"
            );
            let update = {
              active: false ,
              title: "New enquiry created",
              update: {
                type : "enquiry_created",
                user_name : getCurrentUser(this.props.user).user_name,
                enquiry_id : enquiryId,
                item : enquiryId
              },
              description: "A new enquiry is created just now",
              from: [getCurrentUser(this.props.user)],
              to: [addUnRead(this.state.assignee)],
              isRead: false,
            }
            createUpdate(update).then(success=>{
              console.log(success, ' update created')
            }).catch(failure=>{
              console.log(failure, 'err while creating update')
            })
            window.location.href = "/enquiry";
      })
      .catch((errEnquiry) => {
        console.log(errEnquiry, "err with creating new enquiry");
        window.sessionStorage.removeItem("naitik_ethics_committees");
        window.sessionStorage.removeItem(
          "cdsco_reregistered_ethics_committees"
        );
        window.sessionStorage.removeItem("cdsco_registered_ethics_committees");
        alert("Error while creating enquiry");
      });
  };

  backToEnquiries = () => {
    if (this.state.related_ec_type && this.state.related_ec_type.length > 0) {
      alert("The previously selected committees are removed");
      window.sessionStorage.removeItem("naitik_ethics_committees");
      window.sessionStorage.removeItem("cdsco_registered_ethics_committees");
      window.sessionStorage.removeItem("cdsco_reregistered_ethics_committees");
      this.setState(
        {
          naitik_ethics_committees: [],
          cdsco_registered_ethics_committees: [],
          cdsco_reregistered_ethics_committees: [],
        },
        () => {
          this.setState({ showNewEnquiry: false });
        }
      );
    } else {
      window.sessionStorage.removeItem("naitik_ethics_committees");
      window.sessionStorage.removeItem("cdsco_registered_ethics_committees");
      window.sessionStorage.removeItem("cdsco_reregistered_ethics_committees");
      this.setState(
        {
          naitik_ethics_committees: [],
          cdsco_registered_ethics_committees: [],
          cdsco_reregistered_ethics_committees: [],
        },
        () => {
          this.setState({ showNewEnquiry: false });
        }
      );
    }
  };

  applyFiltersChange = (status, priority) => {
    let enquiries = this.state.allEnquiries;
    this.setState({
      enquiries:
        enquiries && enquiries.length > 0
          ? enquiries.filter(
              (enq) => enq.status === status && enq.priority === priority
            )
          : [],
    });
  };

  render() {
    const {
      enquiries,
      showNewEnquiry,
      enquiryCommittees,
      ethics_committees,
      filter_enquiry_status,
      filter_priority_level,
    } = this.state;
    return (
      <div className="px-5 mx-3">
        {showNewEnquiry === true ? (
          <NewEnquiry
            {...this.state}
            user={this.props.user}
            handleNewEnquiryChange={this.handleNewEnquiryChange}
            handleAttachmentChange={this.handleAttachmentChange}
            createNewEnquiry={this.createNewEnquiry}
            removeSelectedEthicsCommittee={this.removeSelectedEthicsCommittee}
            backToEnquiries={this.backToEnquiries}
          />
        ) : (
          <div>
            <p
              style={{
                color: "#BC5405",
                cursor: "pointer",
                fontWeight: "bolder",
                textTransform: "uppercase",
              }}
              className="mb-0 col-lg-1 mx-0 px-0 mt-2"
              onClick={() =>
                this.props.user && this.props.user.role === "ec_coordinator"
                  ? (window.location.href = `/addinfo/${this.props.user.others[0].ethics_committee._id}`)
                  : (window.location.href = "/home")
              }
            >
              Back
            </p>
            <div className="row my-2">
              <div style={{ height:  500, overflowY: "scroll"}} className="col-lg-8 px-0">
                {enquiries && enquiries.length > 0 ? (
                  enquiries.map((enquiry) => (
                    <EnquiryCard
                      user={this.props.user}
                      enquiry={enquiry}
                      goToDetail={this.goToDetail}
                    />
                  ))
                ) : (
                  <div>
                    <NoEnquiry />
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <div className="enquiry_filters mt-2">
                  <div className="filters d-flex align-items-center justify-content-start py-3 px-5">
                    <img
                      style={{ height: 20, width: 20 }}
                      className="img-responsive"
                      src={filter}
                      alt="filter"
                    ></img>
                    <h6 className="mx-3 mb-0">Filters</h6>
                  </div>
                  <div className="px-5 my-4">
                    <div className="my-4">
                      <h6 onClick={()=>this.setState({ enquiries : this.props.user && this.props.user.role === "ec_independent_researcher" ? this.state.researcherEnquiries : this.state.coordinatorEnquiries})} style={{ textDecoration : 'underline', cursor : 'pointer' }} className="mb-0 my-1">Show All Enquiries</h6>
                      <p className="mb-0 my-2 mb-3">Status</p>
                      <select
                        onChange={this.handleFiltersChange}
                        name="filter_enquiry_status"
                        value={filter_enquiry_status}
                        type="text"
                        className="form-control"
                      >
                        <option value="">Please Select</option>
                        <option value="opened">Opened</option>
                        <option value="accepted">Accepted</option>
                        <option value="closed">Closed</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </div>
                    <div className="my-4">
                      <p className="mb-0 my-2 mb-3">Priority</p>
                      <select
                        onChange={this.handleFiltersChange}
                        name="filter_priority_level"
                        value={filter_priority_level}
                        type="text"
                        className="form-control"
                      >
                        <option value="">Please Select</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </select>
                    </div>
                    <button
                      onClick={() =>
                        this.applyFiltersChange(
                          this.state.filter_enquiry_status,
                          this.state.filter_priority_level
                        )
                      }
                      className="apply col-12"
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Enquiry;
