import React from "react";
import {
  getCommitteeDetails,
  additionalInformation,
  getEnrichedInfo,
  updatedSimilarCommittee,
  updateCommittee,
  getSimilarCommittee,
} from "../helpers/api";
import { withRouter } from "react-router-dom";
import {
  objectDecrypt,
  sendUpdate,
  getCurrentUser,
  addUnRead,
} from "../helpers/helper";

class Composition extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: "",
      ec_composition_members: [],
      ec_training: [],
      similarCommittee: null,
      committee: "",
      editing_member_id: "",
      hideAdd: false,
    };
  }

  createCompositionRow = (index) => {
    let memberObj = {};
    memberObj["id"] = "member" + index;
    memberObj["member" + index + "role"] = "";
    memberObj["member" + index + "name"] = "";
    memberObj["member" + index + "qualification"] = "";
    memberObj["member" + index + "experience"] = "";
    memberObj["member" + index + "affiliation"] = "";
    let composition = this.state.ec_composition_members;
    if (composition && composition.length > 0) {
      composition.push(memberObj);
      this.setState({ ec_composition_members: composition, hideAdd: true });
    } else {
      this.setState({ ec_composition_members: [memberObj], hideAdd: true });
    }
  };

  splitMembers = (members) => {
    members.map((member) => {
      console.log(member, "member");
      let keys = Object.keys(member);
      keys.map((key) => {
        console.log(key, member[key]);
        if (key === "id") {
          console.log(key);
        } else {
          this.setState({ [key]: member[key] });
        }
      });
    });
  };

  fetchEnrichedInfo = (regNumber) => {
    getEnrichedInfo(regNumber)
      .then((foundInfo) => {
        console.log(foundInfo, "info found");
        this.setState({ enrichedCommittee: foundInfo }, () => {
          if (this.state.enrichedCommittee !== null) {
            let { ec_composition_EC, ec_training } = foundInfo;
            this.setState(
              {
                ec_composition_members: ec_composition_EC,
                ec_composition_EC: ec_composition_EC.length,
                ec_training: ec_training,
              },
              () => {
                this.splitMembers(ec_composition_EC);
              }
            );
          } else {
            this.setState({ enrichedCommittee: null }, () => {
              getSimilarCommittee(this.state.id)
                .then((gotSimilarCom) => {
                  console.log(gotSimilarCom, "got similar committee");
                  let similarCom = gotSimilarCom;
                  if (similarCom && similarCom.approval_status === "accepted") {
                    this.setState({ similarCommittee: similarCom }, () => {
                      this.setState(
                        {
                          ec_composition_members:
                            similarCom &&
                            similarCom.enriched_information &&
                            similarCom.enriched_information.ec_composition_EC,
                          ec_training:
                            similarCom &&
                            similarCom.enriched_information &&
                            similarCom.enriched_information.ec_training,
                        },
                        () => {
                          let composition_members = this.state.ec_composition_members;
                          if((composition_members === "") || (composition_members === " ") || (composition_members === null) || (composition_members === undefined)){
                            this.setState({ ec_composition_members : []})
                          }else{
                            this.setState({ ec_composition_members : similarCom &&
                              similarCom.enriched_information &&
                              similarCom.enriched_information.ec_composition_EC },()=>{
                                this.splitMembers(similarCom &&
                                  similarCom.enriched_information &&
                                  similarCom.enriched_information.ec_composition_EC)
                              })
                          }
                        }
                      );
                    });
                  } else {
                    this.setState({ similarCommittee: null });
                  }
                })
                .catch((errSimilarCommittee) => {
                  console.log(
                    errSimilarCommittee,
                    "err fetching similar committee"
                  );
                });
            });
          }
        });
        // }
      })
      .catch((errFoundInfo) => {
        console.log(errFoundInfo, "err with info");
      });
  };

  fetchDetails = () => {
    getCommitteeDetails(
      this.props.user && this.props.user.others[0].ethics_committee.type,
      this.state.id
    )
      .then((committee) => {
        console.log(committee, "found committee");
        this.setState({ committee: committee }, () => {
          this.fetchEnrichedInfo(committee && committee.registered_number);
        });
      })
      .catch((errCommittee) => {
        console.log(errCommittee, "err found committee");
        this.setState({ committee: null });
      });
  };

  componentDidMount() {
    console.log(this.props.match.params.id, "info");
    this.setState(
      {
        id: this.props.match.params.id,
      },
      () => {
        this.fetchDetails();
      }
    );
  }
 
  getMember = (targetName) => {
    let replace_role = targetName.replace("role", "");
    let replace_name = targetName.replace("name", "");
    let replace_qualification = targetName.replace("qualification", "");
    let replace_experience = targetName.replace("experience", "");
    let replace_affiliation = targetName.replace("affiliation","");
    let replace_other = targetName.replace("other", "");
    let replace_roleName = targetName.replace("roleName","")
    if(targetName.endsWith("role") === true){
      return replace_role
    } else if(targetName.endsWith("name") === true){
      return replace_name
    } else if(targetName.endsWith("qualification") === true){
      return replace_qualification
    } else if(targetName.endsWith("experience") === true){
      return replace_experience
    } else if(targetName.endsWith("affiliation") === true){
      return replace_affiliation
    } else if(targetName.endsWith("other") === true){
      return replace_other
    }  else if(targetName.endsWith("roleName") === true){
      return replace_roleName
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      let members = this.state.ec_composition_members;
      let member = members.filter(
        (member) => this.getMember(e.target.name) === member.id
        // e.target.name.includes(member.id) === true
      )[0];
      console.log(member, "filtered member");
      member[e.target.name] = e.target.value;

      this.setState({ editing_member_id: member.id, hideAdd: true });
      console.log(
        member,
        members.filter((m) => m.id),
        e.target.name,
        e.target.value,
        "members"
      );
      //members.splice(members.indexOf(member),1)
      this.setState({ ec_composition_members: members.filter((m) => m.id) });
    });
  };

  splitMembers = (members) => {
    members.map((member) => {
      console.log(member, "member");
      let keys = Object.keys(member);
      keys.map((key) => {
        console.log(key, member[key]);
        if (key === "id") {
          console.log(key);
        } else {
          this.setState({ [key]: member[key] });
        }
      });
    });
  };

  addSimilarComInfo = () => {
    this.updateMembersInTraining();
    if (this.state.similarCommittee !== null) {
      let info =
        this.state.similarCommittee &&
        this.state.similarCommittee.enriched_information;
      info["ec_composition_EC"] = this.state.ec_composition_members;
      info["ec_training"] = this.state.ec_training;
      console.log(info, "checking id while updating escom");
      let req_body = { enriched_information: info };
      updatedSimilarCommittee(
        this.state.similarCommittee && this.state.similarCommittee._id,
        this.state.similarCommittee &&
          this.state.similarCommittee.enrichedCommittee,
        this.setSimilarCommitteeSessionBody(
          this.state.similarCommittee && this.state.similarCommittee._id
        ) === null
          ? req_body
          : this.setSimilarCommitteeSessionBody(
              this.state.similarCommittee && this.state.similarCommittee._id
            )
      )
        .then((updatedScom) => {
          console.log(updatedScom, "updated similar committee");
          window.sessionStorage.removeItem(
            `${
              this.state.similarCommittee && this.state.similarCommittee._id
            }-similarinfo`
          );
          alert("Committee details updated successfully");
          let update = {
            active: false,
            title: `Similar committee enriched information updated`,
            update: {
              type: "ethics committee",
              item:
                this.state.similarCommittee && this.state.similarCommittee._id,
            },
            description: `Enriched information updated by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_member_id: "" }, () => {
            //window.location.href="/composition/"+this.state.id
            this.fetchDetails();
          });
        })
        .catch((errUpdatedScom) => {
          console.log(
            errUpdatedScom,
            "err while updating similar committee with enriched info"
          );
          window.sessionStorage.removeItem(
            `${
              this.state.similarCommittee && this.state.similarCommittee._id
            }-similarinfo`
          );
          alert("Error with updating committee details");
        });
    } else {
      console.log(this.state.similarCommittee, "error fetching similar com");
    }
  };

  setSimilarCommitteeSessionBody = (scomId) => {
    let similarSessionData = window.sessionStorage.getItem(
      `${scomId}-similarinfo`
    );
    if (similarSessionData !== null) {
      let parsed = objectDecrypt(similarSessionData);
      parsed["ec_composition_EC"] = this.state.ec_composition_members;
      parsed["ec_training"] = this.state.ec_training;
      console.log(parsed, "checking ecc members while updating escom");
      let body = { enriched_information: parsed };
      return body;
    } else {
      return null;
    }
  };

  setSessionBody = () => {
    let sessionData = window.sessionStorage.getItem("enrichedinfo");
    if (sessionData !== null) {
      let parsed = objectDecrypt(sessionData);
      console.log(parsed, "parsed");
      let body = {
        id: this.state.id,
        committee_type:
          this.props.user &&
          this.props.user.others &&
          this.props.user.others[0].ethics_committee &&
          this.props.user.others[0].ethics_committee.type,
        committee_id: parsed["committee"] && parsed["committee"]["_id"],
        committee_registered_number:
          parsed["committee"] && parsed["committee"]["registered_number"],
        committee: parsed["committee"],
        updated_by: this.props.user._id,
        pincode: parsed["pincode"],
        cdsco_approval_date: parsed["cdsco_approval_date"],
        cdsco_validity_date: parsed["cdsco_validity_date"],
        dhr_approval_date: parsed["dhr_approval_date"],
        dhr_validity_date: parsed["dhr_validity_date"],
        next_meeting_date: parsed["next_meeting_date"],
        how_often_ec_meet: parsed["how_often_ec_meet"],
        ec_existing_since: parsed["ec_existing_since"],
        list_of_holidays: parsed["list_of_holidays"],
        ec_composition_EC: this.state.ec_composition_members,
        ec_days_prior_dossier_submission:
          parsed["ec_days_prior_dossier_submission"],
        ec_issue_approval_post_meeting:
          parsed["ec_issue_approval_post_meeting"],
        list_of_sops: parsed["list_of_sops"],
        conflict_of_interest_policy: parsed["conflict_of_interest_policy"],
        ec_training: this.state.ec_training,
        process_of_ec_submission: parsed["process_of_ec_submission"],
        submission_process: "",
        ec_title_of_policy: parsed["ec_title_of_policy"],
        is_paper_based: parsed["is_paper_based"],
        is_online_based: parsed["is_online_based"],
        submission_process_paper_based_copies:
          parsed["submission_process_paper_based_copies"],
        submission_process_online_description:
          parsed["submission_process_online_description"],
        submission_review_check_list: [],
        review_checklist_process: parsed["review_checklist_process"],
        ec_initial_review_fees:
          parsed["ec_initial_review_fees"] &&
          parsed["ec_initial_review_fees"].includes("INR") === true
            ? parsed["ec_initial_review_fees"]
            : parsed["ec_initial_review_fees"] + "INR",
        ec_amendment_review_fees:
          parsed["ec_amendment_review_fees"] &&
          parsed["ec_amendment_review_fees"].includes("INR") === true
            ? parsed["ec_amendment_review_fees"]
            : parsed["ec_amendment_review_fees"] + "INR",
        ec_final_review_process:
          parsed["ec_final_review_process"] &&
          parsed["ec_final_review_process"].includes("INR") === true
            ? parsed["ec_final_review_process"]
            : parsed["ec_final_review_process"] + "INR",
        mode_of_payment: parsed["mode_of_payment"],
        payee_name: parsed["payee_name"],
        ec_gst:
          parsed["ec_gst"] && parsed["ec_gst"].includes("%") === true
            ? parsed["ec_gst"]
            : parsed["ec_gst"] + "%",
        ec_tds: parsed["ec_tds"],
        submission_process_type: parsed["submission_process_type"],
        ec_submission_forms: [],
        process_for_sae_review: parsed["sae_review"],
        ec_policy_effective_date: parsed["ec_policy_effective_date"],
        ec_policy_validity_date: parsed["ec_policy_validity_date"],
        finance: parsed["finance"],
        frequency_of_ec_site_monitoring:
          parsed["frequency_of_ec_site_monitoring"],
        timeline_for_ec_dossier_submission:
          parsed["timeline_for_ec_dossier_submission"],
        approval_for_ec_and_scientific_timeline:
          parsed["approval_for_ec_and_scientific_timeline"],
        interim_progress_report_timeline:
          parsed["interim_progress_report_timeline"],
        timeline_for_expedite_review_and_approval:
          parsed["timeline_for_expedite_review_and_approval"],
        document_storage_facility: parsed["document_storage_facility"],
        fire_extingusher: parsed["fire_extingusher"],
        emergency_alarm: parsed["emergency_alarm"],
        parent_institute_name: parsed["parent_institute_name"],
        parent_registration_number: parsed["parent_registration_number"],
        primary_contact: {
          name: parsed["primary_contact_name"],
          email: parsed["primary_contact_email"],
          contact_number: parsed["primary_contact_contact_number"],
        },
        alternative_contact: {
          name: parsed["alternative_contact_name"],
          email: parsed["alternative_contact_email"],
          contact_number: parsed["alternative_contact_contact_number"],
        },
      };
      return body;
    } else {
      return null;
    }
  };

  checkECMemberInTraining = (member) => {
    let training = this.state.ec_training;
    if (training && training.length > 0) {
      training.map((trainingrow) => {
        let rowmembers = trainingrow[trainingrow.id + "members"];
        if (rowmembers && rowmembers.length > 0) {
          let filterMember = rowmembers.filter((mem) => mem.id === member.id);
          console.log(filterMember, "filtered member");
          if (filterMember && filterMember.length > 0) {
            let selectedMember = filterMember && filterMember[0];
            selectedMember[selectedMember.id + "role"] =
              member[member.id + "role"];
            selectedMember[selectedMember.id + "name"] =
              member[member.id + "name"];
            selectedMember[selectedMember.id + "experience"] =
              member[member.id + "experience"];
            selectedMember[selectedMember.id + "qualification"] =
              member[member.id + "qualification"];
            selectedMember[selectedMember.id + "affiliation"] =
              member[member.id + "affiliation"];
            selectedMember[selectedMember.id + "other"] =
              member[member.id + "other"];
            console.log(selectedMember, "selected member");
          } else {
            member["checked"] = false;
            rowmembers.push(member);
          }
        } else {
          console.log(rowmembers, "No row members found for this row");
        }
      });
    } else {
      console.log(training, "No training members found");
    }
  };

  updateMembersInTraining = () => {
    let ecMembers = this.state.ec_composition_members;
    let training = this.state.ec_training;
    if (ecMembers && ecMembers.length > 0) {
      ecMembers.map((member) => {
        this.checkECMemberInTraining(member);
      });
    } else {
      console.log(ecMembers, "no ec members found");
    }
  };

  addInfo = () => {
    let { user } = this.props;
    this.updateMembersInTraining();
    let { ec_composition_members, ec_training } = this.state;
    let req_body = {
      id: this.state.id,
      committee_type:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0].ethics_committee &&
        this.props.user.others[0].ethics_committee.type,
      ec_composition_EC: ec_composition_members,
      ec_training: ec_training,
      committee:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0].ethics_committee,
      committee_registered_number:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0] &&
        this.props.user.others[0].ethics_committee &&
        this.props.user.others[0].ethics_committee.registered_number,
    };
    if (
      this.state.enrichedCommittee === "" ||
      this.state.enrichedCommittee === " " ||
      this.state.enrichedCommittee === null ||
      this.state.enrichedCommittee === undefined
    ) {
      additionalInformation(
        this.setSessionBody() === null ? req_body : this.setSessionBody()
      )
        .then((info) => {
          console.log(info, "info");
          this.fetchDetails();
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Information saved successfully");
          let update = {
            active: false,
            title: `Enriched information created`,
            update: {
              type: "enriched_data_created",
              user_name: this.props.user.username,
              ec_registered_number: req_body.committee_registered_number,
              item: this.state.enrichedCommittee._id,
            },
            description: `Enriched information created by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_member_id: "" }, () => {
            //window.location.href="/composition/"+this.state.id
            this.fetchDetails();
          });
          //window.location.href = "/addinfo/" + this.state.id;
        })
        .catch((errInfo) => {
          console.log(errInfo, "err with info");
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Error with submitting training information");
        });
    } else {
      let req_body = {
        committee_type:
          this.props.user && this.props.user.others[0].ethics_committee.type,
        ec_composition_EC: ec_composition_members,
        ec_training: ec_training,
      };
      console.log(
        req_body,
        this.setSessionBody(),
        "req body before submitting"
      );
      updateCommittee(
        this.state.enrichedCommittee._id,
        this.setSessionBody() === null ? req_body : this.setSessionBody()
      )
        .then((success) => {
          console.log(success, "committee updated successfully");
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Updated Committee Details Successfully");
          let update = {
            active: false,
            title: `Enriched information updated`,
            update: {
              type: "enriched_data_updated",
              user_name: this.props.user.username,
              ec_registered_number: req_body.committee_registered_number,
            },
            description: `Enriched information updated by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_member_id: "" }, () => {
            //window.location.href="/composition/"+this.state.id
            this.fetchDetails();
          });
          // getCommitteeDetails(
          //   this.state.committee.type,
          //   this.state.committee._id
          // )
          //   .then((com) => {
          //     console.log(com, "updated com");
          //     this.setState({ committee: com });
          //   })
          //   .catch((errCom) => {
          //     console.log(errCom, "error with fetching updated com");
          //     alert("Error with fetching updated committee");
          //   });
        })
        .catch((failure) => {
          console.log(failure, "error with updating committee details");
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Error with updating committee details");
        });
    }
  };

  render() {
    const { ec_composition_members, hideAdd, editing_member_id } = this.state;
    const { user } = this.props;
    return (
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
        }}
        className="px-5 py-3 mb-5 pb-5"
      >
        <div className="d-flex align-items-center justify-content-between col-lg-7">
          <p
            style={{
              color: "#BC5405",
              cursor: "pointer",
              fontWeight: "bolder",
              textTransform: "uppercase",
            }}
            className="mb-0 col-lg-1 mx-0 px-0"
            onClick={() =>
              (window.location.href = `/addinfo/${user.others[0].ethics_committee._id}`)
            }
          >
            Back
          </p>
          <h3 className="mx-5">EC Composition</h3>
          {/* <button
            onClick={() =>this.state.similarCommittee && this.state.similarCommittee !== null ? this.addSimilarComInfo() : this.addInfo()}
            className="btn btn-sm login mx-2"
          >
            SUBMIT
          </button> */}
        </div>
        {ec_composition_members && ec_composition_members.length > 0 ? (
          ec_composition_members.map((member) => (
            <div className="row my-2 pl-3">
              <div className="col-lg-2">
                <p className="mb-0">Role</p>
                <div className="d-flex align-items-center justify-content-center">
                  <select
                    onChange={this.handleChange}
                    type="text"
                    name={member.id + "role"}
                    value={this.state[member.id + "role"]}
                    className={`form-control ${
                      this.state[member.id + "role"] === "Other"
                        ? "col-lg-6"
                        : ""
                    } `}
                  >
                    <option>Please select</option>
                    <option>Clinician</option>
                    <option>Chair person</option>
                    <option>Member Secretary</option>
                    <option>Basic Medical Scientist</option>
                    <option>Legal Expert</option>
                    <option>Lay Person</option>
                    <option>Social Scientist</option>
                    <option>Subject Expert</option>
                    <option>Other</option>
                  </select>
                  {this.state[member.id + "role"] === "Other" ? (
                    <input
                      onChange={this.handleChange}
                      type="text"
                      name={member.id + "roleName"}
                      value={this.state[member.id + "roleName"]}
                      placeholder="Role"
                      className="form-control col-lg-6"
                    ></input>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-2">
                <p className="mb-0">Name</p>
                <input
                  onChange={this.handleChange}
                  type="text"
                  name={member.id + "name"}
                  value={this.state[member.id + "name"]}
                  placeholder="Name"
                  className="form-control"
                ></input>
              </div>
              <div className="col-lg-2">
                <p className="mb-0">Qualification</p>
                <input
                  onChange={this.handleChange}
                  type="text"
                  name={member.id + "qualification"}
                  value={this.state[member.id + "qualification"]}
                  placeholder="Qualification"
                  className="form-control"
                ></input>
              </div>
              <div className="col-lg-1">
                <p className="mb-0">Experience</p>
                <input
                  onChange={this.handleChange}
                  type="text"
                  name={member.id + "experience"}
                  value={this.state[member.id + "experience"]}
                  placeholder="Experience"
                  className="form-control"
                ></input>
              </div>
              <div className="col-lg-2">
                <p className="mb-0">Affiliation</p>
                <input
                  onChange={this.handleChange}
                  type="text"
                  name={member.id + "affiliation"}
                  value={this.state[member.id + "affiliation"]}
                  placeholder="Affiliation"
                  className="form-control"
                ></input>
              </div>
              <div className="col-lg-2">
                <p className="mb-0">Other</p>
                <input
                  onChange={this.handleChange}
                  type="text"
                  name={member.id + "other"}
                  value={this.state[member.id + "other"]}
                  placeholder="Other"
                  className="form-control"
                ></input>
              </div>
              {member && member.id === editing_member_id ? (
                <button
                  onClick={() =>
                    this.state.similarCommittee &&
                    this.state.similarCommittee !== null
                      ? this.addSimilarComInfo()
                      : this.addInfo()
                  }
                  className="btn btn-sm btn-primary mx-2 mt-4"
                >
                  save
                </button>
              ) : null}
            </div>
          ))
        ) : (
          <p>Please click below button to add row</p>
        )}
        <button
          onClick={() =>
            this.createCompositionRow(
              ec_composition_members && ec_composition_members.length
            )
          }
          className="btn btn-primary btn-small"
          disabled={hideAdd === true ? true : false}
        >
          add
        </button>
      </div>
    );
  }
}

export default withRouter(Composition);
