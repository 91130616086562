import React from "react";
import { useParams } from "react-router-dom";
import EthicsCommitteeRegister from "./EthicsCommitteeRegister";
import IndependentResearcher from "./IndependentResearcherRegister";


const Register = () => {
  const { register_role } = useParams();
  console.log(register_role+'register role')
  if (register_role === "coordinator") {
    return (
      <div>
        <EthicsCommitteeRegister />
      </div>
    );
  } else if(register_role === "researcher"){
    return (
        <div>
          <IndependentResearcher />
        </div>
      );
  }
  else if(register_role=== " " || register_role === null || register_role === undefined){
    return (
      <div className="p-5 m-5 mx-auto text-center">
        <p>Page not found</p>
      </div>
    );
  }else{
    return (
      <div className="p-5 m-5 mx-auto text-center">
        <h3>Page not found</h3>
      </div>
    );
  }
};

export default Register;
