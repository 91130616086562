import React from "react";
import { ec_researcher_logo, ec_sites_logo, ptLogo, ec_ec_logo, Logo } from "../theme/icons";

const LandingScreen = () => {

const navigateLink = (url) =>{
    return window.location.href = url
}

const roles = [
    {
        id:1,
        logo:ec_researcher_logo,
        title: "Researcher",
        subtitle:"Looking for Ethics Committee support across the country?",
        buttonText:"Register Here",
        url: "/register/researcher"
    },
    {
        id:2,
        logo:ec_sites_logo,
        title: "Sites",
        subtitle:"Do you need Ethics Committee as per your requirement?",
        buttonText:"Register Here",
        url: "/register/researcher"
    },
    {
        id:3,
        logo:ec_ec_logo,
        title: "Ethics Committee ",
        subtitle:"Want others to know about your Capabilities and Experience? ",
        buttonText:"Register Here",
        url: "/register/coordinator"
    }
]
  return (
    <div style={{ background : 'linear-gradient(rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)on' }} className="container-fluid">
        <div className="container mx-auto text-center my-0">
            <img className="mt-0 new_logo" src={Logo} alt="patientrials logo"></img>
            {/* <p className="mb-0 landing_subtitle mt-0 mr-2">Already a user? Please login</p> */}
            <div className="d-flex align-items-center justify-content-center">
                <h6 className="main_txt mb-0">Welcome to Ethical Committees Portal</h6>
                <button onClick={()=>navigateLink("/login")} className="btn btn-small login mt-0 ml-5">LOGIN</button>
            </div>
            <div className="row mx-0 px-0 my-3">
                {
                    roles.map(role=>
                        <div className={`${role.id === 3 ? "col-lg-4 mx-auto text-center px-0 mt-2 mb-1" : "col-lg-4 mx-auto text-center px-5 mt-1 mb-3"}`}>
                            <img style={{ height : (role.id === 1 && 150) || (role.id === 2 && 160) || (role.id === 3 && 176) }} src={role.logo} alt={`${role.title} logo`}></img>
                            <h6 className={(role.id === 1 && "landing_title mt-4 pt-2") || (role.id === 2 && "landing_title mt-4" ) || (role.id === 3 && "landing_title mt-1")}>{role.title}</h6>
                            <p className="mb-0 landing_subtitle">{role.subtitle}</p>
                            <button onClick={()=>navigateLink(role.url)} style={{ textTransform: "uppercase" }} className="btn btn-small login mt-4">{role.buttonText}</button>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  );
};

export default LandingScreen;
