import React from "react";
const SubmissionProcess = (props) => {
  const {
    handleChange,
    checkboxChange,
    process_of_ec_submission,
    submission_process_type,
    is_online_based,
    is_paper_based,
    submission_process_paper_based_copies,
    submission_process_online_description,
    review_checklist_process,
    ec_forms_for_submission,
    sae_review,
  } = props;
  return (
    <div className="row text-left">
      <div className="col-lg-6">
        <p className="mb-0 my-1">Dossier Process</p>
        <p>Process of EC Submission</p>
        <div>
          <textarea
            onChange={handleChange}
            name="process_of_ec_submission"
            value={process_of_ec_submission}
            placeholder="Brief description of dossier process"
            rows={2}
            className="form-control my-2"
          ></textarea>
        </div>
        <div>
          <p className="mb-0 my-2">Submission Process</p>
          {/* <input
            onChange={checkboxChange}
            type="radio"
            name="is_online_based"
            value={is_online_based}
            checked={is_online_based}
            className="mt-2 mr-2"
          ></input>
          <span className="mr-2">Online</span>
          <input
            onChange={checkboxChange}
            type="radio"
            name="is_paper_based"
            value={is_paper_based}
            checked={is_paper_based}
            className="mt-2 mr-2"
          ></input>
          <span className="mr-2">Paper based</span> */}
          <select
            onChange={handleChange}
            name="submission_process_type"
            value={submission_process_type}
            type="text"
            placeholder="Submission process type"
            className="form-control"
          >
            <option>Please select</option>
            <option>Online</option>
            <option>Paper Based</option>
          </select>
          {submission_process_type === "Paper Based" ? (
            <input
              onChange={handleChange}
              type="text"
              name="submission_process_paper_based_copies"
              value={submission_process_paper_based_copies}
              placeholder="Number of copies required"
              className="form-control my-2"
            ></input>
          ) : 
          <textarea
          onChange={handleChange}
          type="text"
          name="submission_process_online_description"
          value={submission_process_online_description}
          placeholder="Online process description"
          className="form-control my-2"
          rows={2}
        ></textarea>}
        </div>
      </div>
      <div className="col-lg-6">
        <p className="mb-0 my-1">Review Process</p>
        <div>
          <p className="mb-0">Review Check-list Process</p>
          <textarea
            onChange={handleChange}
            type="text"
            rows={2}
            placeholder="Review Process"
            name="review_checklist_process"
            value={review_checklist_process}
            className="form-control"
          ></textarea>
        </div>
        {/* <div>
          <p className="mb-0">
            EC Forms to be completed along with the submission
          </p>
          <select
            onChange={handleChange}
            type="text"
            placeholder="Forms to be completed"
            name="ec_forms_for_submission"
            value={ec_forms_for_submission}
            className="form-control"
          ></select>
        </div> */}
        <div>
          <p className="mb-0">Process of SAE Review</p>
          <textarea
            onChange={handleChange}
            name="sae_review"
            value={sae_review}
            placeholder="Enter process description here"
            rows={2}
            className="form-control my-2"
          ></textarea>
        </div>
      </div>
    </div>
  );
};
export default SubmissionProcess;
