import React from "react";
import {
  cdscoCommittees,
  naitikCommittees,
  ethicsCommitteeRegister,
} from "../../helpers/api";
import {
  ecRegister,
  Logo,
  ecRegisterUser,
  ecRegisterAddress,
  ecRegisterPhone,
  Email,
  passwordLock,
  ecRegisterSecretary,
} from "../../theme/icons";
import { cryptoSecret } from "../../keys";
import { encrypt, getAllCommittees, validateEmail, getCurrentUser, addUnRead, sendUpdate } from "../../helpers/helper";
// changes in main after creating new branch
class EthicsCommitteeRegister extends React.Component {
  constructor(props) {
    super();
    this.state = {
      suggestions: [],
      address: "",
      ec_name: "",
      email: "",
      username: "",
      password: "",
      phone: "",
      ethics_committee: "",
      showEmailError: false,
    };
  }

  onNavigateBack = () => {
    window.location.href = "/";
  };

  handleNameChange = (e) => {
    this.setState({ ec_name: e.target.value });
  };
  
  handleAddressChange = (e) => {
    this.setState({ address: e.target.value }, () => {
      getAllCommittees()
        .then((allCommittees) => {
          console.log(allCommittees, "all");
          if (allCommittees && allCommittees.length > 0) {
            console.log(allCommittees, "coms");
            console.log(
              allCommittees.filter((com) =>
                com.type === "naitik"
                  ? com.address
                      .toLowerCase()
                      .startsWith(e.target.value.toLowerCase()) === true
                  : com.name_and_address
                      .toLowerCase()
                      .startsWith(e.target.value.toLowerCase()) === true
              ),
              "filtering"
            );
            this.setState({
              suggestions: allCommittees.filter((com) =>
                com.type === "naitik"
                  ? com.address
                      .toLowerCase()
                      .startsWith(e.target.value.toLowerCase()) === true
                  : com.name_and_address
                      .toLowerCase()
                      .startsWith(e.target.value.toLowerCase()) === true
              ),
            });
          } else {
            console.log("committees suggestions not found");
          }
        })
        .catch((errAllCommittees) => {
          console.log(errAllCommittees, "err with fetching all committees");
        });
    });
  };
  handlePhoneChange = (e) => {
    this.setState({ phone: e.target.value });
  };
  handleMailChange = (e) => {
    this.setState({ email: e.target.value }, () => {
      let checkEmail = validateEmail(e.target.value);
      if (checkEmail === false) {
        this.setState({ showEmailError: true });
      } else {
        this.setState({ showEmailError: false });
      }
    });
  };
  handleUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };
  handlePasswordChange = (e) => {
    this.setState({ password: encrypt(e.target.value) });
  };

  next = () => {
    let {
      address,
      ec_name,
      email,
      password,
      phone,
      username,
      ethics_committee,
    } = this.state;
    let check_empty_values = [
      email,
      password,
      username,
      address,
      ec_name,
      ethics_committee,
    ];
    let check = check_empty_values.filter(
      (item) =>
        item === "" || item === " " || item === null || item === undefined
    );
    if (check && check.length > 0) {
      alert("Please enter all required fields");
    } else {
      let req_body = {
        ec_name: ec_name,
        username: username,
        ec_address: address,
        ethics_committee: ethics_committee,
        email: email,
        password: password,
        phone: phone,
        isEmailVerified: false,
        isSocialMediaUser: false,
        socialMediaProvider: "none",
        role: "ec_coordinator",
      };
      console.log(req_body, "data");
      ethicsCommitteeRegister(req_body)
        .then((ecRegistered) => {
          console.log(ecRegistered.output, "ec coordinator registered successfully");
          if (
            ecRegistered &&
            ecRegistered.error === false &&
            ecRegistered.message ===
              "ec coordinator/researcher registration success"
          ) {
            alert("EC coordinator registered successfully");
            let update = {
              active: false,
              title: `New user registered`,
              update: {
                type: "new_user_created",
                role : "ec_coordinator",
                user_name : ecRegistered.output.username,
                user_email : ecRegistered.output.email,
                item: `${ecRegistered.output._id}`,
              },
              description: `${ecRegistered.output.username} user registered`,
              from: [getCurrentUser(ecRegistered.output)],
              to: [],
              isRead: false,
            };
            console.log(update, 'checking update')
            sendUpdate(update);
            window.location.href = "/";
          }
        })
        .catch((errEcRegistered) => {
          console.log(errEcRegistered, "err with ec coordinator registration");
          alert("Error occured while registering EC coordinator");
          window.location.href = "/";
        });
    }
  };

  render() {
    const {
      address,
      ec_name,
      email,
      password,
      phone,
      username,
      showEmailError,
    } = this.state;
    return (
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 100%)",
        }}
      >
        <div className="container">
          <div className="mx-auto text-center">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => this.onNavigateBack()}
              className="img-responsive mt-5 new_logo"
              src={Logo}
              alt="logo"
            ></img>
            <h5 style={{ color: "#BC5405" }} className="mb-0 ml-4">
              Ethical Committees
            </h5>
          </div>
          <div className="row px-0 mt-5 mb-5">
            <div className="col-lg-6">
              <p
                style={{
                  color: "#BC5405",
                  cursor: "pointer",
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                }}
                className="mb-0"
                onClick={() => this.onNavigateBack()}
              >
                Back
              </p>
              <img
                className="ec_register mt-5 img-responsive"
                src={ecRegister}
                alt="register img"
              ></img>
            </div>
            <div className="col-lg-6">
              <h4>Sign Up to Register</h4>
              <p style={{ fontWeight: "bold" }} className="mb-0">
                Ethics Committee
              </p>
              <div>
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid #646464",
                    borderRadius: 5,
                  }}
                  className="col-lg-11 input-group input-group-lg p-0 my-2"
                >
                  <img
                    style={{
                      height: 39,
                      width: 39,
                      backgroundColor: "#A8CDB2",
                      padding: 7,
                      borderRadius: 3,
                    }}
                    src={ecRegisterUser}
                    alt="user"
                    className="img-responsive m-1"
                  ></img>
                  <input
                    onChange={this.handleUsernameChange}
                    className="form-control input-lg"
                    style={{
                      border: "none",
                      outline: "none",
                      boxSizing: "border-box",
                    }}
                    type={"text"}
                    name={username}
                    value={username}
                    placeholder={"Name*"}
                  ></input>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid #646464",
                    borderRadius: 5,
                  }}
                  className="col-lg-11 input-group input-group-lg p-0 my-2"
                >
                  <img
                    style={{
                      height: 39,
                      width: 39,
                      backgroundColor: "#EAE79B",
                      padding: 7,
                      borderRadius: 3,
                    }}
                    src={Email}
                    alt="user"
                    className="img-responsive m-1"
                  ></img>
                  <input
                    onChange={this.handleMailChange}
                    className="form-control input-lg"
                    style={{
                      border: "none",
                      outline: "none",
                      boxSizing: "border-box",
                    }}
                    type={"text"}
                    name={email}
                    value={email}
                    placeholder={"Mail ID*"}
                  ></input>
                </div>
                {showEmailError === true ? (
                  <p style={{ color: "darkred" }} className="mb-0">
                    Please enter valid email
                  </p>
                ) : null}
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid #646464",
                    borderRadius: 5,
                  }}
                  className="col-lg-11 input-group input-group-lg p-0 my-2"
                >
                  <img
                    style={{
                      height: 39,
                      width: 39,
                      backgroundColor: "#D9B7E9",
                      padding: 7,
                      borderRadius: 3,
                    }}
                    src={passwordLock}
                    alt="user"
                    className="img-responsive m-1"
                  ></img>
                  <input
                    onChange={this.handlePasswordChange}
                    className="form-control input-lg"
                    style={{
                      border: "none",
                      outline: "none",
                      boxSizing: "border-box",
                    }}
                    type={"password"}
                    name={password}
                    //value={password}
                    placeholder={"Password*"}
                  ></input>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid #646464",
                    borderRadius: 5,
                  }}
                  className="col-lg-11 input-group input-group-lg p-0 my-2"
                >
                  <img
                    style={{
                      height: 39,
                      width: 39,
                      backgroundColor: "#EDD2A9",
                      padding: 7,
                      borderRadius: 3,
                    }}
                    src={ecRegisterPhone}
                    alt="phone"
                    className="img-responsive m-1"
                  ></img>
                  <input
                    onChange={this.handlePhoneChange}
                    className="form-control input-lg"
                    style={{
                      border: "none",
                      outline: "none",
                      boxSizing: "border-box",
                    }}
                    type={"text"}
                    name={phone}
                    value={phone}
                    placeholder={"Phone Number"}
                  ></input>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid #646464",
                    borderRadius: 5,
                  }}
                  className="col-lg-11 input-group input-group-lg p-0 my-2"
                >
                  <img
                    style={{
                      height: 39,
                      width: 39,
                      backgroundColor: "#9BCDEA",
                      padding: 7,
                      borderRadius: 3,
                    }}
                    src={ecRegisterAddress}
                    alt="address"
                    className="img-responsive m-1"
                  ></img>
                  <input
                    onChange={this.handleAddressChange}
                    className="form-control input-lg"
                    style={{
                      border: "none",
                      outline: "none",
                      boxSizing: "border-box",
                    }}
                    type={"text"}
                    name={address}
                    value={address}
                    placeholder={"EC Address*"}
                  ></input>
                  {this.state.address && this.state.address.length > 0 ? <div
                    style={{
                      overflowY: "scroll",
                      height:
                        this.state.suggestions &&
                        this.state.suggestions.length > 0
                          ? 400
                          : "auto",
                      backgroundColor: "white",
                    }}
                  >
                    {this.state.suggestions && this.state.suggestions.length > 0
                      ? this.state.suggestions.map((suggestion) => (
                          <div
                            style={{
                              borderBottom: "1px solid darkgrey",
                              cursor: "pointer",
                            }}
                            className="d-flex align-items-center justify-content-between px-1"
                          >
                            <p
                              onClick={() =>
                                this.setState(
                                  { ethics_committee: suggestion },
                                  () => {
                                    this.setState(
                                      {
                                        address:
                                          this.state.ethics_committee.type ===
                                          "naitik"
                                            ? this.state.ethics_committee
                                                .address
                                            : this.state.ethics_committee
                                                .name_and_address,
                                        ec_name:
                                          this.state.ethics_committee.type ===
                                          "naitik"
                                            ? this.state.ethics_committee
                                                .name_of_ethics_committee
                                            : this.state.ethics_committee
                                                .name_and_address,
                                      },
                                      () => {
                                        this.setState({ suggestions: [] });
                                      }
                                    );
                                  }
                                )
                              }
                              className="mb-0 px-3 mx-2 py-2"
                            >
                              {suggestion.type === "naitik"
                                ? suggestion.address
                                : suggestion.name_and_address}
                            </p>
                            <p
                              style={{
                                border:
                                  (suggestion &&
                                    suggestion.type === "naitik" &&
                                    "1px solid #9F5F80") ||
                                  (suggestion &&
                                    suggestion.type === "Registered" &&
                                    "1px solid #2C2891") ||
                                  (suggestion &&
                                    suggestion.type === "reRegistered" &&
                                    "1px solid #F5A962"),
                                borderRadius: 5
                              }}
                              className="border-rounded px-2 my-2"
                            >
                              {(suggestion &&
                                suggestion.type === "naitik" &&
                                "Naitik") ||
                                (suggestion &&
                                  suggestion.type === "Registered" &&
                                  "Registered") ||
                                (suggestion &&
                                  suggestion.type === "reRegistered" &&
                                  "CDSCO Re-Registered")}
                            </p>
                          </div>
                        ))
                      : null}
                  </div> : null}
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid #646464",
                    borderRadius: 5,
                  }}
                  className="col-lg-11 input-group input-group-lg p-0 my-2"
                >
                  <img
                    style={{
                      height: 39,
                      width: 39,
                      backgroundColor: "#A8CDB2",
                      padding: 7,
                      borderRadius: 3,
                    }}
                    src={ecRegisterSecretary}
                    alt="user"
                    className="img-responsive m-1"
                  ></img>
                  <input
                    onChange={this.handleNameChange}
                    className="form-control input-lg"
                    style={{
                      border: "none",
                      outline: "none",
                      boxSizing: "border-box",
                    }}
                    type={"text"}
                    name={ec_name}
                    value={ec_name}
                    placeholder={"EC Name*"}
                  ></input>
                </div>
                <button
                  onClick={() => this.next()}
                  className="btn btn-lg login col-lg-11"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EthicsCommitteeRegister;
