import React from "react";

const ECMeeting = (props) => {
  const {
    handleChange,
    next_meeting_date,
    how_often_ec_meet,
    ec_existing_since,
    list_of_holidays,
  } = props;
  return (
    <div className="row text-left">
      <div className="col-lg-3">
        <p className="mb-0 my-2">Next Meeting Date</p>
        <input
          onChange={handleChange}
          type="date"
          name="next_meeting_date"
          value={next_meeting_date}
          placeholder="Next EC Meeting Date"
          className="form-control"
        ></input>
      </div>
      <div className="col-lg-3">
        <p className="mb-0 my-2">How Often We Meet </p>
        <select
          onChange={handleChange}
          type="text"
          placeholder="How Often We Meet"
          name="how_often_ec_meet"
          value={how_often_ec_meet}
          className="form-control"
        >
          <option>Please select</option>
          <option>Once in Week</option>
          <option>Twice in Month</option>
          <option>As and when require</option>
          <option>Once in Month</option>
          <option>Once in 3 Months</option>
          <option>Once in 6 Months</option>
        </select>
      </div>
      <div className="col-lg-3">
        <p className="mb-0 my-2">EC existing since</p>
        <input
          onChange={handleChange}
          type="date"
          name="ec_existing_since"
          value={ec_existing_since}
          placeholder="EC existing since"
          className="form-control"
        ></input>
      </div>
      <div className="col-lg-3">
        <p className="mb-0 my-2">List of Holidays</p>
        <select
          onChange={handleChange}
          type="text"
          placeholder="List of Holidays"
          name="list_of_holidays"
          value={list_of_holidays}
          className="form-control"
        >
          <option>Please select</option>
          <option>Diwali November 5</option>
          <option>Dasara October 15</option>
        </select>
      </div>
    </div>
  );
};

export default ECMeeting;
