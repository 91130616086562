import axios from "axios";
import CryptoJS from "crypto-js";
import { cryptoObjectSecret, cryptoSecret, Google_Map_Key } from "../keys";
import { ecSize } from "../theme/icons";
import {
  cdscoCommittees,
  getUserWithId,
  naitikCommittees,
  getEnrichedInfo,
  getSimilarCommittee,
  getUsers,
  createUpdate,
} from "./api";
import { v4 as uuidv4 } from "uuid";

const colors = [];

const fields = [
  "EC Regulatory",
  "EC Composition",
  "Next Meeting Date",
  "How Often We Meet",
  "EC Existing Since",
  "List of Holidays",
  "List of SOP's",
  "Conflict of Interest Policy",
  "Members Training Status",
  "Process of EC Dossier Submission",
  "Paper Based",
  "Number of Copies",
  "Finance",
  "EC Site Monitoring Frequency",
  "Timeline for EC Dossier Submission",
  "Approval timeline for EC and Scientific committee",
  "Interim progress report timeline",
  "Timeline for expedite review and approval",
  "Document Storage Facility",
  "Fire Extingusher",
  "Emergency Alarm",
  "Name and Registration Number of Parent Institute",
  "Primary Contact Name",
  "Primary Contact Email",
  "Primary Contact Number",
  "Alternative Contact Name",
  "Alternative Contact Email",
  "Alternative Contact Number",
];

const ecRegulatory = (type, committee) => {
  return (
    <td>
      <div>
        <p>
          {type === "naitik" ? "Name" : "Name & Address"}:{" "}
          <span>
            {type === "naitik"
              ? committee && committee.enriched === true
                ? committee &&
                  committee.ethics_committee.name_of_ethics_committee
                : committee.name_of_ethics_committee
              : committee && committee.enriched === true
              ? committee && committee.ethics_committee.name_and_address
              : committee.name_and_address}
          </span>{" "}
        </p>
        {type === "naitik" ? (
          <p>
            Address:{" "}
            {committee && committee.enriched === true
              ? committee &&
                committee.ethics_committee &&
                committee.ethics_committee.address
              : committee.address}
          </p>
        ) : null}
        <p>
          {type === "naitik" ? "Approval-Date" : "Release-Date"}:{" "}
          <span>
            {type === "naitik"
              ? committee && committee.enriched === true
                ? committee && committee.ethics_committee.approval_date
                : committee.approval_date
              : committee && committee.enriched === true
              ? committee && committee.ethics_committee.validity_date
              : committee.validity_date}
          </span>
        </p>
        <p>
          {type === "naitik" ? "state" : "state"}:{" "}
          <span>
            {committee && committee.enriched === true
              ? committee && committee.ethics_committee.state
              : committee.state}
          </span>
        </p>
        {type === "naitik" ? (
          <p>
            Pincode:{" "}
            {committee && committee.enriched === true
              ? committee.ethics_committee &&
                committee.ethics_committee.address &&
                committee.ethics_committee.address.length > 0
                ? committee.ethics_committee.address.split(" - ")[1]
                : null
              : committee.address && committee.address.length > 0
              ? committee.address.split(" - ")[1]
              : null}
          </p>
        ) : null}
        <p>
          {type === "naitik" ? "Registration Number" : "Registration Number"}:{" "}
          <span>
            {committee && committee.enriched === true
              ? committee &&
                committee.ethics_committee &&
                committee.ethics_committee.registered_number
              : committee.registered_number}
          </span>
        </p>
        {type === "naitik" ? null : (
          <p>
            <img
              onClick={() =>
                window.open(
                  committee && committee.enriched === true
                    ? committee &&
                        committee.ethics_committee &&
                        committee.ethics_committee.download_pdf
                    : committee.download_pdf,
                  "_blank"
                )
              }
              src={ecSize}
              alt={"pdf icon"}
            ></img>
            <span className="mb-0">
              {committee && committee.enriched === true
                ? committee &&
                  committee.ethics_committee &&
                  committee.ethics_committee.pdf_size
                : committee.pdf_size}
            </span>
          </p>
        )}
      </div>
    </td>
  );
};

const getFieldData = (fieldName, type, committees) => {
  if (fieldName === "EC Regulatory") {
    return committees.map((compareCommittee) =>
      ecRegulatory(type, compareCommittee)
    );
  } else if (fieldName === "EC Composition") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee &&
            compareCommittee.ec_composition_EC &&
            compareCommittee.ec_composition_EC.length
          : "None"}
      </td>
    ));
  } else if (fieldName === "Next Meeting Date") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.next_meeting_date
          : "None"}
      </td>
    ));
  } else if (fieldName === "How Often We Meet") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.how_often_ec_meet
          : "None"}
      </td>
    ));
  } else if (fieldName === "EC Existing Since") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.ec_existing_since
          : "None"}
      </td>
    ));
  } else if (fieldName === "List of Holidays") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.list_of_holidays
          : "None"}
      </td>
    ));
  } else if (fieldName === "List of SOP's") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.list_of_sops
          : "None"}
      </td>
    ));
  } else if (fieldName === "Conflict of Interest Policy") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.conflict_of_interest_policy
          : "None"}
      </td>
    ));
  } else if (fieldName === "Members Training Status") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? `Topic : ${compareCommittee.ec_training_topic} , Regulatory Training Date: ${compareCommittee.ec_regulatory_training_date}`
          : "None"}
      </td>
    ));
  } else if (fieldName === "Process of EC Dossier Submission") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.process_of_ec_submission
          : "None"}
      </td>
    ));
  } else if (fieldName === "Paper Based") {
    return committees.map((compareCommittee) => (
      <td>
        {JSON.stringify(
          compareCommittee &&
            compareCommittee.additional_information &&
            compareCommittee.additional_information.length > 0
            ? compareCommittee.is_paper_based
            : "None"
        )}
      </td>
    ));
  } else if (fieldName === "Number of Copies") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.submission_process_paper_based_copies
          : "None"}
      </td>
    ));
  } else if (fieldName === "Finance") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.finance
          : "None"}
      </td>
    ));
  } else if (fieldName === "EC Site Monitoring Frequency") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.frequency_of_ec_site_monitoring
          : "None"}
      </td>
    ));
  } else if (fieldName === "Timeline for EC Dossier Submission") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.timeline_for_ec_dossier_submission
          : "None"}
      </td>
    ));
  } else if (
    fieldName === "Approval timeline for EC and Scientific committee"
  ) {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.approval_for_ec_and_scientific_timeline
          : "None"}
      </td>
    ));
  } else if (fieldName === "Interim progress report timeline") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.interim_progress_report_timeline
          : "None"}
      </td>
    ));
  } else if (fieldName === "Timeline for expedite review and approval") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.timeline_for_expedite_review_and_approval
          : "None"}
      </td>
    ));
  } else if (fieldName === "Document Storage Facility") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.document_storage_facility
          : "None"}
      </td>
    ));
  } else if (fieldName === "Fire Extingusher") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.fire_extingusher
          : "None"}
      </td>
    ));
  } else if (fieldName === "Emergency Alarm") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.emergency_alarm
          : "None"}
      </td>
    ));
  } else if (fieldName === "Name and Registration Number of Parent Institute") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? `Name : ${compareCommittee.parent_institute_name}, Registration Number: ${compareCommittee.parent_registration_number}`
          : "None"}
      </td>
    ));
  } else if (fieldName === "Primary Contact Name") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.primary_contact.name
          : "None"}
      </td>
    ));
  } else if (fieldName === "Primary Contact Email") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.primary_contact.email
          : "None"}
      </td>
    ));
  } else if (fieldName === "Primary Contact Number") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.primary_contact.contact_number
          : "None"}
      </td>
    ));
  } else if (fieldName === "Alternative Contact Name") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.alternative_contact.name
          : "None"}
      </td>
    ));
  } else if (fieldName === "Alternative Contact Email") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.alternative_contact.email
          : "None"}
      </td>
    ));
  } else if (fieldName === "Alternative Contact Number") {
    return committees.map((compareCommittee) => (
      <td>
        {compareCommittee && compareCommittee.enriched === true
          ? compareCommittee.alternative_contact.contact_number
          : "None"}
      </td>
    ));
  }
};

const encrypt = (term) => {
  let encrypted = CryptoJS.AES.encrypt(term, cryptoSecret).toString();
  return encrypted;
};

const getLocations = (location) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${Google_Map_Key}`
      )
      .then((s) => {
        console.log(s.data, "geolocation successful");
        resolve(s.data);
      })
      .catch((e) => {
        console.log(e, "error with getting our location details");
        reject("err with geocoding");
      });
  });
};

const getMultipleLocations = async (type, locations) => {
  let locs = [];
  await locations.map((location) => {
    getLocations(
      type === "naitik" ? location.address : location.name_and_address
    )
      .then((gotLocations) => {
        console.log(gotLocations, "got location");
        locs.push({
          id: location._id,
          location: gotLocations.results[0].geometry.location,
          address: gotLocations.results[0].formatted_address,
          actual_address: location.name_and_address,
        });
      })
      .catch((errLocation) => {
        console.log(errLocation, "err with geocoding");
      });
  });
  return locs;
};

const existingCommittees = () => {
  return new Promise((resolve, reject) => {
    getUsers()
      .then((users) => {
        console.log(users, "users found");
        if (users && users.length > 0) {
          let coordinators = users.filter(
            (user) => user.role === "ec_coordinator"
          );
          console.log(coordinators, "coordinators");
          let registeredComms = coordinators.map(
            (coordinator) =>
              coordinator &&
              coordinator.others &&
              coordinator.others[0] &&
              coordinator.others[0].ethics_committee
          );
          console.log(registeredComms, "registered comms");
          getSimilarCommittee("all")
            .then((gotSimilar) => {
              let similar_committees = gotSimilar;
              let similar_committees_with_info =
                similar_committees && similar_committees.length > 0
                  ? similar_committees.map(
                      (scom) =>
                        ((scom && scom.approval_status === "requested") ||
                          (scom && scom.approval_status === "accepted")) &&
                        scom.committee
                      // scom && scom.enriched_information
                      //   ? scom && scom.committee
                      //   : null
                    )
                  : [];
              console.log(similar_committees, "similar coms");
              let existing_committees = [
                ...registeredComms,
                ...similar_committees_with_info,
              ];
              console.log(existing_committees, "existing coms");
              resolve(existing_committees);
            })
            .catch((errSimilar) => {
              console.log(errSimilar, "err while fetching similar comms");
              reject([]);
            });
        }
      })
      .catch((errUsers) => {
        console.log(errUsers, "err with fetching users");
      });
  });
};

const getAllCommittees = () => {
  return new Promise((resolve, reject) => {
    cdscoCommittees("reRegistered")
      .then((gotReRegistered) => {
        console.log(gotReRegistered, "got re registered comms");
        cdscoCommittees("Registered")
          .then((gotRegistered) => {
            console.log(gotRegistered, "got registered comms");
            naitikCommittees()
              .then((naitik) => {
                console.log(naitik, "got naitik comms");
                let all_committees = [
                  ...new Set([
                    ...gotReRegistered.output,
                    ...gotRegistered.output,
                    ...naitik.output,
                  ]),
                ];
                if (all_committees && all_committees.length > 0) {
                  existingCommittees()
                    .then((gotExisting) => {
                      console.log(gotExisting, "got existing comms");
                      let existing_comms = gotExisting;
                      if (existing_comms && existing_comms.length > 0) {
                        existing_comms.map((ecom) => {
                          if (
                            ecom === undefined ||
                            ecom === null ||
                            ecom === "" ||
                            ecom === " " ||
                            ecom === {}
                          ) {
                            console.log(ecom, "ecom showing falsy values");
                          } else {
                            let existingCommittee = all_committees.filter(
                              (acom) =>
                                ecom && ecom.type === "naitik"
                                  ? acom.registered_number ===
                                      ecom.registered_number &&
                                    acom._id === ecom._id &&
                                    acom.s_no === ecom.s_no &&
                                    acom.name_of_ethics_committee ===
                                      ecom.name_of_ethics_committee &&
                                    acom.address === ecom.address
                                  : acom.registered_number ===
                                      ecom.registered_number &&
                                    acom._id === ecom._id &&
                                    acom.s_no === ecom.s_no &&
                                    acom.name_and_address ===
                                      ecom.name_and_address
                            );
                            console.log(
                              existingCommittee,
                              "ec with same reg num"
                            );
                            if (
                              existingCommittee &&
                              existingCommittee.length > 0
                            ) {
                              all_committees.splice(
                                all_committees.indexOf(existingCommittee[0]),
                                1
                              );
                            } else {
                              resolve(all_committees);
                            }
                          }
                        });
                        resolve(all_committees);
                      } else {
                        resolve(all_committees);
                      }
                    })
                    .catch((errExisting) => {
                      console.log(
                        errExisting,
                        "err with fetching existing comms"
                      );
                      reject(all_committees);
                    });
                }
              })
              .catch((errNaitik) => {
                console.log(errNaitik, "err with fetching naitik comms");
                reject([...gotReRegistered.output, ...gotRegistered.output]);
              });
          })
          .catch((errRegistered) => {
            console.log(errRegistered, "err with fetching registered comms");
            reject([...gotReRegistered.output]);
          });
      })
      .catch((errReRegistered) => {
        console.log(errReRegistered, "err with fetching re registered comms");
        reject([]);
      });
  });
};

const validateEmail = (email) => {
  let emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  console.log(emailRegex.test(email), "checking email");
  if (email.indexOf(".") == "-1") {
    return false;
  } else if (email.startsWith(".") === true) {
    return false;
  }
  return emailRegex.test(email);
};

const getUserName = async (id) => {
  return new Promise((resolve, reject) => {
    getUserWithId(id)
      .then((user) => {
        console.log(user, "got user for name");
        resolve(user);
      })
      .catch((errUser) => {
        console.log(errUser, "err while fetching user name");
        reject("None");
      });
  });
};

const objectEncrypt = (data) => {
  let encoded = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    cryptoObjectSecret
  ).toString();
  return encoded;
};

const objectDecrypt = (data) => {
  let bytes = CryptoJS.AES.decrypt(data, cryptoObjectSecret);
  let decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decrypted;
};

const checkForFileExtension = (url) => {
  if (url && url.length > 0) {
    let check = url.endsWith(".png" || ".jpg");
    console.log(check, "checking file type");
    return check;
  } else {
    console.log(url, "url not found");
    return false;
  }
};

const getFileName = (url) => {
  if (url && url.length > 0) {
    let splitUrl = url.split(
      "https://ecdev-file-store.s3.ap-south-1.amazonaws.com/"
    );
    if (splitUrl && splitUrl.length > 0) {
      let fileName = splitUrl[1];
      return fileName;
    } else {
      console.log(splitUrl, "no file name found in url");
      return null;
    }
  } else {
    console.log(url, "no file url found");
    return null;
  }
};

const getRandomID = () => {
  let randomID = uuidv4();
  console.log(randomID, "random id");
  return randomID;
};

const getCurrentUser = (user) => {
  let currentUser = {
    user_id: user._id,
    user_email: user.email,
    user_name: user.username,
    user_role: user.role,
  };
  return currentUser;
};

const addUnRead = (update) => {
  if (update !== null) {
    update["isRead"] = false;
    return update;
  } else {
    console.log(update, "update not found");
  }
};

const sendUpdate = async (update) => {
  return await createUpdate(update)
    .then((success) => {
      console.log(success, " update created");
    })
    .catch((failure) => {
      console.log(failure, "err while creating update");
    });
};

const getRandomColor = () => {
  let colors = [
    "#FFD59E",
    "#069A8E",
    "#FFD124",
    "#533E85",
    "#B958A5",
    "#FFE3E3",
    "#FF8A69",
    "#D7A86E",
    "#94B3FD",
    "#FDCEB9",
    "#750550",
    "#4F091D",
    "#BCCC9A",
    "#FFA1A1",
  ];
  let randomColor = Math.floor(Math.random() * colors.length);
  return colors[randomColor] || "dodgerblue"
};

export {
  fields,
  getFieldData,
  encrypt,
  getLocations,
  getMultipleLocations,
  getAllCommittees,
  validateEmail,
  getUserName,
  objectEncrypt,
  objectDecrypt,
  checkForFileExtension,
  getFileName,
  getRandomID,
  getCurrentUser,
  addUnRead,
  sendUpdate,
  getRandomColor,
};
