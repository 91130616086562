import "./App.css";
import "./bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./routes/Router";
import { Logo, ptLogo } from "./theme/icons";
function App() {
  return (
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
}

export default App;
