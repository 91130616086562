const cryptoSecret = "fre7rh3874hbrfeh36dbywgst$#@&^";
const cryptoObjectSecret = "fe76fe5*^&$*%^#$&mkm$%$#$&(*&^254184"
const Google_Map_Key = "AIzaSyBGeidlKq2m6aN6f2-X5NYtQlisL-GeZHo"
//const Google_Map_Key = "AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo"
const environment = "STAGING"
const local = "http://192.168.226.243:4000";
const dev = "http://34.208.183.242:4000"
const domain = (environment === "DEV" && "http://34.208.183.242:4000/") ||
(environment === "STAGING" && "https://ecdev.etrials.io/") || 
(environment === "PROD" && "https://saral.etrials.io/")
 // environment === "DEV" ? dev : local
export { cryptoSecret,cryptoObjectSecret,Google_Map_Key, domain, environment };
