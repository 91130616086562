import React, { useEffect, useState } from "react";
import { bell, filter, List, Logo } from "../theme/icons";
import { getUpdate, logout, putUpdate, userProfile } from "../helpers/api";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Header = (props) => {
  const {
    userId,
    userEmail,
    userName,
    changeUserStatus,
    userRole,
    updates,
    userStatus,
    userCommitteeId,
  } = props;
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [ displayProfile, setDisplayProfile ] = useState(false)

  const userLogout = () => {
    logout()
      .then((userLogout) => {
        console.log(userLogout, "user logged out");
        if (
          userLogout &&
          userLogout.error === false &&
          userLogout.message === "User logged out"
        ) {
          cookies.remove("connect.sid");
          window.sessionStorage.clear();
          changeUserStatus();
        }
      })
      .catch((errUserLogout) => {
        console.log(errUserLogout, "err with user logging out");
        cookies.remove("connect.sid");
        changeUserStatus();
      });
  };

  const goToFeature = (id, update) => {
    //console.log(update , 'checking update')
    let { type, item } = update.update;
    if (type === "enquiry") {
      let filterUser =
        update &&
        update.to &&
        update.to.filter((item) => item.user_id === userId);
      filterUser[0]["isRead"] = true;
      console.log(filterUser[0], update.to, "after changing");
      putUpdate(id, { isRead: true, to: update.to })
        .then((updated) => {
          console.log(updated, "updated update successfully");
        })
        .catch((errUpdated) => {
          console.log(errUpdated, "err while updating update");
        });
      window.location.href = "/enquiry/detail/" + item;
    } else if (type === "ethics committee") {
      let filterUser =
        update &&
        update.to &&
        update.to.filter((item) => item.user_id === userId);
      filterUser[0]["isRead"] = true;
      console.log(filterUser[0], update.to, "after changing");
      putUpdate(id, { isRead: true, to: update.to })
        .then((updated) => {
          console.log(updated, "updated update successfully");
        })
        .catch((errUpdated) => {
          console.log(errUpdated, "err while updating update");
        });
      if (userRole === "ec_qcr_admin") {
        window.location.href = "/admin/committees";
      } else if (userRole === "ec_coordinator") {
        window.location.href = "/addinfo/" + item;
      }
    } else if (type === "user") {
      let filterUser =
        update &&
        update.to &&
        update.to.filter((item) => item.user_id === userId);
      filterUser[0]["isRead"] = true;
      console.log(filterUser[0], update.to, "after changing");
      putUpdate(id, { isRead: true, to: update.to })
        .then((updated) => {
          console.log(updated, "updated update successfully");
        })
        .catch((errUpdated) => {
          console.log(errUpdated, "err while updating update");
        });
      if (userRole === "ec_qcr_admin") {
        window.location.href = "/admin/users";
      } else if (userRole === "ec_independent_researcher") {
        if (userStatus === true) {
          window.location.href = "/home";
        } else {
          window.location.href = "/in-review";
        }
      } else if (userRole === "ec_coordinator") {
        if (userStatus === true) {
          window.location.href = "/addinfo/" + userCommitteeId;
        } else {
          window.location.href = "/in-review";
        }
      }
    }
  };
  //console.log(props);

  const checkUserRelated = (update) => {
    if (update && update.to && update.to.length > 0) {
      let filterUpdate = update.to.filter(
        (item) => item.user_id === userId && item.isRead === false
      );
      if (filterUpdate && filterUpdate.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      console.log(update.to, "no users found for this update");
      return false;
    }
  };

  const getUnread = (updates) => {
    if (updates && updates.length > 0) {
      let unread = updates.filter(
        (update) => checkUserRelated(update) === true
      );
      console.log(unread, "un read");
      return unread;
    } else {
      return 0;
    }
  };

  return (
    <div className="menubar container-fluid">
      <nav className="navbar navbar-expand-lg px-lg-0 py-0 ml-2">
        <a href="/login">
          <img style={{height : 80}} className="navbar-brand logo" alt="logo" src={Logo}></img>
        </a>
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#menu"
        >
          <span className="navbar-toggler-icon">
            <img className="p-1" src={List} alt="header menu icon"></img>
          </span>
        </button>
        <div id="menu" className="collapse navbar-collapse">
          <div className="ml-auto">
            <div className="row">
              {userRole === "ec_coordinator" ||
              userRole === "ec_independent_researcher" ? (
                <div className="px-4 pt-2 mt-2">
                  <p
                    onClick={() => (window.location.href = "/enquiry")}
                    className="mb-0"
                    style={{
                      color: "#BC5405",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    My Enquiries
                  </p>
                  {/* <img className="img-responsive" src={bell} alt="notification" style={{height:30,width:30}}></img><sup>4</sup> */}
                </div>
              ) : null}
              {
                userRole === "ec_independent_researcher" ? (
                  <div className="px-4 pt-2 mt-2">
                    <p
                      onClick={() => (window.location.href = "/general_enquiry")}
                      className="mb-0"
                      style={{
                        color: "#5B8FB9",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      Request Prospect
                    </p>
                  </div>
                ) : null
              }
              {
                <div className="px-5 pt-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      onClick={() =>
                        setDisplayNotifications(
                          displayNotifications === true ? false : true
                        )
                      }
                      className="img-responsive"
                      src={bell}
                      alt="notification"
                      style={{
                        height: 30,
                        width: 30,
                        cursor: "pointer",
                        transform: "rotate(45deg)",
                      }}
                    ></img>
                    <sup className="p-2">
                      {getUnread(updates) && getUnread(updates).length > 0
                        ? getUnread(updates).length
                        : 0}
                    </sup>
                  </div>
                  {displayNotifications === true ? (
                    <div
                      style={{
                        position: "fixed",
                        top: "90px",
                        right: "103px",
                        height: 300,
                        width: 400,
                        overflowY: "scroll",
                        backgroundColor: "#eaeaeb",
                      }}
                      className=" p-3"
                    >
                      {updates && updates.length > 0 ? updates.sort(
                        (d1, d2) =>
                          new Date(d1.createdAt).getTime() -
                          new Date(d2.createdAt).getTime()
                      )
                      .reverse().map((update) => (
                        <div
                          onClick={() => goToFeature(update._id, update)}
                          style={{
                            //borderBottom: "1px solid black",
                            borderRadius: 10,
                            backgroundColor:
                              checkUserRelated(update) === true
                                ? "white"
                                : "#f1f3f4",
                            cursor: "pointer",
                          }}
                          className="my-2 py-2 px-2"
                        >
                          <h6 className="mb-0">{update.title}</h6>
                          <p className="mb-0">{update.description}</p>
                        </div>
                      )) : <p className="mb-0 text-center">No updates found</p>}
                    </div>
                  ) : null}
                </div>
              }
              <div style={{ cursor : 'pointer' }} onClick={()=> setDisplayProfile( displayProfile === false ? true : false )} className="d-flex">
                  <div className="d-flex align-items-center justify-content-center">
                    <p style={{ fontWeight: "bold", textTransform : "capitalize" }} className="mx-2 my-1 mb-0">
                      {userName}
                    </p>
                  </div>
                  <div
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 30,
                      backgroundColor: "dodgerblue",
                      color: "white",
                    }}
                    className="d-flex align-items-center justify-content-center mx-2"
                  >
                    <h4>{userEmail.toUpperCase().charAt("0")}</h4>
                  </div>
              </div>
              {displayProfile === true ? 
              <div style={{ position: "absolute", top:89, right: 0, backgroundColor: 'rgb(234, 234, 235)' }} className="text-left">
                <div className="profile_option">
                    <p
                      onClick={() => window.location.href = "/profile"}
                      style={{ cursor: "pointer", fontFamily: 'LatoRegular', fontWeight: 500 }}
                      className="my-1 p-2 mb-0"
                    >
                      View Profile
                    </p>
                </div>
                {userRole === "ec_qcr_admin" ? null : 
                <div className="profile_option">
                    <p
                      onClick={() => userLogout()}
                      style={{ cursor: "pointer", fontFamily: 'LatoRegular', fontWeight: 500 }}
                      className="my-1 p-2 mb-0"
                    >
                      Log out
                    </p>
                </div>}
              </div> 
              : null}
            </div>
          </div>
        </div>
      </nav>
    </div>
    // <div style={{ boxShadow: "0px 2px 3px #eeeeee", backgroundColor: "white" }}>
    //   <nav className="navbar navbar-expand-lg px-5">
    //     <img
    //       className="navbar-brand logo"
    //       alt="logo"
    //       style={{ height: 60 }}
    //       src={Logo}
    //     ></img>
    //     <button
    //       className="navbar-toggler"
    //       data-toggle="collapse"
    //       data-target="#menu"
    //     >
    //       <span className="navbar-toggler-icon">
    //         <img src={List} alt="header menu icon"></img>
    //       </span>
    //     </button>
    //     <h5 style={{ color: "#BC5405" }} className="mb-0 ml-4">
    //       Ethical Committees
    //     </h5>
    //     <div
    //       id="menu"
    //       className="ml-auto d-flex align-items-center justify-content-center"
    //     >
    //       {userRole === "ec_coordinator" ||
    //       userRole === "ec_independent_researcher" ? (
    //         <div
    //           style={{ borderRight: "1px solid black" }}
    //           className="px-4 mx-4"
    //         >
    //           <p
    //             onClick={() => (window.location.href = "/enquiry")}
    //             className="mb-0"
    //             style={{color:'#BC5405', fontWeight:'bold', cursor:'pointer'}}
    //           >
    //             My Enquiries
    //           </p>
    //           {/* <img className="img-responsive" src={bell} alt="notification" style={{height:30,width:30}}></img><sup>4</sup> */}
    //         </div>
    //       ) : null}
    //       <div className="d-flex align-items-center justify-content-center">
    //         <div>
    //           <p style={{ fontWeight: "bold" }} className="mx-2 my-1 mb-0">
    //             {userEmail}
    //           </p>
    //           <p
    //             onClick={() => userLogout()}
    //             style={{ cursor: "pointer" }}
    //             className="mx-2 my-1 mb-0"
    //           >
    //             Log out
    //           </p>
    //         </div>
    //         <div
    //           style={{
    //             height: 50,
    //             width: 50,
    //             borderRadius: 30,
    //             backgroundColor: "dodgerblue",
    //             color: "white",
    //           }}
    //           className="d-flex align-items-center justify-content-center mx-2"
    //         >
    //           <h4>{userEmail.toUpperCase().charAt("0")}</h4>
    //         </div>
    //       </div>
    //     </div>
    //   </nav>
    // </div>
  );
};

export default Header;
