import axios from "axios";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  additionalInformation,
  createSimilarCommittee,
  getCommitteeDetails,
  getEnrichedInfo,
  removeSimilarCommittee,
  updateCommittee,
  updateCommitteeStatus,
  userProfile,
  getSimilarCommittee,
  updatedSimilarCommittee,
  getAdmin,
} from "../helpers/api";
import {
  authority,
  capabilities,
  composition,
  contact,
  finance,
  meeting,
  offive,
  plus,
  policy,
  process,
  regulatory,
  minus,
  timeline,
  training,
} from "../theme/icons";
import Header from "./Header";
import {
  Regulatory,
  ECComposition,
  ECMeeting,
  ECPolicy,
  ECTraining,
  SubmissionProcess,
  ContactDetail,
  Authority,
  ECOffice,
  Timeline,
  Capabilites,
  Finance,
} from "../components/addInfo-form";
import {
  getAllCommittees,
  objectEncrypt,
  sendUpdate,
  validateEmail,
  addUnRead,
  getCurrentUser,
} from "../helpers/helper";
import NotFound from "./NotFound";
import NotApproved from "./NotApproved";
import { format } from "crypto-js";
import InfoModal from "./InfoModal";
import InfoQuestion from "./InfoQuestion";

const blueBackground = "#CCF0FB";
const redBackground = "#F2DEDE";

const fields = [
  { title: "Regulatory", icon: regulatory, bgColor: blueBackground },
  { title: "EC Composition (EC)", icon: composition, bgColor: redBackground },
  { title: "EC Meeting", icon: meeting, bgColor: blueBackground },
  { title: "EC Policy", icon: policy, bgColor: redBackground },
  { title: "EC Training", icon: training, bgColor: blueBackground },
  { title: "Submission of Process", icon: process, bgColor: redBackground },
  { title: "Finance", icon: finance, bgColor: blueBackground },
  // { title: "Capabilities", icon: capabilities, bgColor: redBackground },
  { title: "Timeline", icon: timeline, bgColor: redBackground },
  { title: "EC Office", icon: offive, bgColor: blueBackground },
  {
    title: "Authority of Ethics Committee",
    icon: authority,
    bgColor: redBackground,
  },
  { title: "Contact Details", icon: contact, bgColor: blueBackground },
];

const fields_for_clearing = (enriched_info) =>{
  
    enriched_info["pincode"] = ""
    enriched_info["cdsco_approval_date"] = ""
    enriched_info["cdsco_validity_date"] = ""
    enriched_info["similarcommittees"] = ""
    enriched_info["ec_policy"] = ""
    enriched_info["dhr_approval_date"] = ""
    enriched_info["dhr_validity_date"] = ""
    enriched_info["next_meeting_date"] = ""
    enriched_info["how_often_ec_meet"] = ""
    enriched_info["ec_existing_since"] = ""
    enriched_info["ec_title_of_policy"] = ""
    enriched_info["conflict_of_interest_policy"] = ""
    enriched_info["list_of_holidays"] = ""
    enriched_info["ec_composition_members"] = ""
    enriched_info["ec_composition_EC"] = 0
    enriched_info["ec_days_prior_dossier_submission"] = ""
    enriched_info["ec_issue_approval_post_meeting"] = ""
    enriched_info["list_of_sops"] = []
    enriched_info["ec_training"] = []
    enriched_info["process_of_ec_submission"] = ""
    enriched_info["submission_process"] = ""
    enriched_info["is_paper_based"] = ""
    enriched_info["is_online_based"] = ""
    enriched_info["submission_process_paper_based_copies"] = ""
    enriched_info["submission_process_online_description"] = ""
    enriched_info["submission_review_check_list"] = []
    enriched_info["ec_submission_forms"] = []
    enriched_info["review_checklist_process"] = ""
    enriched_info["ec_initial_review_fees"] = ""
    enriched_info["ec_amendment_review_fees"] = ""
    enriched_info["ec_final_review_process"] = ""
    enriched_info["mode_of_payment"] = ""
    enriched_info["payee_name"] = ""
    enriched_info["ec_gst"] = ""
    enriched_info["ec_tds"] = ""
    enriched_info["submission_process_type"] = ""
    enriched_info["sae_review"] = ""
    enriched_info["finance"] = ""
    enriched_info["frequency_of_ec_site_monitoring"] = ""
    enriched_info["timeline_for_ec_dossier_submission"] = ""
    enriched_info["approval_for_ec_and_scientific_timeline"] = ""
    enriched_info["interim_progress_report_timeline"] = ""
    enriched_info["timeline_for_expedite_review_and_approval"] = ""
    enriched_info["document_storage_facility"] = ""
    enriched_info["fire_extingusher"] = ""
    enriched_info["emergency_alarm"] = ""
    enriched_info["parent_institute_name"] = ""
    enriched_info["parent_registration_number"] = ""
    enriched_info["primary_contact_name"] = ""
    enriched_info["primary_contact_email"] = ""
    enriched_info["primary_contact_contact_number"] = ""
    enriched_info["alternative_contact_name"] = ""
    enriched_info["alternative_contact_email"] = ""
    enriched_info["alternative_contact_contact_number"] = ""
  return enriched_info
}

class Info extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fieldTitle: "",
      templateName: "",
      userId: "",
      user: {},
      adminUser: "",
      id: "",
      committee: "",
      enrichedCommittee: "",
      askForLinking: false,
      askNextQuestion: false,
      ec_composition_members: [],
      canLinkSimilarCommittee: false,
      suggestions: [],
      similarcommittees: [],
      ec_training: [],
      ec_policy: [],
      emergency_alarm: false,
      fire_extingusher: false,
      selectedSuggestion: null,
      showPrimaryEmailError: false,
      ask_to_continue_einfo : "",
      showAlternateEmailError: false,
      showSimilarCommitteeModal: false,
      showMembersModal: false,
      selectedEnrichedSimlarCommittee: "",
    };
    this.fileTemplate = React.createRef();
    this.userEmail = window.sessionStorage.getItem("userEmail");
    this.userStatus = window.sessionStorage.getItem("userStatus");
    this.userRole = window.sessionStorage.getItem("userRole");
  }

  openInfoModal = () => {
    this.setState({ showSimilarCommitteeModal: true });
  };
  closeInfoModal = () => {
    this.setState({ showSimilarCommitteeModal: false });
  };

  openMembersModal = () => {
    this.setState({ showMembersModal: true });
  };
  closeMembersModal = () => {
    this.setState({ showMembersModal: false });
  };

  updateLinkingSimilarCommittee = (status) => {
    this.setState({ canLinkSimilarCommittee: status });
  };

  askLinking = (status) => {
    this.setState({ askForLinking: status });
  };

  changeNextQuestion = (status) => {
    this.setState({ askNextQuestion: status });
  };

  getOnlyMainScoms = (search, committees) => {
    if (committees && committees.length > 0) {
      console.log(committees, "before");
      let checkForScoms = committees.filter(
        (committee) =>
          committee && committee.type === "naitik" ? committee.name_of_ethics_committee &&
          committee.name_of_ethics_committee.toLowerCase().startsWith(this.state.committee.name_and_address && this.state.committee.name_and_address.split(" ") && this.state.committee.name_and_address.split(" ").length > 0 ? this.state.committee.name_and_address.split(" ")[0].toLowerCase()  : "") ===
            true :
          committee.name_and_address &&
          committee.name_and_address.toLowerCase().startsWith(this.state.committee.name_and_address && this.state.committee.name_and_address.split(" ") && this.state.committee.name_and_address.split(" ").length > 0 ? this.state.committee.name_and_address.split(" ")[0].toLowerCase()  : "") ===
            true
      );
      console.log(checkForScoms, "checking for scoms");
      let main_committee_type = this.state.committee && this.state.committee.type;
      if(main_committee_type !== undefined){
        if((main_committee_type === "reRegistered") || (main_committee_type === "Registered")){
          return checkForScoms && checkForScoms.length > 0 ? checkForScoms.filter(committee=> committee.type === "naitik") : []
        } else if(main_committee_type === "naitik"){
          return checkForScoms && checkForScoms.length > 0 ? checkForScoms.filter(committee=> (committee.type === "reRegistered") || (committee.type === "Registered")) : []
        }
      }else{
        console.log("main committee type not found" , main_committee_type);
        alert("Error fetching similar committees")
      }
      return checkForScoms
    } else {
      console.log(committees, "no committees found");
      return []
    }
  };

  similarCommiteeSearch = (e) => {
    this.setState({ committeesearch: e.target.value }, () => {
      getAllCommittees()
        .then((allCommittees) => {
          console.log(allCommittees, "all");
          if (allCommittees && allCommittees.length > 0) {
            console.log(allCommittees, "coms");
            let filterComms = allCommittees.filter((com) =>
              com.type === "naitik"
                ? (com.address
                    .toLowerCase()
                    .startsWith(e.target.value.toLowerCase()) === true) || (com.name_of_ethics_committee
                      .toLowerCase()
                      .startsWith(e.target.value.toLowerCase()) === true)
                : com.name_and_address
                    .toLowerCase()
                    .startsWith(e.target.value.toLowerCase()) === true
            );
            console.log(filterComms, "filtering");
            let filterMainCom = filterComms.filter(
              (fcom) =>
                fcom.registered_number ===
                  (this.state.committee &&
                    this.state.committee.registered_number) &&
                fcom.type === this.state.committee.type &&
                (fcom.type === "naitik"
                  ? fcom.name_of_ethics_committee ===
                    this.state.committee.name_of_ethics_committee
                  : fcom.name_and_address ===
                    this.state.committee.name_and_address)
            );
            console.log(filterMainCom, "main com");
            if (filterMainCom && filterMainCom.length > 0) {
              filterComms.splice(filterComms.indexOf(filterMainCom[0]), 1);
              // let result = this.getOnlyMainScoms(this.state.committeesearch, filterComms);
              this.setState({
                suggestions:
                  filterComms && filterComms.length > 0 ? filterComms : [],
              });
            } else {
              // let result = this.getOnlyMainScoms(this.state.committeesearch, filterComms);
              this.setState({
                suggestions:
                  filterComms && filterComms.length > 0 ? filterComms : [],
              });
            }
          } else {
            console.log("committees suggestions not found");
            this.setState({ suggestions: [] });
          }
        })
        .catch((errAllCommittees) => {
          console.log(errAllCommittees, "err with fetching all committees");
          this.setState({ suggestions: [] });
        });
    });
  };

  removeMember = (memberId) => {
    console.log(memberId, "id");
    let members = this.state.ec_composition_members;
    members.splice(
      members.indexOf(members.filter((item) => item.id === memberId)[0])
    );
    this.setState({ ec_composition_members: members }, () => {
      this.setState({
        ec_composition_EC:
          this.state.ec_composition_members &&
          this.state.ec_composition_members.length,
      });
    });
  };
  createMembers = (members) => {
    let total = [];
    for (let i = 0; i < members; i++) {
      let memberObj = {};
      memberObj["id"] = "member" + i;
      memberObj["member" + i + "role"] = "";
      memberObj["member" + i + "name"] = "";
      memberObj["member" + i + "qualification"] = "";
      memberObj["member" + i + "experience"] = "";
      memberObj["member" + i + "affiliation"] = "";
      total.push(memberObj);
    }
    return total;
  };
  splitMembers = (members) => {
    members.map((member) => {
      console.log(member, "member");
      let keys = Object.keys(member);
      keys.map((key) => {
        console.log(key, member[key]);
        if (key === "id") {
          console.log(key);
        } else {
          this.setState({ [key]: member[key] });
        }
      });
    });
  };

  splitRowMembers = (rows) => {
    if (rows && rows.length > 0) {
      rows.map((row) => {
        row[row.id + "members"].map((rowmember) => {
          return this.setState({
            [rowmember.id + "-" + row.id]: rowmember[rowmember.id + "name"],
          });
        });
      });
    }
  };

  selectMembers = (row, member, selected) => {
    let training = this.state.ec_training;
    let rowIndex = training.indexOf(row);
    console.log(row[row.id + "members"], "row members");
    let index = row[row.id + "members"].indexOf(member);
    console.log(index, "checking where item is");
    if (index === -1) {
      member["checked"] = selected;
      row[row.id + "members"].push(member);
      console.log(rowIndex, member, row[row.id + "members"][0], "checking now");
      training[rowIndex] = row;
      this.setState({ ec_training: training });
    } else {
      member["checked"] = selected;
      row[row.id + "members"][index] = member;
      console.log(
        rowIndex,
        index,
        member,
        row[row.id + "members"][index],
        "checking now"
      );
      training[rowIndex] = row;
      this.setState({ ec_training: training });
    }
  };

  chooseMembersCheckboxChange = (e) => {
    console.log(e.target.name, e.target.checked, "for rows");
    this.setState({ [e.target.name]: e.target.checked }, () => {
      let memberField = e.target.name.split("-")[0];
      let rowField = e.target.name.split("-")[1];
      let filterRow =
        this.state.ec_training.filter((row) => row.id === rowField) &&
        this.state.ec_training.filter((row) => row.id === rowField)[0];
      let filterMember =
        this.state.ec_composition_members.filter(
          (member) => member.id === memberField
        ) &&
        this.state.ec_composition_members.filter(
          (member) => member.id === memberField
        )[0];
      let training = this.state.ec_training;
      if (e.target.checked === true) {
        filterRow[filterRow.id + "members"].push(filterMember);
        let rowmembers = filterRow[filterRow.id + "members"];
        let selectedMember =
          rowmembers.filter((rowmember) => rowmember.id === memberField) &&
          rowmembers.filter((rowmember) => rowmember.id === memberField)[0];
        if (selectedMember === undefined) {
          console.log("selected member not present in training");
        } else {
          selectedMember["checked"] = true;
          let memberIndex = rowmembers.indexOf(selectedMember);
          rowmembers[memberIndex] = selectedMember;
          filterRow[filterRow.id + "members"] = rowmembers;
          let rowIndex = training.indexOf(filterRow);
          training[rowIndex] = filterRow;
          this.setState({ ec_training: training });
        }
      } else if (e.target.name === false) {
        filterRow[filterRow.id + "members"].splice(
          filterRow[filterRow.id + "members"].indexOf(
            filterRow[filterRow.id + "members"]
          ),
          1
        );
        let rowmembers = filterRow[filterRow.id + "members"];
        let selectedMember =
          rowmembers.filter((rowmember) => rowmember.id === memberField) &&
          rowmembers.filter((rowmember) => rowmember.id === memberField)[0];
        if (selectedMember === undefined) {
          console.log("selected member not present in training when unchecked");
        } else {
          selectedMember["checked"] = false;
          let memberIndex = rowmembers.indexOf(selectedMember);
          rowmembers[memberIndex] = selectedMember;
          filterRow[filterRow.id + "members"] = rowmembers;
          let rowIndex = training.indexOf(filterRow);
          training[rowIndex] = filterRow;
          this.setState({ ec_training: training });
        }
      }
    });
  };

  createTrainingRow = (index) => {
    let members = this.state.ec_composition_members;
    members.map((member) => (member.checked = false));
    console.log(members, "paradox");
    let obj = { id: "row" + index };
    obj["row" + index + "topic"] = "";
    obj["row" + index + "training_date"] = "";
    obj["row" + index + "members"] = this.state.ec_composition_members;
    console.log(obj, "row obj");
    let training = this.state.ec_training;
    if (training && training.length > 0) {
      training.push(obj);
      this.setState({ ec_training: training });
    } else {
      this.setState({ ec_training: [obj] });
    }
  };

  handlePrimaryMailChange = (e) => {
    this.setState({ primary_contact_email: e.target.value }, () => {
      let checkEmail = validateEmail(e.target.value);
      if (checkEmail === false) {
        this.setState({ showPrimaryEmailError: true });
      } else {
        this.setState({ showPrimaryEmailError: false });
      }
    });
  };

  handleAlternateMailChange = (e) => {
    this.setState({ alternative_contact_email: e.target.value }, () => {
      let checkEmail = validateEmail(e.target.value);
      if (checkEmail === false) {
        this.setState({ showAlternateEmailError: true });
      } else {
        this.setState({ showAlternateEmailError: false });
      }
    });
  };

  handleSimilarCommitteePrimaryMailChange = (e) => {
    console.log(e.target.name, e.target.value, "inputs for escom");
    let scoms =
      this.state.enrichedCommittee &&
      this.state.enrichedCommittee.similar_committees;
    if (scoms && scoms.length > 0) {
      let enrichedSimilarCommittee = scoms.filter(
        (scom) => scom._id === this.state.selectedEnrichedSimlarCommittee._id
      );
      if (enrichedSimilarCommittee && enrichedSimilarCommittee.length > 0) {
        let checkEmail = validateEmail(e.target.value);
        if (checkEmail === false) {
          this.setState({ showPrimaryEmailError: true });
        } else {
          this.setState({ showPrimaryEmailError: false });
          enrichedSimilarCommittee[0].enriched_information[
            "primary_contact_email"
          ] = e.target.value;
          this.setState({
            [this.state.selectedEnrichedSimlarCommittee &&
            this.state.selectedEnrichedSimlarCommittee._id]:
              enrichedSimilarCommittee && enrichedSimilarCommittee[0],
          });
        }
      }
    }
  };

  handleSimilarCommitteeAlternateMailChange = (e) => {
    console.log(e.target.name, e.target.value, "inputs for escom");
    let scoms =
      this.state.enrichedCommittee &&
      this.state.enrichedCommittee.similar_committees;
    if (scoms && scoms.length > 0) {
      let enrichedSimilarCommittee = scoms.filter(
        (scom) => scom._id === this.state.selectedEnrichedSimlarCommittee._id
      );
      if (enrichedSimilarCommittee && enrichedSimilarCommittee.length > 0) {
        let checkEmail = validateEmail(e.target.value);
        if (checkEmail === false) {
          this.setState({ showPrimaryEmailError: true });
        } else {
          this.setState({ showPrimaryEmailError: false });
          enrichedSimilarCommittee[0].enriched_information[
            "alternative_contact_email"
          ] = e.target.value;
          this.setState({
            [this.state.selectedEnrichedSimlarCommittee &&
            this.state.selectedEnrichedSimlarCommittee._id]:
              enrichedSimilarCommittee && enrichedSimilarCommittee[0],
          });
        }
      }
    }
  };

  updateEnrichedSimilarCommittee = () => {
    let info =
      this.state[this.state.selectedEnrichedSimlarCommittee._id] &&
      this.state[this.state.selectedEnrichedSimlarCommittee._id]
        .enriched_information;
    console.log(info, "checking id while updating escom");
    updatedSimilarCommittee(
      this.state.selectedEnrichedSimlarCommittee &&
        this.state.selectedEnrichedSimlarCommittee._id,
      this.state.enrichedCommittee._id,
      { enriched_information: info }
    )
      .then((updatedScom) => {
        console.log(updatedScom, "updated similar committee");
        alert("Committee details updated successfully");
      })
      .catch((errUpdatedScom) => {
        console.log(
          errUpdatedScom,
          "err while updating similar committee with enriched info"
        );
        alert("Error with updating committee details");
      });
  };

  saveEnrichedScomHere = (param, id) => {
    let info =
      this.state[this.state.selectedEnrichedSimlarCommittee._id] &&
      this.state[this.state.selectedEnrichedSimlarCommittee._id]
        .enriched_information;
    console.log(info, "checking id while updating escom");
    updatedSimilarCommittee(
      this.state.selectedEnrichedSimlarCommittee &&
        this.state.selectedEnrichedSimlarCommittee._id,
      this.state.enrichedCommittee._id,
      { enriched_information: info }
    )
      .then((updatedScom) => {
        console.log(updatedScom, "updated similar committee");
        //alert("Committee details updated successfully");
        window.location.href = `/${param}/` + id;
      })
      .catch((errUpdatedScom) => {
        console.log(
          errUpdatedScom,
          "err while updating similar committee with enriched info"
        );
        alert("Error with updating committee details");
      });
  };

  handleSimilarCommitteeChange = (e) => {
    console.log(e.target.name, e.target.value, "inputs for escom");
    let scoms =
      this.state.enrichedCommittee &&
      this.state.enrichedCommittee.similar_committees;
    if (scoms && scoms.length > 0) {
      let enrichedSimilarCommittee = scoms.filter(
        (scom) => scom._id === this.state.selectedEnrichedSimlarCommittee._id
      );

      if (enrichedSimilarCommittee && enrichedSimilarCommittee.length > 0) {
        enrichedSimilarCommittee[0].enriched_information[e.target.name] =
          e.target.value;
        if (e.target.name.startsWith("member") === true) {
          let members =
            enrichedSimilarCommittee[0].enriched_information[
              "ec_composition_EC"
            ];
          console.log(members, "similar committee members");
          let member = members.filter(
            (member) => e.target.name.includes(member.id) === true
          )[0];
          member[e.target.name] = e.target.value;
          console.log(
            member,
            members.filter((m) => m.id),
            e.target.name,
            e.target.value,
            "members"
          );
        } else {
          this.setState({
            [this.state.selectedEnrichedSimlarCommittee &&
            this.state.selectedEnrichedSimlarCommittee._id]:
              enrichedSimilarCommittee && enrichedSimilarCommittee[0],
          });
        }
      }
    }
  };

  handleChange = (e) => {
    console.log(e.target, e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (e.target.name === "ec_template") {
        this.setState({
          templateName: this.fileTemplate.current.files[0].name,
        });
      } else if (e.target.name === "ec_composition_EC") {
        this.setState({ [e.target.name]: e.target.value }, () => {
          console.log(this.createMembers(e.target.value), "members");
          this.setState({
            ec_composition_members: this.createMembers(e.target.value),
          });
        });
      } else if (e.target.name.startsWith("member") === true) {
        let members = this.state.ec_composition_members;
        let member = members.filter(
          (member) => e.target.name.includes(member.id) === true
        )[0];
        member[e.target.name] = e.target.value;
        console.log(
          member,
          members.filter((m) => m.id),
          e.target.name,
          e.target.value,
          "members"
        );
        //members.splice(members.indexOf(member),1)
        this.setState({ ec_composition_members: members.filter((m) => m.id) });
      } else if (e.target.name.startsWith("row") === true) {
        //let training = this.state.ec_training
        let filterRow =
          this.state.ec_training &&
          this.state.ec_training.filter((row) =>
            e.target.name.includes(row.id)
          );
        filterRow[0][e.target.name] = e.target.value;
        console.log(filterRow, "filter row");
        //this.setState({ ec_training: training.filter((row) => row.id) });
      }
    });
  };
  checkboxChange = (e) => {
    console.log(e.target.type, e.target.checked, e.target.name, e.target.value);
    if (e.target.type === "radio") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.checked });
    }
  };

  similarCommitteecheckboxChange = (e) => {
    console.log(
      e.target.type,
      e.target.checked,
      e.target.name,
      e.target.value,
      "inputs for escom"
    );
    let scoms =
      this.state.enrichedCommittee &&
      this.state.enrichedCommittee.similar_committees;
    if (scoms && scoms.length > 0) {
      let enrichedSimilarCommittee = scoms.filter(
        (scom) => scom._id === this.state.selectedEnrichedSimlarCommittee._id
      );
      if (enrichedSimilarCommittee && enrichedSimilarCommittee.length > 0) {
        if (e.target.type === "radio") {
          enrichedSimilarCommittee[0].enriched_information[e.target.name] =
            e.target.value;
          this.setState({
            [this.state.selectedEnrichedSimlarCommittee &&
            this.state.selectedEnrichedSimlarCommittee._id]:
              enrichedSimilarCommittee && enrichedSimilarCommittee[0],
          });
        } else {
          enrichedSimilarCommittee[0].enriched_information[e.target.name] =
            e.target.checked;
          this.setState({
            [this.state.selectedEnrichedSimlarCommittee &&
            this.state.selectedEnrichedSimlarCommittee._id]:
              enrichedSimilarCommittee && enrichedSimilarCommittee[0],
          });
        }
      }
    }
  };

  similarCommitteeRadiocheckboxChange = (e) => {
    console.log(
      e.target.type,
      e.target.checked,
      e.target.name,
      e.target.value,
      "inputs for escom"
    );
    let scoms =
      this.state.enrichedCommittee &&
      this.state.enrichedCommittee.similar_committees;
    if (scoms && scoms.length > 0) {
      let enrichedSimilarCommittee = scoms.filter(
        (scom) => scom._id === this.state.selectedEnrichedSimlarCommittee._id
      );
      if (enrichedSimilarCommittee && enrichedSimilarCommittee.length > 0) {
        enrichedSimilarCommittee[0].enriched_information[e.target.name] =
          e.target.value;
        this.setState({
          [this.state.selectedEnrichedSimlarCommittee &&
          this.state.selectedEnrichedSimlarCommittee._id]:
            enrichedSimilarCommittee && enrichedSimilarCommittee[0],
        });
      }
    }
  };

  cancelInfo = (committee) => {
    this.setState({ committee: committee });
  };
  radioCheckboxChange = (e) => {
    console.log(e.target.name, e.target.value, " for radio inputs");
    this.setState({ [e.target.name]: e.target.value });
  };

  fetchDetails = () => {
    getCommitteeDetails(this.state.committee.type, this.state.committee._id)
      .then((com) => {
        console.log(com, "updated com");
        this.setState({ committee: com });
      })
      .catch((errCom) => {
        console.log(errCom, "error with fetching updated com");
        alert("Error with fetching updated committee");
      });
  };

  saveInfoHere = (param) => {
    let { user } = this.props;
    let {
      id,
      type,
      committee,
      pincode,
      cdsco_approval_date,
      cdsco_validity_date,
      dhr_approval_date,
      dhr_validity_date,
      next_meeting_date,
      how_often_ec_meet,
      ec_existing_since,
      ec_composition_members,
      list_of_holidays,
      list_of_sops,
      ec_days_prior_dossier_submission,
      ec_issue_approval_post_meeting,
      conflict_of_interest_policy,
      ec_training,
      sae_review,
      ec_policy_effective_date,
      ec_policy_validity_date,
      review_checklist_process,
      ec_initial_review_fees,
      ec_amendment_review_fees,
      ec_final_review_process,
      mode_of_payment,
      payee_name,
      ec_gst,
      ec_tds,
      submission_process_type,
      submission_process_online_description,
      process_of_ec_submission,
      is_online_based,
      is_paper_based,
      submission_process_paper_based_copies,
      parent_institute_name,
      parent_registration_number,
      finance,
      ec_title_of_policy,
      timeline_for_ec_dossier_submission,
      approval_for_ec_and_scientific_timeline,
      interim_progress_report_timeline,
      timeline_for_expedite_review_and_approval,
      document_storage_facility,
      fire_extingusher,
      emergency_alarm,
      frequency_of_ec_site_monitoring,
      primary_contact_name,
      primary_contact_email,
      primary_contact_contact_number,
      alternative_contact_name,
      alternative_contact_email,
      alternative_contact_contact_number,
    } = this.state;
    let req_body = {
      id: id,
      committee_type: committee && committee.type,
      committee_id: committee && committee._id,
      committee_registered_number: committee && committee.registered_number,
      committee: committee,
      updated_by: user._id,
      pincode: pincode,
      cdsco_approval_date: cdsco_approval_date,
      cdsco_validity_date: cdsco_validity_date,
      dhr_approval_date: dhr_approval_date,
      dhr_validity_date: dhr_validity_date,
      next_meeting_date: next_meeting_date,
      how_often_ec_meet: how_often_ec_meet,
      ec_existing_since: ec_existing_since,
      list_of_holidays: list_of_holidays,
      ec_composition_EC: ec_composition_members,
      ec_days_prior_dossier_submission: ec_days_prior_dossier_submission,
      ec_issue_approval_post_meeting: ec_issue_approval_post_meeting,
      list_of_sops: list_of_sops,
      conflict_of_interest_policy: conflict_of_interest_policy,
      ec_training: ec_training,
      process_of_ec_submission: process_of_ec_submission,
      submission_process: "",
      ec_title_of_policy: ec_title_of_policy,
      is_paper_based: is_paper_based,
      is_online_based: is_online_based,
      submission_process_paper_based_copies:
        submission_process_paper_based_copies,
      submission_process_online_description:
        submission_process_online_description,
      submission_review_check_list: [],
      review_checklist_process: review_checklist_process,
      ec_initial_review_fees: ec_initial_review_fees,
      ec_amendment_review_fees: ec_amendment_review_fees,
      ec_final_review_process: ec_final_review_process,
      mode_of_payment: mode_of_payment,
      payee_name: payee_name,
      ec_gst: ec_gst,
      ec_tds: ec_tds,
      submission_process_type: submission_process_type,
      ec_submission_forms: [],
      process_for_sae_review: sae_review,
      ec_policy_effective_date: ec_policy_effective_date,
      ec_policy_validity_date: ec_policy_validity_date,
      finance: finance,
      frequency_of_ec_site_monitoring: frequency_of_ec_site_monitoring,
      timeline_for_ec_dossier_submission: timeline_for_ec_dossier_submission,
      approval_for_ec_and_scientific_timeline:
        approval_for_ec_and_scientific_timeline,
      interim_progress_report_timeline: interim_progress_report_timeline,
      timeline_for_expedite_review_and_approval:
        timeline_for_expedite_review_and_approval,
      document_storage_facility: document_storage_facility,
      fire_extingusher: fire_extingusher,
      emergency_alarm: emergency_alarm,
      parent_institute_name: parent_institute_name,
      parent_registration_number: parent_registration_number,
      primary_contact: {
        name: primary_contact_name,
        email: primary_contact_email,
        contact_number: primary_contact_contact_number,
      },
      alternative_contact: {
        name: alternative_contact_name,
        email: alternative_contact_email,
        contact_number: alternative_contact_contact_number,
      },
    };
    if (
      this.state.enrichedCommittee === "" ||
      this.state.enrichedCommittee === " " ||
      this.state.enrichedCommittee === null ||
      this.state.enrichedCommittee === undefined
    ) {
      additionalInformation(req_body)
        .then((info) => {
          console.log(info, "info");
          //alert("Information saved successfully");
          window.location.href = `/${param}/` + this.state.id;
          //this.fetchDetails()
        })
        .catch((errInfo) => {
          console.log(errInfo, "err with info");
          alert("Error with saving information in this page");
        });
    } else {
      console.log(req_body, "req body before submitting");
      updateCommittee(this.state.enrichedCommittee._id, req_body)
        .then((success) => {
          console.log(success, "committee updated successfully");
          //alert("Updated Committee Details Successfully");
          //this.fetchDetails()
          window.location.href = `/${param}/` + this.state.id;
        })
        .catch((failure) => {
          console.log(failure, "error with updating committee details");
          alert("Error with updating committee details");
        });
    }
  };

  addInfo = () => {
    let { user } = this.props;
    let {
      id,
      type,
      committee,
      pincode,
      cdsco_approval_date,
      cdsco_validity_date,
      dhr_approval_date,
      dhr_validity_date,
      next_meeting_date,
      how_often_ec_meet,
      ec_existing_since,
      ec_composition_members,
      list_of_holidays,
      list_of_sops,
      ec_days_prior_dossier_submission,
      ec_issue_approval_post_meeting,
      conflict_of_interest_policy,
      ec_training,
      sae_review,
      ec_policy_effective_date,
      ec_policy_validity_date,
      review_checklist_process,
      ec_initial_review_fees,
      ec_amendment_review_fees,
      ec_final_review_process,
      mode_of_payment,
      payee_name,
      ec_gst,
      ec_tds,
      submission_process_type,
      submission_process_online_description,
      process_of_ec_submission,
      is_online_based,
      is_paper_based,
      submission_process_paper_based_copies,
      parent_institute_name,
      parent_registration_number,
      finance,
      ec_title_of_policy,
      timeline_for_ec_dossier_submission,
      approval_for_ec_and_scientific_timeline,
      interim_progress_report_timeline,
      timeline_for_expedite_review_and_approval,
      document_storage_facility,
      fire_extingusher,
      emergency_alarm,
      frequency_of_ec_site_monitoring,
      primary_contact_name,
      primary_contact_email,
      primary_contact_contact_number,
      alternative_contact_name,
      alternative_contact_email,
      alternative_contact_contact_number,
    } = this.state;
    let req_body = {
      id: id,
      committee_type: committee && committee.type,
      committee_id: committee && committee._id,
      committee_registered_number: committee && committee.registered_number,
      committee: committee,
      updated_by: user._id,
      pincode: pincode,
      cdsco_approval_date: cdsco_approval_date,
      cdsco_validity_date: cdsco_validity_date,
      dhr_approval_date: dhr_approval_date,
      dhr_validity_date: dhr_validity_date,
      next_meeting_date: next_meeting_date,
      how_often_ec_meet: how_often_ec_meet,
      ec_existing_since: ec_existing_since,
      list_of_holidays: list_of_holidays,
      ec_composition_EC: ec_composition_members,
      ec_days_prior_dossier_submission: ec_days_prior_dossier_submission,
      ec_issue_approval_post_meeting: ec_issue_approval_post_meeting,
      list_of_sops: list_of_sops,
      conflict_of_interest_policy: conflict_of_interest_policy,
      ec_training: ec_training,
      process_of_ec_submission: process_of_ec_submission,
      submission_process: "",
      ec_title_of_policy: ec_title_of_policy,
      is_paper_based: is_paper_based,
      is_online_based: is_online_based,
      submission_process_paper_based_copies:
        submission_process_paper_based_copies,
      submission_process_online_description:
        submission_process_online_description,
      submission_review_check_list: [],
      review_checklist_process: review_checklist_process,
      ec_initial_review_fees: ec_initial_review_fees,
      ec_amendment_review_fees: ec_amendment_review_fees,
      ec_final_review_process: ec_final_review_process,
      mode_of_payment: mode_of_payment,
      payee_name: payee_name,
      ec_gst: ec_gst,
      ec_tds: ec_tds,
      submission_process_type: submission_process_type,
      ec_submission_forms: [],
      process_for_sae_review: sae_review,
      ec_policy_effective_date: ec_policy_effective_date,
      ec_policy_validity_date: ec_policy_validity_date,
      finance: finance,
      frequency_of_ec_site_monitoring: frequency_of_ec_site_monitoring,
      timeline_for_ec_dossier_submission: timeline_for_ec_dossier_submission,
      approval_for_ec_and_scientific_timeline:
        approval_for_ec_and_scientific_timeline,
      interim_progress_report_timeline: interim_progress_report_timeline,
      timeline_for_expedite_review_and_approval:
        timeline_for_expedite_review_and_approval,
      document_storage_facility: document_storage_facility,
      fire_extingusher: fire_extingusher,
      emergency_alarm: emergency_alarm,
      parent_institute_name: parent_institute_name,
      parent_registration_number: parent_registration_number,
      primary_contact: {
        name: primary_contact_name,
        email: primary_contact_email,
        contact_number: primary_contact_contact_number,
      },
      alternative_contact: {
        name: alternative_contact_name,
        email: alternative_contact_email,
        contact_number: alternative_contact_contact_number,
      },
    };
    if (
      this.state.enrichedCommittee === "" ||
      this.state.enrichedCommittee === " " ||
      this.state.enrichedCommittee === null ||
      this.state.enrichedCommittee === undefined
    ) {
      additionalInformation(req_body)
        .then((info) => {
          console.log(info, "info");
          alert("Information saved successfully");
          let update = {
            active: false,
            title: `Enriched information created`,
            update: {
              type: "enriched_data_created",
              user_name : this.props.user.username,
              ec_registered_number : committee && committee.registered_number,
              item: this.state.enrichedCommittee._id,
            },
            description: `Enriched information created by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [],
            isRead: false,
          };
          sendUpdate(update);
          window.location.href = "/addinfo/" + this.state.id;
        })
        .catch((errInfo) => {
          console.log(errInfo, "err with info");
        });
    } else {
      console.log(req_body, "req body before submitting");
      updateCommittee(this.state.enrichedCommittee._id, req_body)
        .then((success) => {
          console.log(success, "committee updated successfully");
          alert("Updated Committee Details Successfully");
          let update = {
            active: false,
            title: `Enriched information updated`,
            update: {
              type: "enriched_data_updated",
              user_name : this.props.user.username,
              ec_registered_number : committee && committee.registered_number,
              item: this.state.enrichedCommittee._id,
            },
            description: `Enriched information updated by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.state.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          getCommitteeDetails(
            this.state.committee.type,
            this.state.committee._id
          )
            .then((com) => {
              console.log(com, "updated com");
              this.setState({ committee: com });
            })
            .catch((errCom) => {
              console.log(errCom, "error with fetching updated com");
              alert("Error with fetching updated committee");
            });
        })
        .catch((failure) => {
          console.log(failure, "error with updating committee details");
          alert("Error with updating committee details");
        });
    }
  };

  collapseField = (title) => {
    if (title === "Regulatory") {
      return <Regulatory handleChange={this.handleChange} {...this.state} />;
    } else if (title === "EC Composition (EC)") {
      //window.location.href = "/composition/"+this.state.id
      return (
        <ECComposition
          handleChange={this.handleChange}
          {...this.state}
          removeMember={this.removeMember}
          navigateECComposition={this.navigateECComposition}
        />
      );
    } else if (title === "EC Meeting") {
      return <ECMeeting handleChange={this.handleChange} {...this.state} />;
    } else if (title === "EC Policy") {
      //window.location.href = "/policy/"+this.state.id
      return (
        <ECPolicy
          ec_policy={this.state.ec_policy}
          navigateECPolicy={this.navigateECPolicy}
        />
      );
    } else if (title === "EC Training") {
      //window.location.href = "/training/"+this.state.id
      return (
        <ECTraining
          handleChange={this.handleChange}
          openMembersModal={this.openMembersModal}
          closeMembersModal={this.closeMembersModal}
          chooseMembersCheckboxChange={this.chooseMembersCheckboxChange}
          showMembersModal={this.state.showMembersModal}
          ec_composition_members={this.state.ec_composition_members}
          ec_training={this.state.ec_training}
          createTrainingRow={this.createTrainingRow}
          navigateECTraining={this.navigateECTraining}
          selectMembers={this.selectMembers}
          {...this.state}
        />
      );
    } else if (title === "Submission of Process") {
      return (
        <SubmissionProcess
          handleChange={this.handleChange}
          radioCheckboxChange={this.radioCheckboxChange}
          checkboxChange={this.checkboxChange}
          {...this.state}
        />
      );
    } else if (title === "Contact Details") {
      return (
        <ContactDetail
          handleChange={this.handleChange}
          handlePrimaryMailChange={this.handlePrimaryMailChange}
          handleAlternateMailChange={this.handleAlternateMailChange}
          {...this.state}
        />
      );
    } else if (title === "Authority of Ethics Committee") {
      return <Authority handleChange={this.handleChange} {...this.state} />;
    } else if (title === "EC Office") {
      return (
        <ECOffice
          handleChange={this.handleChange}
          checkboxChange={this.checkboxChange}
          {...this.state}
        />
      );
    } else if (title === "Timeline") {
      return <Timeline handleChange={this.handleChange} {...this.state} />;
    }
    // else if (title === "Capabilities") {
    //   return <Capabilites handleChange={this.handleChange} {...this.state} />;
    // }
    else if (title === "Finance") {
      return (
        <Finance
          handleChange={this.handleChange}
          checkboxChange={this.checkboxChange}
          {...this.state}
          fileRef={this.fileTemplate}
          templateName={this.state.templateName}
          onRemove={this.onRemoveTemplate}
        />
      );
    }
  };

  chooseField = (title) => {
    this.setState({ fieldTitle: title });
  };
  onRemoveTemplate = () => {
    this.setState({ ec_template: "", templateName: "" });
  };
  onNavigateBack = () => {
    window.location.href = `/admin/users`;
  };

  fetchEnrichedInfo = (regNumber) => {
    let sessionData = window.sessionStorage.getItem("enrichedinfo");
    let isSimilarCommitteeOpened =
      window.sessionStorage.getItem("similarcommittee");
    let scomParsed = JSON.parse(isSimilarCommitteeOpened);
    getEnrichedInfo(regNumber)
      .then((foundInfo) => {
        console.log(foundInfo, "info found");
        this.setState({ enrichedCommittee: foundInfo }, () => {
          if (this.state.enrichedCommittee !== null) {
            let scoms =
              this.state.enrichedCommittee &&
              this.state.enrichedCommittee.similar_committees;
            if (scoms && scoms.length > 0) {
              scoms.map((scom) => {
                if (scom !== null) {
                  getSimilarCommittee(scom._id)
                    .then((gotScom) => {
                      console.log(gotScom, "got scom");
                    })
                    .catch((errScom) => {
                      console.log(errScom, "err with scom");
                    });
                }
              });
            }
            let {
              pincode,
              cdsco_approval_date,
              cdsco_validity_date,
              dhr_approval_date,
              dhr_validity_date,
              next_meeting_date,
              how_often_ec_meet,
              ec_existing_since,
              list_of_holidays,
              ec_composition_EC,
              ec_policy,
              list_of_sops,
              ec_training,
              ec_days_prior_dossier_submission,
              ec_issue_approval_post_meeting,
              process_of_ec_submission,
              process_for_sae_review,
              review_checklist_process,
              ec_initial_review_fees,
              ec_amendment_review_fees,
              ec_final_review_process,
              mode_of_payment,
              payee_name,
              ec_gst,
              ec_tds,
              submission_process_type,
              is_online_based,
              is_paper_based,
              submission_process_paper_based_copies,
              submission_process_online_description,
              parent_institute_name,
              parent_registration_number,
              finance,
              timeline_for_ec_dossier_submission,
              approval_for_ec_and_scientific_timeline,
              interim_progress_report_timeline,
              timeline_for_expedite_review_and_approval,
              document_storage_facility,
              fire_extingusher,
              emergency_alarm,
              frequency_of_ec_site_monitoring,
              primary_contact,
              alternative_contact,
              similar_committees,
            } = foundInfo;
            this.setState(
              {
                pincode: pincode,
                cdsco_approval_date: cdsco_approval_date,
                cdsco_validity_date: cdsco_validity_date,
                similarcommittees: similar_committees,
                ec_policy: ec_policy,
                dhr_approval_date: dhr_approval_date,
                dhr_validity_date: dhr_validity_date,
                next_meeting_date: next_meeting_date,
                how_often_ec_meet: how_often_ec_meet,
                ec_existing_since: ec_existing_since,
                list_of_holidays: list_of_holidays,
                ec_composition_members: ec_composition_EC,
                ec_composition_EC: ec_composition_EC.length,
                ec_days_prior_dossier_submission:
                  ec_days_prior_dossier_submission,
                ec_issue_approval_post_meeting: ec_issue_approval_post_meeting,
                list_of_sops: list_of_sops,
                ec_training: ec_training,
                process_of_ec_submission: process_of_ec_submission,
                submission_process: "",
                is_paper_based: is_paper_based,
                is_online_based: is_online_based,
                submission_process_paper_based_copies:
                  submission_process_paper_based_copies,
                submission_process_online_description:
                  submission_process_online_description,
                submission_review_check_list: [],
                ec_submission_forms: [],
                review_checklist_process: review_checklist_process,
                ec_initial_review_fees: ec_initial_review_fees,
                ec_amendment_review_fees: ec_amendment_review_fees,
                ec_final_review_process: ec_final_review_process,
                mode_of_payment: mode_of_payment,
                payee_name: payee_name,
                ec_gst: ec_gst,
                ec_tds: ec_tds,
                submission_process_type: submission_process_type,
                sae_review: process_for_sae_review,
                finance: finance,
                frequency_of_ec_site_monitoring:
                  frequency_of_ec_site_monitoring,
                timeline_for_ec_dossier_submission:
                  timeline_for_ec_dossier_submission,
                approval_for_ec_and_scientific_timeline:
                  approval_for_ec_and_scientific_timeline,
                interim_progress_report_timeline:
                  interim_progress_report_timeline,
                timeline_for_expedite_review_and_approval:
                  timeline_for_expedite_review_and_approval,
                document_storage_facility: document_storage_facility,
                fire_extingusher: fire_extingusher,
                emergency_alarm: emergency_alarm,
                parent_institute_name: parent_institute_name,
                parent_registration_number: parent_registration_number,
                primary_contact_name: primary_contact.name,
                primary_contact_email: primary_contact.email,
                primary_contact_contact_number: primary_contact.contact_number,
                alternative_contact_name: alternative_contact.name,
                alternative_contact_email: alternative_contact.email,
                alternative_contact_contact_number:
                  alternative_contact.contact_number,
              },
              () => {
                let members = ec_composition_EC;
                this.splitMembers(members);
                this.splitMembers(ec_training);
                this.splitMembers(ec_policy);
                this.splitRowMembers(ec_training);

                // if(sessionData === null){
                //   console.log(sessionData,'no session data');
                // } else{
                //   console.log(sessionData, 'session data');
                //   this.setSessionData(sessionData)
                // }
              }
            );
          } else {
            this.setState({ enrichedCommittee: null });
          }
        });
        // }
      })
      .catch((errFoundInfo) => {
        console.log(errFoundInfo, "err with info");
      });
  };

  navigateECTraining = () => {
    this.saveInfoHere("training");
  };

  navigateECComposition = () => {
    this.saveInfoHere("composition");
  };

  navigateECPolicy = () => {
    this.saveInfoHere("policy");
  };

  setSessionData = (sessioninfo) => {
    let parsed = JSON.parse(sessioninfo);
    console.log(parsed, "parsed");
    let fields = Object.keys(parsed);
    console.log(fields, "after parsing");
    if (fields && fields.length > 0) {
      this.setState({ ...parsed }, () => {
        console.log(parsed, "after fetching");
        window.sessionStorage.removeItem("enrichedinfo");
        window.sessionStorage.clear();
      });
    }
  };

  componentDidMount() {
    console.log(this.props.match.params.id, "info");
    this.setState(
      {
        id: this.props.match.params.id,
      },
      () => {
        getAdmin()
          .then((adminUser) => {
            this.setState({ adminUser: adminUser && adminUser[0] });
          })
          .catch((errAdmin) => {
            console.log(errAdmin, "err fetching admin details");
            this.setState({ adminUser: null });
          });
        getCommitteeDetails(
          this.props.user && this.props.user.others[0].ethics_committee.type,
          this.state.id
        )
          .then((committee) => {
            console.log(committee, "found committee");
            this.setState({ committee: committee }, () => {
              this.fetchEnrichedInfo(committee && committee.registered_number);
              this.setState({
                cdsco_approval_date: this.state.committee.release_date,
              });
              this.setState({
                dhr_approval_date:
                  committee && committee.type === "naitik"
                    ? committee && committee.approval_date
                    : "",
              });
            });
          })
          .catch((errCommittee) => {
            console.log(errCommittee, "err found committee");
            this.setState({ committee: null });
          });
      }
    );
  }

  linkSimilarCommittee = () => {
    this.setState({ committeesearch: "" });
    console.log(this.state.selectedSuggestion, "selected suggestion");
    if (
      this.state.selectedSuggestion === null ||
      this.state.selectedSuggestion === "" ||
      this.state.selectedSuggestion === undefined ||
      this.state.selectedSuggestion === " "
    ) {
      alert("Please key in and choose desired ethical committee");
    } else {
      let suggestion_type =
        this.state.selectedSuggestion && this.state.selectedSuggestion.type;
      let registeredCommitteeType =
        this.state.enrichedCommittee &&
        this.state.enrichedCommittee.ethics_committee &&
        this.state.enrichedCommittee.ethics_committee.type;
      console.log(suggestion_type, registeredCommitteeType, "committee types");
      if (
        registeredCommitteeType === "reRegistered" ||
        registeredCommitteeType === "Registered" || registeredCommitteeType === "naitik"
      ) {
        if (suggestion_type === "naitik" || suggestion_type === "reRegistered" || suggestion_type === "Registered") {
          this.setState(
            {
              similarcommittees:
                this.state.similarcommittees &&
                this.state.similarcommittees.length === 0
                  ? [this.state.selectedSuggestion]
                  : [
                      this.state.selectedSuggestion,
                      ...this.state.similarcommittees,
                    ],
            },
            () => {
              // let req_body = {
              //   similar_committees: this.state.similarcommittees,
              // };
              let req_body = {
                approval_status: "requested",
                request_type: "link",
                reason_for_decline: "",
                committee: this.state.selectedSuggestion,
                // primary_committee:
                //   this.state.enrichedCommittee.ethics_committee,
                enrichedCommittee: this.state.enrichedCommittee,
                enriched_information: {},
              };
              if (
                this.state.enrichedCommittee === "" ||
                this.state.enrichedCommittee === " " ||
                this.state.enrichedCommittee === null ||
                this.state.enrichedCommittee === undefined
              ) {
                console.log(
                  "registered enriched committee not found",
                  this.state.enrichedCommittee
                );
              } else {
                createSimilarCommittee(req_body)
                  .then((createdScom) => {
                    console.log(createdScom, "created similar com");
                    this.setState({ selectedSuggestion: null });
                    let update = {
                      active: false,
                      title: `Linking similar committee requested`,
                      update: {
                        type: "linking_scom_request",
                        user_name : this.props.user.username,
                        scom_registered_number : createdScom.committee.registered_number,
                        item: createdScom._id,
                      },
                      description: `${this.props.user.username} requesting for linking similar committee`,
                      from: [getCurrentUser(this.props.user)],
                      to: [],
                      isRead: false,
                    };
                    sendUpdate(update);
                    this.fetchEnrichedInfo(
                      this.state.enrichedCommittee.committee_registered_number
                    );
                  })
                  .catch((errCreatedScom) => {
                    console.log(
                      errCreatedScom,
                      "err while creating similar com"
                    );
                    this.setState({ selectedSuggestion: null });
                  });
              }
            }
          );
        } else {
          console.log(
            suggestion_type,
            registeredCommitteeType,
            "type checking"
          );
          alert("CDSCO committees should be linked with naitik type only");
        }
      } 
      // else if (registeredCommitteeType === "naitik") {
      //   if (
      //     suggestion_type === "reRegistered" ||
      //     suggestion_type === "Registered"
      //   ) {
      //     this.setState(
      //       {
      //         similarcommittees:
      //           this.state.similarcommittees &&
      //           this.state.similarcommittees.length === 0
      //             ? [this.state.selectedSuggestion]
      //             : [
      //                 this.state.selectedSuggestion,
      //                 ...this.state.similarcommittees,
      //               ],
      //       },
      //       () => {
      //         // let req_body = {
      //         //   similar_committees: this.state.similarcommittees,
      //         // };
      //         let req_body = {
      //           approval_status: "requested",
      //           request_type: "link",
      //           reason_for_decline: "",
      //           committee: this.state.selectedSuggestion,
      //           enrichedCommittee: this.state.enrichedCommittee,
      //           enriched_information: {},
      //         };
      //         if (
      //           this.state.enrichedCommittee === "" ||
      //           this.state.enrichedCommittee === " " ||
      //           this.state.enrichedCommittee === null ||
      //           this.state.enrichedCommittee === undefined
      //         ) {
      //           console.log(
      //             "registered enriched committee not found",
      //             this.state.enrichedCommittee
      //           );
      //         } else {
      //           createSimilarCommittee(req_body)
      //             .then((createdScom) => {
      //               console.log(createdScom, "created similar com");
      //               let update = {
      //                 active: false,
      //                 title: `Linking similar committee requested`,
      //                 update: {
      //                   type: "linking_scom_request",
      //                   user_name : this.props.user.username,
      //                   scom_registered_number : createdScom.committee.registered_number,
      //                   item: createdScom._id,
      //                 },
      //                 description: `${this.props.user.username} requesting for linking similar committee`,
      //                 from: [getCurrentUser(this.props.user)],
      //                 to: [],
      //                 isRead: false,
      //               };
      //               sendUpdate(update);
      //               this.setState({ selectedSuggestion: null });
      //               this.fetchEnrichedInfo(
      //                 this.state.enrichedCommittee.committee_registered_number
      //               );
      //             })
      //             .catch((errCreatedScom) => {
      //               console.log(
      //                 errCreatedScom,
      //                 "err while creating similar com"
      //               );
      //               this.setState({ selectedSuggestion: null });
      //             });
      //         }
      //       }
      //     );
      //   } else {
      //     console.log(
      //       suggestion_type,
      //       registeredCommitteeType,
      //       "type checking"
      //     );
      //     alert("Naitik committees should be linked with CDSCO type only");
      //   }
      // }
    }
  };

  componentWillUnmount = () => {
    this.setState({ user: null, committee: null }, () => {
      window.location.href = "/";
    });
  };

  changeStatusForContinuation = (status) =>{
    this.setState({ ask_to_continue_einfo : status})
  }

  enrichSimilarCommitteeInfo = (similarCommittee) => {
    console.log(similarCommittee, "similar committee for enriching");
    let enriched_info =
      similarCommittee && similarCommittee.enriched_information;
    console.log(enriched_info, "checking similar committee enriched info");
    if (enriched_info === undefined) {
      if (
        similarCommittee &&
        similarCommittee.approval_status === "accepted" &&
        similarCommittee.request_type === "link"
      ) {
        this.setState({ ask_to_continue_einfo : "" },()=>{
          let e_info = Object.assign({}, this.state.enrichedCommittee);
          console.log(this.state.ask_to_continue_einfo, 'status value')
          let enrichedInfo = (this.state.ask_to_continue_einfo === true && e_info) || 
                            (this.state.ask_to_continue_einfo === "" && e_info) ||
                            (this.state.ask_to_continue_einfo === false && fields_for_clearing(e_info))
          console.log(enrichedInfo,'after updating')
          enrichedInfo["ethics_committee"] =
            similarCommittee && similarCommittee.committee;
          enrichedInfo["similar_committees"] = [];
          enrichedInfo["committee_registered_number"] =
            similarCommittee.committee.registered_number;
          enrichedInfo["active"] = true;
          if (
            similarCommittee &&
            similarCommittee.committee &&
            similarCommittee.committee.type === "naitik"
          ) {
            enrichedInfo["dhr_approval_date"] = similarCommittee && similarCommittee.committee && similarCommittee.committee.approval_date
              // this.state && this.state.enrichedCommittee.cdsco_approval_date;
            enrichedInfo["dhr_validity_date"] =
              this.state && this.state.enrichedCommittee.dhr_validity_date;
            enrichedInfo["cdsco_approval_date"] = "";
            enrichedInfo["cdsco_validity_date"] = "";
            enrichedInfo["committee_type"] = "naitik";
          } else if (
            (similarCommittee &&
              similarCommittee.committee &&
              similarCommittee.committee.type === "reRegistered") ||
            (similarCommittee &&
              similarCommittee.committee &&
              similarCommittee.committee.type === "Registered")
          ) {
            enrichedInfo["dhr_approval_date"] = "";
            enrichedInfo["dhr_validity_date"] = "";
            enrichedInfo["cdsco_approval_date"] = similarCommittee && similarCommittee.committee && similarCommittee.committee.release_date
              // this.state && this.state.enrichedCommittee.dhr_approval_date;
            enrichedInfo["cdsco_validity_date"] =
              this.state && this.state.enrichedCommittee.cdsco_validity_date;
            enrichedInfo["committee_type"] =
              similarCommittee &&
              similarCommittee.committee &&
              similarCommittee.committee.type;
          }
          console.log(enrichedInfo, 'after copy')
  
          updatedSimilarCommittee(
            similarCommittee && similarCommittee._id,
            this.state.enrichedCommittee._id,
            { enriched_information: enrichedInfo }
          )
            .then((updatedScom) => {
              console.log(updatedScom, "updated similar committee");
              this.fetchEnrichedInfo(
                this.state.enrichedCommittee.committee_registered_number
              );
              this.setState(
                { selectedEnrichedSimlarCommittee: updatedScom },
                () => {
                  this.setState({ showSimilarCommitteeModal: true });
                }
              );
            })
            .catch((errUpdatedScom) => {
              console.log(
                errUpdatedScom,
                "err while updating similar committee with enriched info"
              );
            });
        })
      }
    } else {
      this.setState({ ask_to_continue_einfo : null})
      let enrichedInfo = Object.assign({}, this.state.enrichedCommittee);
      enrichedInfo["ethics_committee"] =
        similarCommittee && similarCommittee.committee;
      enrichedInfo["similar_committees"] = [];
      enrichedInfo["committee_registered_number"] =
        similarCommittee.committee.registered_number;
      enrichedInfo["active"] = true;
      if (
        similarCommittee &&
        similarCommittee.committee &&
        similarCommittee.committee.type === "naitik"
      ) {
        enrichedInfo["dhr_approval_date"] =
          this.state && this.state.enrichedCommittee.cdsco_approval_date;
        enrichedInfo["dhr_validity_date"] =
          this.state && this.state.enrichedCommittee.cdsco_validity_date;
        enrichedInfo["cdsco_approval_date"] = "";
        enrichedInfo["cdsco_validity_date"] = "";
        enrichedInfo["committee_type"] = "naitik";
      } else if (
        (similarCommittee &&
          similarCommittee.committee &&
          similarCommittee.committee.type === "reRegistered") ||
        (similarCommittee &&
          similarCommittee.committee &&
          similarCommittee.committee.type === "Registered")
      ) {
        enrichedInfo["dhr_approval_date"] = "";
        enrichedInfo["dhr_validity_date"] = "";
        enrichedInfo["cdsco_approval_date"] =
          this.state && this.state.enrichedCommittee.dhr_approval_date;
        enrichedInfo["cdsco_validity_date"] =
          this.state && this.state.enrichedCommittee.dhr_validity_date;
        enrichedInfo["committee_type"] =
          similarCommittee &&
          similarCommittee.committee &&
          similarCommittee.committee.type;
      }
      this.setState(
        {
          selectedEnrichedSimlarCommittee: similarCommittee,
        },
        () => {
          this.setState({ showSimilarCommitteeModal: true });
        }
      );
    }
  };



  render() {
    const {
      committee,
      committeesearch,
      showSimilarCommitteeModal,
      selectedEnrichedSimlarCommittee,
      canLinkSimilarCommittee,
      askForLinking,
      askNextQuestion,
      ask_to_continue_einfo,
    } = this.state;
    const { user } = this.props;
    return (
      <div
        style={{
          background: "#FFFFFF",
          //"linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 100%)",
        }}
      >
        {user && user.active === false ? (
          <NotApproved message="QCR Admin need to review and grant access. Thank you" />
        ) : user && user === null ? (
          <NotFound message="Please login to view application" />
        ) : (
          <div className="mx-3 mb-5 pb-5">
            {committee === null ? (
              <NotFound message="Error fetching commitee details" />
            ) : (
              <div>
                <div className="d-flex align-items-center justify-content-between py-3 px-3">
                  {user && user.role === "ec_qcr_admin" ? (
                    <p
                      style={{
                        color: "#BC5405",
                        cursor: "pointer",
                        fontWeight: "bolder",
                        textTransform: "uppercase",
                      }}
                      className="mb-0"
                      onClick={() => this.onNavigateBack()}
                    >
                      Back
                    </p>
                  ) : null}
                  <h4 className="mb-0">Ethical Committee Details</h4>
                  <div>
                    {/* <button
                      onClick={() => window.location.href="/enquiry"}
                      className="btn btn-sm login mx-2"
                    >
                      Enquiry
                    </button> */}
                    <button
                      onClick={() => this.addInfo()}
                      className="btn btn-sm login mx-2"
                    >
                      {this.state.enrichedCommittee === null
                        ? "SUBMIT"
                        : "UPDATE"}
                    </button>
                  </div>
                </div>
                <div className="container-fluid mx-auto text-center px-4 mb-5">
                  {committee && committee.type === "naitik" ? (
                    <div className="text-left">
                      <p style={{ fontWeight: "bold", fontSize: 18 }}>
                        Name :{" "}
                        {committee &&
                        committee.name_of_ethics_committee.length > 0
                          ? committee.name_of_ethics_committee
                          : "None"}
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: 18 }}>
                        Address :{" "}
                        {committee && committee.address.length > 0
                          ? committee.address
                          : "None"}
                      </p>
                      <p>
                        Committee Type:{" "}
                        {committee &&
                        committee.type &&
                        committee.type.length > 0
                          ? committee.type
                          : "N/A"}
                      </p>
                    </div>
                  ) : (
                    <div className="text-left">
                      <p style={{ fontWeight: "bold", fontSize: 15 }}>
                        {committee && committee.name_and_address.length > 0
                          ? committee.name_and_address
                          : "None"}
                      </p>
                      <p className="mb-0">
                        Committee Type:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {committee &&
                          committee.type &&
                          committee.type.length > 0
                            ? (committee.type === "reRegistered" &&
                                "CDSCO Re Registered") ||
                              (committee.type === "Registered" &&
                                "CDSCO Registered") ||
                              (committee.type === "naitik" && "Naitik")
                            : "N/A"}
                        </span>
                      </p>
                    </div>
                  )}
                  {this.state.enrichedCommittee &&
                  this.state.enrichedCommittee !== null ? (
                    <div className="px-0 text-left">
                      {askForLinking === true ? (
                        committee &&
                        committee.type &&
                        committee.type.length > 0 ? (
                          askNextQuestion === true ? (
                            <InfoQuestion
                              committee={committee}
                              question={[
                                "Do you want to link your",
                                "registered committee with each other?",
                              ]}
                              updateLinkingSimilarCommittee={
                                this.updateLinkingSimilarCommittee
                              }
                              changeNextQuestion={this.changeNextQuestion}
                              askLinking={this.askLinking}
                              canLinkSimilarCommittee={canLinkSimilarCommittee}
                              committeesearch={committeesearch}
                              similarCommiteeSearch={this.similarCommiteeSearch}
                              linkSimilarCommittee={this.linkSimilarCommittee}
                            />
                          ) : (
                            <InfoQuestion
                              committee={committee}
                              question={[
                                "Is your ethics committee registered with",
                                "?",
                              ]}
                              updateLinkingSimilarCommittee={
                                this.updateLinkingSimilarCommittee
                              }
                              next={true}
                              changeNextQuestion={this.changeNextQuestion}
                              askLinking={this.askLinking}
                              canLinkSimilarCommittee={canLinkSimilarCommittee}
                              committeesearch={committeesearch}
                              similarCommiteeSearch={this.similarCommiteeSearch}
                              linkSimilarCommittee={this.linkSimilarCommittee}
                            />
                          )
                        ) : null
                      ) : (
                        <button
                          onClick={() => this.setState({ askForLinking: true })}
                          className="btn btn-small btn-primary my-2"
                        >
                          Link the corresponding{" "}
                          {committee.type === "reRegistered" ||
                          committee.type === "Registered"
                            ? "Naitik / DHR"
                            : "CDSCO"}{" "}
                          Committee
                        </button>
                      )}
                      <div>
                        {this.state.suggestions &&
                        this.state.suggestions.length > 0 ? (
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => this.setState({ suggestions: [] })}
                            className="mb-0 text-right"
                          >
                            close suggestions
                          </p>
                        ) : null}
                        <div
                          style={{
                            height:
                              this.state.suggestions &&
                              this.state.suggestions.length > 0
                                ? 150
                                : "auto",
                            overflowY: "scroll",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          {this.state.suggestions &&
                          this.state.suggestions.length > 0
                            ? this.state.suggestions.map((suggestion) => (
                                <div
                                  style={{
                                    borderBottom: "1px solid darkgrey",
                                    cursor: "pointer",
                                  }}
                                  className="d-flex align-items-center justify-content-between px-1"
                                >
                                  <p
                                    onClick={() =>
                                      this.setState(
                                        {
                                          committeesearch:
                                            suggestion &&
                                            suggestion.type === "naitik"
                                              ? suggestion.name_of_ethics_committee
                                              : suggestion.name_and_address,
                                        },
                                        () => {
                                          this.setState({
                                            selectedSuggestion: suggestion,
                                            suggestions: [],
                                          });
                                        }
                                      )
                                    }
                                    className="mb-0 px-3 mx-2 py-2"
                                  >
                                    {suggestion.type === "naitik"
                                      ? suggestion.address
                                      : suggestion.name_and_address}
                                  </p>
                                  <p
                                    style={{
                                      border:
                                        (suggestion &&
                                          suggestion.type === "naitik" &&
                                          "1px solid #9F5F80") ||
                                        (suggestion &&
                                          suggestion.type === "Registered" &&
                                          "1px solid #2C2891") ||
                                        (suggestion &&
                                          suggestion.type === "reRegistered" &&
                                          "1px solid #F5A962"),
                                      borderRadius: 5,
                                    }}
                                    className="px-1 mt-2"
                                  >
                                    {(suggestion &&
                                      suggestion.type === "naitik" &&
                                      "Naitik") ||
                                      (suggestion &&
                                        suggestion.type === "Registered" &&
                                        "Registered") ||
                                      (suggestion &&
                                        suggestion.type === "reRegistered" &&
                                        "CDSCO Re-Registered")}
                                  </p>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.enrichedCommittee &&
                  this.state.enrichedCommittee !== null ? (
                    this.state.enrichedCommittee.similar_committees &&
                    this.state.enrichedCommittee.similar_committees.length >
                      0 ? (
                      <div className="text-left my-3 pb-3">
                        <h6>Linked committees</h6>
                        <div style={{ height : 250, overflowY : "scroll", border : "1px solid #263159" }} className="p-2">
                          {this.state.enrichedCommittee.similar_committees
                            .filter(
                              (scom) =>
                                scom.approval_status === "accepted" ||
                                scom.approval_status === "requested"
                            )
                            .map((similar_committee) => (
                              <div
                                style={{
                                  border: "1px solid black",
                                  borderRadius: 5,
                                }}
                                //className="border border-dark p-2 mr-3 d-flex align-items-start justify-content-between col-lg-12"
                                className = "my-2 p-2"
                              >
                                <div>
                                  <div className="row w-100">
                                      <div className="col-lg-6">
                                          <p
                                            style={{ fontWeight: "bold" }}
                                            className="mb-0"
                                          >
                                            {similar_committee &&
                                              similar_committee.committee &&
                                              similar_committee.committee
                                                .registered_number}
                                          </p>
                                          <p className="mb-0">
                                            {similar_committee &&
                                            similar_committee.committee &&
                                            similar_committee.committee.type &&
                                            similar_committee.committee.type ===
                                              "naitik"
                                              ? similar_committee.committee
                                                  .name_of_ethics_committee
                                              : similar_committee.committee
                                                  .name_and_address}
                                          </p>
                                      </div>
                                      <div className="col-lg-3">
                                          <p className="mb-0">
                                            Committee Type :{" "}
                                            {similar_committee &&
                                              similar_committee.committee &&
                                              similar_committee.committee.type}
                                          </p>
                                          <p>
                                            Approval Status -{" "}
                                            <span style={{ fontWeight: "bold" }}>
                                              {similar_committee.approval_status}
                                            </span>
                                          </p>
                                      </div>
                                      <div className="col-lg-3">
                                            <p>
                                          Request Type :{" "}
                                          {similar_committee &&
                                            similar_committee.request_type}
                                        </p>
                                        {similar_committee &&
                                        similar_committee.approval_status ===
                                          "declined" ? (
                                          <p>
                                            Reason:{" "}
                                            {similar_committee.reason_for_decline}
                                          </p>
                                        ) : null}
                                        {similar_committee &&
                                        similar_committee.approval_status ===
                                          "accepted" &&
                                        similar_committee.request_type === "link" ? (
                                          <p onClick={()=>{
                                            window.location.href = `/similar-committee/${this.state.enrichedCommittee._id}/${similar_committee._id}`
                                          }} style={{cursor:'pointer', textDecoration : "underline"}} className="mb-0">Enrich Info</p>
                                          // <InfoModal
                                          //   similarCommitteecheckboxChange={
                                          //     this.similarCommitteecheckboxChange
                                          //   }
                                          //   similarCommitteeRadiocheckboxChange={
                                          //     this.similarCommitteeRadiocheckboxChange
                                          //   }
                                          //   handleSimilarCommitteePrimaryMailChange={
                                          //     this
                                          //       .handleSimilarCommitteePrimaryMailChange
                                          //   }
                                          //   handleSimilarCommitteeAlternateMailChange={
                                          //     this
                                          //       .handleSimilarCommitteeAlternateMailChange
                                          //   }
                                          //   updateEnrichedSimilarCommittee={
                                          //     this.updateEnrichedSimilarCommittee
                                          //   }
                                          //   saveEnrichedScomHere={
                                          //     this.saveEnrichedScomHere
                                          //   }
                                          //   handleSimilarCommitteeChange={
                                          //     this.handleSimilarCommitteeChange
                                          //   }
                                          //   handleChange={this.handleChange}
                                          //   showSimilarCommitteeModal={
                                          //     showSimilarCommitteeModal
                                          //   }
                                          //   similar_committee={similar_committee}
                                          //   ask_to_continue_einfo = {ask_to_continue_einfo}
                                          //   changeStatusForContinuation = {this.changeStatusForContinuation}
                                          //   selectedEnrichedSimlarCommittee={
                                          //     selectedEnrichedSimlarCommittee
                                          //   }
                                          //   enrichSimilarCommitteeInfo={
                                          //     this.enrichSimilarCommitteeInfo
                                          //   }
                                          //   openInfoModal={this.openInfoModal}
                                          //   closeInfoModal={this.closeInfoModal}
                                          // />
                                        ) : null}
                                      </div>
                                  </div>
                                </div>
                                {/* <span
                                  onClick={() =>
                                    removeSimilarCommittee(
                                      similar_committee._id,
                                      {request_type:'de-link'}
                                    )
                                      .then((deLinked) => {
                                        console.log(
                                          deLinked,
                                          "successfully delinked"
                                        );
                                        this.fetchEnrichedInfo(
                                          this.state.enrichedCommittee
                                            .committee_registered_number
                                        );
                                      })
                                      .catch((errDeLinking) => {
                                        console.log(
                                          errDeLinking,
                                          "err with delinking"
                                        );
                                      })
                                  }
                                  style={{
                                    color: "darkorange",
                                    cursor: "pointer",
                                  }}
                                  className="mx-2"
                                >
                                  x
                                </span> */}
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <p className="text-center mb-0">
                        No linked committees found
                      </p>
                    )
                  ) : null}
                  {fields.map((field, i) => (
                    <div key={i}>
                      <div
                        onClick={() =>
                          this.state.fieldTitle === field.title
                            ? this.setState({ fieldTitle: "" })
                            : this.chooseField(field.title)
                        }
                        style={{
                          backgroundColor: field.bgColor,
                          cursor: "pointer",
                        }}
                        className="col-lg-12 py-2 my-2 d-flex align-items-start justify-content-between"
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <img src={field.icon} alt={`${field} icon`}></img>
                          <h6 className="mx-4 mb-1">{field.title}</h6>
                        </div>
                        {this.state.fieldTitle === field.title ? (
                          <img
                            style={{ height: 4, width: 15 }}
                            className="mt-2"
                            src={minus}
                            alt="minus icon"
                          ></img>
                        ) : (
                          <img
                            className="mt-1"
                            src={plus}
                            alt="plus icon"
                          ></img>
                        )}
                      </div>
                      {this.state.fieldTitle === field.title
                        ? this.collapseField(field.title)
                        : null}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Info);
