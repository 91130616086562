import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter, Redirect } from "react-router-dom";
import Eclogin from "../pages/login/Eclogin";
import Ecselect from "../pages/Ecselect";
import NotFound from "../components/NotFound";
import Info from "../components/Info";
import Register from "../pages/register/index";
import Dashboard from "../pages/admin/Dashboard";
import EnquiryDashboard from "../pages/admin/Dashboard";
import NotApproved from "../components/NotApproved";
import { getAdmin, getEnquiries, getUpdate, userProfile } from "../helpers/api";
import Header from "../components/Header";
import Enquiry from "../pages/enquiry/Enquiry";
import EnquiryDetail from "../pages/enquiry/EnquiryDetail";
import Training from "../components/Training";
import Composition from "../components/Composition";
import LandingScreen from "../components/LandingScreen";
import Policy from "../components/Policy";
import ViewProfile from "../components/ViewProfile";
import ChangePassword from "../components/ChangePassword";
import ForgotPassword from "../components/ForgotPassword";
import { ptLogo, qLogo } from "../theme/icons";
import EnrichScom from "../components/EnrichScom";
import GeneralProspectEnquiry from "../components/GeneralProspectEnquiry";


class Navigation extends React.Component {
  intervalid = 0;
  constructor(props) {
    super();
    this.state = {
      user: "",
      userAuthorized: false,
      adminUser: "",
      updates: [],
      pageRefreshed: false,
    };
  }

  checkUpdates = () => {
    getUpdate("all")
      .then((gotUpdates) => {
        console.log(gotUpdates, "got updates");
        if (gotUpdates && gotUpdates.length > 0) {
          this.setState({ updates: gotUpdates });
        } else {
          this.setState({ updates: [] });
        }
      })
      .catch((errUpdates) => {
        console.log(errUpdates, "err with fetching updates");
        this.setState({ updates: [] });
      });
  };

  getAdminUser = () => {
    getAdmin()
      .then((adminUser) => {
        this.setState({ adminUser: adminUser && adminUser[0] });
      })
      .catch((errAdmin) => {
        console.log(errAdmin, "err fetching admin details");
        this.setState({ adminUser: null });
      });
  };

  componentDidMount() {
    //console.log(this.props, "componentdidmount");
    userProfile()
      .then((gotUser) => {
        //console.log(gotUser.output, "gotUser");
        this.setState({ user: gotUser.output, userAuthorized: true }, () => {
          this.getAdminUser();
          setTimeout(() => {
            this.setState({ pageRefreshed: true });
          }, 15 * 1000);
          if (this.state.pageRefreshed === false) {
            this.checkUpdates();
          }
          this.intervalid = setInterval(() => {
            this.checkUpdates();
          }, 45 * 1000);
        });
      })
      .catch((errUser) => {
        //console.log(errUser, "err with fetching user");
        this.setState({ user: null, updates: [] });
      });
  }

  componentWillUnmount() {
    clearInterval(this.intervalid);
  }

  changeUserStatus = () => {
    this.setState({ user: null, userAuthorized: false, updates: [] }, () => {
      clearInterval(this.intervalid);
      window.location.href = "/";
    });
  };

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/">
            <LandingScreen />
          </Route>
          <Route exact path = "/forgot-password" >
            <ForgotPassword />
          </Route>
          <Route exact path = "/change-password/:user/:exp">
            <ChangePassword />
          </Route>
          <Route exact path="/login">
            <Eclogin {...this.state} />
          </Route>
          <Route path="/register/:register_role" component={Register} />
          {this.state.user === null ? (
            <NotFound message="Please login to view this application." />
          ) : (
            <div>
              <div className="mb-5 pb-3">
                <Header
                  userEmail={
                    this.state.user &&
                    this.state.user.email &&
                    this.state.user.email.length > 0
                      ? this.state.user.email
                      : "Anonymous"
                  }
                  userId={this.state.user && this.state.user._id}
                  userName={this.state.user && this.state.user.username}
                  userRole={this.state.user && this.state.user.role}
                  userStatus={this.state.user && this.state.user.active}
                  userCommitteeId={
                    this.state.user &&
                    this.state.user.others &&
                    this.state.user.others[0] &&
                    this.state.user.others[0].ethics_committee &&
                    this.state.user.others[0].ethics_committee._id
                  }
                  changeUserStatus={this.changeUserStatus}
                  updates={this.state.updates}
                />
              </div>
              {this.state.user === "" ? (
                <p>Loading . . . </p>
              ) : (
                <div className="mt-5 pt-3">
                  <Route path="/home">
                    <Ecselect
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route path="/general_enquiry">
                    <GeneralProspectEnquiry
                      user={this.state.user}
                    />
                  </Route>
                  <Route path="/in-review" component={NotApproved} />
                  <Route path="/profile">
                    <ViewProfile
                      user={this.state.user}
                    />
                  </Route>
                  <Route path="/addinfo/:id/">
                    <Info
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route exact path="/enquiry">
                    <Enquiry
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route exact path="/training/:id">
                    <Training
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route exact path="/composition/:id">
                    <Composition
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route exact path="/similar-committee/:enriched_id/:id">
                    <EnrichScom
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route exact path="/policy/:id">
                    <Policy
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route exact path="/enquiry/detail/:id/">
                    <EnquiryDetail
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                    />
                  </Route>
                  <Route path="/admin/:feature">
                    <Dashboard
                      user={this.state.user}
                      adminUser={this.state.adminUser}
                      changeUserStatus={this.changeUserStatus}
                    />
                  </Route>
                </div>
              )}
            </div>
          )}
          <Route path="*" component={NotFound} />
        </Switch>
        <div style={{"box-shadow": "0px 1px 3px black", position : 'fixed', bottom : 0, left :0, right: 0 , zIndex : 200, backgroundColor : "white" }} className="container-fluid py-2">
            <div style={{display : "flex", alignItems : 'center', justifyContent: 'space-between'}} className = "footer">
                <img style={{height : 40, width : 150}} src={qLogo} alt="qcr_logo"></img>
                <h6 className="mb-0">Copyright © 2022 PatienTrials, a company of PatienTrials Inc.</h6>
                <div className="d-flex align-items-center justify-content-between">
                    <a href="https://www.trials.me/"><img style={{height : 40, width : 150}} src={ptLogo} alt="qcr_logo"></img></a>
                    <h6 className="mb-0 mx-3">Powered By PatienTrials</h6>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Navigation);
