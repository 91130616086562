import React,{useState, useEffect} from 'react';
import moment from 'moment';

const ViewProfile = (props) =>{
    let {
        username,
        email,
        phone,
        isEmailVerified,
        role,
        createdAt,
        others,
        active,
      } = props.user;
      let detailFields = [
        { field: "Name", value: username },
        { field: "Email", value: email },
        { field: "Phone Number", value: phone },
        {
          field: "Role",
          value:
            (role === "ec_qcr_admin" && "QCR Admin") ||
            (role === "ec_independent_researcher" &&
              "EC Independent Researcher") ||
            (role === "ec_coordinator" && "EC Coordinator"),
        },
        {
          field: "Created",
          value: moment(createdAt).format("MMMM Do YYYY, HH:mm:ss"),
        },
        {
          field: "Email Verified",
          value: isEmailVerified === true ? "Yes" : "No",
        },
      ];
      return (
        <div className="my-5 container text-left">
            <p
              style={{
                color: "#BC5405",
                cursor: "pointer",
                fontWeight: "bolder",
                textTransform: "uppercase",
              }}
              className="mb-0 col-lg-1 mx-0 px-0 my-2"
              onClick={() =>
                props.user && props.user.role === "ec_independent_researcher"
                  ? (window.location.href = "/home")
                  : props.user && props.user.role === "ec_qcr_admin" ? (window.location.href = "/admin/users") : (window.location.href = `/addinfo/${props.user.others[0].ethics_committee._id}`)
              }
            >
              Back
            </p>
          {detailFields.map((detailField) => (
            <div className="row">
              <p className="col-lg-5">{detailField.field}</p>
              <p className="col-lg-1">-</p>
              <p className="col-lg-5">{detailField.value || "N/A"}</p>
            </div>
          ))}
          <div className="row">
            <p className="col-lg-5">Status</p>
            <p className="col-lg-1">-</p>
            <p className="col-lg-5">
              {active === true ? "Approved" : "Rejected"}
            </p>
          </div>
          {role === "ec_coordinator" && others && others.length > 0 ? (
            <div>
              <h6 className="my-3">Others</h6>
              {others[0] && others[0].ethics_committee.type === "naitik" ? (
                <div className="row">
                  <p className="col-lg-5">EC Name</p>
                  <p className="col-lg-1">-</p>
                  <p className="col-lg-5">
                    {others[0] &&
                      others[0].ethics_committee.name_of_ethics_committee}
                  </p>
                </div>
              ) : null}
              {others[0] && others[0].ethics_committee.type === "naitik" ? (
                <div className="row">
                  <p className="col-lg-5">EC Address</p>
                  <p className="col-lg-1">-</p>
                  <p className="col-lg-5">
                    {others[0] && others[0].ethics_committee.address}
                  </p>
                </div>
              ) : null}
              <div className="row">
                <p className="col-lg-5">EC Name & Address</p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">
                  {others[0] && others[0].ethics_committee.name_and_address}
                </p>
              </div>
              <div className="row">
                <p className="col-lg-5">EC Registration Number</p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">
                  {others[0] && others[0].ethics_committee.registered_number}
                </p>
              </div>
              <div className="row">
                <p className="col-lg-5">EC Type</p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">
                  {(others[0] &&
                    others[0].ethics_committee.type === "reRegistered" &&
                    "CDSCO Re Registered") ||
                    (others[0] &&
                      others[0].ethics_committee.type === "Registered" &&
                      "CDSCO Registered") ||
                    (others[0] &&
                      others[0].ethics_committee.type === "naitik" &&
                      "Naitik")}
                </p>
              </div>
              <div className="row">
                <p className="col-lg-5">
                  {others[0] && others[0].ethics_committee.type === "naitik"
                    ? "Approval Date"
                    : "Release Date"}
                </p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">
                  {others[0] && others[0].ethics_committee.type === "naitik"
                    ? others[0] && others[0].ethics_committee.approval_date
                    : others[0] && others[0].ethics_committee.release_date}
                </p>
              </div>
            </div>
          ) : null}
          {role === "ec_independent_researcher" && others && others.length > 0 ? (
            <div>
              <h6 className="mb-0">Others</h6>
              <div className="row">
                <p className="col-lg-5">Address</p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">{others[0].address}</p>
              </div>
              <div className="row">
                <p className="col-lg-5">EC Designation</p>
                <p className="col-lg-1">-</p>
                <p className="col-lg-5">{others[0].ec_designation}</p>
              </div>
            </div>
          ) : null}
        </div>
      );
}

export default ViewProfile