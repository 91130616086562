import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  getCommitteeDetails,
  getCommittees,
  getEnrichedInfo,
} from "../../helpers/api";
import { ecSize, List } from "../../theme/icons";
import {
  fields,
  getFieldData,
  getMultipleLocations,
} from "../../helpers/helper";
import { Google_Map_Key } from "../../keys";
import CommitteeDetails from "../../components/CommitteeDetails";
import Map from "../../components/Map";

const ECCommittees = (props) => {
  const {
    type,
    state,
    city,
    pincode,
    enrichedCommittees,
    similarCommittees,
    naitik_committees,
    cdsco,
    cdsco_registered_committees,
    cdsco_re_registered_committees,
    loading,
    hidePincode,
    navigateBack,
    user,
  } = props;
  const obj = {};
  const enquiryObj = {};
  const selectedFields = {};
  const [showCompare, setShowCompare] = useState(false);
  const [selectedCommittee, setSelectedCommittee] = useState(null);
  const [info, setInfo] = useState(false);
  const [compareCommittees, setCompareCommittees] = useState([]);
  const [showOption, setShowOption] = useState(false);
  const [showCommitteeDetails, setShowCommitteeDetails] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [ecfields, setFields] = useState({
    "EC Regulatory": "EC Regulatory",
    "EC Composition": "EC Composition",
    "List of Holidays": "List of Holidays",
    "Timeline for EC Dossier Submission": "Timeline for EC Dossier Submission",
    "Fire Extingusher": "Fire Extingusher",
    "Emergency Alarm": "Emergency Alarm",
    "Primary Contact Name": "Primary Contact Name",
    "Primary Contact Email": "Primary Contact Email",
  });

  const setEnquiryData=(term,ec)=>{
    window.sessionStorage.setItem(term,ec)
  }

  const setEnquiryRelatedECs=(ecs)=>{
    console.log(ecs,'ecs')
    let type = window.sessionStorage.getItem("type");
    if(type !== null){
      if(type === "naitik"){
        setEnquiryData("naitik_ethics_committees",JSON.stringify(ecs))
      }else if(type === "cdsco"){
        if(ecs && ecs[0] && ecs[0].type === "reRegistered"){
          setEnquiryData("cdsco_reregistered_ethics_committees",JSON.stringify(ecs))
        }else{
          setEnquiryData("cdsco_registered_ethics_committees",JSON.stringify(ecs))
        }
      }
    }
  }


  const getCoordinates = (locations) => {
    getMultipleLocations(type, locations)
      .then((locs) => {
        console.log(locs, "got locs");
        setCoordinates(locs);
        setTimeout(() => {
          setShowMap(true);
        }, 1500);
      })
      .catch((errLocs) => {
        console.log(errLocs, "err with fetching locs");
        setCoordinates([]);
      });
  };

  const showCheckboxes = () => {
    setShowOption(!showOption);
  };

  const checkForOtherTypeCommittees = (committee, enrichedCommittee) => {
    let scoms = enrichedCommittee && enrichedCommittee.similar_committees;
    if (scoms && scoms.length > 0) {
      let othercommittee = scoms.filter(
        (scom) =>
          scom &&
          scom.committee &&
          scom.committee.registered_number === committee.registered_number &&
          scom &&
          scom.approval_status === "accepted"
      );
      if (othercommittee && othercommittee.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const viewCommitteeDetails = (committee) => {
    if (enrichedCommittees && enrichedCommittees.length > 0) {
      let filteredCommittee = enrichedCommittees.filter(
        (enrichedCommittee) =>
          enrichedCommittee.committee_registered_number ===
          committee.registered_number
        //   ||
        // checkForOtherTypeCommittees(committee, enrichedCommittee)
      );
      console.log(filteredCommittee, "fitered enriched committee");
      if (filteredCommittee && filteredCommittee.length > 0) {
        let checkActive = filteredCommittee && filteredCommittee[0] && filteredCommittee[0].active;
        if(checkActive === true){
          let indicateEnriched = { enriched: true };
        setSelectedCommittee({ ...indicateEnriched, ...filteredCommittee[0] });
        setInfo(true);
        setShowCommitteeDetails(true);
        }else{
          let notenriched = { enriched: false };
          setSelectedCommittee({ ...notenriched, ...committee });
          setInfo(false);
          setShowCommitteeDetails(true);
        }
      } else {
        let filteredSimilarCommittee =
          similarCommittees &&
          similarCommittees.filter(
            (scom) =>
              scom &&
              scom.committee &&
              scom.committee.registered_number ===
                committee.registered_number &&
              scom &&
              scom.approval_status === "accepted"
          );
        console.log(filteredSimilarCommittee, "filtered similar committee");
        if (filteredSimilarCommittee && filteredSimilarCommittee.length > 0) {
          let enriched_info =
            filteredSimilarCommittee &&
            filteredSimilarCommittee[0] &&
            filteredSimilarCommittee[0].enriched_information;
          let indicateEnriched = { enriched: true };
          console.log(
            { ...indicateEnriched, ...enriched_info },
            "selected similar committee"
          );
          setSelectedCommittee({ ...indicateEnriched, ...enriched_info });
          setInfo(true);
          setShowCommitteeDetails(true);
        } else {
          let notenriched = { enriched: false };
          setSelectedCommittee({ ...notenriched, ...committee });
          setInfo(false);
          setShowCommitteeDetails(true);
        }
      }
    } else {
      let notenriched = { enriched: false };
      setSelectedCommittee({ ...notenriched, ...committee });
      setInfo(false);
      setShowCommitteeDetails(true);
    }
  };

  const hideCommitteeDetails = () => {
    setShowCommitteeDetails(false);
    setSelectedCommittee(null);
  };
  const committees = (type) => {
    if (type === "naitik") {
      return naitik_committees;
    } else if (type === "reRegistered") {
      return cdsco_re_registered_committees;
    } else if (type === "Registered") {
      return cdsco_registered_committees;
    } else if (type === "cdsco") {
      return cdsco;
    } else {
      return [];
    }
  };

  const openPdf = (link) => {
    window.open(link, "_blank");
  };
  const addInformation = (id, type) => {
    window.location.href = `/addInfo/${id}`;
  };
  //console.log(committees(type));
  const committeeCheckboxChange = (e) => {
    console.log(obj, "checking obj");
    if (e.target.checked) {
      obj.hasOwnProperty("selected_committees")
        ? obj["selected_committees"].push(e.target.name)
        : (obj["selected_committees"] = [e.target.name]);
    } else {
      let unselected = obj["selected_committees"].indexOf(e.target.name);
      obj["selected_committees"].splice(unselected, 1);
    }
    console.log(obj, "checking obj");
  };
  const onNavigateBack = () => {
    obj["selected_committees"] = [];
    enquiryObj["selected_committees"] = [];
    setShowCompare(false);
  };
  const fieldsCheckboxChange = (e) => {
    if (e.target.checked) {
      if (Object.keys(ecfields).length > 0) {
        let currentField = {};
        currentField[e.target.name] = e.target.name;
        let ob = { ...currentField, ...ecfields };
        setFields(ob);
      } else {
        let ob = {};
        ob[e.target.name] = e.target.name;
        setFields(ob);
      }
    } else {
      if (ecfields.hasOwnProperty(e.target.name)) {
        delete ecfields[e.target.name];
        console.log(ecfields, "after del");
        let ob = { ...ecfields };
        setFields(ob);
      }
    }
    //console.log(ecfields, "ec fields checklist");
  };

  const enquiryCheckboxChange = (e) => {
    console.log(enquiryObj, "checking enquiryObj");
    if (e.target.checked) {
      enquiryObj.hasOwnProperty("selected_committees")
        ? enquiryObj["selected_committees"].push(JSON.parse(e.target.name))
        : (enquiryObj["selected_committees"] = [JSON.parse(e.target.name)]);
    } else {
      let unselected = enquiryObj["selected_committees"].indexOf(JSON.parse(e.target.name));
      enquiryObj["selected_committees"].splice(unselected, 1);
    }
    console.log(enquiryObj, "checking enquiryObj");
  };

  const getDetails = (ecs) => {
    let selected = [];
    ecs.map((committee) => {
      let checkForEnriched = enrichedCommittees.filter(
        (enriched) =>
          enriched.committee_registered_number === committee ||
          checkForOtherTypeCommittees(committee, enriched)
      );
      if (checkForEnriched && checkForEnriched.length > 0) {
        let indicateEnriched = { enriched: true };
        selected.push({ ...indicateEnriched, ...checkForEnriched[0] });
      } else {
        let notEnriched =
          committees(type) &&
          committees(type).filter(
            (generalCommittee) =>
              generalCommittee.registered_number === committee
          );
        console.log(notEnriched, "notenriched");
        let indicateNotEnriched = { enriched: false };
        selected.push({ ...indicateNotEnriched, ...notEnriched[0] });
      }
    });
    setCompareCommittees(selected);
    setTimeout(() => {
      setShowCompare(true);
    }, 1000);
  };
  const onNext = () => {
    if (
      obj &&
      obj["selected_committees"] &&
      obj["selected_committees"].length > 0
    ) {
      if (obj["selected_committees"].length > 4) {
        alert("Maximum number of selected committees should be 4");
      } else if (obj["selected_committees"].length === 1) {
        alert("Need to select 2 committees to compare");
      } else {
        getDetails(obj["selected_committees"]);
      }
    } else {
      alert("Please select committees to proceed next");
    }
    console.log(obj, "selected coms");
  };

  const EthicalCommittee = (committee) => {
    let ec_name =
        type === "naitik"
          ? committee.name_of_ethics_committee
          : committee.name_and_address,
      id = committee._id,
      number = committee.registered_number,
      approval_date = committee.approval_date,
      release_date = committee.release_date,
      size = committee.pdf_size,
      link = committee.download_pdf,
      state = committee.state,
      additional_information = committee.additional_information,
      city = committee.city,
      address = committee.address;
    return (
      <div style={{ border: "1.5px solid black" }} className="my-4">
        <div style={{ backgroundColor : "#E5E5E5" }} className="ec_top row mx-0 px-0">
          <div className="col-lg-4 mt-2">
            <div className="d-block ml-4">
              <div className="d-flex align-items-center justify-content-start">
                {user.role === "ec_coordinator" ? null : (
                  <input
                    onChange={committeeCheckboxChange}
                    type="checkbox"
                    name={number}
                    className="committee_checkbox"
                  ></input>
                )}
                {type === "naitik" ? (
                  <h3 className="mx-2">{ec_name}</h3>
                ) : (
                  <h3 className="mx-2">
                    {ec_name && ec_name.length > 100
                      ? ec_name.slice(0, 100) + "..."
                      : ec_name}
                  </h3>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-center mt-4">
            {type === "cdsco" ? (
              <p className="mb-0">
                {committee.type === "reRegistered"
                  ? "CDSCO Re-Registered"
                  : "CDSCO Registered"}
              </p>
            ) : null}
          </div>
          <div className="col-lg-5 mt-2 d-flex align-items-center justify-content-between">
            <div className="d-block">
              <p className="mb-0">
                {type === "naitik" ? "Approval Date" : "Release Date"} -{" "}
                <span
                  style={{ color: "#000000", fontSize: 14, fontWeight: "bold" }}
                >
                  {type === "naitik" ? approval_date : release_date}
                </span>
              </p>
              {/* <p className="mb-0">
                Registration Expiry Date -{" "}
                <span
                  style={{ color: "#000000", fontSize: 14, fontWeight: "bold" }}
                >
                  {expiry_date}
                </span>
              </p> */}
            </div>
            <p
              style={{
                textDecoration: "underline",
                color: "black",
                cursor: "pointer",
              }}
              className="mb-0"
              onClick={() => viewCommitteeDetails(committee)}
            >
              view details
            </p>
            {type === "naitik" ? null : (
              <div className="d-flex align-items-center justify-content-center px-0">
                <div className="d-block px-3 text-center">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => openPdf(link)}
                    src={ecSize}
                    alt="size"
                  ></img>
                  <p className="mb-0">{size}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr style={{ borderBottom: "1.5px solid black" }} className="p-0 m-0"></hr>
        <div className="ec_bottom row mx-2 px-0">
          {/* <div className="col-lg-5 d-flex align-items-start justify-content-between mx-2">
            <h2>Panel of Experts</h2>
            <h2>List of CV of Committee members </h2>
          </div> */}
          <div className="col-lg-12 d-flex align-items-start justify-content-between mx-2">
            <div className="d-block mx-2 my-2">
              <p className="mb-0 my-2">
                State : <span className="mb-0">{state}</span>
              </p>
              <p className="mb-0 my-2">
                Registration Number -{" "}
                <span
                  style={{
                    color: "#BC5405",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {number}
                </span>
              </p>
            </div>
            <div className="col-lg-5 d-block align-items-center justify-content-start mx-2">
              <p className="mb-0">
                {type === "naitik" ? "Address" : "Name And Address"}
              </p>
              <h2 className="text-left">
                {type === "naitik" ? address : ec_name}
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const goToEnquiry = () => {
    if (
      enquiryObj &&
      enquiryObj["selected_committees"] &&
      enquiryObj["selected_committees"].length > 0
    ) {
      // window.sessionStorage.setItem(
      //   "enquiry_committees",
      //   JSON.stringify(enquiryObj["selected_committees"])
      // );
      setEnquiryRelatedECs(enquiryObj["selected_committees"])
      window.location.href = "/enquiry";
    } else {
      alert("Please select committee to raise enquiry");
    }
    console.log(enquiryObj, "selected coms for enquiry");
  };

  return (
    <div className="cdsco_intro pt-5 col-lg-12">
      {showCompare === true ? (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <p
              style={{
                color: "#BC5405",
                cursor: "pointer",
                fontWeight: "bolder",
                textTransform: "uppercase",
              }}
              className="mb-0 col-lg-1 mx-0 px-0"
              onClick={() => onNavigateBack()}
            >
              Back
            </p>
            <button
              style={{
                cursor: "pointer",
                fontWeight: "bolder",
                textTransform: "uppercase",
              }}
              className="btn btn-sm login"
              onClick={() => goToEnquiry()}
            >
              Enquiry
            </button>
          </div>
          <div className="multiselect col-lg-6 mt-3 my-2 text-center px-1 position-absolute">
            <div className="select-group" onClick={() => showCheckboxes()}>
              <select
                style={{
                  border: "none",
                }}
              >
                <option>Select Fields</option>
              </select>
              <div className="hide_select"></div>
            </div>
            <div
              className={`${
                showOption === true ? "d-block" : "d-none"
              } checkboxes px-1`}
            >
              {fields.map((selected_ec_field) => (
                <div className="field d-block text-left my-1">
                  <input
                    type="checkbox"
                    name={selected_ec_field}
                    checked={
                      ecfields.hasOwnProperty(selected_ec_field) === true &&
                      true
                    }
                    onChange={fieldsCheckboxChange}
                  />
                  <span className="mx-2">{selected_ec_field}</span>
                </div>
              ))}
            </div>
          </div>
          {Object.values(ecfields) && Object.values(ecfields).length > 0 ? (
            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th style={{ backgroundColor :"#E5E5E5"}} scope="col">Fields</th>
                  {compareCommittees.map((compareCommittee) => (
                    <th style={{ backgroundColor :"#E5E5E5"}}>
                      <input
                        type="checkbox"
                        onChange={enquiryCheckboxChange}
                        name={
                          compareCommittee && compareCommittee.enriched === true
                            ? JSON.stringify({id:compareCommittee &&
                              compareCommittee.ethics_committee &&
                              compareCommittee.ethics_committee
                                ._id, type: compareCommittee &&
                                compareCommittee.ethics_committee &&
                                compareCommittee.ethics_committee
                                  .type, registered_number: compareCommittee &&
                                  compareCommittee.ethics_committee &&
                                  compareCommittee.ethics_committee
                                    .registered_number })
                            : JSON.stringify({id:compareCommittee && compareCommittee._id, type: compareCommittee &&
                                compareCommittee.type, registered_number: compareCommittee && compareCommittee.registered_number })
                        }
                      ></input>
                      <span className="ml-2">
                        {compareCommittee && compareCommittee.enriched === true
                          ? compareCommittee &&
                            compareCommittee.ethics_committee &&
                            compareCommittee.ethics_committee.registered_number
                          : compareCommittee.registered_number}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {compareCommittees.filter(
                  (compareCommittee) =>
                    compareCommittee && compareCommittee.enriched === false
                ) &&
                compareCommittees.filter(
                  (compareCommittee) =>
                    compareCommittee && compareCommittee.enriched === false
                ).length >= compareCommittees.length
                  ? Object.values({ "EC Regulatory": "EC Regulatory" }).map(
                      (field) => (
                        <tr>
                          <th scope="row">{field}</th>
                          {getFieldData(
                            "EC Regulatory",
                            type,
                            compareCommittees
                          )}
                        </tr>
                      )
                    )
                  : Object.values(ecfields).map((field) => (
                      <tr>
                        <th scope="row">{field}</th>
                        {getFieldData(field, type, compareCommittees)}
                      </tr>
                    ))}
              </tbody>
            </table>
          ) : (
            <div className="mx-auto text-center">
              <h5>Please select desired fields to compare committees</h5>
            </div>
          )}
        </div>
      ) : (
        <div>
          {showCommitteeDetails === true ? (
            <CommitteeDetails
              user={user}
              openPdf={openPdf}
              naitikPincode={pincode}
              type={type}
              committee={selectedCommittee}
              hasEnrichedInfo={info}
              hideCommitteeDetails={hideCommitteeDetails}
            />
          ) : (
            <div>
              {showMap === true ? (
                <div id="map" className="container my-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p
                      style={{
                        color: "#BC5405",
                        cursor: "pointer",
                        fontWeight: "bolder",
                        textTransform: "uppercase",
                      }}
                      className="mb-0 my-2"
                      onClick={() => {
                        setCoordinates([]);
                        setShowMap(false);
                      }}
                    >
                      Back
                    </p>
                    {/* <button
                      onClick={() => onNext()}
                      className="btn btn-sm login"
                    >
                      Compare
                    </button> */}
                  </div>
                  <Map
                    isMarkerShown={true}
                    position={coordinates}
                    showMap={showMap}
                    containerElement={<div style={{ height: `500px` }} />}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Google_Map_Key}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    committeeCheckboxChange={committeeCheckboxChange}
                    obj={obj}
                    onNext={onNext}
                  />
                </div>
              ) : (
                <div>
                  <h2>{`${
                    type === "naitik"
                      ? "Naitik Committees"
                      : // : (type === "reRegistered"
                      // ? "CDSCO Re Registered Committees"
                      // : "CDSCO Registered Committees")
                      type === "cdsco"
                      ? "CDSCO Committees"
                      : null
                  }`}</h2>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center mx-3">
                      <h4 className="mb-0 mx-2">State: </h4>
                      <span>{state}</span>
                    </div>
                    {hidePincode === true ? null : (
                      <div className="d-flex align-items-center justify-content-center mx-3">
                        <h4 className="mb-0 mx-2">Pincode: </h4>
                        <span>{pincode}</span>
                      </div>
                    )}
                  </div>
                  <div className="container-fluid p-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-center">
                        {user && user.role === "ec_coordinator" ? null : (
                          <p
                            style={{
                              color: "#BC5405",
                              cursor: "pointer",
                              fontWeight: "bolder",
                              textTransform: "uppercase",
                            }}
                            className="mb-0"
                            onClick={() => navigateBack()}
                          >
                            Back
                          </p>
                        )}
                        <p
                          style={{
                            fontWeight: "normal",
                            textTransform: "uppercase",
                          }}
                          className="mb-0 mx-3"
                        >
                          Number of Committees:{" "}
                          <span style={{ fontWeight : 'bold' , fontSize: 15  }}>{committees(type) && committees(type).length}</span>
                        </p>
                      </div>
                      <div>
                        {/* <img className="mx-3" src={List} alt="list"></img> */}
                        <button
                          onClick={() => getCoordinates(committees(type))}
                          className="btn btn-sm login mx-2"
                        >
                          Map View
                        </button>
                        {user.role === "ec_coordinator" ? null : (
                          <button
                            onClick={() => onNext()}
                            className="btn btn-sm login"
                          >
                            Compare
                          </button>
                        )}
                        {user.role === "ec_independent_researcher" ? null : (
                          <button
                            className="mb-0 btn btn-sm login"
                            onClick={() =>
                              addInformation(
                                cdsco_re_registered_committees[0]._id
                              )
                            }
                          >
                            Update Information
                          </button>
                        )}
                      </div>
                    </div>
                    {committees(type) && committees(type).length > 0 ? (
                      committees(type).map((committee, i) => (
                        <EthicalCommittee key={i} {...committee} />
                      ))
                    ) : loading === false ? (
                      <div className="my-5 mx-auto text-center">
                        <p className="mb-0">Loading ...</p>
                      </div>
                    ) : (
                      <div className="my-5 mx-auto text-center">
                        <p className="mb-0">No Committees Found</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ECCommittees;
