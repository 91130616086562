import React from "react";
import "../App.css";
import {
  cdscoCommittees,
  getCommitteeDetails,
  getEnrichedInfo,
  getSimilarCommittee,
  naitikCommittees,
  userProfile,
} from "../helpers/api";
import { Committee, ecType, ecState, ecCity } from "../theme/icons";
import ECCommittees from "./login/EC_Committees";
import Header from "../components/Header";
import NotFound from "../components/NotFound";
import NotApproved from "../components/NotApproved";

const ecTypes = [
  { type: "Type of Ethical Committee", value: "" },
  { type: "CDSCO", value: "cdsco" },
  // { type: "CDSCO Re-Registered", value: "reRegistered" },
  { type: "NAITIK", value: "naitik" },
];

class Ecselect extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showCommittees: false,
      naitik_committees: [],
      all_naitik_committees:[],
      cdsco_registered_committees: [],
      cdsco_re_registered_committees: [],
      cdsco: [],
      enriched_committees: [],
      similarCommittees: [],
      search_pincode: "",
      type: "",
      user: {},
      committee: "",
      state: "",
      states: [],
      city: "",
      loading: false,
      hidePincode: false,
      pincode: "",
      pincodes: [],
      selectedCommittees: [],
    };
  }

  componentDidMount() {
    let user = this.props.user;
    this.setState(
      {
        type:
          window.sessionStorage.getItem("type") === null
            ? ""
            : window.sessionStorage.getItem("type"),
        state:
          window.sessionStorage.getItem("state") === null
            ? ""
            : window.sessionStorage.getItem("state"),
        city:
          window.sessionStorage.getItem("city") === null
            ? ""
            : window.sessionStorage.getItem("city"),
        pincode:
          window.sessionStorage.getItem("pincode") === null
            ? ""
            : window.sessionStorage.getItem("pincode"),
      },
      () => {
        console.log(this.state.type,this.state.state,'checking for type')
        if(this.state.type && this.state.type.length > 0){
        //console.log(this.state.type,'checking for type')
         if(this.state.type === "naitik"){
           this.setNaitikCommittees()
         } else if(this.state.type === "cdsco"){
           this.setCdscoCommitteees()
         }
        }
      }
    );
    if (user !== null && Object.keys(user) && Object.keys(user).length > 0) {
      if (user.role === "ec_independent_researcher") {
        console.log(user, "user");
        getEnrichedInfo("all")
          .then((enrichedComms) => {
            console.log(enrichedComms, "got enriched comms");
            this.setState({ enriched_committees: enrichedComms });
          })
          .catch((errEnrichedComms) => {
            console.log(errEnrichedComms, "err with fetching enriched comms");
            this.setState({ enriched_committees: [] });
          });
        getSimilarCommittee("all")
          .then((gotSimilarComms) => {
            console.log(gotSimilarComms, "got similar committees");
            this.setState({ similarCommittees: gotSimilarComms });
          })
          .catch((errSimilarComms) => {
            console.log(
              errSimilarComms,
              "error with fetching similar committees"
            );
            this.setState({ similarCommittees: [] });
          });
      }
    }
  }

  handleSearchPincodeChange = (e) => {
    this.setState({ search_pincode: e.target.value });
  };

  removeRepeatedItems = (list) => {
    console.log(list.filter(item=>item === '0'),'checking 0')
    let items = [...new Set([...list])];
    //items.splice(items.indexOf(items.filter(item=>item === "0")[0]), 1);
    return items;
  };
  displayAlert = (message) => {
    alert(message);
  };

  getSearchFilter = (city, pincode) => {
    if (city === "" && pincode === "") {
      return "s";
    } else if (city && city.length > 0 && pincode === "") {
      return "c";
    } else if (city === "" && pincode && pincode.length > 0) {
      return "p";
    } else if (city && city.length > 0 && pincode && pincode.length > 0) {
      return "cp";
    }
  };

  goToCommittees = () => {
    console.log(
      this.state.type,
      this.state.pincode,
      this.state.city,
      this.state.state,
      "func called"
    );
    if (this.state.type === "naitik") {
      if (
        this.state.naitik_committees &&
        this.state.naitik_committees.length === 0
      )
        return this.displayAlert("Committees not found");
      let naitik_state_filtered = this.state.all_naitik_committees && this.state.all_naitik_committees.length > 0 ? this.state.all_naitik_committees.filter(com=>com.state === this.state.state) : []
      let option = this.getSearchFilter(this.state.city, this.state.pincode);
      console.log(option, "option");
      let filteredCommittees = naitik_state_filtered && naitik_state_filtered.length > 0 ? naitik_state_filtered.filter
      (
        (naitikCommittee) =>
          (option === "s" && naitikCommittee.state === this.state.state) ||
          (option === "c" &&
            (naitikCommittee.address.indexOf(this.state.city) !== -1 ||
              naitikCommittee.address
                .toLowerCase()
                .indexOf(this.state.city.toLowerCase()) !== -1 ||
              naitikCommittee.address
                .toUpperCase()
                .indexOf(this.state.city.toUpperCase()) !== -1)) ||
          (option === "p" &&
            this.getNaitikPincode(naitikCommittee.address) ===
              this.state.pincode) ||
          (option === "cp" &&
            (naitikCommittee.address.indexOf(this.state.city) !== -1 ||
              naitikCommittee.address
                .toLowerCase()
                .indexOf(this.state.city.toLowerCase()) !== -1 ||
              naitikCommittee.address
                .toUpperCase()
                .indexOf(this.state.city.toUpperCase()) !== -1) &&
            this.getNaitikPincode(naitikCommittee.address) ===
              this.state.pincode)
      ) : [];
      let enrichedcomms = this.state.enriched_committees;
      let filterEnriched = enrichedcomms && enrichedcomms.length > 0 ? enrichedcomms.filter(ecom=>ecom.pincode === this.state.pincode) : []
      console.log(filterEnriched, 'enriched committees')
      let existing_similar_committees = this.state.similarCommittees && this.state.similarCommittees.length > 0 ? this.state.similarCommittees : [];
      console.log(existing_similar_committees,'existing scoms')
      let enriched_scoms = existing_similar_committees.filter(scom=> scom.hasOwnProperty("enriched_information") === true );
      console.log(enriched_scoms,'enriched_scoms')
      let filtered_scoms = enriched_scoms && enriched_scoms.length > 0 ? enriched_scoms.filter(escom=>(escom.enriched_information.pincode === this.state.pincode) && (escom.committee.type === "naitik")) : []
      console.log(filtered_scoms,'got scom with pincode');
      let filtered_similar_committees = filtered_scoms && filtered_scoms.length > 0 ? filtered_scoms.map(scom=>scom.committee) : []
      let filterEnrichedECs = filterEnriched && filterEnriched.length > 0 ? filterEnriched && filterEnriched.length > 0 ? filterEnriched.map(ecom=>ecom && ecom.ethics_committee).filter(item => (item && item.type === "reRegistered" && item.state === this.state.state) || (item && item.type === "Registered" && item.state === this.state.state)) : [] : []
      

      if (filteredCommittees && filteredCommittees.length > 0) {
        console.log(filteredCommittees, filtered_similar_committees, "filtered committees");
        this.setState({ naitik_committees: [...filteredCommittees,...filterEnrichedECs, ...filtered_similar_committees] }, () => {
          this.setState({ showCommittees: true });
        });
      } else if(filterEnrichedECs && filterEnrichedECs.length > 0){
        console.log(filterEnrichedECs, "filtered enriched");
        this.setState({ naitik_committees: [...filterEnrichedECs, ...filtered_similar_committees] }, () => {
          this.setState({ showCommittees: true });
        });
      } else if(filtered_similar_committees && filtered_similar_committees.length > 0){
        console.log(filtered_similar_committees, "filtered similar committees");
        this.setState({ naitik_committees: [...filterEnrichedECs, ...filtered_similar_committees] }, () => {
          this.setState({ showCommittees: true });
        });
      }
      else{
        alert("No results matching found. Please try again");
      }
    } else if (this.state.type === "cdsco") {
      let option = this.getSearchFilter(this.state.city, this.state.pincode);
      console.log(option, "option");
      let enrichedcomms = this.state.enriched_committees;
      let all_cdsco = [...this.state.cdsco_re_registered_committees, ...this.state.cdsco_registered_committees]
      let stateFiltered = all_cdsco  && all_cdsco.length > 0 ? all_cdsco.filter(com=>com.state === this.state.state) : []
      let filteredCommittees = stateFiltered && stateFiltered.length > 0 ? stateFiltered.filter(
        (cdscoCommittee) =>
          (option === "s" && cdscoCommittee.state === this.state.state) ||
          (option === "c" &&
            (cdscoCommittee.name_and_address.indexOf(this.state.city) !== -1 ||
              cdscoCommittee.name_and_address
                .toLowerCase()
                .indexOf(this.state.city.toLowerCase()) !== -1 ||
              cdscoCommittee.name_and_address
                .toUpperCase()
                .indexOf(this.state.city.toUpperCase()) !== -1)) ||
          (option === "p" &&
            (cdscoCommittee.name_and_address.indexOf(this.state.pincode) !== -1 ||
              cdscoCommittee.name_and_address
                .toLowerCase()
                .indexOf(this.state.pincode.toLowerCase()) !== -1 ||
              cdscoCommittee.name_and_address
                .toUpperCase()
                .indexOf(this.state.pincode.toUpperCase()) !== -1)) ||
          (option === "cp" &&
            (cdscoCommittee.name_and_address.indexOf(this.state.city) !== -1 ||
              cdscoCommittee.name_and_address
                .toLowerCase()
                .indexOf(this.state.city.toLowerCase()) !== -1 ||
              cdscoCommittee.name_and_address
                .toUpperCase()
                .indexOf(this.state.city.toUpperCase()) !== -1) &&
            (cdscoCommittee.name_and_address.indexOf(this.state.pincode) !==
              -1 ||
              cdscoCommittee.name_and_address
                .toLowerCase()
                .indexOf(this.state.pincode.toLowerCase()) !== -1 ||
              cdscoCommittee.name_and_address
                .toUpperCase()
                .indexOf(this.state.pincode.toUpperCase()) !== -1))
      ) : [];
      let filterEnriched = enrichedcomms && enrichedcomms.length > 0 ? enrichedcomms.filter(ecom=>ecom.pincode === this.state.pincode) : []
      console.log(filterEnriched, 'enriched committees')
      let existing_similar_committees = this.state.similarCommittees && this.state.similarCommittees.length > 0 ? this.state.similarCommittees : [];
      console.log(existing_similar_committees,'existing scoms')
      let enriched_scoms = existing_similar_committees.filter(scom=> scom.hasOwnProperty("enriched_information") === true );
      console.log(enriched_scoms,'enriched_scoms')
      let filtered_scoms = enriched_scoms && enriched_scoms.length > 0 ? enriched_scoms.filter(escom=>(escom.enriched_information.pincode === this.state.pincode) && ((escom.committee.type === "reRegistered") || (escom.committee.type === "Registered"))) : []
      console.log(filtered_scoms,'got scom with pincode');
      let filtered_similar_committees = filtered_scoms && filtered_scoms.length > 0 ? filtered_scoms.map(scom=>scom.committee) : []
      let filterEnrichedECs = filterEnriched && filterEnriched.length > 0 ? filterEnriched && filterEnriched.length > 0 ? filterEnriched.map(ecom=>ecom && ecom.ethics_committee).filter(item => (item && item.type === "reRegistered" && item.state === this.state.state) || (item && item.type === "Registered" && item.state === this.state.state)) : [] : []
      
      if (filteredCommittees && filteredCommittees.length > 0) {
        console.log(filteredCommittees, filterEnrichedECs, "filtered committees");
        this.setState({ cdsco: [ ...new Set([...filterEnrichedECs,...filteredCommittees, ...filtered_similar_committees])] }, () => {
          this.setState({ showCommittees: true });
        });
      } else {
        console.log(filteredCommittees, "no results found");
        if(filterEnrichedECs && filterEnrichedECs.length > 0){
          this.setState({ cdsco: [...filterEnrichedECs, ...filtered_similar_committees] }, () => {
            this.setState({ showCommittees: true });
          });
        } else if(filtered_similar_committees && filtered_similar_committees.length > 0){
          this.setState({ cdsco: [...filterEnrichedECs, ...filtered_similar_committees] }, () => {
            this.setState({ showCommittees: true });
          });
        }
        else{
          alert("No results matching found. Please try again");
        }
      }
    }
  };

  getNaitikPincode = (address) => {
    //console.log(address, "address for nc");
    if (address && address.length === 0) return;
    let zipcode = address.split(" - ")[1];
    //console.log(zipcode, "pincode for nc");
    return zipcode;
  };

  getPincodes = (arr) => {
    console.log(arr, "pincodes for nc");
    if (arr && arr.length === 0) return;
    console.log(
      arr.map((nc) => this.getNaitikPincode(nc.address)),
      "while sending array"
    );
    let pincodesList = this.removeRepeatedItems(
      arr.map((nc) => this.getNaitikPincode(nc.address))
    ).sort();
    console.log(pincodesList, "list of pincodes");
    this.setState({ pincodes: ["Please Select"].concat(pincodesList) });
  };

  setNaitikCommittees=()=>{
    naitikCommittees()
          .then((naitik) => {
            console.log(
              this.getNaitikPincode(naitik.output[0].address),
              "naitik"
            );
            this.setState({ naitik_committees: naitik.output, all_naitik_committees: naitik.output }, () => {
              this.setState({ hidePincode: false });
            });
            this.getPincodes(naitik.output);
            this.setState({
              states: ["Please Select State"].concat(
                this.removeRepeatedItems(
                  naitik.output.map((committee) => committee.state)
                ).sort()
              ),
            });
          })
          .catch((errNaitik) => {
            console.log(errNaitik, "naitik committees not found");
            this.setState({ naitik_committees: [] });
          });
  }

  setCdscoCommitteees=()=>{
    cdscoCommittees("reRegistered")
          .then((reRegistered) => {
            console.log(reRegistered, "cdsco re registered committees");
            this.setState(
              { cdsco_re_registered_committees: reRegistered.output },
              () => {
                cdscoCommittees("Registered")
                  .then((Registered) => {
                    console.log(Registered, "cdsco registered committees");
                    this.setState(
                      { cdsco_registered_committees: Registered.output },
                      () => {
                        let cdsco_committees = [
                          ...this.state.cdsco_re_registered_committees,
                          ...this.state.cdsco_registered_committees,
                        ];
                        console.log(cdsco_committees.filter(item => item.state === "0"),'coms with state as 0')
                        this.setState({
                          hidePincode: true,
                          cdsco: cdsco_committees,
                          states: ["Please Select State"].concat(
                            this.removeRepeatedItems(
                              cdsco_committees.map(
                                (committee) => committee.state
                              )
                            ).sort()
                          ),
                        });
                      }
                    );
                  })
                  .catch((errRegistered) => {
                    console.log(
                      errRegistered,
                      "cdsco registered committees not found"
                    );
                    this.setState({ cdsco_registered_committees: [] });
                  });
              }
            );
          })
          .catch((errReRegistered) => {
            console.log(
              errReRegistered,
              "cdsco re registered committees not found"
            );
            this.setState({ cdsco_re_registered_committees: [] });
          });
  }

  handleTypeChange = async (e) => {
    this.setState({ type: e.target.value }, () => {
      window.sessionStorage.setItem("type", this.state.type);
      if (e.target.value === "naitik") {
        this.setNaitikCommittees()
      }
      // else if (e.target.value === "Registered") {
      //   cdscoCommittees("Registered")
      //     .then((Registered) => {
      //       console.log(Registered, "cdsco registered committees");
      //       this.setState(
      //         { cdsco_registered_committees: Registered.output },
      //         () => {
      //           this.setState({ hidePincode: true });
      //         }
      //       );
      //       //this.getPincodes(Registered.output);
      //       this.setState({
      //         states: ["Please Select"].concat(
      //           this.removeRepeatedItems(
      //             Registered.output.map((committee) => committee.state)
      //           ).sort()
      //         ),
      //       });
      //     })
      //     .catch((errRegistered) => {
      //       console.log(errRegistered, "cdsco registered committees not found");
      //       this.setState({ cdsco_registered_committees: [] });
      //     });
      //   }
      // else if (e.target.value === "reRegistered") {
      //   cdscoCommittees("reRegistered")
      //     .then((reRegistered) => {
      //       console.log(reRegistered, "cdsco re registered committees");
      //       this.setState(
      //         { cdsco_re_registered_committees: reRegistered.output },
      //         () => {
      //           this.setState({
      //             hidePincode: true,
      //             states: ["Please Select"].concat(
      //               this.removeRepeatedItems(
      //                 reRegistered.output.map((committee) =>
      //                   committee.state === 0 ? null : committee.state
      //                 )
      //               ).sort()
      //             ),
      //           });
      //         }
      //       );
      //     })
      //     .catch((errReRegistered) => {
      //       console.log(
      //         errReRegistered,
      //         "cdsco re registered committees not found"
      //       );
      //       this.setState({ cdsco_re_registered_committees: [] });
      //     });
      // }
      else if (e.target.value === "cdsco") {
        this.setCdscoCommitteees()
      }
    });
  };
  handleCityChange = (e) => {
    this.setState({ city: e.target.value }, () => {
      window.sessionStorage.setItem("city", this.state.city);
    });
  };
  handleStateChange = (e) => {
    console.log(e.target.value);
    this.setState({ state: e.target.value }, () => {
      window.sessionStorage.setItem("state", this.state.state);
      if (this.state.type === "naitik") {
        let selectedCommittees = this.state.naitik_committees.filter(
          (committee) => committee.state === e.target.value
        );
        console.log(selectedCommittees, "selected committees");
        this.getPincodes(selectedCommittees);
      }
    });
  };

  handlePincodeChange = (e) => {
    console.log(e.target.value);
    this.setState({ pincode: e.target.value }, () => {
      window.sessionStorage.setItem("pincode", this.state.pincode);
    });
  };
  navigateBack = () => {
    this.setState({ showCommittees: false });
  };
  changeUserStatus = () => {
    console.log(this.state.user);
    this.setState({ user: null }, () => {
      //window.location.href = "/";
    });
  };
  render() {
    const {
      type,
      state,
      city,
      cdsco,
      hidePincode,
      pincode,
      loading,
      showCommittees,
      naitik_committees,
      cdsco_registered_committees,
      cdsco_re_registered_committees,
      states,
      enriched_committees,
      similarCommittees,
      pincodes,
    } = this.state;
    const { user } = this.props;
    return (
      <div
        style={{
          background: showCommittees === true ? "#FFFFFF" :
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
        }}
        className="mb-5"
      >
        {user === null ? (
          <NotFound message={"Please login to view the application"} />
        ) : user && user.active === false ? (
          <NotApproved
            message="QCR Admin need to review and grant access. Thank you"
            changeUserStatus={this.changeUserStatus}
            userId={user._id}
          />
        ) : (
          <div>
            {(user && user.role === "ec_coordinator") || (user && user.role === "ec_qcr_admin") ?
              <NotApproved
              message="You are not authorized"
              changeUserStatus={this.changeUserStatus}
              userId={user._id}
            />
             : showCommittees === true ? (
              <ECCommittees
                type={type}
                state={state}
                city={city}
                cdsco={cdsco}
                user={user}
                pincode={pincode}
                hidePincode={hidePincode}
                navigateBack={this.navigateBack}
                loading={loading}
                enrichedCommittees={enriched_committees}
                similarCommittees={similarCommittees}
                naitik_committees={naitik_committees}
                cdsco_registered_committees={cdsco_registered_committees}
                cdsco_re_registered_committees={cdsco_re_registered_committees}
              />
            ) : (
              <div className="mx-auto text-center col-lg-10">
                <div className="d-block">
                  <img
                    style={{ height: 350 }}
                    src={Committee}
                    alt="committee"
                    className="img-responsive py-2"
                  ></img>
                  {/* <img src={committeeBottom} alt="bottom" className="committee_bottom img-responsive"></img> */}
                </div>
                <h6 className="mb-0 mx-auto text-left pl-2">
                  Please select ethics committee type
                </h6>
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid #646464",
                    borderRadius: 5,
                  }}
                  className="input-group input-group-lg p-0 my-2 mx-2 d-flex align-items-center justify-content-start"
                >
                  <div style={{ height: 43, width: 43, borderRadius: 4, backgroundColor: "#BBC1E2", display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                  <img
                    style={{
                      // height: 47,
                      // width: 47,
                    }}
                    src={ecType}
                    alt="committee type"
                    className="img-responsive m-1"
                  ></img>
                  </div>
                  <select
                    style={{
                      border: "none",
                      outline: "none",
                      width: "93%",
                      height: 50,
                    }}
                    placeholder="committe type"
                    className="form-select px-0"
                    name={type}
                    value={type}
                    onChange={(e) => this.handleTypeChange(e)}
                  >
                    {ecTypes.map((ec, i) => (
                      <option key={i} value={ec.value}>
                        {ec.type}
                      </option>
                    ))}
                  </select>
                </div>
                {/* {type === "naitik" ? null : (
                  <div>
                    <div className="text-left pl-2">
                      <h6 className="mb-0 mx-auto text-left my-1">
                        Search by pincode
                      </h6>
                      <input
                        onChange={this.handleSearchPincodeChange}
                        className="form-control input-lg"
                        style={{
                          outline: "none",
                          boxSizing: "border-box",
                          border: "1px solid black",
                        }}
                        type="text"
                        name="pincode-search"
                        placeholder="Please enter pincode"
                      ></input>
                    </div>
                    <h6 className="my-3">OR</h6>
                  </div>
                )} */}
                {type && type.length > 0 ? (
                  <div className="row my-3">
                    <div className="col-lg-4">
                      <div
                        style={{
                          backgroundColor: "white",
                          border: "1.5px solid #646464",
                          borderRadius: 5,
                        }}
                        className="input-group input-group-lg p-0 my-2 mx-2"
                      >
                        <div className="mt-1" style={{ height: 43, width: 43, borderRadius: 4, backgroundColor: "#AFE1DB", display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                        <img
                          
                          src={ecState}
                          alt="state"
                          className="img-responsive m-1"
                        ></img>
                        </div>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            width: "82%",
                            height: 50,
                          }}
                          placeholder="state"
                          className="form-select px-0"
                          name={state}
                          value={state}
                          onChange={this.handleStateChange}
                        >
                          {states.map((state, i) => (
                            <option key={i} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {type && type.length > 0 && state && state.length > 0 ? (
                      <div className="col-lg-8 row mx-0 px-0">
                        <div className="col-lg-6">
                          <div
                            style={{
                              backgroundColor: "white",
                              border: "1.5px solid #646464",
                              borderRadius: 5,
                            }}
                            className="input-group input-group-lg p-0 my-2 mx-2"
                          >
                            <div className="mt-1" style={{ height: 43, width: 43, borderRadius: 4, backgroundColor: "#EAE0AD", display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                            <img
                              // style={{
                              //   height: 47,
                              //   width: 47,
                              //   backgroundColor: "#EAE0AD",
                              //   padding: 7,
                              //   borderRadius: 3,
                              // }}
                              src={ecCity}
                              alt="pincode"
                              className="img-responsive m-1"
                            ></img>
                            </div>
                            <input
                              style={{
                                border: "none",
                                outline: "none",
                                width: "82%",
                                height: 50,
                              }}
                              placeholder="Please Enter City"
                              type="text"
                              className="form-input px-2"
                              name={city}
                              value={city}
                              onChange={this.handleCityChange}
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            style={{
                              backgroundColor: "white",
                              border: "1.5px solid #646464",
                              borderRadius: 5,
                            }}
                            className="input-group input-group-lg p-0 my-2 mx-2"
                          >
                            <div className="mt-1" style={{ height: 43, width: 43, borderRadius: 4, backgroundColor: "#EAE0AD", display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                            <img
                              // style={{
                              //   height: 47,
                              //   width: 47,
                              //   backgroundColor: "#EAE0AD",
                              //   padding: 7,
                              //   borderRadius: 3,
                              // }}
                              src={ecCity}
                              alt="pincode"
                              className="img-responsive m-1"
                            ></img>
                            </div>
                            {/* <select
                          style={{
                            border: "none",
                            outline: "none",
                            width: "82%",
                          }}
                          placeholder="pincode"
                          className="form-select px-2"
                          name={pincode}
                          value={pincode}
                          onChange={this.handlePincodeChange}
                        >
                          {pincodes.map((pincode, i) => (
                            <option key={i} value={pincode}>
                              {pincode}
                            </option>
                          ))}
                        </select> */}
                            <input
                              style={{
                                border: "none",
                                outline: "none",
                                width: "82%",
                                height: 50,
                              }}
                              placeholder="Please Enter Pin code"
                              type="text"
                              className="form-input px-2"
                              name={pincode}
                              value={pincode}
                              onChange={this.handlePincodeChange}
                            ></input>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <button
                  onClick={() => this.goToCommittees()}
                  className="my-3 btn btn-lg login col-lg-1"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default Ecselect;
