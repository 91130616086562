import React from "react";

const InfoQuestion = (props) => {
  const {
    committee,
    question,
    updateLinkingSimilarCommittee,
    askLinking,
    canLinkSimilarCommittee,
    committeesearch,
    similarCommiteeSearch,
    linkSimilarCommittee,
    next,
    changeNextQuestion,
  } = props;

  return (
    <div
      style={{
        borderRadius: 10,
        border: "1px solid dodgerblue",
      }}
      className="px-3 py-2"
    >
      <div className="d-flex align-items-center justify-content-between">
        <p className="mb-0">
          {question[0]}{" "}
          {committee.type === "reRegistered" || committee.type === "Registered"
            ? "DHR / Naitik"
            : "CDSCO"}{" "}
          {question[1]}
        </p>
        <p
          onClick={() => {
            updateLinkingSimilarCommittee(false);
            changeNextQuestion(false);
            askLinking(false);
          }}
          style={{ cursor: "pointer", fontSize: 15 }}
          className="mb-0"
        >
          X
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-start my-2">
        <button
          onClick={() => next === true ? changeNextQuestion(true) : updateLinkingSimilarCommittee(true)}
          className="btn btn-small btn-primary mr-3 px-2 py-1"
        >
          yes
        </button>
        <button
          onClick={() => {
            updateLinkingSimilarCommittee(false);
            changeNextQuestion(false);
            askLinking(false);
          }}
          className="btn btn-small btn-danger mr-3 px-2 py-1"
        >
          no
        </button>
      </div>
      {canLinkSimilarCommittee === true ? (
        <div>
          <h6>Link Similar Committees</h6>
          <div className="d-flex align-items-center justify-content-start">
            <input
              name="committeesearch"
              value={committeesearch}
              type="text"
              onChange={similarCommiteeSearch}
              placeholder="Search by the address of desired ethical committee"
              className="form-control input-lg col-lg-11"
            ></input>
            <button
              onClick={() => linkSimilarCommittee()}
              className="btn btn-sm login mx-5"
            >
              Link
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InfoQuestion;
