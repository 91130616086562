import React, { useState } from "react";
import ChooseMembersModal from "../chooseMembersModal";
const ECTraining = (props) => {
  const {
    handleChange,
    chooseMembersCheckboxChange,
    openMembersModal,
    showMembersModal,
    closeMembersModal,
    ec_composition_members,
    ec_training,
    navigateECTraining,
    createTrainingRow,
    selectMembers,
  } = props;

  const arrangeItems = (arr) => {
    let len = arr && arr.length;
    if (len === 3 || len > 3) {
      return [arr[arr.length - 1], arr[arr.length - 2], arr[arr.length - 3]];
    } else if (len === 2) {
      return [arr[arr.length - 1], arr[arr.length - 2]];
    } else if (len === 1) {
      return [arr[arr.length - 1]];
    }
  };

  const [selectedRow, setSelectedRow] = useState("");
  const [showMembers, setShowMembers] = useState(false);

  const getBgColor = (row, member) => {
    let rowmembers = row[row.id + "members"];
    let filterMember = rowmembers.filter((rmember) => rmember.id === member.id);
    console.log(filterMember, "filtermember for color");
    if (filterMember && filterMember.length > 0) {
      let checkForTrue =
        filterMember && filterMember[0] && filterMember[0]["checked"];
      console.log(filterMember, checkForTrue, "filtermember for color");
      return checkForTrue === true ? "green" : "seablue";
    } else {
      return "seablue";
    }
  };

  return (
    <div className="text-left">
      <p style = {{ textDecoration: 'underline', cursor:'pointer', color: "#BC5405"}} className="mb-0" onClick={()=>navigateECTraining()}>{ec_training && ec_training.length > 0 ? "View All" : "Add"}</p>
      {ec_training && ec_training.length > 0 ? (
        arrangeItems(ec_training).map((row, id) => (
          <div className="my-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col-lg-3 d-flex align-items-center justify-content-center px-0">
                <p className="mb-0 mr-2">Topic</p>
                <select
                  // onChange={this.handleChange}
                  disabled={true}
                  name={row.id + "topic"}
                  value={row[row.id + "topic"]}
                  type="text"
                  placeholder="topic"
                  className="form-control"
                >
                  <option value="">Please Select</option>
                  {/* <option value="Scientific">Scientific</option>
                    <option value="Ethics">Ethics</option> */}
                  <option value="ICH-GCP Guidelines">ICH-GCP Guidelines</option>
                  <option value="NDCT Rules">NDCT Rules</option>
                  <option value="EC SOP">EC SOP</option>
                  <option value="ICMR Guidelines">ICMR Guidelines</option>
                  <option value="Other">Other</option>
                </select>
                {row[row.id + "topic"] === "Other" ? (
                  <input
                    // onChange={this.handleChange}
                    type="text"
                    disabled={true}
                    name={row.id + "topicName"}
                    value={row[row.id + "topicName"]}
                    placeholder="Topic"
                    className="form-control"
                  ></input>
                ) : null}
              </div>
              <div className="col-lg-7 d-flex align-items-center justify-content-center">
                <p className="mb-0 col-lg-2 px-0">Training Date</p>
                <input
                  //onChange={this.handleChange}
                  disabled={true}
                  name={row.id + "training_date"}
                  value={row[row.id + "training_date"]}
                  type="date"
                  placeholder="Date of Regulatory Training"
                  className="form-control col-lg-5"
                ></input>
              </div>
              <p
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  setSelectedRow(row.id);
                  setShowMembers(true);
                }}
                className="mb-0"
              >
                view members
              </p>
            </div>
            <div style={{}}>
              {row.id === selectedRow ? (
                <div
                  className={showMembers === true ? "d-block modal" : "d-none"}
                >
                  <div className="modal-content container">
                    <div className="modal-header">
                      <div>
                        <p className="mb-0">
                          Topic - {row[row.id + "topic"]}, Training Date -{" "}
                          {row[row.id + "training_date"]}
                        </p>
                      </div>
                      <button
                        style={{ outline: "none" }}
                        onClick={() => setShowMembers(false)}
                        className="close"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      {row[row.id + "members"].map((mem) => (
                        <div
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              mem.checked === true ? "#76D6EE" : "#D3D3D3",
                          }}
                          className="d-flex align-items-center justify-content-start col-lg-4 my-2"
                        >
                          <p className="col-lg-4 mb-0">
                            {mem[mem.id + "name"]}
                          </p>
                          <p className=" mb-0 mr-5">-</p>
                          <p className=" mb-0">{mem[mem.id + "role"]}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ))
      ) : (
        <p className="mb-0">please click above to continue ...</p>
      )}
      {/* <button
        onClick={() => navigateECTraining()}
        className="btn btn-sm btn-primary"
      >
        {ec_training && ec_training.length > 0 ? "More ..." : "Add"}
      </button> */}
    </div>
  );
};
export default ECTraining;
