import React from "react";
import { checkForFileExtension } from "../helpers/helper";
import {
  regulatory,
  meeting,
  composition,
  policy,
  training,
  finance,
  Dossier,
  capabilities,
  offive,
  timeline,
  authority,
  contact,
  ecRegisterUser,
  Email,
  ecRegisterPhone,
  displayUser,
  displayEmail,
  displayPhone,
  activeBox,
  ecSize,
  enquiry_file_icon,
} from "../theme/icons";

const CommitteeDetailsModal = (props) => {
  const {
    //committee,
    scom,
    type,
    openCommitteeDetailsModal,
    closeCommitteeDetailsModal,
    showCommitteeDetailsModal,
    handleSelectedSimilarCommittee,
    selectedSimilarCommitee,
  } = props;

  let committee = selectedSimilarCommitee && selectedSimilarCommitee.enriched_information
  let general_committee_details = selectedSimilarCommitee && selectedSimilarCommitee.committee

    // scom && scom.enriched_information;
  
  return (
    <div className="container my-3">
      <p
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => {
          handleSelectedSimilarCommittee(scom)
          openCommitteeDetailsModal();
        }}
      >
        View Info
      </p>
      <div
        style={{
          display: showCommitteeDetailsModal ? "block" : "none",
          overflowY: "scroll",
        }}
        className="modal p-0 px-5"
      >
        <div className="modal-content container">
          <div
            style={{ backgroundColor: "white" }}
            className="modal-header my-0 py-0"
          >
            <button
              style={{ outline: "none" }}
              onClick={() => closeCommitteeDetailsModal()}
              className="close"
            >
              &times;
            </button>
          </div>
          <p>
            {general_committee_details !== undefined ?
              (general_committee_details &&
              general_committee_details.type === "naitik" ? (general_committee_details &&
                general_committee_details.name_of_ethics_committee) : 
                (general_committee_details &&
                  general_committee_details.name_and_address)) 
                  : "None"
            }
            {/* {(committee &&
              committee.ethics_committee &&
              committee.ethics_committee.name_and_address) ||
              (committee &&
                committee.ethics_committee &&
                committee.ethics_committee.name_of_ethics_committee)} */}
          </p>
          <p>
            Committee Type :{" "}
            {general_committee_details !== undefined ? (
              general_committee_details &&
              general_committee_details.type === "reRegistered" &&
              "CDSCO Re-Registered") ||
              (general_committee_details &&
                general_committee_details.type === "Registered" &&
                "CDSCO Registered") ||
              (general_committee_details &&
                general_committee_details.type === "naitik" &&
                "Naitik") : "None"}
          </p>
          <div className="row">
            <div className="col-lg-6">
              <div
                style={{ backgroundColor: "rgba(118, 214, 238, 0.5)" }}
                className="d-flex align-items-center justify-content-start py-1"
              >
                <img
                  className="mx-2"
                  src={regulatory}
                  alt="regulatory icon"
                ></img>
                <h6 className="mx-2 mt-1">Regulatory</h6>
              </div>
              <table
                style={{
                  borderTop: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: 10,
                }}
                className="table"
              >
                <thead>
                  <tr style={{ backgroundColor: "rgba(235, 235, 235, 0.5)" }}>
                    <th scope="col"></th>
                    <th scope="col">Approval Date</th>
                    <th scope="col">Validity Date</th>
                  </tr>
                </thead>
                <tbody>
                {(committee && committee.committee_type === "reRegistered") || (committee && committee.committee_type === "Registered") ? <tr>
                    <th scope="col">
                      EC Registration Number CDSCO:{" "}
                      {committee &&
                        committee.ethics_committee &&
                        committee.ethics_committee.registered_number}
                    </th>
                    <td className="col-lg-3">
                      {(committee && committee.cdsco_approval_date) || "None"}
                    </td>
                    <td className="col-lg-3">
                      {(committee && committee.cdsco_validity_date) || "None"}
                    </td>
                  </tr> : null}
                  {committee && committee.committee_type === "naitik" ? 
                  <tr>
                    <th scope="col">EC Registration Number DHR: {}</th>
                    <td className="col-lg-3">
                      {(committee && committee.dhr_approval_date) || "None"}
                    </td>
                    <td className="col-lg-3">
                      {(committee && committee.dhr_validity_date) || "None"}
                    </td>
                  </tr>
                  : null}
                </tbody>
              </table>
            </div>
            <div className="col-lg-6">
              <div
                style={{ backgroundColor: "rgba(118, 214, 238, 0.5)" }}
                className="d-flex align-items-center justify-content-start py-1"
              >
                <img className="mx-2" src={meeting} alt="meeting icon"></img>
                <h6 className="mx-2 mt-1">EC Meeting</h6>
              </div>
              <div
                style={{
                  borderTop: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="d-flex align-items-center justify-content-between mt-3 px-4">
                  <div>
                    <h6>Next Meeting Date</h6>
                    <p className="mb-0">
                      {committee && committee.next_meeting_date}
                    </p>
                  </div>
                  <div>
                    <h6>How Often We Meet</h6>
                    <p className="mb-0">
                      {committee && committee.how_often_ec_meet}
                    </p>
                  </div>
                </div>
                <hr className="mx-5"></hr>
                <div className="d-flex align-items-center justify-content-between px-4">
                  <div>
                    <h6>EC existing since</h6>
                    <p className="mb-0">
                      {committee && committee.ec_existing_since}
                    </p>
                  </div>
                  <div>
                    <h6>List of holidays</h6>
                    <p className="mb-0">
                      {committee && committee.list_of_holidays}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 px-0">
            <div
              style={{ backgroundColor: "#F2DEDE" }}
              className="d-flex align-items-center justify-content-between py-1"
            >
              <div className="d-flex align-items-center justify-content-center">
                <img
                  className="mx-2"
                  src={composition}
                  alt="composition icon"
                ></img>
                <h6 className="mx-2 mt-1">EC Composition (EC)</h6>
              </div>
              <h6 className="mx-3">
                Members -{" "}
                {committee &&
                committee.ec_composition_EC &&
                committee.ec_composition_EC.length > 0
                  ? committee.ec_composition_EC.length
                  : "None"}
              </h6>
            </div>
            <table
              style={{
                borderTop: 0,
                border: "1px solid rgba(0, 0, 0, 0.2)",
                borderRadius: 10,
              }}
              className="table"
            >
              <thead>
                <tr style={{ backgroundColor: "rgba(235, 235, 235, 0.5)" }}>
                  <th scope="col">S.no</th>
                  <th scope="col">Name</th>
                  <th scope="col">Role</th>
                  <th scope="col">Qualification</th>
                  <th scope="col">Affiliation</th>
                  <th scope="col">Other</th>
                </tr>
              </thead>
              <tbody>
                {committee &&
                committee.ec_composition_EC &&
                committee.ec_composition_EC.length > 0
                  ? committee.ec_composition_EC.map((member, i) => (
                      <tr key={member.id}>
                        <td>{i + 1}</td>
                        <td>{member[member.id + "name"]}</td>
                        <td>{member[member.id + "role"] === "Other" ? member[member.id + "role"] + " - " + member[member.id + "roleName"] : member[member.id + "role"]}</td>
                        <td>{member[member.id + "qualification"]}</td>
                        <td>{member[member.id + "affiliation"]}</td>
                        <td>{member[member.id + "other"]}</td>
                      </tr>
                    ))
                  : "None"}
              </tbody>
            </table>
          </div>
            <div className="col-lg-12 px-0 mb-3">
              <div
                style={{ backgroundColor: "#F2DEDE" }}
                className="d-flex align-items-center justify-content-start py-1"
              >
                <img className="mx-2" src={policy} alt="policy icon"></img>
                <h6 className="mx-2 mt-1">EC Policy</h6>
              </div>
              <div className="col-lg-12 px-0">
              <table
                style={{
                  borderTop: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: 10,
                }}
                className="table px-0"
              >
                <thead>
                  <tr style={{ backgroundColor: "rgba(235, 235, 235, 0.5)" }}>
                    <th scope="col">S.no</th>
                    <th scope="col-lg-2 px-0">Title</th>
                    <th scope="col-lg-1 px-0">Effective Date</th>
                    <th scope="col-lg-1 px-0">Validity Date</th>
                    <th scope="col-lg-4 px-0">Description</th>
                    <th scope="col-lg-2 px-0">Attachments</th>
                  </tr>
                </thead>
                <tbody>
                  {committee &&
                  committee.ec_policy &&
                  committee.ec_policy.length > 0
                    ? committee.ec_policy.map((policy, i) => (
                        <tr className="my-2">
                          <td className="col-1">{i + 1}</td>
                          <td className="col-lg-2 px-2 pl-3">
                            {policy[policy.id + "title"]}
                          </td>
                          <td className="col-lg-1 px-2 pl-3">
                            {policy[policy.id + "effective_date"]}
                          </td>
                          <td className="col-lg-1 px-2 pl-3">
                            {policy[policy.id + "validity_date"]}
                          </td>
                          <td className="col-lg-4 px-2 pl-3">
                            {policy[policy.id + "description"]}
                          </td>
                          <td className="col-lg-2 px-2 pl-3">
                            <div className="">
                              {policy[policy.id + "attachments"] &&
                              policy[policy.id + "attachments"].length > 0
                                ? policy[policy.id + "attachments"].map(
                                    (url) => (
                                      <div className="d-flex align-items-center justify-content-start">
                                        <a
                                          className="mx-2"
                                          rel="noreferrer"
                                          href={url}
                                          target="_blank"
                                        >
                                          <img
                                            className="p-1"
                                            style={{
                                              height: 35,
                                              width: 40,
                                              border: "1.5px solid dimgrey",
                                              borderRadius: 10,
                                            }}
                                            src={
                                              checkForFileExtension(url) ===
                                              true
                                                ? url
                                                : enquiry_file_icon
                                            }
                                            alt="uploaded file"
                                          ></img>
                                        </a>
                                      </div>
                                    )
                                  )
                                : "No file chosen"}
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>            </div>
          <div className="col-lg-12 px-0">
            <div
              style={{ backgroundColor: "rgba(118, 214, 238, 0.5)" }}
              className="d-flex align-items-center justify-content-between py-1"
            >
              <div className="d-flex align-items-center justify-content-center">
                <img
                  className="mx-2"
                  src={training}
                  alt="composition icon"
                ></img>
                <h6 className="mx-2 mt-1">EC Training</h6>
              </div>
              {/* <h6 className="mx-3">
                Members -{" "}
                {committee &&
                committee.ec_training &&
                committee.ec_training.length > 0
                  ? committee.ec_training.length
                  : "None"}
              </h6> */}
            </div>
            <table
              style={{
                borderTop: 0,
                border: "1px solid rgba(0, 0, 0, 0.2)",
                borderRadius: 10,
              }}
              className="table"
            >
              <thead>
                <tr style={{ backgroundColor: "rgba(235, 235, 235, 0.5)" }}>
                  <th scope="col">S.no</th>
                  <th scope="col">Topic</th>
                  <th scope="col">Regulatory Training Date</th>
                  <th scope="col">Members Attended</th>
                </tr>
              </thead>
              <tbody>
                {committee &&
                committee.ec_training &&
                committee.ec_training.length > 0
                  ? committee.ec_training.map((member, i) => (
                      <tr key={member.id}>
                        <td>{i + 1}</td>
                        <td>{member[member.id + "topic"] === "Other" ? member[member.id + "topic"] + " - " + member[member.id + "topicName"] : member[member.id + "topic"]}</td>
                        <td>{member[member.id + "training_date"]}</td>
                        <td>
                          {member[member.id + "members"] &&
                          member[member.id + "members"].length > 0
                            ? member[member.id + "members"]
                                .filter((member) => member.checked === true)
                                .map(
                                  (member) => member[member.id + "name"] + ","
                                )
                            : "None"}
                        </td>
                      </tr>
                    ))
                  : "None"}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div
                style={{ backgroundColor: "#F2DEDE" }}
                className="d-flex align-items-center justify-content-start py-1"
              >
                <img className="mx-2" src={Dossier} alt="dossier icon"></img>
                <h6 className="mx-2 mt-1">Submission of Dossier Process</h6>
              </div>
              <div
                style={{
                  borderTop: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                }}
                className="px-3"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h6>Dossier Process</h6>
                    <p className="mb-0 col-lg-11">
                      Process of EC Submission :{" "}
                      <span style={{ fontSize: 15 }}>
                        {committee && committee.process_of_ec_submission}
                      </span>
                    </p>
                  </div>
                  <div>
                    <h6>Submission Process</h6>
                    <p className="mb-0">
                      Paper based :{" "}
                      <span>
                        {committee &&
                          committee.submission_process_paper_based_copies &&
                          committee.submission_process_paper_based_copies
                            .length}
                      </span>
                    </p>
                  </div>
                </div>
                <hr></hr>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h6>Review Process</h6>
                    <p className="mb-0">Review Checklist Process</p>
                    <p className="mb-0">None</p>
                  </div>
                  <div>
                    <p>EC Forms to be completed along with the submission</p>
                    <h6 className="mb-0">None</h6>
                  </div>
                </div>
                <hr></hr>
                <div>
                  <p>Process of SAE Review</p>
                  <h6>
                    {committee &&
                    committee.process_for_sae_review &&
                    committee.process_for_sae_review.length > 0
                      ? committee.process_for_sae_review
                      : "None"}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                style={{ backgroundColor: "rgba(118, 214, 238, 0.5)" }}
                className="d-flex align-items-center justify-content-start py-1"
              >
                <img className="mx-2" src={finance} alt="finance icon"></img>
                <h6 className="mx-2 mt-1">Finance</h6>
              </div>
              <div
                style={{
                  borderTop: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* <div className="d-flex align-items-center justify-content-end">
              <p style={{ backgroundColor: "#8CD2A9" }} className="mb-0 p-1">
                Review Fees: 1452
              </p>
            </div> */}
                <div className="px-3">
                  {/* <div>
                    <p className="mb-0">Account Detail of EC: </p>
                    <p className="mb-0">Process of Payment: </p>
                  </div> */}
                  <div className="border-bottom">
                    <h6>Review Fees</h6>
                    <p className="mb-0">
                      Initial :{" "}
                      {committee && committee.ec_initial_review_fees.length > 0
                        ? committee.ec_initial_review_fees
                        : "N/A"}
                    </p>
                    <p className="mb-0">
                      Amendment :{" "}
                      {committee &&
                      committee.ec_amendment_review_fees.length > 0
                        ? committee.ec_amendment_review_fees
                        : "N/A"}
                    </p>
                    <p className="mb-0">
                      Final Review :{" "}
                      {committee && committee.ec_final_review_process.length > 0
                        ? committee.ec_final_review_process
                        : "N/A"}
                    </p>
                  </div>
                  <div className="">
                    <h6>Review Fees</h6>
                    <p className="mb-0">
                      Payee Name :{" "}
                      {committee && committee.payee_name.length > 0
                        ? committee.payee_name
                        : "N/A"}
                    </p>
                    <p className="mb-0">
                      Payment Mode :{" "}
                      {committee && committee.mode_of_payment.length > 0
                        ? committee.mode_of_payment
                        : "N/A"}
                    </p>
                    <p className="mb-0">
                      GST :{" "}
                      {committee && committee.ec_gst.length > 0
                        ? committee.ec_gst
                        : "N/A"}
                    </p>
                    <p className="mb-0">
                      TDS :{" "}
                      {committee && committee.ec_tds.length > 0
                        ? committee.ec_tds
                        : "N/A"}
                    </p>
                  </div>
                  <hr className="px-5"></hr>
                  <div>
                    <h6>Process</h6>
                    <p className="mb-0">
                      {committee &&
                      committee.finance &&
                      committee.finance.length > 0
                        ? committee.finance
                        : "None"}
                    </p>
                    {/* <p className="mb-0">
                  Any agreement required with client? :{" "}
                  <span style={{ fontStyle: "bold" }}>Yes</span>
                </p> */}
                  </div>
                  {/* <p className="text-center"> uploaded files coming soon ...</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              {/* <div className="my-2">
              <div
                style={{ backgroundColor: "#F2DEDE" }}
                className="d-flex align-items-center justify-content-start py-1"
              >
                <img
                  className="mx-2"
                  src={capabilities}
                  alt="capabilities icon"
                ></img>
                <h6 className="mx-2 mt-1">Capabilities</h6>
              </div>
              <div
                style={{
                  borderTop: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                }}
                className="px-3"
              >
                <p className="mb-0">Frequency of EC Site Monitoring Visit</p>
                <h6>
                  {committee &&
                  committee.frequency_of_ec_site_monitoring &&
                  committee.frequency_of_ec_site_monitoring.length > 0
                    ? committee.frequency_of_ec_site_monitoring
                    : "None"}
                </h6>
              </div>
            </div> */}
              <div className="my-2">
                <div
                  style={{ backgroundColor: "#F2DEDE" }}
                  className="d-flex align-items-center justify-content-start py-1"
                >
                  <img className="mx-2" src={offive} alt="office icon"></img>
                  <h6 className="mx-2 mt-1">EC Office</h6>
                </div>
                <div
                  style={{
                    borderTop: 0,
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                  className="px-3"
                >
                  <div>
                    <p className="mb-0">Document Storage Facility</p>
                    <h6>
                      {committee &&
                      committee.document_storage_facility &&
                      committee.document_storage_facility.length > 0
                        ? committee.document_storage_facility
                        : "None"}
                    </h6>
                  </div>
                  <hr></hr>
                  <ul className="pl-4">
                    {[
                      { title: "Emergency Alarm", value: "emergency_alarm" },
                      { title: "Fire Extingusher", value: "fire_extingusher" },
                    ].map((item) => (
                      <li key={item.title}>
                        {item && item.title && item.title.length > 0
                          ? item.title
                          : "None"}
                        <span>
                          {committee && committee[item.value] === "true" ? (
                            <img
                              style={{ height: 20, width: 20 }}
                              className="mx-4"
                              src={activeBox}
                              alt={"right icon"}
                            ></img>
                          ) : null}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="my-2">
                <div
                  style={{ backgroundColor: "rgba(118, 214, 238, 0.5)" }}
                  className="d-flex align-items-center justify-content-start py-1"
                >
                  <img
                    className="mx-2"
                    src={timeline}
                    alt="timeline icon"
                  ></img>
                  <h6 className="mx-2 mt-1">Timeline</h6>
                </div>
                <div
                  style={{
                    borderTop: 0,
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                  className="px-3"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0">
                        Frequency of EC Site monitoring visit
                      </p>
                      <h6>
                        {committee &&
                        committee.frequency_of_ec_site_monitoring &&
                        committee.frequency_of_ec_site_monitoring.length > 0
                          ? committee.frequency_of_ec_site_monitoring
                          : "None"}
                      </h6>
                    </div>
                    <div>
                      <p className="mb-0">
                        Approval for EC & Scientific Timeline
                      </p>
                      <h6>
                        {committee &&
                        committee.approval_for_ec_and_scientific_timeline &&
                        committee.approval_for_ec_and_scientific_timeline
                          .length > 0
                          ? committee.approval_for_ec_and_scientific_timeline
                          : "None"}
                      </h6>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0">
                        {committee &&
                        committee.timeline_for_expedite_review_and_approval &&
                        committee.timeline_for_expedite_review_and_approval
                          .length > 0
                          ? committee.timeline_for_expedite_review_and_approval
                          : "None"}
                      </p>
                      <h6>3/2/2021</h6>
                    </div>
                    <div>
                      <p className="mb-0">Interim progress report timeline</p>
                      <h6>
                        {committee &&
                        committee.interim_progress_report_timeline &&
                        committee.interim_progress_report_timeline.length > 0
                          ? committee.interim_progress_report_timeline
                          : "None"}
                      </h6>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0">
                        How many days prior dossier submitted to ethics
                        committee?
                      </p>
                      <h6 className="mb-0">
                        {committee &&
                        committee.ec_days_prior_dossier_submission &&
                        committee.ec_days_prior_dossier_submission.length > 0
                          ? committee.ec_days_prior_dossier_submission
                          : "None"}
                      </h6>
                    </div>
                    <div>
                      <p className="mb-0">
                        How long EC will take to issue approval letter post
                        meeting?
                      </p>
                      <h6>
                        {committee &&
                        committee.ec_issue_approval_post_meeting &&
                        committee.ec_issue_approval_post_meeting.length > 0
                          ? committee.ec_issue_approval_post_meeting
                          : "None"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-2">
                <div
                  style={{ backgroundColor: "rgba(118, 214, 238, 0.5)" }}
                  className="d-flex align-items-center justify-content-start py-1"
                >
                  <img
                    className="mx-2"
                    src={authority}
                    alt="authority icon"
                  ></img>
                  <h6 className="mx-2 mt-1">Authority Of Ethics Committee</h6>
                </div>
                <div
                  style={{
                    borderTop: 0,
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                  className="px-3"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0">Name and address of the Parent Institute</p>
                      <h6>
                        {committee &&
                        committee.parent_institute_name &&
                        committee.parent_institute_name.length > 0
                          ? committee.parent_institute_name
                          : "None"}
                      </h6>
                    </div>
                    <div>
                      <p className="mb-0">Registration Number</p>
                      <h6>
                        {committee &&
                        committee.parent_registration_number &&
                        committee.parent_registration_number.length > 0
                          ? committee.parent_registration_number
                          : "None"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 px-0 mb-3">
            <div
              style={{ backgroundColor: "#F2DEDE" }}
              className="d-flex align-items-center justify-content-start py-1"
            >
              <img className="mx-2" src={contact} alt="contact icon"></img>
              <h6 className="mx-2 mt-1">Contact Details</h6>
            </div>
            <div
              style={{ borderTop: 0, border: "1px solid rgba(0, 0, 0, 0.2)" }}
            >
              <p
                style={{ backgroundColor: "rgba(235, 235, 235, 0.5)" }}
                className="text-right p-1 mb-0 px-3"
              >
                Committee Pincode:{" "}
                {committee && committee.pincode && committee.pincode.length > 0
                  ? committee.pincode
                  : "None"}
              </p>
              <div className="row px-3">
                <div className="col-lg-6 border-right">
                  <p>Primary Contact Person</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        style={{ height: 20, width: 20 }}
                        className="mx-1"
                        src={displayUser}
                        alt={"user icon"}
                      ></img>
                      <p className="mb-0">
                        {committee &&
                        committee.primary_contact &&
                        committee.primary_contact.name &&
                        committee.primary_contact.name.length > 0
                          ? committee.primary_contact.name
                          : "None"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        style={{ height: 20, width: 20 }}
                        className="mx-1"
                        src={displayEmail}
                        alt={"email icon"}
                      ></img>
                      <p className="mb-0">
                        {committee &&
                        committee.primary_contact &&
                        committee.primary_contact.email &&
                        committee.primary_contact.email.length > 0
                          ? committee.primary_contact.email
                          : "None"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        style={{ height: 20, width: 20 }}
                        className="mx-1"
                        src={displayPhone}
                        alt={"phone icon"}
                      ></img>
                      <p className="mb-0">
                        {committee &&
                        committee.primary_contact &&
                        committee.primary_contact.contact_number &&
                        committee.primary_contact.contact_number.length > 0
                          ? committee.primary_contact.contact_number
                          : "None"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p>Alernative Contact Person</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        style={{ height: 20, width: 20 }}
                        className="mx-1"
                        src={displayUser}
                        alt={"user icon"}
                      ></img>
                      <p className="mb-0">
                        {committee &&
                        committee.alternative_contact &&
                        committee.alternative_contact.name &&
                        committee.alternative_contact.name.length > 0
                          ? committee.alternative_contact.name
                          : "None"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        style={{ height: 20, width: 20 }}
                        className="mx-1"
                        src={displayEmail}
                        alt={"email icon"}
                      ></img>
                      <p className="mb-0">
                        {committee &&
                        committee.alternative_contact &&
                        committee.alternative_contact.email &&
                        committee.alternative_contact.email.length > 0
                          ? committee.alternative_contact.email
                          : "None"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        style={{ height: 20, width: 20 }}
                        className="mx-1"
                        src={displayPhone}
                        alt={"phone icon"}
                      ></img>
                      <p className="mb-0">
                        {committee &&
                        committee.alternative_contact &&
                        committee.alternative_contact.contact_number &&
                        committee.alternative_contact.contact_number.length > 0
                          ? committee.alternative_contact.contact_number
                          : "None"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommitteeDetailsModal;
