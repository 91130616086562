import React from "react";
import moment from "moment";
import { imageAttachment } from "../../theme/icons";
import { getRandomColor } from "../../helpers/helper";

const EnquiryCard = ({ user, enquiry, goToDetail, getUserName }) => {
  return (
    <div
      onClick={() => goToDetail(enquiry && enquiry._id)}
      style={{ cursor: "pointer" }}
      className="enquiry_card px-4 my-3 py-2"
    >
      <div className="d-flex align-items-center justify-content-end">
        {/* <h6>
          {enquiry && enquiry._id && enquiry._id.length > 0
            ? enquiry._id
            : "None"}
        </h6> */}
        <div className="col-lg-7 d-flex align-items-center justify-content-between">
          <p className="mb-0 time">
            Status -{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {(enquiry && enquiry.status) || "None"}
            </span>
          </p>
          <p className="mb-0 time">
            Priority -{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {(enquiry && enquiry.priority) || "None"}
            </span>
          </p>
          <p className="mb-0 time">
            {enquiry && enquiry.updatedAt && enquiry.updatedAt.length > 0
              ? moment(enquiry.updatedAt).format("YYYY-MM-DD HH:MM:SS")
              : "None"}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <div
              style={{
                height: 60,
                width: 60,
                borderRadius: 30,
                backgroundColor: getRandomColor(),
                color: "white",
              }}
              className="d-flex align-items-center justify-content-center mx-2"
            >
              <h4 style={{ fontSize: 20 }} className="mt-1">
                {enquiry.title.toUpperCase().charAt("0")}
              </h4>
            </div>
            <div>
              <h3 className="mx-2">
                {enquiry && enquiry.title && enquiry.title.length > 0
                  ? enquiry.title && enquiry.title.length > 30
                    ? enquiry.title.slice(0, 30) + "..."
                    : enquiry.title
                  : "None"}
              </h3>
              {user && user.role === "ec_coordinator" ? (
                <p className="by_whom mb-0">
                  Raised by{" "}
                  <span
                    style={{ fontWeight: "bold", textTransform: "capitalize" }}
                  >
                    {(enquiry &&
                      enquiry.raised_by &&
                      enquiry.raised_by.username) ||
                      "None"}
                  </span>
                </p>
              ) : (
                <p className="by_whom mb-0">
                  Assigned to{" "}
                  <span
                    style={{ fontWeight: "bold", textTransform: "capitalize" }}
                  >
                    {(enquiry &&
                      enquiry.assignee &&
                      enquiry.assignee.username) ||
                      "None"}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          {/* <img
            style={{ height: 50, width: 50 }}
            className="img-responsive"
            src={imageAttachment}
            alt="attachment"
          ></img> */}
        </div>
      </div>
    </div>
  );
};

export default EnquiryCard;
