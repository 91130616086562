import React from "react";
import { getCommitteeDetails } from "../../helpers/api";
import { closeIcon, noEnquiry } from "../../theme/icons";

const NewEnquiry = (props) => {
  const {
    user,
    ethics_committees,
    priority,
    title,
    file,
    fileName,
    fileType,
    description,
    handleNewEnquiryChange,
    createNewEnquiry,
    removeSelectedEthicsCommittee,
    handleAttachmentChange,
    related_ec_type,
    backToEnquiries,
  } = props;

  return (
    <div className="new_enquiry">
      <p
        style={{
          color: "#BC5405",
          cursor: "pointer",
          fontWeight: "bolder",
          textTransform: "uppercase",
        }}
        className="mb-0 col-lg-1 mx-0 px-0 mt-2"
        onClick={() => backToEnquiries()}
      >
        Back
      </p>

        <div className="col-lg-12 px-0">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h4>New Enquiry</h4>
              <p className="mb-0">Raise an enquiry to the ethics committee</p>
            </div>
            <div>
              <h6>Priority</h6>
              <select
                onChange={handleNewEnquiryChange}
                name="priority"
                value={priority}
                type="text"
                className="form-control"
              >
                <option value="">Please Select</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </div>
          </div>
          {/* <div className="my-3">
            <h6>Ticket Number</h6>
            <input
              type="text"
              className="form-control col-lg-2"
              name="ticket_number"
              value={"TK005"}
              disabled={true}
            ></input>
          </div> */}
          <div className="my-4">
            <p>EC Name and Registration Number</p>
            <div className="d-flex border border-dark py-2">
              {related_ec_type && related_ec_type.length > 0
                ? related_ec_type.map((ethics_committee) => (
                    <div
                      style={{ border: "1px solid grey", borderRadius: 15 }}
                      className="d-flex align-items-center justify-content-center mx-3"
                    >
                      <p className="mb-0 mx-3">
                        {ethics_committee && ethics_committee.registered_number}
                      </p>
                      <img
                        onClick={() =>
                          removeSelectedEthicsCommittee(
                            ethics_committee && ethics_committee.id
                          )
                        }
                        style={{ height: 15, width: 15, cursor: "pointer" }}
                        src={closeIcon}
                        alt="close_icon"
                      ></img>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div>
            <div className="my-3">
              <p className="mb-0">Title</p>
              <input
                name="title"
                value={title}
                type="text"
                onChange={handleNewEnquiryChange}
                placeholder="Title of enquiry"
                className="form-control input-lg"
              ></input>
            </div>
            <div className="my-3">
              <p className="mb-0">Description</p>
              <textarea
                name="description"
                value={description}
                type="text"
                onChange={handleNewEnquiryChange}
                placeholder="Please enter description"
                rows={3}
                className="form-control input-lg"
              ></textarea>
            </div>
            <div>
              <input
                onChange={handleAttachmentChange}
                accept=".png, .jpg, .pdf, .xlsx, .ods, .docx, .txt"
                type="file"
                name="file"
              ></input>
            </div>
          </div>
          <div className="text-right">
            <button
              onClick={() => createNewEnquiry()}
              className="btn btn-sm login"
            >
              SUBMIT
            </button>
          </div>
        </div>
    </div>
  );
};

export default NewEnquiry;
