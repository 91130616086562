import moment from "moment";
import React from "react";
import EnquiryCard from "../../components/enquiry/EnquiryCard";
import NewEnquiry from "../../components/enquiry/NewEnquiry";
import NoEnquiry from "../../components/enquiry/NoEnquiry";
import NotFound from "../../components/NotFound";
import {
  createEnquiry,
  createReply,
  createUpdate,
  deleteAttachment,
  getEnquiries,
  getReply,
  getUsers,
  getUserWithId,
  updateEnquiry,
  uploadAttachment,
} from "../../helpers/api";
import {
  displayEmail,
  ecSize,
  enquiryDelete,
  enquiryEdit,
  filter,
  imageAttachment,
  preview,
  enquiry_file_icon,
} from "../../theme/icons";
import { withRouter } from "react-router-dom";
import {
  getAllCommittees,
  getUserName,
  checkForFileExtension,
  getFileName,
  getRandomID,
  getCurrentUser,
  addUnRead,
  sendUpdate
} from "../../helpers/helper";
import { environment } from "../../keys";

class EnquiryDetail extends React.Component {
  constructor(props) {
    super();
    this.state = {
      enquiry: "",
      adminUser: "",
      id: "",
      status: "",
      priority: "",
      title: "",
      description: "",
      changeTitle: false,
      changeDescription: false,
      file: "",
      fileName: "",
      fileUrl: "",
      reply: "",
      related_committees: [],
      isLoading: false,
      showAssignees: false,
      coordinators: [],
      selected_coordinator: "",
      isEnquiryResponded: false,
      showAlert: false,
    };
  }

  changeAssignee = () => {
    this.setState(
      {
        showAssignees: true,
      },
      () => {
        this.getCoordinatorUsers();
      }
    );
  };

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachmentChange = (e) => {
    let file = e.target.files[0];
    let user_file_name = e.target.files[0].name;
    let randomNumber = getRandomID();
    let fileName = `${this.props.user.email}-${this.props.user._id}/${environment}/enquiry/${randomNumber}/${user_file_name}`;
    let fileType = e.target.files[0].type;
    console.log(file, fileType, e.target.files[0].name, "file details");
    this.setState({
      file: file,
      fileName: fileName,
      fileType: fileType,
    });
  };

  upload = (file, fileName, fileType) => {
    uploadAttachment(file, fileName, fileType)
      .then((success) => {
        console.log(success, "uploaded");
        let enquiry_attachments =
          this.state.enquiry && this.state.enquiry.attachments;
        console.log(enquiry_attachments, " enq attachments");
        this.setState({ fileUrl: success && success.Location }, () => {
          let files = [this.state.fileUrl, ...enquiry_attachments];
          console.log(files, "after adding file");
          let update = {
            active: false,
            title: `Attachment added to enquiry by ${this.props.user.username}`,
            update: { type: "enquiry", item: this.state.enquiry._id },
            description: `Attachment added`,
            from: [getCurrentUser(this.props.user)],
            to: [
              this.props.user.role === "ec_qcr_admin"
                ? this.state.enquiry.raised_by
                : this.state.adminUser,
              this.state.enquiry.assignee,
            ],
            isRead: false,
          }
          sendUpdate(update)
          updateEnquiry(this.state.enquiry && this.state.enquiry._id, {
            attachments: files,
          })
            .then((success) => {
              console.log(success, "file updated to enquiry");
              alert(this.state.fileUrl);
              this.fetchEnquiry(this.state.enquiry._id);
            })
            .catch((failure) => {
              console.log(failure, "err updating file to enquiry");
              alert("Error updating file to enquiry");
              this.fetchEnquiry(this.state.enquiry._id);
            });
        });
      })
      .catch((failure) => {
        console.log(failure, "upload failed");
        alert("Error updating file to enquiry");
        this.fetchEnquiry(this.state.enquiry._id);
      });
  };

  deleteFile = (url) => {
    if (url && url.length > 0) {
      let fileName = getFileName(url);
      if (fileName === null) {
        console.log(fileName, "err with getting file name");
      } else {
        deleteAttachment(fileName)
          .then((success) => {
            console.log(success, "file deleted successfully");
            let enquiry_attachments =
              this.state.enquiry && this.state.enquiry.attachments;
            enquiry_attachments.splice(
              enquiry_attachments.filter((file) => file === url) &&
                enquiry_attachments.filter((file) => file === url)[0],
              1
            );
            console.log(enquiry_attachments, "after removing url");
            let update = {
              active: false,
              title: `Attachment removed by ${this.state.adminUser.user_name}`,
              update: { type: "enquiry", item: this.state.enquiry._id },
              description: `Attachment removed`,
              from: [getCurrentUser(this.props.user)],
              to: [
                this.props.user.role === "ec_qcr_admin"
                  ? this.state.enquiry.raised_by
                  : this.state.adminUser,
                this.state.enquiry.assignee,
              ],
              isRead: false,
            }
            sendUpdate(update)
            updateEnquiry(this.state.enquiry._id, {
              attachments: enquiry_attachments,
            })
              .then((enquiryUpdate) => {
                console.log(enquiryUpdate, "update enquiry successfully");
                alert("Enquiry updated successfully");
                this.fetchEnquiry(this.state.id);
              })
              .catch((errUpdating) => {
                console.log(
                  errUpdating,
                  "error updating the enquiry while removing url"
                );
                alert("Error updating the attachment for the enquiry");
                this.fetchEnquiry(this.state.id);
              });
          })
          .catch((failure) => {
            console.log(failure, "err with deleting file");
            alert("Error with deleting the file");
          });
      }
    } else {
      console.log(url, "file url not found");
    }
  };

  selectCoordinator = (user) => {
    this.setState({ selected_coordinator: user }, () => {
      updateEnquiry(this.state.enquiry._id, {
        assignee: this.state.selected_coordinator,
      })
        .then((updated) => {
          console.log(updated, "enquiry updated");
          this.setState(
            { showAssignees: false, selected_coordinator: "" },
            () => {
              this.fetchEnquiry(this.state.enquiry._id);
              let update = {
                active: false,
                title: `Enquiry assignee changed by ${this.state.adminUser.user_name}`,
                update: { type: "enquiry", item: this.state.enquiry._id },
                description: `Assignee changed to ${this.state.enquiry.assignee}`,
                from: [getCurrentUser(this.props.user)],
                to: [
                  this.props.user.role === "ec_qcr_admin"
                    ? this.state.enquiry.raised_by
                    : this.state.adminUser,
                  this.state.enquiry.assignee,
                ],
                isRead: false,
              }
              console.log(update , 'checking update')
              sendUpdate(update)
            }
          );
        })
        .catch((errUpdated) => {
          console.log(errUpdated, "err updating enquiry");
          alert("Error updating enquiry assignee");
          this.setState({ showAssignees: false, selected_coordinator: "" });
        });
    });
  };

  fetchEnquiry(id) {
    getEnquiries(id)
      .then((enquiryFound) => {
        console.log(enquiryFound, "found enquiry");
        this.setState(
          {
            enquiry: enquiryFound,
            status: enquiryFound.status,
            priority: enquiryFound.priority,
            title: enquiryFound.title,
            description : enquiryFound.description
          },
          () => {
            //this.fetchReplies(this.state.enquiry && this.state.enquiry.replies);
            this.setState({ isEnquiryResponded: this.checkEnquiryResponse() });
            let relatedecs = [
              ...this.state.enquiry.naitik_ethics_committees,
              ...this.state.enquiry.cdsco_registered_ethics_committees,
              ...this.state.enquiry.cdsco_reregistered_ethics_committees,
            ];
            this.setState({ related_committees: relatedecs });
          }
        );
      })
      .catch((errEnquiryFound) => {
        console.log(errEnquiryFound, "err with fetching enquiry");
        this.setState({ enquiry: null });
      });
  }

  getEnquiryRelatedCommittees = () => {
    let enquiry_related_committees = [];
    getAllCommittees()
      .then((committees) => {
        console.log(committees, "all coms");
        if (committees && committees.length > 0) {
          let enquiryComms =
            this.state.enquiry &&
            this.state.enquiry.ethics_committees &&
            this.state.enquiry.ethics_committees.length > 0
              ? this.state.enquiry.ethics_committees
              : [];
          if (enquiryComms && enquiryComms.length > 0) {
            enquiryComms.map((ecom) => {
              console.log(ecom, "ecom");
              let relatedCom = committees.filter(
                (com) => com.registered_number === ecom
              );
              console.log(relatedCom, "related com");
              enquiry_related_committees.push(relatedCom);
            });
            this.setState({
              related_committees: enquiry_related_committees,
            });
          }
        } else {
          console.log("No committees found");
        }
      })
      .catch((errCommittees) => {
        console.log(errCommittees, "error with fetching all comms");
      });
  };

  // fetchReplies = (ids) => {
  //   let replies = [];
  //   if (ids && ids.length > 0) {
  //     ids.map((id) => {
  //       return getReply(id)
  //         .then((gotReply) => {
  //           console.log(gotReply, "got reply");
  //           replies.push(gotReply);
  //         })
  //         .catch((errReply) => {
  //           console.log(errReply, "err reply");
  //         });
  //     });
  //     this.setState({ enquiryReplies: replies, isLoading: true });
  //   }
  // };

  handleChange = (e) => {
    this.setState({ status: e.target.value }, () => {
      updateEnquiry(this.state.enquiry._id, { status: this.state.status })
        .then((updated) => {
          console.log(updated, "enquiry updated");
          let update = {
            active: false,
            title: `Enquiry status changed by ${this.props.user.username}`,
            update: { 
              type: "enquiry_responded",
              status : updated.status,
              assignee_name : this.state.enquiry.assignee.username,
              assignee_email : this.state.enquiry.assignee.user_email,
              researcher_name : this.state.enquiry.raised_by.username,
              researcher_email : this.state.enquiry.raised_by.user_email,
              enquiry_id: this.state.enquiry._id 
            },
            description: `Status : ${this.state.status}`,
            from: [getCurrentUser(this.props.user)],
            // to: [
            //   this.props.user.role === "ec_qcr_admin"
            //     ? this.state.enquiry.raised_by
            //     : this.state.adminUser,
            //     this.state.enquiry.assignee,
            // ],
            to : [
              this.state.enquiry.raised_by,
              this.state.adminUser,
              this.state.enquiry.assignee
            ],
            isRead: false,
          }
          console.log(update , 'checking update')
          sendUpdate(update)
          alert("Enquiry updated successfully");
          this.fetchEnquiry(this.state.enquiry._id);
        })
        .catch((errUpdated) => {
          console.log(errUpdated, "err updating enquiry");
          alert("Error with updating enquiry");
        });
    });
  };

  handleReplyChange = (e) => {
    this.setState({ reply: e.target.value });
  };

  handlePriorityChange = (e) => {
    this.setState({ priority: e.target.value }, () => {
      updateEnquiry(this.state.enquiry._id, { priority: this.state.priority })
        .then((updated) => {
          console.log(updated, "enquiry updated");
          let update = {
            active: false,
            title: `Enquiry priority changed by ${this.props.user.username}`,
            update: { type: "enquiry", item: this.state.enquiry._id },
            description: `Priority : ${this.state.priority}`,
            from: [getCurrentUser(this.props.user)],
            to: [
              this.props.user.role === "ec_qcr_admin"
                ? addUnRead(this.state.enquiry.raised_by)
                : addUnRead(this.state.adminUser),
                addUnRead(this.state.enquiry.assignee),
            ],
            isRead: false,
          }
          sendUpdate(update)
          alert("Enquiry updated successfully");
          this.fetchEnquiry(this.state.enquiry._id);
        })
        .catch((errUpdated) => {
          console.log(errUpdated, "err updating enquiry");
          alert("Error with updating enquiry");
        });
    });
  };

  updateText=()=>{
    updateEnquiry(this.state.enquiry._id, { title: this.state.title, description : this.state.description })
        .then((updated) => {
          console.log(updated, "enquiry updated");
          //alert("Enquiry updated successfully");
          let update = {
            active: false,
            title: `Enquiry details changed by ${this.props.user.username}`,
            update: { type: "enquiry", item: this.state.enquiry._id },
            description: `Details : ${this.state.enquiry.title, this.state.enquiry.description}`,
            from: [getCurrentUser(this.props.user)],
            to: [
              this.props.user.role === "ec_qcr_admin"
                ? addUnRead(this.state.enquiry.raised_by)
                : addUnRead(this.state.adminUser),
                addUnRead(this.state.enquiry.assignee),
            ],
            isRead: false,
          }
          sendUpdate(update)
          this.setState({ changeTitle: false, changeDescription: false })
          this.fetchEnquiry(this.state.enquiry._id);
        })
        .catch((errUpdated) => {
          console.log(errUpdated, "err updating enquiry");
          alert("Error with updating enquiry");
        });
  }

  componentDidMount() {
    console.log(this.props, "props");
    this.setState({ id: this.props.match.params.id }, () => {
      this.fetchEnquiry(this.state.id);
      this.getCoordinatorUsers();
    });
  }

  onReply = () => {
    let { reply, status } = this.state;
    let req_body = {
      reply: reply,
      status: status,
      enquiry: this.state.enquiry._id,
      replied_by: {
        user_id: this.props.user && this.props.user._id,
        username: this.props.user && this.props.user.username,
        user_email: this.props.user && this.props.user.email,
        user_phone: this.props.user && this.props.user.phone,
        user_role: this.props.user && this.props.user.role,
      },
    };
    createReply(req_body)
      .then((created) => {
        console.log(created.output, "reply created");
        this.setState({ reply: "" });
        let update = {
          active: false,
          title: `Enquiry replied by ${created.output.replied_by.username}`,
          update: { 
            type: "enquiry_replied", 
            assignee_name : this.state.enquiry.assignee,
            researcher_name : this.state.enquiry.raised_by.username,
            enquiry_id: this.state.enquiry._id },
          description: `Reply : ${created.output.reply}`,
          from: [getCurrentUser(this.props.user)],
          to: [
            // this.props.user.role === "ec_qcr_admin"
             // ? 
              getCurrentUser(this.state.enquiry.raised_by),
              //:
              getCurrentUser(this.state.adminUser),
              getCurrentUser(this.state.enquiry.assignee),
          ],
          isRead: false,
        };
        sendUpdate(update);
        this.fetchEnquiry(this.state.id);
      })
      .catch((errCreated) => {
        console.log(errCreated, "err creating reply");
        this.setState({ reply: "" });
        this.fetchEnquiry(this.state.id);
      });
  };

  getCoordinatorUsers = () => {
    getUsers("all").then((all) => {
      console.log(all, "all users");
      if (all && all.length > 0) {
        let admin =
          all.filter((user) => user.role === "ec_qcr_admin") &&
          all.filter((user) => user.role === "ec_qcr_admin")[0];
        this.setState({
          adminUser: {
            user_id: admin._id,
            user_email: admin.email,
            user_name: admin.username,
            user_role: admin.role,
          },
        });
        let coordinators = all.filter((user) => user.role === "ec_coordinator");
        if (coordinators && coordinators.length > 0) {
          this.setState({
            coordinators:
              coordinators &&
              coordinators.map((coordinator) => {
                return {
                  user_id: coordinator && coordinator._id,
                  username: coordinator && coordinator.username,
                  user_email: coordinator && coordinator.email,
                  user_role: coordinator && coordinator.role,
                  user_phone: coordinator && coordinator.phone,
                  user_committee:
                    coordinator &&
                    coordinator.others &&
                    coordinator.others[0] &&
                    coordinator.others[0].ethics_committee,
                };
              }),
          });
        } else {
          this.setState({ coordinators: [] });
        }
      } else {
        console.log(all, "No users found");
        this.setState({ coordinators: [] });
      }
    });
  };

  checkEnquiryResponse = () => {
    let replies = this.state.enquiry && this.state.enquiry.replies;
    if (replies && replies.length > 0) {
      let filterReply = replies.filter(
        (reply) =>
          reply.replied_by.user_role === "ec_coordinator" ||
          reply.replied_by.user_role === "ec_qcr_admin"
      );
      console.log(filterReply, "filtered reply");
      if (filterReply && filterReply.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  render() {
    const {
      enquiry,
      status,
      reply,
      isLoading,
      related_committees,
      isEnquiryResponded,
      priority,
      title,
      changeTitle,
      description,
      changeDescription,
    } = this.state;
    const { user } = this.props;
    return (
      <div className="px-5 mx-3 enquiry_detail mb-3 mb-5 pb-4">
        {enquiry === null ? (
          <div>
            <p
              style={{
                color: "#BC5405",
                cursor: "pointer",
                fontWeight: "bolder",
                textTransform: "uppercase",
              }}
              className="mb-0 col-lg-1 mx-0 px-0 my-2"
              onClick={() =>
                user && user.role === "ec_qcr_admin"
                  ? (window.location.href = "/admin/enquiries")
                  : (window.location.href = "/enquiry")
              }
            >
              Back
            </p>
            <p className="d-flex align-items-center justify-content-center my-5 py-5">
              No Enquiry Found
            </p>
          </div>
        ) : (
          <div>
            <p
              style={{
                color: "#BC5405",
                cursor: "pointer",
                fontWeight: "bolder",
                textTransform: "uppercase",
              }}
              className="mb-0 col-lg-1 mx-0 px-0 my-2"
              onClick={() =>
                user && user.role === "ec_qcr_admin"
                  ? (window.location.href = "/admin/enquiries")
                  : (window.location.href = "/enquiry")
              }
            >
              Back
            </p>
            <div className="row">
              <div className="col-lg-8">
                {related_committees && related_committees.length > 0 ? (
                  related_committees.map((relatedCom) => (
                    <div
                      style={{ backgroundColor: "#EBEBEB" }}
                      className="border border-rounded my-2 p-1"
                      key={relatedCom._id}
                    >
                      {relatedCom && relatedCom.type === "naitik" ? (
                        <div>
                          <p className="mb-0">
                            {(relatedCom &&
                              relatedCom.name_of_ethics_committee) ||
                              "None"}
                          </p>
                          <p className="mb-0">
                            {(relatedCom && relatedCom.address) || "None"}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p className="mb-0">
                            {(relatedCom && relatedCom.name_and_address) ||
                              "None"}
                          </p>
                        </div>
                      )}
                      <p style={{ fontWeight: "bold" }} className="mb-0">
                        {relatedCom && relatedCom.registered_number}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>please wait ...</p>
                )}
                {enquiry &&
                (enquiry.status === "opened" ||
                  enquiry.status === "accepted") &&
                isEnquiryResponded === false &&
                user &&
                user.role === "ec_independent_researcher" ? (
                  <div
                    style={{
                      backgroundColor: "#FDF3EB",
                      borderRadius: 7,
                      borderColor: "orange",
                    }}
                    className="warning border p-3"
                  >
                    <p style={{ color: "#000000" }} className="mb-0">
                      Your enquiry was sent successfully. We will revert within
                      24 to 48 hours. In case there is a delay or any further
                      queries please contact us on
                      sudhir.patel@quinaryglobal.com or call us on 9740233593.
                    </p>
                  </div>
                ) : null}
                <div className="d-flex align-items-center justify-content-between my-2">
                  {/* <h6 className="mb-0">
                      {(enquiry && enquiry._id) || "None"}
                    </h6> */}
                  <p className="mb-0">
                    Ticket Raised By{" "}
                    <span>
                      {enquiry &&
                        enquiry.raised_by &&
                        enquiry.raised_by.username}
                    </span>
                  </p>
                  <div className="d-flex">
                    <p className="mt-1 mx-2">Priority</p>
                    <select
                      onChange={this.handlePriorityChange}
                      name="priority"
                      value={priority}
                      type="text"
                      className="form-control"
                    >
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                    </select>
                  </div>
                </div>
                <div className="title my-3">
                  <h3>Title</h3>
                  {changeTitle === true ? (
                    <div>
                      <textarea
                        onChange={this.handleTextChange}
                        cols={2}
                        type="text"
                        name="title"
                        value={title}
                        className="form-control col-lg-12"
                      ></textarea>
                      <div className="d-flex align-items-center justify-content-end my-2">
                        <button onClick={()=>this.updateText()} className="btn btn-small btn-primary mr-3">&#10003;</button>
                        <button onClick={()=>this.setState({ title :this.state.enquiry.title,changeTitle: false, })} className="btn btn-small btn-danger">&times;</button>
                      </div>
                    </div>
                  ) : (
                    <h5 onClick={() => this.setState({ changeTitle: true })}>
                      {(enquiry && enquiry.title) || "None"}
                    </h5>
                  )}
                </div>
                <div className="title my-3">
                  <h3>Description</h3>
                  {changeDescription === true ? (
                    <div>
                    <textarea
                      onChange={this.handleTextChange}
                      cols={2}
                      type="text"
                      name="description"
                      value={description}
                      className="form-control col-lg-12"
                    ></textarea>
                    <div className="d-flex align-items-center justify-content-end my-2">
                        <button onClick={()=>this.updateText()} className="btn btn-small btn-primary mr-3">&#10003;</button>
                        <button onClick={()=>this.setState({ description : this.state.enquiry.description,changeDescription: false, })} className="btn btn-small btn-danger">&times;</button>
                      </div>
                    </div>
                  ) : (
                    <h5 onClick={() => this.setState({ changeDescription: true })}>
                      {(enquiry && enquiry.description) || "None"}
                    </h5>
                  )}
                </div>
                <div className="my-2 d-flex align-items-center justify-content-start">
                  {enquiry &&
                  enquiry.attachments &&
                  enquiry.attachments.length > 0
                    ? enquiry.attachments.map((file) =>
                        file === "" ||
                        file === null ||
                        file === undefined ? null : (
                          <div>
                            <a
                              className="mx-2"
                              rel="noreferrer"
                              href={file}
                              target="_blank"
                            >
                              <img
                                className="p-2"
                                style={{
                                  height: 80,
                                  width: 80,
                                  border: "1.5px solid dimgrey",
                                  borderRadius: 15,
                                }}
                                src={
                                  checkForFileExtension(file) === true
                                    ? file
                                    : enquiry_file_icon
                                }
                                alt="uploaded file"
                              ></img>
                            </a>
                            <p
                              onClick={() => this.setState({ showAlert: true })}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              className="mb-0"
                            >
                              remove
                            </p>
                            <div
                              style={{
                                display:
                                  this.state.showAlert === true
                                    ? "block"
                                    : "none",
                              }}
                              className="modal"
                            >
                              <div className="modal-content container">
                                <div className="modal-header">
                                  <button
                                    onClick={() =>
                                      this.setState({ showAlert: false })
                                    }
                                    className="close"
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <p style={{ fontWeight: "bold" }}>
                                    This action cannot be undone. File will be
                                    removed permanently
                                  </p>
                                  <div className="d-flex align-items-center justify-content-start">
                                    <button
                                      onClick={() => {
                                        this.deleteFile(file);
                                        this.setState({ showAlert: false });
                                      }}
                                      className="btn btn-small btn-danger mx-5"
                                    >
                                      Yes
                                    </button>
                                    <button
                                      onClick={() =>
                                        this.setState({ showAlert: false })
                                      }
                                      className="btn btn-small btn-primary mx-5"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
                {user && user.role === "ec_qcr_admin" ? null : (
                  <div>
                    <input
                      onChange={this.handleAttachmentChange}
                      accept=".png, .jpg, .pdf, .xlsx, .ods, .docx, .txt"
                      type="file"
                      name="attachment"
                    ></input>
                    <button
                      className="btn btn-small btn-primary"
                      onClick={() =>
                        this.upload(
                          this.state.file,
                          this.state.fileName,
                          this.state.fileType
                        )
                      }
                    >
                      submit
                    </button>
                  </div>
                )}
                <div style={{ height: 200, overflowY: "scroll" }}>
                  {enquiry.replies && enquiry.replies.length > 0 ? (
                    enquiry.replies
                      .sort(
                        (d1, d2) =>
                          new Date(d1.createdAt).getTime() -
                          new Date(d2.createdAt).getTime()
                      )
                      .reverse()
                      .map((enquiryReply) => (
                        <div
                          key={enquiryReply._id}
                          className="reply_comment d-flex align-items-start justify-content-start"
                        >
                          <div
                            style={{
                              height: 40,
                              width: 40,
                              borderRadius: 20,
                              backgroundColor: "dodgerblue",
                              color: "white",
                            }}
                            className="d-flex align-items-center justify-content-center mx-2"
                          >
                            <h4 style={{ fontSize: 20 }} className="mt-1">
                              {enquiryReply &&
                                enquiryReply.replied_by &&
                                enquiryReply.replied_by.username &&
                                enquiryReply.replied_by.username
                                  .toUpperCase()
                                  .charAt("0")}
                            </h4>
                          </div>
                          <div>
                            <div>
                              <h3>
                                {enquiryReply &&
                                  enquiryReply.replied_by &&
                                  enquiryReply.replied_by.username}
                              </h3>
                              <p className="comment_time mb-0">
                                {moment(
                                  enquiryReply && enquiryReply.createdAt
                                ).format("MMMM Do YYYY, HH:mm:ss")}
                              </p>
                            </div>
                            <p className="mb-0" style={{ color: "#000000" }}>
                              {enquiryReply && enquiryReply.reply}
                            </p>
                          </div>
                        </div>
                      ))
                  ) : (
                    <p className="my-5 mb-0">No Replies found</p>
                  )}
                </div>
                {(user && user.role === "ec_coordinator") ||
                (user && user.role === "ec_independent_researcher") ||
                (user && user.role === "ec_qcr_admin") ? (
                  <div>
                    <textarea
                      onChange={this.handleReplyChange}
                      name="reply"
                      value={reply}
                      placeholder="Please enter your reply here"
                      rows={2}
                      className="enquiry_reply form-control my-2"
                    ></textarea>
                    <button
                      onClick={() => this.onReply()}
                      className="btn btn-sm login"
                    >
                      Reply
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="col-lg-4">
                <div className="enquiry_filters mt-2">
                  <div className="filters d-flex align-items-center justify-content-start py-3 px-5">
                    <img
                      style={{ height: 25, width: 25 }}
                      className="img-responsive"
                      src={filter}
                      alt="filter"
                    ></img>
                    <h6 className="mx-3 mb-0">Status</h6>
                  </div>
                  <div className="mx-5 my-3">
                    {(user && user.role === "ec_qcr_admin" && (
                      <select
                        onChange={this.handleChange}
                        name="status"
                        value={status}
                        type="text"
                        className="form-control"
                      >
                        <option value="">Please Select</option>
                        <option value="opened">Opened</option>
                        <option value="accepted">Accepted</option>
                        <option value="closed">Closed</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    )) ||
                      (user && user.role === "ec_coordinator" && (
                        <select
                          onChange={this.handleChange}
                          name="status"
                          value={status}
                          type="text"
                          className="form-control"
                        >
                          <option value="">Please Select</option>
                          <option value="closed">Closed</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      )) ||
                      (user && user.role === "ec_independent_researcher" && (
                        <select
                          onChange={this.handleChange}
                          name="status"
                          value={status}
                          type="text"
                          className="form-control"
                        >
                          <option value="">Please Select</option>
                          <option value="opened">Opened</option>
                          <option disabled={true} value="accepted">
                            Accepted
                          </option>
                          <option value="closed">Closed</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      ))}
                  </div>
                  <div className="mx-5 my-1">
                    <p className="mb-0 my-3">
                      Assigned to :{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          user && user.role === "ec_qcr_admin"
                            ? this.changeAssignee()
                            : {}
                        }
                      >
                        {(enquiry &&
                          enquiry.assignee &&
                          enquiry.assignee.username) ||
                          "None"}
                      </span>
                    </p>
                  </div>
                  <div style={{ backgroundColor: "#D3D3D3" }}>
                    {this.state.showAssignees === true ? (
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-end">
                          <p
                            onClick={() =>
                              this.setState({ showAssignees: false })
                            }
                            style={{ cursor: "pointer", color: "#BC5405" }}
                            className="mb-0"
                          >
                            cancel
                          </p>
                        </div>
                        {this.state.coordinators &&
                        this.state.coordinators.length > 0
                          ? this.state.coordinators.map((coordinator) => (
                              <div className="mx-3 text-left">
                                <p
                                  onClick={() =>
                                    this.selectCoordinator(coordinator)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {coordinator && coordinator.username} -{" "}
                                  {(coordinator &&
                                    coordinator.user_committee &&
                                    coordinator &&
                                    coordinator.user_committee
                                      .registered_number) ||
                                    "None"}
                                </p>
                              </div>
                            ))
                          : "Please wait"}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="enquiry_filters mt-2">
                  <div className="filters d-flex align-items-center justify-content-start py-3 px-5">
                    <img
                      style={{ height: 25, width: 25 }}
                      className="img-responsive"
                      src={preview}
                      alt="filter"
                    ></img>
                    <h6 className="mx-3 mb-0">Contact Details</h6>
                  </div>
                  <div>
                    <div
                      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                      className="my-2 px-5"
                    >
                      <p className="mb-0 my-3">Ticket No : {enquiry._id}</p>
                      <p className="mb-0 my-3">
                        Created :{" "}
                        {moment(enquiry.createdAt).format(
                          "MMMM Do YYYY, HH:mm:ss"
                        )}
                      </p>
                      <p className="mb-0 my-3">
                        Updated :{" "}
                        {moment(enquiry.updatedAt).format(
                          "MMMM Do YYYY, HH:mm:ss"
                        )}
                      </p>
                    </div>
                    <div className="px-5">
                      <div className="d-flex align-items-start justify-content-start">
                        <div
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            backgroundColor: "dodgerblue",
                            color: "white",
                          }}
                          className="d-flex align-items-center justify-content-center mx-2"
                        >
                          <h4 style={{ fontSize: 20 }} className="mt-1">
                            {enquiry &&
                              enquiry.raised_by &&
                              enquiry.raised_by.username
                                .toUpperCase()
                                .charAt("0")}
                          </h4>
                        </div>
                        <div>
                          <p
                            className="mb-0"
                            style={{ textTransform: "capitalize" }}
                          >
                            {enquiry &&
                              enquiry.raised_by &&
                              enquiry.raised_by.username}
                          </p>
                          <p className="mb-0 my-3">
                            {moment(enquiry.updatedAt).format(
                              "MMMM Do YYYY, HH:mm:ss"
                            )}
                          </p>
                          <div className="d-flex align-items-center justify-content-start">
                            <img
                              src={displayEmail}
                              style={{ height: 15, width: 15 }}
                              alt="email icon"
                            ></img>
                            <p className="mb-0">
                              {enquiry &&
                                enquiry.raised_by &&
                                enquiry.raised_by.user_email}
                            </p>
                          </div>
                        </div>
                      </div>
                      {(enquiry &&
                        enquiry.responded_by &&
                        enquiry.responded_by !== null) ||
                      (enquiry &&
                        enquiry.responded_by &&
                        enquiry.responded_by !== {}) ? (
                        <div className="d-flex align-items-start justify-content-start">
                          <div
                            style={{
                              height: 40,
                              width: 40,
                              borderRadius: 20,
                              backgroundColor: "dodgerblue",
                              color: "white",
                            }}
                            className="d-flex align-items-center justify-content-center mx-2"
                          >
                            <h4 style={{ fontSize: 20 }} className="mt-1">
                              {enquiry &&
                                enquiry.responded_by &&
                                enquiry.responded_by.username
                                  .toUpperCase()
                                  .charAt("0")}
                            </h4>
                          </div>
                          <div>
                            <p
                              className="mb-0"
                              style={{ textTransform: "capitalize" }}
                            >
                              {enquiry &&
                                enquiry.responded_by &&
                                enquiry.responded_by.username}
                            </p>
                            {/* <p className="mb-0 my-3">
                            {moment(enquiry.updatedAt).format(
                              "MMMM Do YYYY, HH:mm:ss"
                            )}
                          </p> */}
                            <div className="d-flex align-items-center justify-content-start">
                              <img
                                src={displayEmail}
                                style={{ height: 15, width: 15 }}
                                alt="email icon"
                              ></img>
                              <p className="mb-0">
                                {enquiry &&
                                  enquiry.responded_by &&
                                  enquiry.responded_by.user_email}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(EnquiryDetail);
