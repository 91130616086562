import React from "react";
import {
  getCommitteeDetails,
  additionalInformation,
  getEnrichedInfo,
  updatedSimilarCommittee,
  updateCommittee,
  getSimilarCommittee,
  uploadAttachment,
  deleteAttachment,
} from "../helpers/api";
import { withRouter } from "react-router-dom";
import {
  objectDecrypt,
  checkForFileExtension,
  getFileName,
  sendUpdate,
  getCurrentUser,
  addUnRead,
  getRandomID,
} from "../helpers/helper";
import { enquiry_file_icon, policy } from "../theme/icons";
import { environment } from "../keys";

class Policy extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: "",
      ec_policy: [],
      similarCommittee: null,
      editing_policy_id: "",
      committee: "",
      hideAdd: false,
      selected_policy_id: "",
      removingPolicy: "",
      removingUrl: "",
      selected_policy: "",
      showSelectedAttachment: false,
      showAlert: false,
      isLoading: "",
    };
  }

  createPolicyRow = (index) => {
    let policyObj = {};
    policyObj["id"] = "policy" + index;
    policyObj["policy" + index + "title"] = "";
    policyObj["policy" + index + "effective_date"] = "";
    policyObj["policy" + index + "validity_date"] = "";
    policyObj["policy" + index + "description"] = "";
    policyObj["policy" + index + "attachments"] = [];
    let policy = this.state.ec_policy;
    if (policy && policy.length > 0) {
      policy.push(policyObj);
      this.setState({ ec_policy: policy, hideAdd: true });
    } else {
      this.setState({ ec_policy: [policyObj], hideAdd: true });
    }
  };

  splitMembers = (policies) => {
    if (policies && policies.length > 0) {
      policies.map((policy) => {
        console.log(policy, "policy");
        let keys = Object.keys(policy);
        keys.map((key) => {
          console.log(key, policy[key]);
          if (key === "id") {
            console.log(key);
          } else {
            this.setState({ [key]: policy[key] });
          }
        });
      });
    } else {
      console.log(policies, "no policies found");
    }
  };

  fetchEnrichedInfo = (regNumber) => {
    getEnrichedInfo(regNumber)
      .then((foundInfo) => {
        console.log(foundInfo, "info found");
        this.setState({ enrichedCommittee: foundInfo }, () => {
          if (this.state.enrichedCommittee !== null) {
            let { ec_policy } = foundInfo;
            this.setState(
              {
                ec_policy: ec_policy,
              },
              () => {
                this.splitMembers(ec_policy);
              }
            );
          } else {
            this.setState({ enrichedCommittee: null }, () => {
              getSimilarCommittee(this.state.id)
                .then((gotSimilarCom) => {
                  console.log(gotSimilarCom, "got similar committee");
                  let similarCom = gotSimilarCom;
                  if (similarCom && similarCom.approval_status === "accepted") {
                    this.setState({ similarCommittee: similarCom }, () => {
                      this.setState(
                        {
                          ec_policy:
                            (similarCom &&
                              similarCom.enriched_information &&
                              similarCom.enriched_information.ec_policy) ||
                            [],
                        },
                        () => {
                          this.splitMembers(
                            (similarCom &&
                              similarCom.enriched_information &&
                              similarCom.enriched_information.ec_policy) ||
                              []
                          );
                        }
                      );
                    });
                  } else {
                    this.setState({ similarCommittee: null });
                  }
                })
                .catch((errSimilarCommittee) => {
                  console.log(
                    errSimilarCommittee,
                    "err fetching similar committee"
                  );
                });
            });
          }
        });
        // }
      })
      .catch((errFoundInfo) => {
        console.log(errFoundInfo, "err with info");
      });
  };

  fetchDetails = () => {
    getCommitteeDetails(
      this.props.user && this.props.user.others[0].ethics_committee.type,
      this.state.id
    )
      .then((committee) => {
        console.log(committee, "found committee");
        this.setState({ committee: committee }, () => {
          this.fetchEnrichedInfo(committee && committee.registered_number);
        });
      })
      .catch((errCommittee) => {
        console.log(errCommittee, "err found committee");
        this.setState({ committee: null });
      });
  };

  componentDidMount() {
    console.log(this.props.match.params.id, "info");
    this.setState(
      {
        id: this.props.match.params.id,
      },
      () => {
        this.fetchDetails();
      }
    );
  }

  getMember = (targetName) => {
    let replace_title = targetName.replace("title", "");
    let replace_effective_date = targetName.replace("effective_date", "");
    let replace_validity_date = targetName.replace("validity_date","");
    let replace_description = targetName.replace("description", "");
    let replace_attachments = targetName.replace("attachments","");

    if(targetName.endsWith("title") === true){
      return replace_title
    } else if(targetName.endsWith("effective_date") === true){
      return replace_effective_date
    } else if(targetName.endsWith("validity_date") === true){
      return replace_validity_date
    } else if(targetName.endsWith("description") === true){
      return replace_description
    } else if(targetName.endsWith("attachments") === true){
      return replace_attachments
    } 
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      let policies = this.state.ec_policy;
      let policy = policies.filter(
        (policy) => 
        this.getMember(e.target.name) === policy.id
        //e.target.name.includes(policy.id) === true
      )[0];
      policy[e.target.name] = e.target.value;
      this.setState({ editing_policy_id: policy.id, hideAdd: true });
      console.log(
        policy,
        policies.filter((p) => p.id),
        e.target.name,
        e.target.value,
        "policies"
      );
      //members.splice(members.indexOf(member),1)
      this.setState({ ec_policy: policies.filter((m) => m.id) });
    });
  };

  addSimilarComInfo = () => {
    if (this.state.similarCommittee !== null) {
      let info =
        this.state.similarCommittee &&
        this.state.similarCommittee.enriched_information;
      info["ec_policy"] = this.state.ec_policy;
      console.log(info, "checking id while updating escom");
      let req_body = { enriched_information: info };
      updatedSimilarCommittee(
        this.state.similarCommittee && this.state.similarCommittee._id,
        this.state.similarCommittee &&
          this.state.similarCommittee.enrichedCommittee,
        this.setSimilarCommitteeSessionBody(
          this.state.similarCommittee && this.state.similarCommittee._id
        ) === null
          ? req_body
          : this.setSimilarCommitteeSessionBody(
              this.state.similarCommittee && this.state.similarCommittee._id
            )
      )
        .then((updatedScom) => {
          console.log(updatedScom, "updated similar committee");
          window.sessionStorage.removeItem(
            `${
              this.state.similarCommittee && this.state.similarCommittee._id
            }-similarinfo`
          );
          alert("Committee details updated successfully");
          let update = {
            active: false,
            title: `Similar committee enriched information updated`,
            update: {
              type: "ethics committee",
              item:
                this.state.similarCommittee && this.state.similarCommittee._id,
            },
            description: `Enriched information updated by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_policy_id: "" }, () => {
            //window.location.href="/composition/"+this.state.id
            this.fetchDetails();
          });
        })
        .catch((errUpdatedScom) => {
          console.log(
            errUpdatedScom,
            "err while updating similar committee with enriched info"
          );
          window.sessionStorage.removeItem(
            `${
              this.state.similarCommittee && this.state.similarCommittee._id
            }-similarinfo`
          );
          alert("Error with updating committee details");
        });
    } else {
      console.log(this.state.similarCommittee, "error fetching similar com");
    }
  };

  setSimilarCommitteeSessionBody = (scomId) => {
    let similarSessionData = window.sessionStorage.getItem(
      `${scomId}-similarinfo`
    );
    if (similarSessionData !== null) {
      let parsed = objectDecrypt(similarSessionData);
      parsed["ec_policy"] = this.state.ec_policy;
      console.log(parsed, "checking ec policies while updating escom");
      let body = { enriched_information: parsed };
      return body;
    } else {
      return null;
    }
  };

  setSessionBody = () => {
    let sessionData = window.sessionStorage.getItem("enrichedinfo");
    if (sessionData !== null) {
      let parsed = objectDecrypt(sessionData);
      console.log(parsed, "parsed");
      let body = {
        id: this.state.id,
        committee_type:
          this.props.user &&
          this.props.user.others &&
          this.props.user.others[0].ethics_committee &&
          this.props.user.others[0].ethics_committee.type,
        committee_id: parsed["committee"] && parsed["committee"]["_id"],
        committee_registered_number:
          parsed["committee"] && parsed["committee"]["registered_number"],
        committee: parsed["committee"],
        updated_by: this.props.user._id,
        pincode: parsed["pincode"],
        cdsco_approval_date: parsed["cdsco_approval_date"],
        cdsco_validity_date: parsed["cdsco_validity_date"],
        dhr_approval_date: parsed["dhr_approval_date"],
        dhr_validity_date: parsed["dhr_validity_date"],
        next_meeting_date: parsed["next_meeting_date"],
        how_often_ec_meet: parsed["how_often_ec_meet"],
        ec_existing_since: parsed["ec_existing_since"],
        list_of_holidays: parsed["list_of_holidays"],
        ec_composition_EC: this.state.ec_composition_members,
        ec_days_prior_dossier_submission:
          parsed["ec_days_prior_dossier_submission"],
        ec_issue_approval_post_meeting:
          parsed["ec_issue_approval_post_meeting"],
        list_of_sops: parsed["list_of_sops"],
        conflict_of_interest_policy: parsed["conflict_of_interest_policy"],
        ec_training: this.state.ec_training,
        process_of_ec_submission: parsed["process_of_ec_submission"],
        submission_process: "",
        ec_title_of_policy: parsed["ec_title_of_policy"],
        is_paper_based: parsed["is_paper_based"],
        is_online_based: parsed["is_online_based"],
        submission_process_paper_based_copies:
          parsed["submission_process_paper_based_copies"],
        submission_process_online_description:
          parsed["submission_process_online_description"],
        submission_review_check_list: [],
        review_checklist_process: parsed["review_checklist_process"],
        ec_initial_review_fees:
          parsed["ec_initial_review_fees"] &&
          parsed["ec_initial_review_fees"].includes("INR") === true
            ? parsed["ec_initial_review_fees"]
            : parsed["ec_initial_review_fees"] + "INR",
        ec_amendment_review_fees:
          parsed["ec_amendment_review_fees"] &&
          parsed["ec_amendment_review_fees"].includes("INR") === true
            ? parsed["ec_amendment_review_fees"]
            : parsed["ec_amendment_review_fees"] + "INR",
        ec_final_review_process:
          parsed["ec_final_review_process"] &&
          parsed["ec_final_review_process"].includes("INR") === true
            ? parsed["ec_final_review_process"]
            : parsed["ec_final_review_process"] + "INR",
        mode_of_payment: parsed["mode_of_payment"],
        payee_name: parsed["payee_name"],
        ec_gst:
          parsed["ec_gst"] && parsed["ec_gst"].includes("%") === true
            ? parsed["ec_gst"]
            : parsed["ec_gst"] + "%",
        ec_tds: parsed["ec_tds"],
        submission_process_type: parsed["submission_process_type"],
        ec_submission_forms: [],
        process_for_sae_review: parsed["sae_review"],
        ec_policy_effective_date: parsed["ec_policy_effective_date"],
        ec_policy_validity_date: parsed["ec_policy_validity_date"],
        finance: parsed["finance"],
        frequency_of_ec_site_monitoring:
          parsed["frequency_of_ec_site_monitoring"],
        timeline_for_ec_dossier_submission:
          parsed["timeline_for_ec_dossier_submission"],
        approval_for_ec_and_scientific_timeline:
          parsed["approval_for_ec_and_scientific_timeline"],
        interim_progress_report_timeline:
          parsed["interim_progress_report_timeline"],
        timeline_for_expedite_review_and_approval:
          parsed["timeline_for_expedite_review_and_approval"],
        document_storage_facility: parsed["document_storage_facility"],
        fire_extingusher: parsed["fire_extingusher"],
        emergency_alarm: parsed["emergency_alarm"],
        parent_institute_name: parsed["parent_institute_name"],
        parent_registration_number: parsed["parent_registration_number"],
        primary_contact: {
          name: parsed["primary_contact_name"],
          email: parsed["primary_contact_email"],
          contact_number: parsed["primary_contact_contact_number"],
        },
        alternative_contact: {
          name: parsed["alternative_contact_name"],
          email: parsed["alternative_contact_email"],
          contact_number: parsed["alternative_contact_contact_number"],
        },
      };
      return body;
    } else {
      return null;
    }
  };

  checkECMemberInTraining = (member) => {
    let training = this.state.ec_training;
    if (training && training.length > 0) {
      training.map((trainingrow) => {
        let rowmembers = trainingrow[trainingrow.id + "members"];
        if (rowmembers && rowmembers.length > 0) {
          let filterMember = rowmembers.filter((mem) => mem.id === member.id);
          console.log(filterMember, "filtered member");
          if (filterMember && filterMember.length > 0) {
            let selectedMember = filterMember && filterMember[0];
            selectedMember[selectedMember.id + "role"] =
              member[member.id + "role"];
            selectedMember[selectedMember.id + "name"] =
              member[member.id + "name"];
            selectedMember[selectedMember.id + "experience"] =
              member[member.id + "experience"];
            selectedMember[selectedMember.id + "qualification"] =
              member[member.id + "qualification"];
            selectedMember[selectedMember.id + "affiliation"] =
              member[member.id + "affiliation"];
            selectedMember[selectedMember.id + "other"] =
              member[member.id + "other"];
            console.log(selectedMember, "selected member");
          } else {
            member["checked"] = false;
            rowmembers.push(member);
          }
        } else {
          console.log(rowmembers, "No row members found for this row");
        }
      });
    } else {
      console.log(training, "No training members found");
    }
  };

  updateMembersInTraining = () => {
    let ecMembers = this.state.ec_composition_members;
    let training = this.state.ec_training;
    if (ecMembers && ecMembers.length > 0) {
      ecMembers.map((member) => {
        this.checkECMemberInTraining(member);
      });
    } else {
      console.log(ecMembers, "no ec members found");
    }
  };

  addInfo = () => {
    let { user } = this.props;
    let { ec_policy } = this.state;
    let req_body = {
      id: this.state.id,
      committee_type:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0].ethics_committee &&
        this.props.user.others[0].ethics_committee.type,
      ec_policy: ec_policy,
      committee:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0].ethics_committee,
      committee_registered_number:
        this.props.user &&
        this.props.user.others &&
        this.props.user.others[0] &&
        this.props.user.others[0].ethics_committee &&
        this.props.user.others[0].ethics_committee.registered_number,
    };
    if (
      this.state.enrichedCommittee === "" ||
      this.state.enrichedCommittee === " " ||
      this.state.enrichedCommittee === null ||
      this.state.enrichedCommittee === undefined
    ) {
      additionalInformation(
        this.setSessionBody() === null ? req_body : this.setSessionBody()
      )
        .then((info) => {
          console.log(info, "info");
          this.fetchDetails();
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Information saved successfully");
          let update = {
            active: false,
            title: `Enriched information created`,
            update: {
              type: "enriched_data_created",
              user_name : this.props.user.username,
              ec_registered_number : req_body.committee_registered_number,
              item: info.output._id,
            },
            description: `Enriched information created by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_policy_id: "" }, () => {
            //window.location.href="/composition/"+this.state.id
            this.fetchDetails();
          });
          //window.location.href = "/addinfo/" + this.state.id;
        })
        .catch((errInfo) => {
          console.log(errInfo, "err with info");
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Error with submitting training information");
        });
    } else {
      let req_body = {
        committee_type:
          this.props.user && this.props.user.others[0].ethics_committee.type,
        ec_policy: ec_policy,
      };
      console.log(
        req_body,
        this.setSessionBody(),
        "req body before submitting"
      );
      updateCommittee(
        this.state.enrichedCommittee._id,
        this.setSessionBody() === null ? req_body : this.setSessionBody()
      )
        .then((success) => {
          console.log(success, "committee updated successfully");
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Updated Committee Details Successfully");
          let update = {
            active: false,
            title: `Enriched information updated`,
            update: {
              type: "enriched_data_created",
              user_name : this.props.user.username,
              ec_registered_number : req_body.committee_registered_number,
              item: this.state.enrichedCommittee._id,
            },
            description: `Enriched information updated by ${this.props.user.username}`,
            from: [getCurrentUser(this.props.user)],
            to: [addUnRead(this.props.adminUser)],
            isRead: false,
          };
          sendUpdate(update);
          this.setState({ hideAdd: false, editing_row_id:"" }, () => {
            //window.location.href = "/training/" + this.state.id;
            this.fetchDetails()
          });
          // getCommitteeDetails(
          //   this.state.committee.type,
          //   this.state.committee._id
          // )
          //   .then((com) => {
          //     console.log(com, "updated com");
          //     this.setState({ committee: com });
          //   })
          //   .catch((errCom) => {
          //     console.log(errCom, "error with fetching updated com");
          //     alert("Error with fetching updated committee");
          //   });
        })
        .catch((failure) => {
          console.log(failure, "error with updating committee details");
          window.sessionStorage.removeItem("enrichedinfo");
          alert("Error with updating committee details");
        });
    }
  };

  handleAttachmentChange = (e, policyID) => {
    this.setState(
      {
        [e.target.name]: e.target.files[0],
        editing_policy_id: "",
        isLoading: false,
      },
      () => {
        let policies = this.state.ec_policy;
        let policy = policies.filter(
          (policy) => this.getMember(e.target.name) === policy.id
          // e.target.name.includes(policy.id) === true
        )[0];
        policy[e.target.name] = e.target.files[0];
        let file = e.target.files[0];
        let randomNumber = getRandomID();
        let user_file_name = e.target.files[0].name;
        let fileName = `${this.props.user.email}-${this.props.user._id}/${environment}/policy/${randomNumber}/${user_file_name}`;
        let fileType = e.target.files[0].type;
        uploadAttachment(file, fileName, fileType)
          .then((success) => {
            console.log(success, "upload success");
            let attachments = [success.Location];
            policy[e.target.name] = attachments;
            this.setState({ isLoading: true, editing_policy_id: policyID });
          })
          .catch((failure) => {
            console.log(failure, "err while file upload");
            alert("Error while uploading file to policy");
            this.setState({ [e.target.name]: "", isLoading: true });
          });
      }
    );
  };

  deletePolicyFile = () => {
    let policies = this.state.ec_policy;
    let removingPolicy = this.state.removingPolicy;
    let policyIndex = policies.indexOf(removingPolicy);
    let url = this.state.removingUrl;
    console.log(
      policyIndex,
      policy,
      this.state.editing_policy_id,
      this.state.removingUrl,
      "inputs"
    );
    if (url && url.length > 0) {
      let fileName = getFileName(url);
      console.log(fileName, "file name");
      if (fileName === null) {
        console.log(fileName, "err with getting file name");
      } else {
        this.setState({ isLoading: false });
        deleteAttachment(fileName)
          .then((success) => {
            console.log(success, "file deleted successfully");
            removingPolicy[removingPolicy.id + "attachments"] = [];
            this.setState({ removingPolicy: removingPolicy });
            policies[policyIndex] = removingPolicy;
            this.setState({ ec_policy: policies });
            this.setState({ isLoading: true });
          })
          .catch((failure) => {
            console.log(failure, "err with deleting file");
            alert("Error with deleting the file");
            this.setState({ isLoading: true });
          });
      }
    } else {
      console.log(url, "no url found for url");
    }
  };

  render() {
    const { ec_policy, hideAdd, editing_policy_id } = this.state;
    const { user } = this.props;
    return (
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
        }}
        className="px-5 py-3 mb-5 pb-5"
      >
        <div className="d-flex align-items-center justify-content-between col-lg-7">
          <p
            style={{
              color: "#BC5405",
              cursor: "pointer",
              fontWeight: "bolder",
              textTransform: "uppercase",
            }}
            className="mb-0 col-lg-1 mx-0 px-0"
            onClick={() =>
              (window.location.href = `/addinfo/${user.others[0].ethics_committee._id}`)
            }
          >
            Back
          </p>
          <h3 className="mx-5">EC Policy</h3>
        </div>
        <div className="col-lg-12">
          <table className="table">
            <thead>
              <tr>
                <th style={{ border: "none" }} scope="col-lg-2 px-0">
                  Title
                </th>
                <th style={{ border: "none" }} scope="col-lg-1 px-0">
                  Effective Date
                </th>
                <th style={{ border: "none" }} scope="col-lg-1 px-0">
                  Validity Date
                </th>
                <th style={{ border: "none" }} scope="col-lg-4 px-0">
                  Description
                </th>
                <th style={{ border: "none" }} scope="col-lg-2 px-0">
                  Attachments
                </th>
              </tr>
            </thead>
            <tbody>
              {ec_policy && ec_policy.length > 0
                ? ec_policy.map((policy) => (
                    <tr key={policy.id} className="my-2">
                      <td
                        style={{ border: "none" }}
                        className="col-lg-2 px-2 pl-3"
                      >
                        <input
                          onChange={this.handleChange}
                          type="text"
                          name={policy.id + "title"}
                          value={this.state[policy.id + "title"]}
                          placeholder="Title"
                          className="form-control"
                        ></input>
                      </td>
                      <td
                        style={{ border: "none" }}
                        className="col-lg-1 px-2 pl-3"
                      >
                        <input
                          onChange={this.handleChange}
                          type="date"
                          name={policy.id + "effective_date"}
                          value={this.state[policy.id + "effective_date"]}
                          placeholder="Effective Date"
                          className="form-control"
                        ></input>
                      </td>
                      <td
                        style={{ border: "none" }}
                        className="col-lg-1 px-2 pl-3"
                      >
                        <input
                          onChange={this.handleChange}
                          type="date"
                          name={policy.id + "validity_date"}
                          value={this.state[policy.id + "validity_date"]}
                          placeholder="Validity Date"
                          className="form-control"
                        ></input>
                      </td>
                      <td
                        style={{ border: "none" }}
                        className="col-lg-4 px-2 pl-3"
                      >
                        <input
                          onChange={this.handleChange}
                          type="text"
                          name={policy.id + "description"}
                          value={this.state[policy.id + "description"]}
                          placeholder="Description"
                          className="form-control"
                        ></input>
                      </td>
                      <td
                        style={{ border: "none" }}
                        className="col-lg-1 px-2 pl-3"
                      >
                        <div className="row">
                          {policy[policy.id + "attachments"] &&
                          policy[policy.id + "attachments"].length > 0 ? (
                            policy[policy.id + "attachments"].map((url) => (
                              <div className="d-flex align-items-center justify-content-start">
                                <a
                                  className="mx-2"
                                  rel="noreferrer"
                                  href={url}
                                  target="_blank"
                                >
                                  <img
                                    className="p-1"
                                    style={{
                                      height: 35,
                                      width: 40,
                                      border: "1.5px solid dimgrey",
                                      borderRadius: 10,
                                    }}
                                    src={
                                      checkForFileExtension(url) === true
                                        ? url
                                        : enquiry_file_icon
                                    }
                                    alt="uploaded file"
                                  ></img>
                                </a>
                                <p
                                  onClick={() => {
                                    this.setState({
                                      editing_policy_id: policy.id,
                                      removingUrl: url,
                                      removingPolicy: policy,
                                      showAlert: true,
                                    });
                                  }}
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  className="mb-0"
                                >
                                  remove
                                </p>
                                <div
                                  style={{
                                    display:
                                      this.state.showAlert === true
                                        ? "block"
                                        : "none",
                                  }}
                                  className="modal"
                                >
                                  <div className="modal-content container">
                                    <div className="modal-header">
                                      <button
                                        onClick={() =>
                                          this.setState({ showAlert: false })
                                        }
                                        className="close"
                                      >
                                        &times;
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <p style={{ fontWeight: "bold" }}>
                                        This action cannot be undone. File will
                                        be removed permanently
                                      </p>
                                      <div className="d-flex align-items-center justify-content-start">
                                        <button
                                          onClick={() => {
                                            //this.deleteFile(policy.id,url);
                                            this.deletePolicyFile(
                                              policy,
                                              policy.id,
                                              url
                                            );
                                            this.setState({ showAlert: false });
                                          }}
                                          className="btn btn-small btn-danger mx-5"
                                        >
                                          Yes
                                        </button>
                                        <button
                                          onClick={() =>
                                            this.setState({ showAlert: false })
                                          }
                                          className="btn btn-small btn-primary mx-5"
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="d-flex align-items-center justify-content-center">
                              <input
                                onChange={(e) =>
                                  this.handleAttachmentChange(e, policy.id)
                                }
                                type="file"
                                accept=".pdf, .doc, .xlsx, .ods, .txt"
                                name={policy.id + "attachments"}
                              ></input>
                              {policy &&
                              policy.id === editing_policy_id &&
                              this.state.isLoading === false ? (
                                <button
                                  className="btn btn-sm btn-primary mx-2"
                                  disabled={
                                    this.state.isLoading === false
                                      ? true
                                      : false
                                  }
                                >
                                  wait ...
                                </button>
                              ) : null}
                            </div>
                          )}
                          {policy && policy.id === editing_policy_id ? (
                            <button
                              onClick={() =>
                                this.state.similarCommittee &&
                                this.state.similarCommittee !== null
                                  ? this.addSimilarComInfo()
                                  : this.addInfo()
                              }
                              className="btn btn-sm btn-primary mx-2"
                              disabled={
                                this.state.isLoading === false ? true : false
                              }
                            >
                              {this.state.isLoading === false
                                ? "wait ... "
                                : "save"}
                            </button>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
        <button
          onClick={() =>
            this.createPolicyRow(
              this.state.ec_policy && this.state.ec_policy.length
            )
          }
          className="btn btn-primary btn-small ml-3"
          disabled={hideAdd === true ? true : false}
        >
          add
        </button>
      </div>
    );
  }
}

export default withRouter(Policy);
