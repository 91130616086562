import React from "react";
import {
  acceptedEnquiries,
  closedEnquiries,
  openEnquiries,
  rejectedEnquiries,
} from "../../theme/icons";
import { withRouter } from "react-router-dom";
import EnquiryLabel from "../../components/enquiry/EnquiryLabel";
import EnquiryCard from "../../components/enquiry/EnquiryCard";
import NoEnquiry from "../../components/enquiry/NoEnquiry";
import { getEnquiries, getUserWithId } from "../../helpers/api";

class EnquiryDashboard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      enquiries:"",
      openEnquiries: [],
      closedEnquiries: [],
      acceptedEnquiries: [],
      rejectedEnquiries: [],
      selectedEnquiries: "",
    };
  }

  getUserName=(enquiry)=>{
    getUserWithId(enquiry.raised_by).then(gotUser=>{
      console.log(gotUser, 'got user');
      return gotUser.username
    }).catch(errUser=>{
      console.log(errUser, 'err with fetching  user');
      return "None"
    })
  }

  enquiryLabels = () => {
    let labels = [
      {
        icon: openEnquiries,
        count: this.state.openEnquiries && this.state.openEnquiries.length,
        title: "Opened",
        value: "opened",
        bgColor: "#F2D888",
      },
      {
        icon: acceptedEnquiries,
        count:
          this.state.acceptedEnquiries && this.state.acceptedEnquiries.length,
        title: "Accepted",
        value: "accepted",
        bgColor: "#8CD2A9",
      },
      {
        icon: rejectedEnquiries,
        count:
          this.state.rejectedEnquiries && this.state.rejectedEnquiries.length,
        title: "Rejected",
        value: "rejected",
        bgColor: "#FFA0A0",
      },
      {
        icon: closedEnquiries,
        count: this.state.closedEnquiries && this.state.closedEnquiries.length,
        title: "Closed",
        value: "closed",
        bgColor: "#C9AFD2",
      },
    ];
    return labels;
  };

  componentDidMount() {
    getEnquiries("all")
            .then((gotEnquiries) => {
              console.log(gotEnquiries, "got all enquiries");
              this.setState({ enquiries: gotEnquiries },()=>{
                if (this.state.enquiries && this.state.enquiries.length > 0) {
                  let open_enquiries = this.state.enquiries.filter(
                    (enquiry) => enquiry.status === "opened"
                  );
                  let accepted_enquiries = this.state.enquiries.filter(
                    (enquiry) => enquiry.status === "accepted"
                  );
                  let rejected_enquiries = this.state.enquiries.filter(
                    (enquiry) => enquiry.status === "rejected"
                  );
                  let closed_enquiries = this.state.enquiries.filter(
                    (enquiry) => enquiry.status === "closed"
                  );
                  this.setState({
                    openEnquiries: open_enquiries,
                    closedEnquiries: closed_enquiries,
                    acceptedEnquiries: accepted_enquiries,
                    rejectedEnquiries: rejected_enquiries,
                  });
                }
              });
            })
            .catch((errEnquiries) => {console.log(errEnquiries, 'err with fetching enquiries');
            this.setState({enquiries:[]})
          });
  }

  handleClick = (enquiryType) => {
    console.log(enquiryType, "type");
    if (enquiryType === "Opened") {
      this.setState({ selectedEnquiries: this.state.openEnquiries });
    } else if (enquiryType === "Closed") {
      this.setState({ selectedEnquiries: this.state.closedEnquiries });
    } else if (enquiryType === "Rejected") {
      this.setState({ selectedEnquiries: this.state.rejectedEnquiries });
    } else if (enquiryType === "Accepted") {
      this.setState({ selectedEnquiries: this.state.acceptedEnquiries });
    }
  };

  render() {
    const { selectedEnquiries } = this.state;
    const { enquiries } = this.props;
    return (
      <div className="px-3 my-2">
        {enquiries === null ? (
          <NoEnquiry />
        ) : (
          <div>
            <div className="row px-2">
              {this.enquiryLabels().map((label) => (
                <div className="col-lg-3 px-0 mx-0">
                  <EnquiryLabel {...label} handleClick={this.handleClick} />
                </div>
              ))}
            </div>
            <div style={{ height: '66vh', overflowY : "scroll", border : 'none' }} className="my-3 enquiry_filters">
              {selectedEnquiries && selectedEnquiries.length > 0 ? (
                selectedEnquiries === "" ? (
                  <div className="my-5 py-5">
                    <p className="mb-0">No enquiry type selected</p>
                  </div>
                ) : (
                  <div className="m-1 mb-5 pb-5">
                    {selectedEnquiries.map((selectedEnquiry) => (
                      <EnquiryCard
                        enquiry={selectedEnquiry}
                        goToDetail={() =>
                          (window.location.href =
                            "/enquiry/detail/" + selectedEnquiry._id)
                        }
                        getUserName={this.getUserName}
                      />
                    ))}
                  </div>
                )
              ) : (
                <div className="mx-auto text-center">
                  <p className="mb-0 my-5 py-5">No enquiry type selected</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(EnquiryDashboard);
