import React, { useEffect } from "react";
import moment from "moment";

const Regulatory = (props) => {
  const {
    handleChange,
    committee,
    cdsco_approval_date,
    cdsco_validity_date,
    dhr_approval_date,
    dhr_validity_date,
    isSimilarCommittee,
  } = props;

  const selectedCommittee =
    isSimilarCommittee === true ? committee.committee : committee;
  //console.log(selectedCommittee,'selected com')

  useEffect(() => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = `${yyyy}-${mm}-${dd}`;
    document.getElementById("validity date").min = today;
  }, []);

  return (
    <div className="row my-4">
      {(selectedCommittee && selectedCommittee.type === "Registered") ||
      (selectedCommittee && selectedCommittee.type === "reRegistered") ? (
        <div className="col-lg-6 text-left">
          <p>
            EC Registration Number CDSCO :{" "}
            <span style={{ fontStyle: "bold" }}>
              {selectedCommittee && selectedCommittee.type === "naitik"
                ? "None"
                : selectedCommittee.registered_number}
            </span>
          </p>
          <div className="row">
            <div className="col-lg-6">
              <p className="mb-0">Approval Date</p>
              <input
                onChange={handleChange}
                type="date"
                name="cdsco_approval_date"
                value={
                  selectedCommittee.type === "Registered" ||
                  selectedCommittee.type === "reRegistered"
                    ? moment(cdsco_approval_date).format("YYYY-MM-DD")
                    : cdsco_approval_date
                }
                placeholder="CDSCO Approval Date"
                className="form-control"
              ></input>
            </div>
            <div className="col-lg-6">
              <p className="mb-0">Validity Date</p>
              <input
                id="validity date"
                onChange={handleChange}
                type="date"
                name="cdsco_validity_date"
                value={
                  isSimilarCommittee === true
                    ? moment(
                        cdsco_validity_date
                      ).format("YYYY-MM-DD")
                    : cdsco_validity_date && cdsco_validity_date.length > 0
                    ? moment(cdsco_validity_date).format("YYYY-MM-DD")
                    : cdsco_validity_date
                }
                placeholder="CDSCO Validity Date"
                className="form-control"
              ></input>
            </div>
          </div>
        </div>
      ) : null}
      {selectedCommittee && selectedCommittee.type === "naitik" ? (
        <div className="col-lg-6 text-left">
          <p>
            EC Registration Number DHR:{" "}
            <span style={{ fontStyle: "bold" }}>
              {selectedCommittee && selectedCommittee.type === "naitik"
                ? selectedCommittee.registered_number
                : "None"}
            </span>
          </p>
          <div className="row">
            <div className="col-lg-6">
              <p className="mb-0">Approval Date</p>
              <input
                onChange={handleChange}
                type="date"
                name="dhr_approval_date"
                value={
                  selectedCommittee.type === "naitik"
                    ? moment(selectedCommittee.approval_date).format(
                        "YYYY-MM-DD"
                      )
                    : dhr_approval_date
                }
                placeholder="DHR Approval Date"
                className="form-control"
              ></input>
            </div>
            <div className="col-lg-6">
              <p className="mb-0">Validity Date</p>
              <input
                id="validity date"
                onChange={handleChange}
                type="date"
                name="dhr_validity_date"
                value={
                  isSimilarCommittee === true
                    ? moment(
                        // committee.enriched_information.dhr_validity_date
                        dhr_validity_date
                      ).format("YYYY-MM-DD")
                    : dhr_validity_date && dhr_validity_date.length > 0
                    ? moment(dhr_validity_date).format("YYYY-MM-DD")
                    : dhr_validity_date
                }
                placeholder="CDSCO Validity Date"
                className="form-control"
              ></input>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Regulatory;
