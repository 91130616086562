import React from "react";
import {
  getCommitteeDetails,
  additionalInformation,
  getEnrichedInfo,
  updatedSimilarCommittee,
  updateCommittee,
  getSimilarCommittee,
  getEnrichedInfoFromId,
} from "../helpers/api";
import { withRouter } from "react-router-dom";
import {
  objectDecrypt,
  sendUpdate,
  getCurrentUser,
  addUnRead,
  validateEmail,
} from "../helpers/helper";
import {
  regulatory,
  composition,
  meeting,
  policy,
  training,
  finance,
  timeline,
  offive,
  authority,
  contact,
  minus,
  plus,
  process,
} from "../theme/icons";
import {
  Regulatory,
  ECComposition,
  ECMeeting,
  ECPolicy,
  ECTraining,
  SubmissionProcess,
  ContactDetail,
  Authority,
  ECOffice,
  Timeline,
  Capabilites,
  Finance,
} from "./addInfo-form";

const blueBackground = "#CCF0FB";
const redBackground = "#F2DEDE";

const fields = [
  { title: "Regulatory", icon: regulatory, bgColor: blueBackground },
  { title: "EC Composition (EC)", icon: composition, bgColor: redBackground },
  { title: "EC Meeting", icon: meeting, bgColor: blueBackground },
  { title: "EC Policy", icon: policy, bgColor: redBackground },
  { title: "EC Training", icon: training, bgColor: blueBackground },
  { title: "Submission of Process", icon: process, bgColor: redBackground },
  { title: "Finance", icon: finance, bgColor: blueBackground },
  // { title: "Capabilities", icon: capabilities, bgColor: redBackground },
  { title: "Timeline", icon: timeline, bgColor: redBackground },
  { title: "EC Office", icon: offive, bgColor: blueBackground },
  {
    title: "Authority of Ethics Committee",
    icon: authority,
    bgColor: redBackground,
  },
  { title: "Contact Details", icon: contact, bgColor: blueBackground },
];

class EnrichScom extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: "",
      enriched_id : "",
      similarCommittee: null,
      fieldTitle: "",
      ask_to_continue_einfo: null,
      pincode: "",
      cdsco_approval_date: "",
      cdsco_validity_date: "",
      similarcommittees: "",
      ec_policy: "",
      dhr_approval_date: "",
      dhr_validity_date: "",
      next_meeting_date: "",
      how_often_ec_meet: "",
      ec_existing_since: "",
      list_of_holidays: "",
      ec_composition_members: "",
      ec_composition_EC: "",
      ec_days_prior_dossier_submission: "",
      ec_issue_approval_post_meeting: "",
      list_of_sops: "",
      ec_training: "",
      process_of_ec_submission: "",
      submission_process: "",
      is_paper_based: "",
      is_online_based: "",
      submission_process_paper_based_copies: "",
      submission_process_online_description: "",
      submission_review_check_list: "",
      ec_submission_forms: "",
      review_checklist_process: "",
      ec_initial_review_fees: "",
      ec_amendment_review_fees: "",
      ec_final_review_process: "",
      mode_of_payment: "",
      payee_name: "",
      ec_gst: "",
      ec_tds: "",
      submission_process_type: "",
      sae_review: "",
      finance: "",
      frequency_of_ec_site_monitoring: "",
      timeline_for_ec_dossier_submission: "",
      approval_for_ec_and_scientific_timeline: "",
      interim_progress_report_timeline: "",
      timeline_for_expedite_review_and_approval: "",
      document_storage_facility: "",
      fire_extingusher: "",
      emergency_alarm: "",
      parent_institute_name: "",
      parent_registration_number: "",
      primary_contact_name: "",
      primary_contact_email: "",
      primary_contact_contact_number: "",
      alternative_contact_name: "",
      alternative_contact_email: "",
      alternative_contact_contact_number: "",
    };
  }

  putInfo = (enriched_info) => {
    let {
      pincode,
      cdsco_approval_date,
      cdsco_validity_date,
      dhr_approval_date,
      dhr_validity_date,
      next_meeting_date,
      how_often_ec_meet,
      ec_existing_since,
      list_of_holidays,
      ec_composition_EC,
      ec_policy,
      list_of_sops,
      ec_training,
      ec_days_prior_dossier_submission,
      ec_issue_approval_post_meeting,
      process_of_ec_submission,
      process_for_sae_review,
      review_checklist_process,
      ec_initial_review_fees,
      ec_amendment_review_fees,
      ec_final_review_process,
      mode_of_payment,
      payee_name,
      ec_gst,
      ec_tds,
      submission_process_type,
      is_online_based,
      is_paper_based,
      submission_process_paper_based_copies,
      submission_process_online_description,
      parent_institute_name,
      parent_registration_number,
      finance,
      timeline_for_ec_dossier_submission,
      approval_for_ec_and_scientific_timeline,
      interim_progress_report_timeline,
      timeline_for_expedite_review_and_approval,
      document_storage_facility,
      fire_extingusher,
      emergency_alarm,
      frequency_of_ec_site_monitoring,
      primary_contact,
      alternative_contact,
      similar_committees,
    } = enriched_info;
    this.setState(
      {
        pincode: pincode,
        cdsco_approval_date: cdsco_approval_date,
        cdsco_validity_date: cdsco_validity_date,
        similarcommittees: similar_committees,
        ec_policy: ec_policy,
        dhr_approval_date: dhr_approval_date,
        dhr_validity_date: dhr_validity_date,
        next_meeting_date: next_meeting_date,
        how_often_ec_meet: how_often_ec_meet,
        ec_existing_since: ec_existing_since,
        list_of_holidays: list_of_holidays,
        ec_composition_members: ec_composition_EC,
        ec_composition_EC: ec_composition_EC.length,
        ec_days_prior_dossier_submission: ec_days_prior_dossier_submission,
        ec_issue_approval_post_meeting: ec_issue_approval_post_meeting,
        list_of_sops: list_of_sops,
        ec_training: ec_training,
        process_of_ec_submission: process_of_ec_submission,
        submission_process: "",
        is_paper_based: is_paper_based,
        is_online_based: is_online_based,
        submission_process_paper_based_copies:
          submission_process_paper_based_copies,
        submission_process_online_description:
          submission_process_online_description,
        submission_review_check_list: [],
        ec_submission_forms: [],
        review_checklist_process: review_checklist_process,
        ec_initial_review_fees: ec_initial_review_fees,
        ec_amendment_review_fees: ec_amendment_review_fees,
        ec_final_review_process: ec_final_review_process,
        mode_of_payment: mode_of_payment,
        payee_name: payee_name,
        ec_gst: ec_gst,
        ec_tds: ec_tds,
        submission_process_type: submission_process_type,
        sae_review: process_for_sae_review,
        finance: finance,
        frequency_of_ec_site_monitoring: frequency_of_ec_site_monitoring,
        timeline_for_ec_dossier_submission: timeline_for_ec_dossier_submission,
        approval_for_ec_and_scientific_timeline:
          approval_for_ec_and_scientific_timeline,
        interim_progress_report_timeline: interim_progress_report_timeline,
        timeline_for_expedite_review_and_approval:
          timeline_for_expedite_review_and_approval,
        document_storage_facility: document_storage_facility,
        fire_extingusher: fire_extingusher,
        emergency_alarm: emergency_alarm,
        parent_institute_name: parent_institute_name,
        parent_registration_number: parent_registration_number,
        primary_contact_name: primary_contact.name,
        primary_contact_email: primary_contact.email,
        primary_contact_contact_number: primary_contact.contact_number,
        alternative_contact_name: alternative_contact.name,
        alternative_contact_email: alternative_contact.email,
        alternative_contact_contact_number: alternative_contact.contact_number,
      },
      () => {
        let members = ec_composition_EC;
        this.splitMembers(members);
        this.splitMembers(ec_training);
        this.splitMembers(ec_policy);
        this.splitRowMembers(ec_training);

        // if(sessionData === null){
        //   console.log(sessionData,'no session data');
        // } else{
        //   console.log(sessionData, 'session data');
        //   this.setSessionData(sessionData)
        // }
      }
    );
  };

  splitMembers = (members) => {
    if(members && members.length > 0){
      members.map((member) => {
        console.log(member, "member");
        let keys = Object.keys(member);
        keys.map((key) => {
          console.log(key, member[key]);
          if (key === "id") {
            console.log(key);
          } else {
            this.setState({ [key]: member[key] });
          }
        });
      });
    }
  };

  splitRowMembers = (rows) => {
    if (rows && rows.length > 0) {
      rows.map((row) => {
        row[row.id + "members"].map((rowmember) => {
          return this.setState({
            [rowmember.id + "-" + row.id]: rowmember[rowmember.id + "name"],
          });
        });
      });
    }
  };

  handleChange = (e) => {
    console.log(e.target, e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (e.target.name === "ec_template") {
        this.setState({
          templateName: this.fileTemplate.current.files[0].name,
        });
      } else if (e.target.name === "ec_composition_EC") {
        this.setState({ [e.target.name]: e.target.value }, () => {
          console.log(this.createMembers(e.target.value), "members");
          this.setState({
            ec_composition_members: this.createMembers(e.target.value),
          });
        });
      } else if (e.target.name.startsWith("member") === true) {
        let members = this.state.ec_composition_members;
        let member = members.filter(
          (member) => e.target.name.includes(member.id) === true
        )[0];
        member[e.target.name] = e.target.value;
        console.log(
          member,
          members.filter((m) => m.id),
          e.target.name,
          e.target.value,
          "members"
        );
        //members.splice(members.indexOf(member),1)
        this.setState({ ec_composition_members: members.filter((m) => m.id) });
      } else if (e.target.name.startsWith("row") === true) {
        //let training = this.state.ec_training
        let filterRow =
          this.state.ec_training &&
          this.state.ec_training.filter((row) =>
            e.target.name.includes(row.id)
          );
        filterRow[0][e.target.name] = e.target.value;
        console.log(filterRow, "filter row");
        //this.setState({ ec_training: training.filter((row) => row.id) });
      }
    });
  };

  checkboxChange = (e) => {
    console.log(e.target.type, e.target.checked, e.target.name, e.target.value);
    if (e.target.type === "radio") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.checked });
    }
  };

  handlePrimaryMailChange = (e) => {
    this.setState({ primary_contact_email: e.target.value }, () => {
      let checkEmail = validateEmail(e.target.value);
      if (checkEmail === false) {
        this.setState({ showPrimaryEmailError: true });
      } else {
        this.setState({ showPrimaryEmailError: false });
      }
    });
  };

  handleAlternateMailChange = (e) => {
    this.setState({ alternative_contact_email: e.target.value }, () => {
      let checkEmail = validateEmail(e.target.value);
      if (checkEmail === false) {
        this.setState({ showAlternateEmailError: true });
      } else {
        this.setState({ showAlternateEmailError: false });
      }
    });
  };

  componentDidMount() {
    console.log(this.props.match.params.id, "info");
    console.log(this.props.match.params.enriched_id, "main enriched com");
    this.setState(
      {
        id: this.props.match.params.id,
        enriched_id : this.props.match.params.enriched_id
      },
      () => {
        getSimilarCommittee(this.state.id)
          .then((scom) => {
            console.log(scom, "scom found");
            this.setState({ similarCommittee: scom },()=>{
              let enriched = this.state.similarCommittee && this.state.similarCommittee.enriched_information;
              if(enriched !== undefined){
                this.putInfo(enriched)
              } else if(enriched === undefined){
                this.setState({ask_to_continue_einfo : ""})
              }
              else {
                alert("Error while fetching enriched information of similar committee")
              }
            });
          })
          .catch((errScom) => {
            console.log(errScom, "err while fetching scom");
            this.setState({ similarCommittee: null });
          });
      }
    );
  }

  setFieldTitle = (title) => {
    this.setState({ fieldTitle: title });
  };

  navigateECTraining = () => {
    this.updateScom("training");
  };

  navigateECComposition = () => {
    this.updateScom("composition");
  };

  navigateECPolicy = () => {
    this.updateScom("policy");
  };

  collapseField = (title) => {
    if (title === "Regulatory") {
      return <Regulatory 
                  handleChange={this.handleChange} 
                  {...this.state} 
                  isSimilarCommittee={true} 
                  committee = {this.state.similarCommittee}
             />;
    } else if (title === "EC Composition (EC)") {
      //window.location.href = "/composition/"+this.state.id
      return (
        <ECComposition
          handleChange={this.handleChange}
          {...this.state}
          removeMember={this.removeMember}
          navigateECComposition={this.navigateECComposition}
        />
      );
    } else if (title === "EC Meeting") {
      return <ECMeeting handleChange={this.handleChange} {...this.state} />;
    } else if (title === "EC Policy") {
      //window.location.href = "/policy/"+this.state.id
      return (
        <ECPolicy
          ec_policy={this.state.ec_policy}
          navigateECPolicy={this.navigateECPolicy}
        />
      );
    } else if (title === "EC Training") {
      //window.location.href = "/training/"+this.state.id
      return (
        <ECTraining
          handleChange={this.handleChange}
          openMembersModal={this.openMembersModal}
          closeMembersModal={this.closeMembersModal}
          chooseMembersCheckboxChange={this.chooseMembersCheckboxChange}
          showMembersModal={this.state.showMembersModal}
          ec_composition_members={this.state.ec_composition_members}
          ec_training={this.state.ec_training}
          createTrainingRow={this.createTrainingRow}
          navigateECTraining={this.navigateECTraining}
          selectMembers={this.selectMembers}
          {...this.state}
        />
      );
    } else if (title === "Submission of Process") {
      return (
        <SubmissionProcess
          handleChange={this.handleChange}
          radioCheckboxChange={this.radioCheckboxChange}
          checkboxChange={this.checkboxChange}
          {...this.state}
        />
      );
    } else if (title === "Contact Details") {
      return (
        <ContactDetail
          handleChange={this.handleChange}
          handlePrimaryMailChange={this.handlePrimaryMailChange}
          handleAlternateMailChange={this.handleAlternateMailChange}
          {...this.state}
        />
      );
    } else if (title === "Authority of Ethics Committee") {
      return <Authority handleChange={this.handleChange} {...this.state} />;
    } else if (title === "EC Office") {
      return (
        <ECOffice
          handleChange={this.handleChange}
          checkboxChange={this.checkboxChange}
          {...this.state}
        />
      );
    } else if (title === "Timeline") {
      return <Timeline handleChange={this.handleChange} {...this.state} />;
    }
    // else if (title === "Capabilities") {
    //   return <Capabilites handleChange={this.handleChange} {...this.state} />;
    // }
    else if (title === "Finance") {
      return (
        <Finance
          handleChange={this.handleChange}
          checkboxChange={this.checkboxChange}
          {...this.state}
          fileRef={this.fileTemplate}
          templateName={this.state.templateName}
          onRemove={this.onRemoveTemplate}
        />
      );
    }
  };

  updateScom = (param) => {
    // let info =
    //   this.state[this.state.selectedEnrichedSimlarCommittee._id] &&
    //   this.state[this.state.selectedEnrichedSimlarCommittee._id]
    //     .enriched_information;
    // console.log(info, "checking id while updating escom");
    let {
      pincode,
      cdsco_approval_date,
      cdsco_validity_date,
      dhr_approval_date,
      dhr_validity_date,
      next_meeting_date,
      how_often_ec_meet,
      ec_existing_since,
      ec_composition_members,
      ec_policy,
      list_of_holidays,
      list_of_sops,
      ec_days_prior_dossier_submission,
      ec_issue_approval_post_meeting,
      conflict_of_interest_policy,
      ec_training,
      sae_review,
      ec_policy_effective_date,
      ec_policy_validity_date,
      review_checklist_process,
      ec_initial_review_fees,
      ec_amendment_review_fees,
      ec_final_review_process,
      mode_of_payment,
      payee_name,
      ec_gst,
      ec_tds,
      submission_process_type,
      submission_process_online_description,
      process_of_ec_submission,
      is_online_based,
      is_paper_based,
      submission_process_paper_based_copies,
      parent_institute_name,
      parent_registration_number,
      finance,
      ec_title_of_policy,
      timeline_for_ec_dossier_submission,
      approval_for_ec_and_scientific_timeline,
      interim_progress_report_timeline,
      timeline_for_expedite_review_and_approval,
      document_storage_facility,
      fire_extingusher,
      emergency_alarm,
      frequency_of_ec_site_monitoring,
      primary_contact_name,
      primary_contact_email,
      primary_contact_contact_number,
      alternative_contact_name,
      alternative_contact_email,
      alternative_contact_contact_number,
    } = this.state;
    let info = {
      id: this.state.enriched_id,
      committee_type: this.state.similarCommittee.committee && this.state.similarCommittee.committee.type,
      committee_id: this.state.similarCommittee.committee && this.state.similarCommittee.committee._id,
      committee_registered_number: this.state.similarCommittee.committee && this.state.similarCommittee.committee.registered_number,
      committee: this.state.similarCommittee.committee,
      updated_by: this.props.user._id,
      pincode: pincode,
      cdsco_approval_date: cdsco_approval_date,
      cdsco_validity_date: cdsco_validity_date,
      dhr_approval_date: dhr_approval_date,
      dhr_validity_date: dhr_validity_date,
      next_meeting_date: next_meeting_date,
      how_often_ec_meet: how_often_ec_meet,
      ec_existing_since: ec_existing_since,
      list_of_holidays: list_of_holidays,
      ec_composition_EC: ec_composition_members,
      ec_days_prior_dossier_submission: ec_days_prior_dossier_submission,
      ec_issue_approval_post_meeting: ec_issue_approval_post_meeting,
      list_of_sops: list_of_sops,
      conflict_of_interest_policy: conflict_of_interest_policy,
      ec_training: ec_training,
      ec_policy : ec_policy,
      process_of_ec_submission: process_of_ec_submission,
      submission_process: "",
      ec_title_of_policy: ec_title_of_policy,
      is_paper_based: is_paper_based,
      is_online_based: is_online_based,
      submission_process_paper_based_copies:
        submission_process_paper_based_copies,
      submission_process_online_description:
        submission_process_online_description,
      submission_review_check_list: [],
      review_checklist_process: review_checklist_process,
      ec_initial_review_fees: ec_initial_review_fees,
      ec_amendment_review_fees: ec_amendment_review_fees,
      ec_final_review_process: ec_final_review_process,
      mode_of_payment: mode_of_payment,
      payee_name: payee_name,
      ec_gst: ec_gst,
      ec_tds: ec_tds,
      submission_process_type: submission_process_type,
      ec_submission_forms: [],
      process_for_sae_review: sae_review,
      ec_policy_effective_date: ec_policy_effective_date,
      ec_policy_validity_date: ec_policy_validity_date,
      finance: finance,
      frequency_of_ec_site_monitoring: frequency_of_ec_site_monitoring,
      timeline_for_ec_dossier_submission: timeline_for_ec_dossier_submission,
      approval_for_ec_and_scientific_timeline:
        approval_for_ec_and_scientific_timeline,
      interim_progress_report_timeline: interim_progress_report_timeline,
      timeline_for_expedite_review_and_approval:
        timeline_for_expedite_review_and_approval,
      document_storage_facility: document_storage_facility,
      fire_extingusher: fire_extingusher,
      emergency_alarm: emergency_alarm,
      parent_institute_name: parent_institute_name,
      parent_registration_number: parent_registration_number,
      primary_contact: {
        name: primary_contact_name,
        email: primary_contact_email,
        contact_number: primary_contact_contact_number,
      },
      alternative_contact: {
        name: alternative_contact_name,
        email: alternative_contact_email,
        contact_number: alternative_contact_contact_number,
      },
    };
    let enriched_id = this.state.enriched_id;
    if((enriched_id !== "") || (enriched_id !== undefined)){
      updatedSimilarCommittee(
        this.state.similarCommittee &&
          this.state.similarCommittee._id,
        enriched_id,
        { enriched_information: info }
      )
        .then((updatedScom) => {
          console.log(updatedScom, "updated similar committee");
          if(param && param.length > 0){
            window.location.href = `/${param}/` + this.state.id;
          }else{
            alert("Committee details updated successfully");
          }
        })
        .catch((errUpdatedScom) => {
          console.log(
            errUpdatedScom,
            "err while updating similar committee with enriched info"
          );
          alert("Error with updating committee details");
        });
    } else{
      console.log(enriched_id, 'cannot get main enriched id');
      alert("Error while updating enriched information of similar committee")
    }
  };

clear_fields = () =>{
  this.setState({
     pincode :  "",
     cdsco_approval_date :  "",
     cdsco_validity_date :  "",
     similarcommittees :  "",
     ec_policy :  "",
     dhr_approval_date :  "",
     dhr_validity_date :  "",
     next_meeting_date :  "",
     how_often_ec_meet :  "",
     ec_existing_since :  "",
     ec_title_of_policy :  "",
     conflict_of_interest_policy :  "",
     list_of_holidays :  "",
     ec_composition_members :  [],
     ec_composition_EC : [], 
     ec_days_prior_dossier_submission :  "",
     ec_issue_approval_post_meeting :  "",
     list_of_sops : [],
     ec_training : [],
     process_of_ec_submission :  "",
     submission_process :  "",
     is_paper_based :  "",
     is_online_based :  "",
     submission_process_paper_based_copies :  "",
     submission_process_online_description :  "",
     submission_review_check_list : [],
     ec_submission_forms : [],
     review_checklist_process :  "",
     ec_initial_review_fees :  "",
     ec_amendment_review_fees :  "",
     ec_final_review_process :  "",
     mode_of_payment :  "",
     payee_name :  "",
     ec_gst :  "",
     ec_tds :  "",
     submission_process_type :  "",
     sae_review :  "",
     finance :  "",
     frequency_of_ec_site_monitoring :  "",
     timeline_for_ec_dossier_submission :  "",
     approval_for_ec_and_scientific_timeline :  "",
     interim_progress_report_timeline :  "",
     timeline_for_expedite_review_and_approval :  "",
     document_storage_facility :  "",
     fire_extingusher :  "",
     emergency_alarm :  "",
     parent_institute_name :  "",
     parent_registration_number :  "",
     primary_contact_name :  "",
     primary_contact_email :  "",
     primary_contact_contact_number :  "",
     alternative_contact_name :  "",
     alternative_contact_email :  "",
     alternative_contact_contact_number :  "",
  })
}

  continuation_status = (status) =>{
    console.log(status,'status received')
    this.setState({ ask_to_continue_einfo : status},()=>{
      if(status === false){
        this.clear_fields()
      }else{
        console.log("not clearing info");
        getEnrichedInfoFromId(this.state.enriched_id).then((foundInfo) => {
          console.log(foundInfo,' found enriched info with id')
        let {
          pincode,
          cdsco_approval_date,
          cdsco_validity_date,
          dhr_approval_date,
          dhr_validity_date,
          next_meeting_date,
          how_often_ec_meet,
          ec_existing_since,
          list_of_holidays,
          ec_composition_EC,
          ec_policy,
          list_of_sops,
          ec_training,
          ec_days_prior_dossier_submission,
          ec_issue_approval_post_meeting,
          process_of_ec_submission,
          process_for_sae_review,
          review_checklist_process,
          ec_initial_review_fees,
          ec_amendment_review_fees,
          ec_final_review_process,
          mode_of_payment,
          payee_name,
          ec_gst,
          ec_tds,
          submission_process_type,
          is_online_based,
          is_paper_based,
          submission_process_paper_based_copies,
          submission_process_online_description,
          parent_institute_name,
          parent_registration_number,
          finance,
          timeline_for_ec_dossier_submission,
          approval_for_ec_and_scientific_timeline,
          interim_progress_report_timeline,
          timeline_for_expedite_review_and_approval,
          document_storage_facility,
          fire_extingusher,
          emergency_alarm,
          frequency_of_ec_site_monitoring,
          primary_contact,
          alternative_contact,
          similar_committees,
        } = foundInfo;
        this.setState(
          {
            pincode: pincode,
            cdsco_approval_date: cdsco_approval_date,
            cdsco_validity_date: cdsco_validity_date,
            similarcommittees: similar_committees,
            ec_policy: ec_policy,
            dhr_approval_date: dhr_approval_date,
            dhr_validity_date: dhr_validity_date,
            next_meeting_date: next_meeting_date,
            how_often_ec_meet: how_often_ec_meet,
            ec_existing_since: ec_existing_since,
            list_of_holidays: list_of_holidays,
            ec_composition_members: ec_composition_EC,
            ec_composition_EC: ec_composition_EC.length,
            ec_days_prior_dossier_submission:
              ec_days_prior_dossier_submission,
            ec_issue_approval_post_meeting: ec_issue_approval_post_meeting,
            list_of_sops: list_of_sops,
            ec_training: ec_training,
            process_of_ec_submission: process_of_ec_submission,
            submission_process: "",
            is_paper_based: is_paper_based,
            is_online_based: is_online_based,
            submission_process_paper_based_copies:
              submission_process_paper_based_copies,
            submission_process_online_description:
              submission_process_online_description,
            submission_review_check_list: [],
            ec_submission_forms: [],
            review_checklist_process: review_checklist_process,
            ec_initial_review_fees: ec_initial_review_fees,
            ec_amendment_review_fees: ec_amendment_review_fees,
            ec_final_review_process: ec_final_review_process,
            mode_of_payment: mode_of_payment,
            payee_name: payee_name,
            ec_gst: ec_gst,
            ec_tds: ec_tds,
            submission_process_type: submission_process_type,
            sae_review: process_for_sae_review,
            finance: finance,
            frequency_of_ec_site_monitoring:
              frequency_of_ec_site_monitoring,
            timeline_for_ec_dossier_submission:
              timeline_for_ec_dossier_submission,
            approval_for_ec_and_scientific_timeline:
              approval_for_ec_and_scientific_timeline,
            interim_progress_report_timeline:
              interim_progress_report_timeline,
            timeline_for_expedite_review_and_approval:
              timeline_for_expedite_review_and_approval,
            document_storage_facility: document_storage_facility,
            fire_extingusher: fire_extingusher,
            emergency_alarm: emergency_alarm,
            parent_institute_name: parent_institute_name,
            parent_registration_number: parent_registration_number,
            primary_contact_name: primary_contact.name,
            primary_contact_email: primary_contact.email,
            primary_contact_contact_number: primary_contact.contact_number,
            alternative_contact_name: alternative_contact.name,
            alternative_contact_email: alternative_contact.email,
            alternative_contact_contact_number:
              alternative_contact.contact_number,
          },
          () => {
            let members = ec_composition_EC;
            this.splitMembers(members);
            this.splitMembers(ec_training);
            this.splitMembers(ec_policy);
            this.splitRowMembers(ec_training);

            // if(sessionData === null){
            //   console.log(sessionData,'no session data');
            // } else{
            //   console.log(sessionData, 'session data');
            //   this.setSessionData(sessionData)
            // }
          }
        );
      }).catch(errEnriched=>{
        console.log(errEnriched,' err while fetching enriched info')
      })
      }
    })
  }

  render() {
    const {
      ec_composition_members,
      hideAdd,
      editing_member_id,
      fieldTitle,
      ask_to_continue_einfo,
      similarCommittee,
    } = this.state;
    const { user } = this.props;
    return (
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 30%)",
        }}
        className="px-5 py-3 mb-5 pb-5"
      >
        <div className="d-flex align-items-center justify-content-between col-lg-7">
          <p
            style={{
              color: "#BC5405",
              cursor: "pointer",
              fontWeight: "bolder",
              textTransform: "uppercase",
            }}
            className="mb-0 col-lg-1 mx-0 px-0"
            onClick={() =>
              (window.location.href = `/addinfo/${user.others[0].ethics_committee._id}`)
            }
          >
            Back
          </p>
          <h3 className="mx-5">Similar Committee Details</h3>
          {/* <button
            onClick={() =>this.state.similarCommittee && this.state.similarCommittee !== null ? this.addSimilarComInfo() : this.addInfo()}
            className="btn btn-sm login mx-2"
          >
            SUBMIT
          </button> */}
        </div>
        {this.state.similarCommittee !== null ||
        this.state.similarCommittee !== undefined ? (
          <div 
            // style={{ backgroundColor: "white" }}
           className="my-2">
            <p>
              {(similarCommittee &&
              similarCommittee.committee &&
              similarCommittee.committee.type === "naitik"
                ? similarCommittee &&
                  similarCommittee.committee &&
                  similarCommittee.committee.name_of_ethics_committee
                : similarCommittee &&
                  similarCommittee.committee &&
                  similarCommittee.committee.name_and_address) || "None"}
            </p>
            <p>
              Committee Type :{" "}
              {(similarCommittee &&
                similarCommittee.committee &&
                similarCommittee.committee.type === "reRegistered" &&
                "CDSCO Re-Registered") ||
                (similarCommittee &&
                  similarCommittee.committee &&
                  similarCommittee.committee.type === "Registered" &&
                  "CDSCO Registered") ||
                (similarCommittee &&
                  similarCommittee.committee &&
                  similarCommittee.committee.type === "naitik" &&
                  "Naitik")}
            </p>
            <div className="text-right">
              <button
                onClick={() => this.updateScom()}
                className="btn btn-sm login mx-2"
              >
                UPDATE
              </button>
            </div>
            <div>
              {
                ask_to_continue_einfo === "" ?
                  <div>
                      <h6>Would you like to continue the enriched information of main ethics committee?</h6>
                      <button onClick={()=>{
                        this.continuation_status(true);
                        }} className="btn btn-small btn-success">Yes</button>
                      <button onClick={()=>{
                        this.continuation_status(false);
                        }} className="btn btn-small btn-danger mx-5">No</button>
                  </div>
                  :
                fields.map((field, i) => (
                  <div key={i}>
                    <div
                      onClick={() =>
                        fieldTitle === field.title
                          ? this.setFieldTitle("")
                          : this.setFieldTitle(field.title)
                      }
                      style={{
                        backgroundColor: field.bgColor,
                        cursor: "pointer",
                      }}
                      className="col-lg-12 py-3 my-2 d-flex align-items-start justify-content-between"
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <img src={field.icon} alt={`${field} icon`}></img>
                        <h6 className="mx-4 mb-1">{field.title}</h6>
                      </div>
                      {fieldTitle === field.title ? (
                        <img
                          style={{ height: 4, width: 15 }}
                          className="mt-2"
                          src={minus}
                          alt="minus icon"
                        ></img>
                      ) : (
                        <img className="mt-1" src={plus} alt="plus icon"></img>
                      )}
                    </div>
                    {fieldTitle === field.title
                      ? this.collapseField(field.title)
                      : null}
                  </div>
                ))
              }
            </div>
          </div>
        ) : (
          <p>Error while fetching similar committee information</p>
        )}
      </div>
    );
  }
}

export default withRouter(EnrichScom);
