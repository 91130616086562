import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'
import { updatePassword } from '../helpers/api';

const ChangePassword = (props) =>{
    const [new_password , setNewPassword] = useState("");
    const [confirm_password , setConfirmPassword] = useState("");
    const [ id, setId ] = useState("");
    const [ exp_time, set_exp_time ] = useState("");
    const [ isValid, setIsValid ] = useState("");

    const { user , exp} = useParams()

    const handleChange = (e) =>{
        if(e.target.name === "new_password"){
            setNewPassword(e.target.value)
        }else if(e.target.name === "confirm_password"){
            setConfirmPassword(e.target.value)
        }
    }

    useEffect(()=>{
        let current = Date.now();
        setId(user)
        set_exp_time(parseInt(exp));
        console.log(parseInt(exp), 'result exp')
        if(parseInt(exp) > 0){
            let difference = parseInt(exp) - current;
            let result = difference/1000/60;
            if(result <= 15){
                console.log('valid', result);
                setIsValid(true)
            }else{
                console.log('time expired', result)
                setIsValid(false)
            }
        }else{
            console.log(exp, ' no exp found')
        }

    }, [])

    const checkForInputs = (arr) =>{
        let inputs = arr;
        if(inputs && inputs.length > 0){
            let check = inputs.filter(input => input === "" ||  input === " " ||  input === null ||  input === undefined);
            if(check && check.length > 0){
                return false
            } else{
                return true
            }
        } else{
            console.log(" no inputs received");
            alert("Error while receiving inputs")
        }
    }

    const onSubmit = () =>{
        if(checkForInputs([new_password , confirm_password]) === false){
            alert("Please enter required fields")
        } else{
            if(new_password === confirm_password){
                updatePassword({ userId : user , new_pwd : new_password }).then(update=>{
                    console.log(update, 'updated');
                    if(update === "password updated"){
                        alert("Password updated successfully");
                        window.location.href = "/"
                    } else if( update === "password update failed"  || update === "No user found" || update === "No user found"){
                        console.log(update, 'err updating');
                        alert("Error updating password")
                    }
                }).catch(errUpdate => {
                    console.log(errUpdate, 'err updating');
                    alert("Error updating password")
                })
            } else{
                console.log('passwords do not match');
                alert("Passwords do not match. Please try again")
            }
        }
    }

    return(
        <div className='my-5 py-5 container'>
            {isValid === true ? <div className='col-lg-8 mx-auto text-left'>
                <div className='my-2'>
                    <p className='mb-0'>New Password*</p>
                    <input className='form-control' type={"password"} name="new_password" value = {new_password} onChange={handleChange}></input>
                </div>
                <div className='my-2'>
                    <p className='mb-0'>Confirm Password*</p>
                    <input className='form-control' type={"password"} name="confirm_password" value = {confirm_password} onChange={handleChange}></input>
                </div>
                <button onClick={()=>onSubmit()} className='login btn btn-small'>submit</button>
            </div> : 
            <div>
                <h6>Reset link expired. Please request again</h6>
            </div>}
        </div>
    )
}

export default ChangePassword;