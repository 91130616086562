import React from "react";
import { upload } from "../../theme/icons";
const Finance = (props) => {
  const {
    handleChange,
    checkboxChange,
    templateName,
    fileRef,
    onRemove,
    ec_initial_review_fees,
    ec_amendment_review_fees,
    ec_final_review_process,
    mode_of_payment,
    payee_name,
    ec_gst,
    ec_tds,
    review_fees,
    ec_finance_account_detail,
    ec_agreement,
    finance,
  } = props;
  return (
    <div>
    <div className="row text-left">
      <div className="col-lg-6">
        <div className="row">
          {/* <div className="col-lg-6">
            <p>Review Fees</p>
            <select
              onChange={handleChange}
              name="review_fees"
              value={review_fees}
              type="text"
              placeholder="Review Fees"
              className="form-control"
            ></select>
          </div>
          <div className="col-lg-6">
            <p>Account Detail of EC</p>
            <input
              onChange={handleChange}
              name="ec_finance_account_detail"
              value={ec_finance_account_detail}
              type="text"
              placeholder="Account Detail"
              className="form-control"
            ></input>
          </div> */}
          <div className="col-lg-4">
            <p>Initial Review Fees</p>
            <input
              onChange={handleChange}
              name="ec_initial_review_fees"
              value={ec_initial_review_fees}
              type="text"
              placeholder="Initial Review Fees"
              className="form-control"
            ></input>
          </div>
          <div className="col-lg-4">
            <p>Amendment Review Fees</p>
            <input
              onChange={handleChange}
              name="ec_amendment_review_fees"
              value={ec_amendment_review_fees}
              type="text"
              placeholder="Amendment Review Fees"
              className="form-control"
            ></input>
          </div>
          <div className="col-lg-4">
            <p>Annual Review Fees</p>
            <input
              onChange={handleChange}
              name="ec_final_review_process"
              value={ec_final_review_process}
              type="text"
              placeholder="Final Review Fees"
              className="form-control"
            ></input>
          </div>
        </div>
        {/* <p>Contractual Process</p>
        <p className="mb-0">Any agreement required with client?</p>
        <div className="d-flex align-items-center justify-content-start">
          <div className="d-flex align-items-center justify-content-center mx-2">
            <input
              onChange={checkboxChange}
              name="ec_agreement"
              value={ec_agreement}
              value="Yes"
              type="radio"
              className="mt-2 mr-2"
            ></input>
            <p className="mb-0 mt-2">Yes</p>
          </div>
          <div className="d-flex align-items-center justify-content-center mx-2">
            <input
              onChange={checkboxChange}
              name="ec_agreement"
              value={ec_agreement}
              value="No"
              type="radio"
              className="mt-2 mr-2"
            ></input>
            <p className="mb-0 mt-2">No</p>
          </div>
        </div> */}
      </div>
      <div className="col-lg-6">
        <div>
          <p className="mb-0">Process for payment</p>
          <textarea
            onChange={handleChange}
            name="finance"
            value={finance}
            placeholder="Enter description of process"
            rows={2}
            className="form-control my-2"
          ></textarea>
        </div>
        {/* <p className="mb-0">
          If yes then upload any template of EC or client template
        </p>
        <div>
          <input
            style={{ display: "none" }}
            ref={fileRef}
            type="file"
            onChange={handleChange}
            class="custom-file-input"
            id="ec_template"
            name="ec_template"
          ></input>
          <div className="d-flex align-items-center justify-content-start">
            <div
              style={{ height: 35, backgroundColor: "white" }}
              className="col-lg-9 border border-dark d-flex align-items-center justify-content-between"
            >
              <p className="col-lg-9 pt-1 mb-0 px-0">
                File Name: {templateName}
              </p>
              {templateName && templateName.length > 0 ? (
                <h4
                  style={{ color: "darkred" }}
                  onClick={() => onRemove()}
                  className="mb-0"
                >
                  X
                </h4>
              ) : null}
            </div>
            <label
              class="border border-dark px-2 py-1 mx-2 mt-2"
              for="ec_template"
            >
              <img src={upload} alt="upload icon"></img>
            </label>
          </div>
        </div> */}
      </div>
    </div>
    <div className="row text-left">
          <div className="col-lg-3">
            <p>Mode of payment</p>
            <select
              onChange={handleChange}
              name="mode_of_payment"
              value={mode_of_payment}
              type="text"
              placeholder="Payment Mode"
              className="form-control"
            >
              <option>Please select</option>
              <option>Cheque</option>
              <option>Online</option>
            </select>
          </div>
          <div className="col-lg-3">
            <p>Payee Name</p>
            <input
              onChange={handleChange}
              name="payee_name"
              value={payee_name}
              type="text"
              placeholder="Name of the Payee"
              className="form-control"
            ></input>
          </div>
          <div className="col-lg-3">
            <p>GST</p>
            <input
              onChange={handleChange}
              name="ec_gst"
              value={ec_gst}
              type="text"
              placeholder="GST in %"
              className="form-control"
            ></input>
          </div>
          <div className="col-lg-3">
            <p>TDS</p>
            <select
              onChange={handleChange}
              name="ec_tds"
              value={ec_tds}
              type="text"
              placeholder="TDS"
              className="form-control"
            >
              <option>Please select</option>
              <option>Including</option>
              <option>Excluding</option>
            </select>
          </div>
        </div>
    </div>
  );
};
export default Finance;
