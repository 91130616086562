import React from "react";
const Authority = (props) => {
  const { handleChange, parent_institute_name, parent_registration_number } =
    props;
  return (
    <div className="row text-left">
      <div className="col-lg-8">
        <p className="mb-0 my-2 mb-3">Name and address of the Parent Institute</p>
        <input
          onChange={handleChange}
          name="parent_institute_name"
          value={parent_institute_name}
          type="text"
          placeholder="Institute Name"
          className="form-control"
        ></input>
        {/* <select
          onChange={handleChange}
          name="parent_institute_name"
          value={parent_institute_name}
          type="text"
          placeholder="Institute Name"
          className="form-control"
        >
          <option value="">
            Please Select
          </option>
          <option value="Institutional Ethics Committee">
            Institutional Ethics Committee
          </option>
          <option value="Independant Ethics Committee (IEC)">
            Independant Ethics Committee (IEC)
          </option>
        </select> */}
      </div>
      <div className="col-lg-4">
        <p className="mb-0 my-2 mb-3">Registration Number</p>
        <input
          onChange={handleChange}
          name="parent_registration_number"
          value={parent_registration_number}
          type="text"
          placeholder="Number"
          className="form-control"
        ></input>
      </div>
    </div>
  );
};
export default Authority;
