import React from "react";
import { ethicsCommitteeRegister } from "../../helpers/api";
import { encrypt, validateEmail, getCurrentUser, sendUpdate } from "../../helpers/helper";
import {
  Logo,
  ecRegisterUser,
  ecRegisterAddress,
  ecRegisterPhone,
  ecRegisterSecretary,
  Email,
  passwordLock,
  IR_Icon,
  IR_institute,
} from "../../theme/icons";
const registerFields = [
  {
    field: "Name*",
    fieldIcon: ecRegisterUser,
    bgColor: "#A8CDB2",
    type: "text",
  },
  {
    field: "Mail ID*",
    fieldIcon: Email,
    bgColor: "#EAE79B",
    type: "text",
  },
  {
    field: "Password*",
    fieldIcon: passwordLock,
    bgColor: "#D9B7E9",
    type: "password",
  },
  {
    field: "Phone Number",
    fieldIcon: ecRegisterPhone,
    bgColor: "#EDD2A9",
    type: "text",
  },
  {
    field: "Designation",
    fieldIcon: ecRegisterSecretary,
    bgColor: "#D7ACD0",
    type: "text",
  },
  {
    field: "Address",
    fieldIcon: ecRegisterAddress,
    bgColor: "#9BCDEA",
    type: "text",
  },
  {
    field: "Affiliated Inst/ Org (If applicable)",
    fieldIcon: IR_institute,
    bgColor: "#A1A3C8",
    type: "text",
  },
];

class IndependentResearcher extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showEmailError: false,
    };
  }
  onNavigateBack = () => {
    window.location.href = "/";
  };
  handleChange = (e) => {
    console.log(e.target.value, "input");
    if (e.target.name === "Password*") {
      this.setState({ "Password*": encrypt(e.target.value) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  handleMailChange = (e) => {
    this.setState({ "Mail ID*": e.target.value }, () => {
      let checkEmail = validateEmail(e.target.value);
      if (checkEmail === false) {
        this.setState({ showEmailError: true });
      } else{
        this.setState({showEmailError:false})
      }
    });
  };
  next = () => {
    let check_empty_values = [
      this.state["Name*"],
      this.state["Mail ID*"],
      this.state["Password*"],
    ];
    console.log(check_empty_values,'check for empty values')
    let check = check_empty_values.filter(
      (item) => item === "" || item === " " || item === null || item === undefined
    );
    console.log(check,'check for empty values length')
    if (check && check.length > 0) {
      alert("Please enter all required fields");
    } else {
    let req_body = {
      username: this.state["Name*"],
      email: this.state["Mail ID*"],
      password: this.state["Password*"],
      phone: this.state["Phone Number"],
      ec_designation: this.state["Designation"],
      address: this.state["Address"],
      ec_affillated_institute:
        this.state["Affiliated Inst/ Org (If applicable)"],
      isEmailVerified: false,
      isSocialMediaUser: false,
      socialMediaProvider: "none",
      role: "ec_independent_researcher",
    };
    console.log(req_body, "data");
    ethicsCommitteeRegister(req_body)
      .then((ecRegistered) => {
        console.log(
          ecRegistered,
          "ec independent researcher registered successfully"
        );
        if (
          ecRegistered &&
          ecRegistered.error === false &&
          ecRegistered.message ===
            "ec coordinator/researcher registration success"
        ) {
          alert("EC independent researcher registered successfully");
          let update = {
            active: false,
            title: `New user registered`,
            update: {
              type: "new_user_created",
              role : "ec_independent_researcher",
              user_name : ecRegistered.output.username,
              user_email : ecRegistered.output.email,
              item: `${ecRegistered.output._id}`,
            },
            description: `${ecRegistered.output.username} user registered`,
            from: [getCurrentUser(ecRegistered.output)],
            to: [],
            isRead: false,
          };
          console.log(update, 'checking update')
          sendUpdate(update);
          window.location.href = "/";
        }
      })
      .catch((errEcRegistered) => {
        console.log(
          errEcRegistered,
          "err with ec independent researcher registration"
        );
        alert("Error occured while registering EC independent researcher");
        window.location.href = "/register/researcher";
      });
    }
  };

  registerField = ({ fieldIcon, field, bgColor, type, input }) => {
    //console.log(fieldIcon, field, bgColor, type, input);
    return (
      <div>
        <div
          style={{
            backgroundColor: "white",
            border: "1.5px solid #646464",
            borderRadius: 5,
          }}
          className="col-lg-11 input-group input-group-lg p-0 my-2"
        >
          <img
            style={{
              height: 39,
              width: 39,
              backgroundColor: bgColor,
              padding: 7,
              borderRadius: 3,
            }}
            src={fieldIcon}
            alt="email"
            className="img-responsive m-1"
          ></img>
          <input
            onChange={field === "Mail ID*" ? this.handleMailChange : this.handleChange}
            className="form-control input-lg"
            style={{
              border: "none",
              outline: "none",
              boxSizing: "border-box",
            }}
            type={type}
            name={field}
            placeholder={field}
          ></input>
        </div>
        {field === "Mail ID*" && this.state.showEmailError === true ? (
          <p style={{ color: "darkred" }} className="mb-0">
            Please enter valid email
          </p>
        ) : null}
      </div>
    );
  };
  render() {
    return (
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(131, 219, 247, 0.2) 0%, rgba(196, 196, 196, 0) 100%)",
        }}
      >
        <div className="container">
          <div className="mx-auto text-center">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => this.onNavigateBack()}
              className="img-responsive mt-5 new_logo"
              src={Logo}
              alt="logo"
            ></img>
            <h5 style={{ color: "#BC5405" }} className="mb-0 ml-4">
              Ethical Committees
            </h5>
          </div>
          <div className="row px-0 mt-5 mb-5">
            <div className="col-lg-6">
              <p
                style={{
                  color: "#BC5405",
                  cursor: "pointer",
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                }}
                className="mb-0"
                onClick={() => this.onNavigateBack()}
              >
                Back
              </p>
              <div className="col-lg-10 p-0 m-0 mt-5">
                <img
                  className=""
                  style={{ height: 350 }}
                  src={IR_Icon}
                  alt="login"
                ></img>
                <div
                  style={{
                    height: 400,
                    background: "rgba(131, 219, 247, 0.5)",
                    borderRadius: 250,
                    position: "absolute",
                    inset: "-18px 0px 0px 25px",
                    zIndex: -10,
                  }}
                  className="circle"
                ></div>
              </div>
            </div>
            <div className="col-lg-6">
              <h4>Sign Up to Register</h4>
              <p style={{ fontWeight: "bold" }} className="mb-0">
                Independent Researcher / CRO Registration
              </p>
              {registerFields.map((registerField) =>
                this.registerField({ ...registerField })
              )}
              <button
                onClick={() => this.next()}
                className="btn btn-lg login col-lg-11"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndependentResearcher;
